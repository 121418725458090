<script>
import draggable from "vuedraggable";
import Datepicker from "vuejs-datepicker";
import selectSender from "../../components/form/selectSender.vue";
import redactor from "../../components/form/redactor.vue";
import filepicker from "../../components/create/filepicker.vue";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'


export default {
  name: "SendNewVersion",
  props: {
    proof: {
      required: true,
      type: Object,
    },
    curUser: {},
  },
  components: {
    draggable,
    Datepicker,
    selectSender,
    redactor,
    filepicker,
    Treeselect
  },
  data() {
    return {
      proofName: "",
      templates: [],
      proofVersionFileIdsToPromoteToTheNextVersion: [],
      curTemplate: 0,
      sendToWorkflowStageId: 0,
      sendOptions: 0,
      proofWithAllVersions: null,
      loadingData: false,
      working: 0,
      subjectLine: "",
    };
  },
  async mounted() {
  },
  computed: {
    proofVersionFileIdsToPromoteToTheNextVersionOptions: function () {
      if (this.proofWithAllVersions != null
          && this.proofWithAllVersions.id > 0
          && this.proofWithAllVersions.versions !== undefined
          && this.proofWithAllVersions.versions !== null
          && this.proofWithAllVersions.versions.length > 0) {

        let files = this.proofWithAllVersions.versions.map(v => {
          return {
            id: 'v' + v.versionNumber,
            label: 'Version ' + v.versionNumber,
            children: v.versionFiles.map(f => {
              return {
                "id": 'v' + v.versionNumber + ':vf' + f.id,
                "label": f.name
              }
            })
          }
        });

        return files;
        //"label": 'Version '+this.$props.proof.currentVersion.versionNumber +" - " + f.name

        // return files.length>0?[{
        //   id: 'v'+this.$props.proof.currentVersion.versionNumber,
        //   label:'Version '+this.$props.proof.currentVersion.versionNumber,
        //   children:files
        // }]:[]
      } else {
        return [];
      }
    },
    commentFileApi: function () {
      if (this.proof) {
        const apiToken = _.get(this, 'curUser.apiToken.token', "")
        let apiUrl = `/annotation/comment-file/${this.proof.publicId}?u=${apiToken}`;
        return apiUrl;
      }
      return "";
    },
  },
  methods: {
    sendNewVersionAndClose: async function () {
      this.working = 1;
      if (this.$refs.filePicker.GetFileStatus().length === 0 &&
          this.proofVersionFileIdsToPromoteToTheNextVersion.length === 0
      ) {
        this.$A.AlertUser(
            "Please Select Files To Upload Or Select Files From The Previous Version",
            "warning",
            10000);
        this.working = 0;
        return;
      }
      let proof = this.$props.proof;

      let versionsBeingPromoted = [];

      // console.log(this.proofVersionFileIdsToPromoteToTheNextVersion)

      if (Array.isArray(this.proofVersionFileIdsToPromoteToTheNextVersion)) {
        //ignore dupe ids
        for (let i = 0; i < this.proofVersionFileIdsToPromoteToTheNextVersion.length; i++) {
          let selection = this.proofVersionFileIdsToPromoteToTheNextVersion[i];
          // console.log(selection,selection.indexOf('vf'),isNaN(selection))
          if (isNaN(selection) && selection.indexOf('vf') !== -1) {
            let parts = selection.split(':');
            let versionFileId = Number.parseInt(parts[1].replace(/vf/, ''));
            if (versionsBeingPromoted.indexOf(versionFileId) === -1 && versionFileId > 0) {
              versionsBeingPromoted.push(versionFileId);
            }
          } else {
            //is version all add all files
            let versionNumber = Number.parseInt(selection.replace(/v/, ''));
            let version = this.proofWithAllVersions.versions.find(v => v.versionNumber === versionNumber)
            if (version === undefined)
              console.log("version was not found ", versionNumber, this.proofWithAllVersions.versions)

            let versionFileIdsToAdd = version.versionFiles.map(f => {
              return f.id
            });
            versionFileIdsToAdd.forEach(id => {
              if (versionsBeingPromoted.indexOf(id) === -1 && id > 0) {
                versionsBeingPromoted.push(id);
              }
            });
          }
        }
      } else {
        if (isNaN(this.proofVersionFileIdsToPromoteToTheNextVersion)
            && this.proofVersionFileIdsToPromoteToTheNextVersion.indexOf('vf') !== -1) {
          let splits = this.proofVersionFileIdsToPromoteToTheNextVersion.split(':');
          // console.log(this.proofVersionFileIdsToPromoteToTheNextVersion)
          // console.log(splits)
          let selection = splits[1];
          let versionFileId = Number.parseInt(selection.replace(/vf/, ''));
          if (versionsBeingPromoted.indexOf(versionFileId) === -1 && versionFileId > 0) {
            versionsBeingPromoted.push(versionFileId);
          }
        }
      }


      let newVersion = await this.$A.ProofService.CreateNewVersion({
        senderUserId: this.$refs.selectSender.getSender(),
        proofId: proof.id,
        proofName: this.proofName,
        subjectLine: this.subjectLine,
        message: this.$refs.redactor.getTextBody(),
        proofVersionFileIdsToPromoteToTheNextVersion: versionsBeingPromoted,
        templateId:
            this.sendToWorkflowStageId !== this.curTemplate
                ? this.curTemplate
                : 0,
        sendToWorkflowStageId: this.sendToWorkflowStageId,
        sendOptions: this.sendOptions,
      });

      if (!newVersion || !newVersion.id) {
        this.$A.AlertUser(
            "Something Went Wrong, Please Try Again Later",
            "warning",
            10000
        );
        this.working = 0;
        return;
      }

      this.$A.AlertUser("Uploading Files. Please Wait...", "success", 10000);

      await this.$refs.filePicker.UploadFiles(proof.id, newVersion.id);

      if (
          this.$refs.filePicker
              .GetFileStatus()
              .filter((f) => f.uploadProgress === 100).length !==
          this.$refs.filePicker.GetFileStatus().length
      ) {
        this.$A.AlertUser(
            "There Was A Problem Uploading Your Files, Please Try Again Later",
            "warning",
            10000
        );
        this.working = 0;
        return;
      }

      await this.$A.ProofService.StartWorkflowForProofVersion(proof.id);

      this.$root.$emit("proof::refresh", {});

      this.$bvModal.hide("SendNewVersion");
      this.working = 0;
      this.proofVersionFileIdsToPromoteToTheNextVersion = [];
    },
    getTemplates: async function () {
      this.templates = await this.$A.TemplateService.GetAll();
      let curStage = {
        name: "Stage Selected's Template",
        id: 0,
        html: "",
        subject: "",
      };
      this.templates.push(curStage);
    },
    getAllVersions: async function (proofId) {
      this.proofWithAllVersions = proofId && await this.$A.ProofService.GetFullProof(proofId, 0, true);
    },
    useTemplate: async function () {
      if (this.curTemplate === 0) {
        //use the cur stage selected's message and body
        await this.useCurStagesTemplate();
      } else {
        let selectedTemplate = this.templates.filter(
            (f) => f.id === this.curTemplate
        )[0];

        if (selectedTemplate && selectedTemplate.subject)
          this.subjectLine = selectedTemplate.subject;

        if (selectedTemplate && selectedTemplate.html)
          this.$refs.redactor.setTextBody(selectedTemplate.html);
      }
    },
    useCurStagesTemplate: async function () {
      let selectedStage = this.$props.proof.currentVersion.stages.filter(
          (s) => s.id === this.sendToWorkflowStageId
      );
      if (selectedStage.length > 0) {
        selectedStage = selectedStage[0];
        this.subjectLine = selectedStage.subjectLine;
        this.$refs.redactor.setTextBody(selectedStage.message);
      }
    },
    modalVisible: function () {
      let self = this;
      setTimeout(async function () {
        self.proofName = self.$props.proof.name;
        self.curTemplate = self.$props.proof.templateWorkflowId;
        self.sendToWorkflowStageId = self.$props.proof.currentWorkflowStageId;
        await self.getTemplates();
        await self.useTemplate();
        self.loadingData = true;
        await self.getAllVersions(self.$props.proof.id);
        self.loadingData = false;
        //load possible files into selector
        if (self.$props.proof.id > 0
            && self.$props.proof.currentVersion !== undefined
            && self.$props.proof.currentVersion !== null
            && self.$props.proof.currentVersion.versionFiles !== undefined
            && self.$props.proof.currentVersion.versionFiles !== null
            && self.$props.proof.currentVersion.versionFiles.length > 0) {
          // self.proofVersionFileIdsToPromoteToTheNextVersion =
          //     ['v'+self.$props.proof.currentVersion.versionNumber];
          //this.$props.proof.currentVersion.versionFiles.map(f => f.id);
        }
      }, 200);
    },
  },
};
</script>

<template>
  <!-- Send New Version Modal -->
  <b-modal id="SendNewVersion" ref="sendNewVersionModal" size="lg" scrollable @show="modalVisible">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <div class="header-text">Send New Version of Proof</div>
        <div class="sub-text">
          Approvers who are not currently marked as approved in this approval
          stage will receive this new version.
        </div>
      </div>
    </template>

    <form role="form" class="modal-form">
      <div class="mt-10">
        <div class="col-md-12 px-0">
          <label class="label-text">Proof Name:</label>
          <input
              name="name"
              type="text"
              placeholder="Proof Name"
              class="form-control modal-input"
              v-model="proofName"
          />
        </div>
      </div>
      <div class="mt-10">
        <div class="col-md-12 px-0">
          <label class="label-text">Send From:</label>
          <selectSender
              :SelectedSenderId="proof.currentVersion.sender.id"
              ref="selectSender"
          />
        </div>
      </div>
      <div class="mt-10">
        <div class="col-md-12 px-0">
          <div class="form-group">
            <label for="SendToWorkflowStageId" class="label-text">Send to Stage:
              <img
                  id="SendToWorkflowStageIdIcon"
                  src="/img/icon-help.svg"
                  style="height: 20px"
              />
              <b-tooltip target="SendToWorkflowStageIdIcon" triggers="hover">
                Whichever <b>stage</b> you select will become the active stage
                and receive the new version of this proof.
              </b-tooltip>
            </label>
            <select
                id="SendToWorkflowStageId"
                class="form-control modal-input"
                @change="useCurStagesTemplate()"
                v-model="sendToWorkflowStageId"
            >
              <option
                  :value="stage.id"
                  v-for="stage in proof.currentVersion.stages"
              >
                {{ stage.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="SendMode" class="label-text">Send Options:
              <img
                  id="SendModeIcon"
                  src="/img/icon-help.svg"
                  style="height: 20px"
              />
              <b-tooltip target="SendModeIcon" triggers="hover">
                <b>"Send To All On Stage"</b> means this version will be sent to
                all approvers regardless of their current status. <br/><br/>
                <b>"Send To Unapproved On Stage"</b> means this version will not
                be sent to approvers who have already approved the proof.
              </b-tooltip>
            </label>
            <select id="SendMode" class="form-control modal-input" v-model="sendOptions">
              <option value="0" selected>Send To All On Stage</option>
              <option value="1">Send To Unapproved On Stage</option>
              <option value="2">Send To None</option>
            </select>
          </div>
          <div class="form-group" v-show="sendOptions !== '2'">
            <label class="label-text">Email Templates:</label>
            <select
                class="form-control modal-input"
                @change="useTemplate()"
                v-model="curTemplate"
            >
              <option :value="template.id" v-for="template in templates">
                {{ template.name }}
              </option>
            </select>
          </div>
          <div class="form-group" v-show="sendOptions !== '2'">
            <input
                name="name"
                type="text"
                placeholder="Subject Line"
                class="form-control modal-input"
                v-model="subjectLine"
            />
          </div>
          <redactor ref="redactor"
                    v-show="sendOptions !== '2'"
                    :comment-api-url="commentFileApi"
          />
        </div>
      </div>
      <label for="FilesToPromote" class="label-text" style="margin-top: 10px">Include Files from Previous Version
        <img
            id="FilesToPromoteIcon"
            src="/img/icon-help.svg"
            style="height: 20px"
        />
        <b-tooltip target="FilesToPromoteIcon" triggers="hover">
          Optionally select files from your previous proof version to include in this new proof version.
        </b-tooltip>

        <b-spinner
            type="grow"
            label="Spinning"
            class="loading_data_spinner"
            v-show="loadingData === true"
        ></b-spinner>
      </label>

      <treeselect
          id="FilesToPromote"
          v-model="proofVersionFileIdsToPromoteToTheNextVersion"
          :multiple="true"
          :options="proofVersionFileIdsToPromoteToTheNextVersionOptions"
      />

      <label class="mt-25 label-text">Upload New Files</label>
      <filepicker
          ref="filePicker"
          :is-send-new-version-proof-modal="true"
      />
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            class="button gray-btn"
            @click="cancel()">
          Cancel
        </b-button
        >
        <b-button
            :disabled="working === 1"
            class="button green-btn"
            @click="sendNewVersionAndClose"
        >Send New Version
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.label-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
  margin-bottom: 4px;
}

.modal-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  height: 40px;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 10px !important;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

/deep/ .modal-body {
  padding: 0px !important;
}

::v-deep .modal-footer {
  border-top: none !important;
}

.modal-form {
  padding: 0px 24px 0px 24px !important
}

/deep/ .modal-header {
  padding: 24px 24px 0px 24px !important;
  margin-bottom: 20px;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  margin-bottom: 12px;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
  }

}

</style>