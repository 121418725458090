<script>
export default {
  name: "reportcards",
  async mounted() {
    this.reportcards = await this.$A.AccountService.AccountStats();
  },
  data() {
    return {
      reportcards: [],
    };
  },
};
</script>

<template>
  <!-- Report Cards -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-lg col-sm-12" v-for="card in reportcards">
        <div class="jumbotron report-card report-overview-card">
          <h1 class="card-number" v-html="card.number"></h1>
          <div class="report-card-description">{{ card.description }}</div>
          <span class="report-card-subtitle">{{ card.subTitle }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- /Report Cards -->
</template>

<style scoped>

.report-overview-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: white;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
  border-radius: 8px;
  margin-bottom: 24px !important;
}

.card-number {
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #575759;
}

.report-card-description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #575759;
}

.report-card-subtitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #707073;
}



</style>
