<script>


export default {
  components: {},
  name: "reviewScreenVersions",
  methods: {
    SaveReviewScreenVersion: async function () {
      try {
        await this.$A.AccountService.SetAccountReviewScreenTheme(this.reviewScreenTheme);
        _.set(this.$A, 'Auth.account.themeSettings.reviewScreenTheme', this.reviewScreenTheme);
        this.$A.SaveSessionData(this.$A.Auth);
        self.$A.AlertUser(
            "Review Screen Theme Saved",
            "success",
            10000
        );
      } catch (err) {
        self.$A.AlertUser(
            "Failed to save Review Screen Theme. Please, try again.",
            "warning",
            10000
        );
      }
    },
  },
  data() {
    return {
      reviewScreenTheme: 0,
      options: [
        {reviewScreenTheme: 10, text: "Modern"},
        {reviewScreenTheme: 0, text: "Minimal"},
      ],
    }
  },
  mounted() {
    this.reviewScreenTheme = _.get(this.$A, 'Auth.account.themeSettings.reviewScreenTheme', 0);
  },
  computed: {}
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="text-dark d-flex justify-content-center flex-md-wrap px-md-5 row">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10">
      <b-card-body class="mb-4">
        <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 pl-lg-3 text-dark on-mobile header-text">Review Screen Theme
        </b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 pl-lg-3 on-mobile description-text">Select the theme you’d like to use for
          your review screens.
        </b-card-text>
        <div class="card-text mx-md-3 pl-md-2 pl-lg-3 on-mobile">Theme</div>
        <!-- Select Review Screen Versions -->
        <div class="mx-md-3 pl-md-2 pl-lg-3 mt-2 font-weight-normal">
          <v-select v-model="reviewScreenTheme"
                    :clearable="false"
                    :options="options"
                    :reduce="option => option.reviewScreenTheme"
                    label="text">
          </v-select>
        </div>
      </b-card-body>
      <div class="style-footer">
        <div class="d-flex col-sm-6 col-md-2 pr-lg-0 ml-auto justify-content-sm-around justify-content-lg-end">
          <b-button
              class="button button-green button-style ml-2 mr-sm-4"
              @click="SaveReviewScreenVersion"
          >Save
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style scoped>

.button-style {
  padding: 8px !important;
  border-radius: 5px;
  font-weight: normal !important;
  display: block;
  font-weight: normal;
  background-color: #019256;
  width: 108px;
  height: 38px;
}

::v-deep .vs__selected {
  font-weight: normal;
}

::v-deep vs__selected-options {
  font-weight: normal;
}

::v-deep .card-body {
  padding: 0px;
}

.style-footer {
  padding: 12px 24px;
  background-color: #F4F4F6;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

@media (max-width: 500px) {
  .button-style {
    width: 100%;
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
    margin-right: 10px;
  }

  .on-mobile {
    padding-left: 18px;
  }
}

</style>
