<script>
import selectTags from "../form/selectTags.vue";
import tagFilter from "../../generated/model/TagFilter.js";

export default {
  name: "modalEditTagFilters",
  props: ["initalTagFilterId"],
  components: {
    selectTags,
  },
  data() {
    return {
      curTagFilterIdSelected: 0,
      tagFilters: [],
      tagFilter: {
        id: 0,
        name: "",
        tags: [],
      },
    };
  },
  async mounted() {
    this.tagFilters = await this.$A.TagsService.GetAllTagFilters();
  },
  methods: {
    saveTagFilter: async function () {
      if (this.tagFilter.name.length === 0) {
        this.$A.AlertUser("Please Set The Filter's Name");
        return;
      }
      if (this.$refs.selectTags.getSelectedTags().length < 2) {
        this.$A.AlertUser("Please Select At Least Two Tags");
        return;
      }
      await this.$A.TagsService.SaveTagFilter({
        id: this.tagFilter.id,
        name: this.tagFilter.name,
        tagIds: this.$refs.selectTags.getSelectedTags().map((t) => t.id),
      });
      this.tagFilters = await this.$A.TagsService.GetAllTagFilters();
      this.$root.$emit("tag::filters::reload", this.tagFilters);
      this.reset();
      // this.$bvModal.hide("ManageTagFilters");
    },
    deleteTagFilter: async function () {
      await this.$A.TagsService.DeleteTagFilter(this.tagFilter.id);
      this.tagFilter.name = "";
      this.tagFilter.id = 0;
      this.tagFilters = await this.$A.TagsService.GetAllTagFilters();
      this.$root.$emit("tag::filters::reload", this.tagFilters);
      this.reset();
      // this.$bvModal.hide("ManageTagFilters");
    },
    reset: function () {
      let newVal = {
        id: 0,
        name: "",
        tags: [],
      };
      this.tagFilter = newVal;
      this.$refs.selectTags.setSelectedTags(newVal.tags);
      this.curTagFilterIdSelected = 0;
    },
  },
  computed: {
    editOrCreateText: function () {
      return this.tagFilter.id > 0 ? "Update" : "Create";
    },
    editOrCreateSentence: function () {
      return this.tagFilter.id > 0 ? "Create a New" : "Update existing";
    },
  },
  watch: {
    initalTagFilterId: async function (newVal, oldValue) {
      this.tagFilter = newVal;
      this.$refs.selectTags.setSelectedTags(newVal.tags);
    },
    curTagFilterIdSelected: async function (newVal, oldValue) {
      if (newVal === "0" || newVal === 0) {
        newVal = {
          id: 0,
          name: "",
          tags: [],
        };
      } else {
        newVal = await this.$A.TagsService.GetTagFilter(newVal);
      }
      this.tagFilter = newVal;
      this.$refs.selectTags.setSelectedTags(newVal.tags);
    },
  },
};
</script>

<template>
  <div>
    <b-modal id="ManageTagFilters" size="lg" no-stacking>
      <template v-slot:modal-header>
        <alertHeader/>
        <div class="w-100">
          <div class="header-text">Manage Filter</div>
          <span class="sub-header-text">Select which tags to include in this filter. Rename or delete a filter
            here.</span>
        </div>
      </template>

      <form role="form">
        <div class="row">
          <div class="col-md-12">
            <label class="sub-text">Select Filter To Update</label>
            <select
                type="text"
                class="ti-new-tag-input ti-valid ti-input"
                v-model="curTagFilterIdSelected"
                style="padding-left: 13px"
            >
              <option value="0">Create New Tag Filter</option>
              <option :value="tagFilter.id" v-for="tagFilter in tagFilters">
                {{ tagFilter.name }}
              </option>
            </select>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-12">
            <label class="sub-text">{{ editOrCreateText }} Filter</label>
            <input
                type="text"
                class="ti-new-tag-input ti-valid ti-input"
                v-model="tagFilter.name"
                placeholder="Filter Label"
                style="padding-left: 13px"
            />
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-12">
            <label class="sub-text mb-0">Select Tags To Be Included In Filter</label>
            <selectTags ref="selectTags"/>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start align-items-center px-0">
            <b-button
                class="button green-btn"
                @click="saveTagFilter()"
            >
              {{ editOrCreateText }} Filter
            </b-button>
            <b-button
                class="red-btn ml-2"
                @click="deleteTagFilter()"
                v-show="tagFilter.id > 0"
            >Delete Filter
            </b-button
            >
          </div>
        </div>
      </form>

      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100 text-right d-flex justify-content-end">
          <b-button
              class="button gray-btn"
              @click="cancel()">
            Done
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<style scoped>

.ti-input {
  outline: none !important;
}


.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  width: 18%;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  color: white;
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  width: 18%;
}

.gray-btn:hover {
  color: white;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  margin-bottom: 12px;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.red-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  color: #EB4654;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #F9F9F9;
  border: 1px solid #EB4654;
}


@media screen and (max-width: 991px) {
  .green-btn {
    width: 30%;
  }
}

</style>
