<template>
  <div>
    <div class="login-page registration-page">
      <div class="container-fluid">
        <div class="row justify-content-md-center">
          <!-- Sidebar -->
          <div class="col-md-4">
            <div class="generic-sidebar text-center" style="max-height: 762px">
              <h4>Email Validated!</h4>
              <p class="mt-25">
                You can now use this email configuration to send emails from Ashore as your user.
              </p>
              <p class="mt-25">
                You're done! Great job! Feel free to close this tab.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.login-page {
  background-color: #019255;
}
</style>
