<script>
import createEmailTemplateItem from './../automation-archive/create-email-template-item'

export default {
  name: "templatelist",
  components: {
    createEmailTemplateItem
  },
  async mounted() {
    let self = this;

    await this.pullTemplates();

    this.$root.$on("duplicate::template::confirmed", async function () {
      await self.$A.TemplateService.Duplicate(self.duplicateThis);
      self.duplicateThis = null;
      await self.pullTemplates();
    });

    this.$root.$on("delete::template::confirmed", async function () {
      await self.$A.TemplateService.Delete(self.deleteThis);
      self.deleteThis = null;
      await self.pullTemplates();
    });

    this.$root.$on("template_item:canceled", function () {
      self.editThis = null;
    });

    this.$root.$on("email::template::saved", async function () {
      await self.pullTemplates();
    })
  },
  methods: {
    editTemplate: function (template) {
      this.editThis = template;
      this.$root.$emit("edit::template::email");
    },
    pullTemplates: async function () {
      this.templates = await this.$A.TemplateService.GetAll();
    },
    createEmailTemplate: function () {
      this.editThis = null;
      this.$root.$emit("create::template::email");
    }
  },
  computed: {
    sortedTemplates: function () {
      function compare(a, b) {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      }

      return this.templates.sort(compare);
    }
  },
  data() {
    return {
      templates: [],
      duplicateThis: null,
      deleteThis: null,
      editThis: null,
      fields: [
        {
          key: 'emailinfo',
          sortable: false,
          label: "EMAIL TEMPLATES"
        },
        {
          key: 'edit',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'duplicate',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'delete',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
      ],
      isMobile: window.innerWidth <= 767,
    };
  },
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10 account-template-card">
      <b-card-body>
        <b-card-text class="mb-2 mx-md-3 mt-4 pl-md-2 pl-sm-4 text-dark account-templates-header">Email Templates</b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 pl-sm-4 description-text mb-4">Create email templates that you can apply when
          sending
          messages or creating workflows.
        </b-card-text>
        <!-- Template Table -->
        <b-table
            :class="isMobile ? 'mt-3 px-4 rounded template-table table-borderless mb-0': 'mt-3 px-4 rounded template-table table-borderless mb-4'"
            :items="sortedTemplates"
            :fields="fields"
            responsive
            hover
            outlined
            thead-class="template-table-bg"
        >
          <template #head()="data">
            <span class="table-header-text">{{ data.label }}</span>
          </template>
          <template #cell(emailinfo)="data">
            <div class="row-cols-9">
              <span class="column-header-text">{{ data.item.name }}</span>
            </div>
          </template>
          <template #cell(edit)="data">
            <div>
              <a class="font-weight-normal edit-delete-template"
                 v-b-toggle.create-template-sidebar-right
                 @click="editTemplate(data.item)"
              >
                Edit
              </a>
            </div>
          </template>
          <template #cell(duplicate)="data">
            <div>
              <a class="font-weight-normal edit-delete-template"
                 @click="duplicateThis = data.item.id"
                 data-cy="duplicateEmailTemplate"
                 v-b-modal.duplicateTemplateModal
              >
                Duplicate
              </a>
            </div>
          </template>
          <template #cell(delete)="data">
            <div>
              <a class="font-weight-normal edit-delete-template"
                 @click="deleteThis = data.item.id"
                 data-cy="deleteEmailTemplate"
                 v-if="data.item.name !== 'Automatic Reminder'"
                 v-b-modal.deleteTemplateModal
              >
                Delete
              </a>
            </div>
          </template>
        </b-table>
        <!-- Modals -->
        <modal
            name="deleteTemplateModal"
            eventToEmitOnConfirmation="delete::template::confirmed"
            question="Delete This Template?"
            body="Are you sure you want to delete this template? You cannot undo this action."
            confirm-button-text="Yes, Delete"
        />
        <modal
            name="duplicateTemplateModal"
            eventToEmitOnConfirmation="duplicate::template::confirmed"
            question="Duplicate This Template?"
            ref="duplicateTemplate"
            confirm-button-text="Yes, Duplicate"
            body="Create a copy of this template."
        />
      </b-card-body>
      <div :class="isMobile ? 'w-100 px-4 py-3': 'border-top-0 d-flex mb-0 justify-content-end table-footer'">
        <div>
          <a class="button button-light button-green templates-btn mb-0" v-b-toggle.create-template-sidebar-right
             @click=createEmailTemplate()>Create
            Email Template</a>
          <!--  Create template Right Sidebar  -->
          <createEmailTemplateItem @saved="pullTemplates" :template-to-edit="{...editThis}"/>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style scoped>

/deep/ .table th, .table td {
  vertical-align: none;
}

.field-item h3 {
  font-size: 18px;
}

.template-button img {
  height: 15px;
  margin-left: 10px;
}

.account-template-card ::v-deep .card-body {
  padding: 0px;
}

.account-templates-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

/deep/ .template-table {
  border-radius: 4px !important;
}

::v-deep .template-table table thead th {
  border-bottom: 0px;
  padding: 12px 24px;
}

::v-deep .template-table table td {
  padding: 16px 24px !important;
  vertical-align: middle !important;
}

/deep/ .template-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.column-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.edit-delete-template {
  color: #006F41;
}

.edit-delete-template:hover {
  cursor: pointer;
}

.templates-btn {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.table-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  color: #575759;
}



@media (max-width: 576px) {

  .button-locked-templates {
    background: #F44271;
    color: #ffffff;
    transition: all .5s ease;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .templates-btn {
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .account-templates-header {
    margin-left: 20px;
  }

  .description-text {
    margin-right: 20px;
    margin-left: 20px;
    text-align: justify;
  }

}

</style>