<template>
  <div>
    <navigation></navigation>
    <div id="exportCaptureDiv" class="container-fluid" v-show="proof.id > 0">
      <div class="row">
        <sidebarproofitem :proof.sync="proof" :user="user" :teams="teams" v-on:exportTimeline="exportPdf($event)"/>
        <create-workflow-item :input-proof="proof"
                              ref="workflowSidebar"
        />
        <div class="col-xl-8 col-xxl-9">
          <div class="row">
            <!-- Workflow -->
            <div class="col-md-12 px-0 proof-item-margin" id="proof_stages">
              <approvers :proof="proof" :active-ids="activeUserIds"/>
            </div>
          </div>
          <timeline :all-users="allUsers" :proof="proof"/>
        </div>
      </div>
    </div>
    <!--  <copyright /> -->
    <confirmationModal
        body=""
        question=""
        confirm-button-text=""
        event-to-emit-on-confirmation=""
        name=""
        button-css=""
    />
    <modalEditContact/>
    <modalAddNote :proof="proof" :cur-user="user"/>
    <modalSendMessage :proof="proof"/>
    <modalEditNonApprovers
        :account-users="proof.currentWorkflow.stages[0].bccUserIds"
    />
    <modalEditName
        :proof-name="proof.name"
        v-on:proof_name_update="handleNameChangeEvent($event)"
    />
    <modalEditTeam :proof="proof"/>
    <modalEditDeadline/>
    <modalEditOwner :users="allUsers" :proof="proof"/>
    <modalSendNewVersion :proof="proof" :cur-user="user"/>
    <modalManageFiles :proof="proof"
                      :cur-user="user"
                      :proof-public-id="proof.publicId"
    />
    <modal-update-proof-options :proof="proof"
                                :cur-user="user"/>
    <archiveProofModal
        name="archiveProofModal"
        @confirmed="archiveProof"
        :proof-id="proof.id"
        question="Archive This Proof?"
        body="This proof will no longer be accessible to approvers and you will be unable to send new versions. You will have full access to files and timeline."
        confirm-button-text="Yes, Archive"
        :cur-user="user"
        :proof="proof"
    />
    <modal
        name="restoreProofModal"
        eventToEmitOnConfirmation="restore::proof::confirmed"
        question="Restore This Proof?"
        body="Restoring this proof will allow you to actively manage this proof, including new versions, contacts and workflows."
        confirm-button-text="Yes, Restore"
    />
    <modal
        name="deleteProofModal"
        eventToEmitOnConfirmation="delete::proof::confirmed"
        question="Delete This Proof?"
        body="Are you sure you want to delete this proof? You cannot undo this action."
        confirm-button-text="Yes, Delete"
    />
    <proofLink :proof-public-id="proof.publicId" :proof-reviewer-id="proof.reviewerPublicId"/>
  </div>
</template>

<script>
import modalEditName from "../components/modals/modal-editname.vue";
import modalEditContact from "../components/modals/modal-editcontact.vue";
import sidebarproofitem from "../components/proof-item/sidebar-proofitem.vue";
import thumbnails from "../components/proof-item/thumbnails.vue";
import approvers from "../components/proof-item/approvers.vue";
import timeline from "../components/proof-item/timeline.vue";
import modalAddNote from "../components/modals/modal-addnote.vue";
import modalSendMessage from "../components/modals/modal-sendmessage.vue";
import modalEditNonApprovers from "../components/modals/modal-editnonapprover.vue";
import modalEditTeam from "../components/modals/modal-edit-team.vue";
import modalEditDeadline from "../components/modals/modal-editdeadline.vue";
import modalEditOwner from "../components/modals/modal-editowner.vue";
import modalSendNewVersion from "../components/modals/modal-sendnewversion.vue";
import modalManageFiles from "../components/modals/modal-manage-files";
import modalUpdateProofOptions from "../components/modals/modal-updated-proof-options";
import proofLink from "../components/modals/modal-proof-link.vue";
import confirmationModal from "../components/modals/modal-confirmation.vue";
import createWorkflowItem from "../components/automation-archive/create-workflow-item";
import archiveProofModal from "../components/modals/modal-archive-proof.vue";
import VueRandomColor from "vue-randomcolor";
import moment from "moment";
import Vue from "vue";

Vue.use(VueRandomColor);

export default {
  components: {
    modalEditName,
    modalEditContact,
    confirmationModal,
    sidebarproofitem,
    thumbnails,
    approvers,
    timeline,
    modalAddNote,
    modalSendMessage,
    modalEditNonApprovers,
    modalEditTeam,
    modalEditDeadline,
    modalEditOwner,
    modalSendNewVersion,
    modalManageFiles,
    modalUpdateProofOptions,
    proofLink,
    createWorkflowItem,
    archiveProofModal
  },
  data() {
    return {
      user: this.$A.Auth || {},
      saveTask: null,
      poolTask: null,
      fullyLoaded: false,
      proof: {
        id: 0,
        name: "",
        publicId: "",
        reviewerPublicId: "",
        currentVersion: {
          sender: {
            name: "",
          },
          versionFiles: [],
        },
        currentWorkflow: {
          stages: [
            {
              bccUserIds: [],
            },
          ],
        },
      },
      nonApprovers: [],
      steps: [
        {
          target: "#proof_link",
          content:
              "Share your review link at any time. New contacts will be appended to the active approval stage.",
          params: {
            enableScrolling: false,
            placement: "right",
          },
        },
        {
          target: "#proof_version",
          content:
              "You can upload new files and send a new version of the proof at any time. We’ll record the new version on your timeline.",
          params: {
            enableScrolling: false,
            placement: "right",
          },
        },
        {
          target: "#proof_view",
          content:
              "Your review screen will consolidate all of your contacts comments on each file in your proof. You can also reply to comments.",
          params: {
            enableScrolling: false,
            placement: "right",
          },
        },
        {
          target: "#proof_message",
          content:
              "Send a message to all current contacts. Your message will contain a link to the current proof.",
          params: {
            enableScrolling: false,
            placement: "right",
          },
        },
        {
          target: "#proof_options",
          content: "You can adjust your proof settings at any time.",
          params: {
            placement: "right",
          },
        },
        {
          target: "#proof_thumbnail",
          content:
              "We’ve included a carousel of proof screenshots. Just in case you forgot what you sent ;)",
          params: {
            placement: "right",
          },
        },
        {
          target: "#proof_stages",
          content:
              "You can view all of your approval stages and edit them here. If you want to append a workflow to this proof, select “Edit Workflow”.",
          params: {
            placement: "right",
          },
        },
        {
          target: "#proof_timeline",
          content:
              "The proof timeline will include every approver decision, such as approved or not approved. It will also include a log of when each contact viewed the proof. If you send a new version of a proof, that will be recorded here too.",
          params: {
            placement: "right",
          },
        },
        {
          target: "#export_timeline",
          content: `This is your proof timeline. It is an audit log of every status change to your proof, a record of all of your contacts and their decisions, and a place to send new versions.`,
          params: {
            enableScrolling: false,
            placement: "right",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("proof-item");
        },
      },
      teams: [],
      realTimeConnection: null,
      activeUserInactivityInMinutes: 3,
      activeUserIds: {},
      lastKnownActiveUsers: {},
      allUsers: []
    };
  },
  async mounted() {
    let self = this;
    let proofId = 0;

    if (this.$route.query.id !== undefined) {
      proofId = this.$route.query.id;
    } else if (
        this.$A.viewToViewData != null &&
        this.$A.viewToViewData.proof !== undefined &&
        this.$A.viewToViewData.proof !== null &&
        this.$A.viewToViewData.proof.id > 0
    ) {
      proofId = this.$A.viewToViewData.proof.id;
      this.$A.InsertUrlParam("id", proofId);
    }

    if (proofId > 0) {
      await this.loadProof(proofId);
      this.allUsers = await this.$A.AccountService.All() || [];

      // this.poll();

      this.$root.$on("proof::refresh", async function (proofMaybe) {
        if (proofMaybe && proofMaybe.proof) {
          self.proof = proofMaybe.proof;
        } else {
          await self.loadProof(self.proof.id);
        }
      });
      this.$root.$on("approver::updated", async function () {
        //await self.loadProof(self.proof.id);
      });
      this.$root.$on("proof::tags::updated", async function (updatedTags) {
        self.proof.tags = updatedTags;
      });
      this.$root.$on("proof::custom-props::updated", async function () {
        await self.loadProof(self.proof.id);
      });
      this.$root.$on("delete::proof::confirmed", async function () {
        await self.$A.ProofService.Delete(self.proof.id);
        self.$A.NavigateToView("/proof-archive", {});
      });
      this.$root.$on("restore::proof::confirmed", async function () {
        await self.$A.ProofService.Restore(self.proof.id);
        await self.loadProof(proofId);
      });
      this.$root.$on("proof::save::dueDate", async function (obj) {
        self.proof.dueDate = obj.dueDate;
        await self.saveProof();
      });
      this.$root.$on("proof::save::settings", async function (settings) {
        if (!self.fullyLoaded || settings === null) {
          return;
        }
        self.proof.allowApproveWithChanges = settings.allowApproveWithChanges;
        self.proof.allowDownload = settings.allowDownload;
        self.proof.allowApproveIndividualFiles =
            settings.allowApproveIndividualFiles;
        self.proof.allowViewAllComments = settings.allowViewAllComments;
        self.proof.limitVersionVisibility = settings.limitVersionVisibility;
        self.proof.blockCommentingAfterProofDecision =
            settings.blockCommentingAfterProofDecision;
        await self.saveProof();
      });
      this.$root.$on("proof::save", async function () {
        await self.saveProof();
      });
      this.$root.$on("proof::save::owner", async function (obj) {
        if (obj.senderId > 0) {
          let proof = await self.$A.ProofService.UpdateSender(
              self.proof.id,
              obj.senderId
          );
          if (proof && proof.id > 0) {
            self.proof = proof;
          }
        }
      });
      this.$root.$on("proof::save::team", async function (obj) {
        self.proof.usersTeamIds = obj.ashoreUsersTeamIds;
        await self.saveProof();
      });
    } else {
      setTimeout(function () {
        self.proof.name = "Proof Not Found";
      }, 1000);
    }

    setTimeout(() => {
      const productTourState = _.get(self, 'user.productTourState', [])
      if (productTourState.indexOf("proof-item") === -1) { //  || true
        // self.$tours["proofTour"].start();
      }
    }, 2000)
    this.teams = await this.$A.TeamService.GetAccountTeams();
  },
  computed: {},
  methods: {
    archiveProof: async function (args) {
      await this.$A.ProofService.Archive(this.proof.id, args.note);
      await this.loadProof(this.proof.id);
    },
    poll: function () {
      let self = this;
      clearTimeout(this.poolTask);
      this.poolTask = setTimeout(async function () {
        if ((self.$refs['workflowSidebar'] && !self.$refs['workflowSidebar'].showSideBar) ||
            self.$refs['workflowSidebar'] === undefined) {
          if (self.$A.viewToViewData && self.$A.viewToViewData.proof) {
            await self.loadProof(self.$A.viewToViewData.proof.id);
          }
        }
        self.poll();
      }, 5000);
    },
    registerRealTimeConnection: async function () {
      let self = this;
      if (this.realTimeConnection !== null) {
        return;
      }
      this.realTimeConnection = this.$A.RealtimeService.Connect();

      //ReadyForRegistration
      this.realTimeConnection.on("ReadyForRegistration", async () => {
        console.log("RealTimeConnectionEstablished");
        const token = _.get(self, '$A.Auth.apiToken.token', "");
        const publicId = _.get(self, 'proof.publicId', "");
        if (!token || !publicId) return;
        try {
          await self.realTimeConnection.invoke(
              "RegisterReviewerConnection",
              token,
              publicId,
              "proofPage"
          );
          await self.realTimeConnection.invoke(
              "LogLocalIdentity",
              JSON.stringify(window.$A.LoadDataCache("approver_keychain"))
          );
        } catch (err) {
          console.error(err);
        }
      });

      //ReceiveProofEvents
      this.realTimeConnection.on("ReceiveProofEvents", async (appEvent) => {
        if (self.proof.proofEvents.find(e => e.id === appEvent.id)) {
          return;
        }
        self.proof.proofEvents.unshift(appEvent);
        switch (appEvent.eventType) {
          case 39:
            const statusToLabel = {
              0: "Not Started",
              1: "Waiting For Review",
              2: "Waiting For Response",
              3: "Not Approved",
              4: "Approved"
            };
            const stage = self.proof.currentVersion.stages.find(s => appEvent.appEventObject.workflowStageId);
            stage.approvers.filter(a => a.id === appEvent.appEventObject.approverId).forEach(a => {
              a.workflowStageApproverStatus = appEvent.appEventObject.proofApproverResponse.approvalStatus;
              a.workflowStageApproverStatusLabel = statusToLabel[a.workflowStageApproverStatus]
            });
            stage.reviewers.filter(a => a.id === appEvent.appEventObject.approverId).forEach(a => {
              a.workflowStageApproverStatus = appEvent.appEventObject.proofApproverResponse.approvalStatus;
              a.workflowStageApproverStatusLabel = statusToLabel[a.workflowStageApproverStatus]
            });
            break;
        }
      });

      // Incorporate the other logic here
      this.realTimeConnection.on("ReceiveUserCursorUpdate", () => {});
      this.realTimeConnection.on("PresenceUpdate", (users) => {
        let self = this;
        self.activeUserIds = {};
        users.filter(u => self.proof != null && u.proofId === self.proof.id)
              .filter(u => !u.lastHeartbeat ||
                  moment(u.lastHeartbeat).add(this.activeUserInactivityInMinutes, "minute").isAfter(moment()))
              .forEach(u => self.activeUserIds[u.userId || u.approverId] = true);
      });

      //Start connection
      this.realTimeConnection.start();
    },
    exportPdf: async function ($event) {
      // console.log("download timeline");
      let self = this;

      let token = await this.$A.AccountService.GetTempToken();
      let url = this.$A.Env.apiHost + "/proof/" + self.proof.id + "/export-pdf?temp_access_token=" + token;
      // console.log(url);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.setAttribute("target", "_blank");
      a.setAttribute(
          "href",
          url
      );
      a.setAttribute("download", self.proof.name + ".pdf");
      a.click();
      document.body.removeChild(a);

      // let downloadProof = function () {
      //   html2canvas(document.querySelector("body"), {
      //     letterRendering: 1,
      //     allowTaint: false,
      //     useCORS: true,
      //   }).then((canvas) => {
      //     document.body.appendChild(canvas);
      //     let img = canvas.toDataURL();
      //     document.body.removeChild(canvas);
      //     let a = document.createElement("a");
      //     a.style.display = "none";
      //     document.body.appendChild(a);
      //     a.setAttribute("target", "_blank");
      //     a.setAttribute("href", img);
      //     a.setAttribute("download", self.proof.name + ".pdf");
      //     a.click();
      //     document.body.removeChild(a);
      //   });
      // };
      //
      // if (document.getElementById("downloadProofScript") === null) {
      //   let el = document.createElement("script");
      //   el.setAttribute("id", "downloadProofScript");
      //   el.setAttribute("src", "/js/html2canvas.js");
      //   el.addEventListener("load", function (loadedEvent) {
      //     downloadProof();
      //   });
      //   document.body.appendChild(el);
      // } else {
      //   downloadProof();
      // }
    },
    loadProof: async function (proofId) {
      let proof = proofId && await this.$A.ProofService.GetFullProof(proofId);
      if (proof) {
        if (proof.id > 0) {
          this.proof = proof;
          let s = this;
          setTimeout(function () {
            s.fullyLoaded = true;
            s.registerRealTimeConnection();
          }, 100);
        } else if (proof.message !== undefined) {
            this.$A.AlertUser(proof.message, "warning", 20000);
        }
      }
    },
    reallySaveProof: async function () {
      if (this.proof.id > 0) {
        // let tagids = [];
        // if (this.proof.tags !== null && this.proof.tags !== undefined) {
        //   this.proof.tags.forEach(function (t) {
        //     tagids.push(t.id);
        //   });
        // }
        let saveProofObj = {
          name: this.proof.name,
          dueDate: this.proof.dueDate,
          password: this.proof.password,
          allowDownload: this.proof.allowDownload,
          allowEdit: this.proof.allowEdit,
          allowApproveWithChanges: this.proof.allowApproveWithChanges,
          allowApproveIndividualFiles: this.proof.allowApproveIndividualFiles,
          allowViewAllComments: this.proof.allowViewAllComments,
          limitVersionVisibility: this.proof.limitVersionVisibility,
          blockCommentingAfterProofDecision: this.proof.blockCommentingAfterProofDecision,
          tagIds: [],
          usersTeamIds: this.proof.usersTeamIds
        };
        let proof = await this.$A.ProofService.CreateUpdateProof(
            this.proof.id,
            saveProofObj
        );
        if (proof !== null && proof.id > 0) {
          this.proof = await this.$A.ProofService.GetFullProof(this.proof.id);
          this.$A.AlertUser("Proof Has Been Saved", "success");
        } else if (_.get(proof, 'success') !== null && !_.get(proof, 'success')) {
          const msg = _.get(proof, 'message', 'Proof Failed to Save. Please try again.');
          this.$A.AlertUser(msg);
        }
      }
    },
    handleNameChangeEvent: function ($event) {
      this.proof.name = $event.name;
      this.saveProof();
    },
    saveProof: async function () {
      // console.log(this.saveTask);
      if (!this.fullyLoaded) {
        return;
      }
      if (this.poolTask !== null) {
        clearTimeout(this.poolTask);
      }
      if (this.saveTask !== null) {
        clearTimeout(this.saveTask);
      }
      let s = this;
      this.saveTask = setTimeout(async function () {
        await s.reallySaveProof();
        s.saveTask = null;
        // s.poll();
      }, 1000);
    },
  },
};
</script>


