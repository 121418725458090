<script>
export default {
  name: "sidebarAutomation",
};
</script>

<template>
  <!--Sidebar -->
  <div class="col-md-3">
    <div class="sidebar sidebar-archive">
      <h4>What is This?</h4>
      <p>Ashore helps you automate and streamline your proofing efforts:</p>
      <h5>Email Templates</h5>
      <p>
        Here, you can modify the automatic proof reminder email that is sent
        from Ashore. You can also create email templates that you can apply when
        sending messages or creating workflows.
      </p>
      <h5>Workflows</h5>
      <p>
        Workflows allow you to create approval sequences and predefined approval
        stages. You can create workflows here and append them to your proofs at
        any time!
      </p>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
