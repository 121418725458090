<script>
import Datepicker from "vuejs-datepicker";

export default {
  name: "sidebarproof",
  components: {
    Datepicker,
  },
  data() {
    return {
      senders: [],
      workflows: [],
      teams: [],
      customProperties: [],
      customPropertyValues: {},
      tags: [],
      tagFilters: [],
      filter: {
        approverId: 0,
        proofId: 0,
        senderId: 0,
        workflowId: 0,
        teamId: 0,
        tagId:
            this.$A.viewToViewData !== null &&
            this.$A.viewToViewData.tag !== undefined
                ? this.$A.viewToViewData.tag.id
                : 0,
        tagFilterId:
            this.$A.viewToViewData !== null &&
            this.$A.viewToViewData.tagFilter !== undefined
                ? this.$A.viewToViewData.tagFilter.id
                : 0,
        status: 0,
        overdue: false,
        archived: false,
        sortByDeadlineDesc: false,
      },
      tagsSearchBoxModel: "",
      tagsFilterSearchBoxModel: "",
      proofsSearchBoxModel: "",
      filterStatus: 0,
      statusFilterOptions: [
        {status: 0, label: "Most Recent Proofs"},
        {status: 1, label: "Waiting for Review"},
        {status: 4, label: "Not Approved Proofs"},
        {status: 6, label: "Approved With Changes"},
        {status: 5, label: "Approved Proofs"},
        {status: 7, label: "Overdue Proofs"},
        {status: 8, label: "By Upcoming Deadline"},
        {status: 9, label: "Archived Proofs"}
      ],
      tagsModel: [],
      tagsFilterModel: [],
      modifyingFilterProperties: false,
    };
  },
  methods: {
    ByStatus: function (status) {
      if (status === 7) {
        status = 0;
        this.filter.overdue = true;
      } else {
        this.filter.overdue = false;
      }

      if (status === 8) {
        status = 0;
        this.filter.sortByDeadlineDesc = true;
      } else {
        this.filter.sortByDeadlineDesc = false;
      }

      if (status === 9) {
        status = 0;
        this.filter.archived = true;
      } else {
        this.filter.archived = false;
      }

      this.filter.status = status;
      this.EmitFilterChange();
    },
    FilterByStatusOption: function (statusSelected) {
      this.ByStatus(statusSelected);
    },
    FilterByCustomFieldValue: function (property, value) {
      this.customPropertyValues[property.label] = value;
      this.FilterByCustomField(property);
    },
    FilterByCustomField: function (property) {
      let newCustomFieldValues = [];
      if (this.filter.customFieldValues) {
        newCustomFieldValues = this.filter.customFieldValues.filter(cf => cf.key !== property.label);
      }
      if (this.customPropertyValues[property.label]) {
        if (property.dataType === "Checkboxes (Multiple Options)") {
          if (this.customPropertyValues[property.label].length > 0) {
            newCustomFieldValues.push({
              "key": property.label,
              "value": this.customPropertyValues[property.label],
              "dataType": property.dataType
            });
          }
        } else {
          if (property.dataType === 'Datepicker') {
            let dateValue = this.$A.FormatDate(new Date(this.customPropertyValues[property.label]));
            newCustomFieldValues.push({"key": property.label, "value": [dateValue], "dataType": property.dataType})
          } else {
            newCustomFieldValues.push({
              "key": property.label,
              "value": [this.customPropertyValues[property.label]],
              "dataType": property.dataType
            });
          }
        }
      }

      this.filter.customFieldValues = newCustomFieldValues;
      this.EmitFilterChange();
    },
    FilterByTag: function () {
      this.filter.tagIds = this.tagsModel;
      this.EmitFilterChange();
    },
    ByTagFilter: function () {
      this.modifyingFilterProperties = true;
      let tags = [];
      this.tagsFilterModel.forEach(filter => {
        tags.push(...filter.tags.map(t => t.id));
      });
      this.tagsModel = tags;
      this.filter.tagFilterIds = this.tagsFilterModel;
      this.modifyingFilterProperties = false;
      this.FilterByTag();
    },
    EmitFilterChange: function () {
      if (this.modifyingFilterProperties) {
        return;
      }
      if (this.filter.archived) {
        this.filterStatus = 9;
      } else if (this.filter.overdue) {
        this.filterStatus = 7;
      } else if (this.filter.sortByDeadlineDesc) {
        this.filterStatus = 8;
      } else {
        this.filterStatus = this.filter.status;
      }
      // console.log("emit", this.filter);
      this.$root.$emit("proof::list::filter::update", this.filter);
    },
    ResetFilters: function () {
      this.filterStatus = 0;
      this.filter.status = 0;
      this.filter.archived = false;
      this.filter.overdue = false;
      this.filter.sortByDeadlineDesc = false;
      this.filter.senderId = null;
      this.filter.workflowId = null;
      this.filter.customFieldValues = null;
      this.filter.tagIds = null;
      this.filter.tagFilterId = null;
      this.filter.teamId = null;
      this.proofsSearchBoxModel = "";
      this.tagsModel = [];
      this.tagsFilterModel = [];
      this.loadCustomProperties();
      this.EmitFilterChange();
    },
    loadCustomProperties: async function () {
      this.customProperties = await this.$A.AccountService.GetAllCustomProofProperties();
      this.resetCustomPropertyValues();
    },
    resetCustomPropertyValues: function () {
      let self = this;
      this.customPropertyValues = {};
      if ( _.isArray(this.customProperties) ) {
        this.customProperties.forEach(cp => {
        self.customPropertyValues[cp.label] = null;
        if (cp.dataType === "Checkboxes (Multiple Options)") {
          this.customPropertyValues[cp.label] = [];
        }});
      }
    },
    broadcastSearchValues: function (e) {
      let self = this;
      clearTimeout(self.searchFunctionWaitForNoChanges);
      if (this.proofsSearchBoxModel.length === 0 || e.keyCode === 13) {
        self.searchFunctionWaitForNoChanges = setTimeout(async function () {
          self.$root.$emit("page::search::value::updated", self.proofsSearchBoxModel);
        }, 100);
      }
    },
    isArray: function (arr) {
      return _.isArray(arr);
    }
  },
  computed: {
    getTags: function () {
      if (this.tagsSearchBoxModel == null || this.tagsSearchBoxModel === "") {
        return this.tags
      }
      return this.tags.filter(tag => tag.name.toLowerCase().includes(this.tagsSearchBoxModel.toLowerCase()))
    },
    getTagFilters: function () {
      if (this.tagsFilterSearchBoxModel == null || this.tagsFilterSearchBoxModel === "") {
        return this.tagFilters;
      }
      return this.tagFilters.filter(tag => tag.name.toLowerCase().includes(this.tagsFilterSearchBoxModel.toLowerCase()));
    },
    isFilterActive: function () {
      if (this.filterStatus && this.filterStatus !== 0) {
        return true;
      }
      if (this.filter.archived || this.filter.overdue || this.filter.sortByDeadlineDesc) {
        return true;
      }
      if (this.filter.senderId || this.filter.workflowId) {
        return true;
      }
      if (this.filter.customFieldValues && this.filter.customFieldValues.length > 0 && Object.keys(this.filter.customFieldValues)) {
        return true;
      }
      if (this.filter.tagIds || this.filter.tagFilterId) {
        return true
      }

      if (this.filter.teamId) {
        return true
      }

      if (this.proofsSearchBoxModel !== "") {
        return true;
      }
      return false;
    }
  },
  async mounted() {
    let self = this;
    self.loadingCustomProperties = true;
    this.senders = await this.$A.AccountService.All();
    this.workflows = await this.$A.WorkflowService.GetAll();
    this.teams = await this.$A.TeamService.GetAccountTeams();
    await this.loadCustomProperties();
    this.tags = await this.$A.TagsService.GetAll();
    this.tagFilters = await this.$A.TagsService.GetAllTagFilters();
    this.$root.$on("tags::reloaded", function (tags) {
      self.tags = tags;
    });
    this.$root.$on("tag::filters::reload", async function (tagFilters) {
      self.tagFilters = tagFilters;
    });
    let cacheFilter = this.$A.LoadCookie("proof::list::filter::update");
    this.modifyingFilterProperties = true;
    if (cacheFilter != null) {
      this.filter = cacheFilter;
      if (this.filter.customFieldValues) {
        this.filter.customFieldValues.forEach(prop => {
          if (prop.dataType === "Checkboxes (Multiple Options)") {
            self.customPropertyValues[prop.key] = prop.value;
          } else {
            self.customPropertyValues[prop.key] = prop.value[0];
          }
        })
      }
      if (this.filter.tagIds) {
        this.tagsModel = this.filter.tagIds;
      }
      if (this.filter.tagFilterIds) {
        this.tagsFilterModel = this.filter.tagFilterIds;
      }
    }
    this.modifyingFilterProperties = false;
    this.loadingCustomProperties = false;
    this.EmitFilterChange();
  },
};
</script>

<template>
  <!--Sidebar -->
  <div class="col-md-3 pr-2" style="padding-right: 9px !important;">
    <div class="sidebar sidebar-archive mb-25" style="padding: 24px; padding-bottom: 24px">
      <div class="d-flex align-items-center justify-content-between" style="padding-bottom: 24px">
        <div id="proofs_sort" class="header-text">Proofs</div>
        <b-button v-show="isFilterActive" class="reset-btn" @click="ResetFilters">Reset</b-button>
      </div>
      <!-- Proof Search -->
      <b-form-group style="margin-bottom: 10px !important;">
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text class="filter-input bg-white">
              <div class="h7">
                <b-icon-search></b-icon-search>
              </div>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
              class="filter-input border-left-0 pl-0"
              v-model="proofsSearchBoxModel"
              type="text"
              placeholder="Search Proofs..."
              maxlength="30"
              size="25"
              @keydown.enter.native="broadcastSearchValues"
          />
        </b-input-group>
      </b-form-group>
      <!--  Filter by Status  -->
      <div v-if="isArray(statusFilterOptions)" class="filter-text">Filter by Status</div>
      <v-select
          v-if="isArray(statusFilterOptions)"
          v-model="filterStatus"
          :options="statusFilterOptions"
          label="label"
          :reduce="option => option.status"
          placeholder="Select"
          class="proof-select"
          :searchable="false"
          :clearable="false"
          @input="FilterByStatusOption"
      >
      </v-select>
      <!--  Filter by Owner  -->
      <div v-if="isArray(senders)" class="filter-text">Filter by Owner</div>
      <v-select
          v-if="isArray(senders)"
          :options="senders"
          v-model="filter.senderId"
          label="name"
          :reduce="sender => sender.id"
          placeholder="Select"
          class="proof-select"
          :searchable="false"
          :clearable="true"
          @input="EmitFilterChange"
      >
        <template #option="{name, profileImageUrl}">
          <div class="d-flex align-center">
            <b-avatar :src="profileImageUrl" class="owner-avatar"/>
            <span class="avatar-text"> {{ name }}</span>
          </div>
        </template>
        <template #selected-option="{name, profileImageUrl}">
          <div class="d-flex align-center">
            <b-avatar :src="profileImageUrl" class="owner-avatar"/>
            <span class="avatar-text"> {{ name }}</span>
          </div>
        </template>

      </v-select>

      <!-- Filter by Team -->
      <div class="filter-text" v-if="isArray(teams) && teams.length">Filter by Team</div>
      <v-select
          v-if="isArray(teams) && teams.length"
          name="sort_by_team"
          :options="teams"
          class="proof-select"
          placeholder="Select"
          :searchable="false"
          :clearable="true"
          label="name"
          :reduce="team => team.id"
          @input="EmitFilterChange"
          v-model="filter.teamId"
      >
      </v-select>

      <!--  Filter by Workflow  -->
      <div v-if="isArray(workflows)" class="filter-text">Filter by Workflow</div>
      <v-select
          v-if="isArray(workflows)"
          name="sort_by_workflow"
          :options="workflows"
          class="proof-select"
          placeholder="Select"
          :searchable="false"
          :clearable="true"
          label="name"
          :reduce="workflow => workflow.id"
          @input="EmitFilterChange"
          v-model="filter.workflowId"
      >
      </v-select>
      <!--  Filter by Custom Fields -->
      <div v-for="property in customProperties">
        <!-- Datepicker Custom Label -->
        <div v-if="property.dataType === 'Datepicker'">
          <div class="filter-text">Filter by {{ property.label }}</div>
          <datepicker
              v-model="customPropertyValues[property.label]"
              style="display: block"
              placeholder="MM-DD-YYYY"
              @input="FilterByCustomField(property)"
          />
        </div>
        <!-- Dropdown Custom Label -->
        <div v-if="property.dataType === 'Dropdown Select'">
          <div v-if="property && isArray(property.enum)" class="filter-text">Filter by {{ property.label }}</div>
          <v-select
              v-if="property && isArray(property.enum)"
              v-model="customPropertyValues[property.label]"
              :options="property.enum"
              placeholder="Select"
              class="proof-select"
              :clearable="true"
              @input="(value) => FilterByCustomFieldValue(property, value)"
          >
          </v-select>
        </div>
        <!-- Checkboxes Custom Label -->
        <div class="proof-item-checkboxes">
          <b-form-group
              :label="'Filter by '+ property.label"
              v-if="property.dataType === 'Checkboxes (Multiple Options)'"
              class="text-normal mb-0 filter-text check-group">
            <b-form-checkbox-group
                v-model="customPropertyValues[property.label]"
                :options="property.enum"
                name="flavour-4a"
                inline
                checked="true"
                @input="FilterByCustomField(property)"
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <!-- Filter by Tags  -->
    <div class="sidebar sidebar-archive mb-25">
      <div id="proofs_tags">
        <div class="header-text" style="margin-bottom: 24px">Tags</div>
        <b-card class="tags-card" v-show="tags.length > 0">
          <!-- Tags Filter  -->
          <b-form-group :class="(tags.length > 15) ? '': ' tags-reset-fieldset'">
            <b-input-group v-if="tags.length > 15">
              <b-input-group-prepend>
                <b-input-group-text class="filter-input bg-white">
                  <div class="h7">
                    <b-icon-search></b-icon-search>
                  </div>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                  class="filter-input border-left-0"
                  v-model="tagsSearchBoxModel"
                  type="text"
                  placeholder="Search to Filter..."
                  maxlength="30"
                  size="25"
              />
            </b-input-group>
          </b-form-group>
          <b-card-body>
            <!-- Tags Checkboxes -->
            <div class="tags-wrapper tag-item tag-item-child">
              <b-form-group>
                <b-form-checkbox-group
                    id="tag-checkbox-group"
                    v-model="tagsModel"
                    :options="getTags"
                    value-field="id"
                    text-field="name"
                    stacked
                    @input="FilterByTag"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-card-body>
        </b-card>
        <!-- Button Manage Tags -->
        <div class="tag-item" v-b-modal.ManageTags>
          <b-button class="gray-button">Manage Tags</b-button>
        </div>
      </div>
    </div>
    <!-- Filter Tags  -->
    <div class="sidebar sidebar-archive mb-25 scroll-sidebar">
      <div class="header-text" style="margin-bottom: 24px">Tag Filter</div>
      <b-card id="proofs_filters" v-show="isArray(getTagFilters) && getTagFilters.length > 0">
        <!-- Filter Tags Search  -->
        <b-form-group v-if="isArray(getTagFilters) && getTagFilters.length > 15" :class="(tags.length > 15) ? '': ' tags-reset-fieldset'">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text class="filter-input bg-white">
                <div class="h7">
                  <b-icon-search></b-icon-search>
                </div>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
                class="filter-input border-left-0 pl-0"
                v-model="tagsFilterSearchBoxModel"
                type="text"
                placeholder="Search to Filter..."
                maxlength="30"
                size="25"
            />
          </b-input-group>
        </b-form-group>
        <b-card-body class="pt-0 pl-0">
          <!-- Checkboxes -->
          <div class="tags-wrapper tag-item tag-item-child">
            <b-form-group>
              <b-form-checkbox
                  v-if="isArray(getTagFilters)"
                  v-for="tagFilter in getTagFilters"
                  v-model="tagsFilterModel"
                  :id="`${tagFilter.id}`"
                  :key="`${tagFilter.id}`"
                  :value="tagFilter"
                  stacked
                  @input="ByTagFilter"
              >{{ tagFilter.name }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-card-body>
      </b-card>
      <!--  Button Manage Filters -->
      <div class="tag-item" v-b-modal.ManageTagFilters>
        <b-button class="gray-button">Manage Filters</b-button>
      </div>
    </div>
  </div>
  <!-- /Sidebar -->
</template>

<style scoped>

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #575759;
}

.reset-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #EB4654;
  border-color: #EB4654;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
}

.reset-btn:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #EB4654;
}

.filter-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
  margin-bottom: 4px;
}

.proof-select {
  margin-bottom: 10px;
}

.scroll-sidebar {
  max-height: 600px;
  overflow-y: scroll;
}

.owner-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 12px;
}

.avatar-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
  padding-top: 2px;
}

.field-item h3 {
  font-size: 18px;
}

#proofs_filters {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 446px;
  overflow-y: scroll;
  border: 1px solid #D5D5D8;
  border-radius: 8px;
}

.single-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  width: 100%;
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.proof-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  gap: 4px;
  width: 100%;
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.proof-item-checkboxes {
  font-weight: normal;
  margin-top: 10px;
}

.gray-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  width: 100%;
  margin-top: 24px;
}

.tags-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 446px;
  overflow-y: scroll;
  border: 1px solid #D5D5D8;
  border-radius: 8px;
}

.filter-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
  padding-right: 24px;
}

/deep/ .filter-input .custom-control:focus {
  outline: none !important;
}

/deep/ .proof-item-checkboxes span {
  font-weight: normal;
  margin-top: 0px;
}

/deep/ .proof-item-checkboxes .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: 0px;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

/deep/ .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #019256 !important;
  border-radius: 0.25rem;
}

/deep/ .custom-checkbox {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

/deep/ .custom-control-label::before {
  top: 0.2rem !important;
}

/deep/ .custom-control {
  min-height: 0rem !important;
}

/deep/ .tag-item label {
  margin-bottom: 16px;
}

/deep/ .vs__search, .vs__search:focus {
  padding-bottom: 8px;
  padding-top: 2px;
}

/deep/ .vs--single .vs__selected {
  padding-bottom: 8px;
  padding-top: 2px;
}

/deep/ .vs__clear {
  padding-bottom: 3px;
}

/deep/ .tags-wrapper label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

/deep/ .input-group-text {
  padding-right: 8px;
}

/deep/ .input-group > .form-control:focus {
  border-color: #B5B5B8 !important;
}

/deep/ .vdp-datepicker input {
  background-image: url("/img/datepicker-icon.svg");
  background-size: 6%;
}

/deep/ .proof-item-checkboxes .col-form-label {
  margin-bottom: 4px !important;
}

/deep/ #proofs_tags .card {
  padding: 24px !important;
}

/deep/ #proofs_tags .card-body {
  padding: 0px !important;
}

.tags-reset-fieldset {
  margin: 0px !important;
  padding: 0px !important;
}

/deep/ .vs__actions {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

</style>
