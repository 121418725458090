<template>
  <div>
    {{ formValid }}
    <div class="login-page registration-page">
      <div class="container-fluid">
        <div class="row">
          <!-- Sidebar -->
          <div class="col-lg-4 col-md-5 m-auto">
            <div class="generic-sidebar">
              <div v-if="accountLogo" class="ashore-logo-registration">
                <img :src="accountLogo"/>
              </div>
              <h2>Set Your Password</h2>
              <div :class="['form-group', inputValid(passwordValid)]">
                <label>Password:</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  v-model="password"
                />
              </div>
              <div :class="['form-group', inputValid(passwordMatch)]">
                <label>Password Confirmation:</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="Password Confirmation"
                  v-model="password_confirm"
                  v-on:keyup.enter="SetPassword()"
                />
              </div>
              <a
                style="margin-top: 25px"
                v-on:click="SetPassword()"
                class="button button-light button-green button-block"
              >
                <b-spinner
                  variant="success"
                  v-show="working"
                  type="grow"
                  small
                ></b-spinner>
                Save Password And Accept Your Invite</a
              >
              <b-alert
                :show="dismissCountDown"
                dismissible
                :variant="variant"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
                style="margin-top: 25px"
              >
                <p>{{ errorMessage }}</p>
                <b-progress
                  :variant="variant"
                  :max="dismissSecs"
                  :value="dismissCountDown"
                  height="4px"
                ></b-progress>
              </b-alert>
            </div>
          </div>
          <!-- /Sidebar -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.login-page {
  background-color: #019255;
}
</style>

<script>
import UserApi from "../generated/api/UserApi";
import UpdatePasswordRequest from "../generated/model/UpdatePasswordRequest";
import { getAccountLogo } from "../utils/helpers";

export default {
  components: {},
  data() {
    return {
      password: "",
      password_confirm: "",
      passwordValid: false,
      passwordMatch: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      errorMessage: "",
      variant: "warning",
      working: false,
    };
  },
  computed: {
    formValid: function () {
      this.passwordValid =
        this.password.length === 0 ? true : this.password.length > 3;
      this.passwordMatch = this.password == this.password_confirm;
      return "";
    },
    accountLogo: function () {
      return getAccountLogo();
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    inputValid: function (val) {
      return val ? "has-success" : "has-error";
    },
    allFormElementsValid: function () {
      return this.passwordValid && this.passwordMatch;
    },
    async SetPassword() {
      if (this.$route.query.h === undefined) {
        this.errorMessage = "password reset token is invalid";
        this.showDismissibleAlert = true;
        this.variant = "warning";
        this.showAlert();
      } else if (!this.allFormElementsValid() || this.password.length === 0) {
        this.errorMessage = "Password is not valid";
        this.showDismissibleAlert = true;
        this.variant = "warning";
        this.showAlert();
      } else {
        this.errorMessage = "Password is updating";
        this.showDismissibleAlert = true;
        this.variant = "success";
        this.showAlert();
        this.working = true;

        let validationHash = this.$route.query.h;
        let self = this;
        // console.log(validationHash);
        this.working = true;
        let response = await this.$A.UserService.UpdatePasswordViaToken(
          validationHash,
          this.password,
          this.password_confirm
        );
        if (response.success !== undefined && response.success === false) {
          this.showDismissibleAlert = true;
          this.errorMessage = response.message;
          this.variant = "warning";
          this.showAlert();
          this.working = false;
        }
      }
    },
  },
};
</script>
