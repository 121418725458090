<script>
import reportcards from "../components/overview/reportcards.vue";
import recentproofs from "../components/overview/recentproofs.vue";
import tutorials from "../components/overview/tutorials.vue";
import VueApexCharts from 'vue-apexcharts'
import viewEmailModal from "../components/modals/modal-viewemail";
import { PROOF_ACCESS_PERMISSIONS } from "../utils/constants";
import PROOF_EVENTS from "../utils/proofEvents";
import { getAppEventProperty, sortEventsChronologically } from "../utils/helpers"

const {
  ProofCreated,
  ProofSent,
  ProofApprovedWithChanges,
  ProofApproved,
  ProofNotApproved,
  ProofNoteEvent,
  EmailSent,
  AccountAlertEvent,
  AnnotationCommentThreadUpdated,
  ProofFileUploadedEvent,
  ProofFileDeletedEvent,
  ProofVersionHiddenEvent,
  ProofVersionRevealedEvent,
} = PROOF_EVENTS;

const { OWNED_ONLY, TEAMS_ONLY, NONE } = PROOF_ACCESS_PERMISSIONS;

const FILTERS = {
  proofId: 0,
  senderId: 0,
  workflowId: 0,
  approverId: 0,
  overdue: false,
  sortByDeadlineDesc: false,
  teamId: 0,
  text: "",
  status: 0
}

export default {
  data() {
    return {
      steps: [
        {
          target: "#overview-tour-step-0",
          content: `Welcome Ashore! We’ve created the world’s fastest and easiest way to proof digital files. This tour will provide you with some brief points about the platform.`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#overview-tour-step-1",
          content: `If you want to jump right into Ashore, go here to upload files and create a review link to share with your approvers. That’s all you need to get started!`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#nav_proofs",
          content: `Any proofs you create will be listed here. We’ll track the status of all of your proofs for you, and give you a full timeline of changes.`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#nav_automation",
          content: `You can automate your approval process by adding workflows to your proofs. You can also customize email templates sent from Ashore (and create your own).`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#nav_contacts",
          content: `All of your contacts and their individual settings are listed and managed here.`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#nav_profile",
          content: `Upload your profile photo or add an email signature. If you have a paid account and a business email, you can also integrate your email with Ashore.`,
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
        },
        {
          target: "#nav_account_settings",
          content: `Set your account defaults, and even create an approval checklist. If you have a paid account, you can rebrand Ashore with your company logo.`,
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
        },
        {
          target: "#nav_billing",
          content: `Full-featured at a great price! Update your plan here to unlock more users, whitelabeling, and send more proofs per month.`,
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
        },
        {
          target: "#hubspot-messages-iframe-container",
          content: `Want one-on-one training or have a question? Reach out to us using the support and we’ll be happy to assist. We’re available from 9 am to 5 pm CDT.`,
        },
      ],
      tourCallbacks: {
        onPreviousStep: this.tourPreviousStepCallback,
        onNextStep: this.tourNextStepCallback,
        onStop: this.SaveCompletionOfProductTours(),
      },
      chart: {
        type: "bar",
        options: {
          chart: {
            id: 'overview-proof-chart',
            height: 350,
            stacked: true,
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: false,
              hideOverlappingLabels: true,
              showDuplicates: false,
              trim: false,
              minHeight: undefined,
              maxHeight: 120,
              style: {
                colors: ['#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8'],
                fontSize: '12px',
                fontFamily: 'Public Sans',
                fontWeight: 400,
              },
            }
          },
          yaxis: {
            // min: 0,
            // tickAmount: 4,
            labels: {
              show: false,
              style: {
                colors: ['#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8', '#B5B5B8'],
                fontSize: '12px',
                fontFamily: 'Public Sans',
                fontWeight: 400,
              },
            }
          },
          stroke: {
            width: [4, 4, 4, 4, 4],
            curve: 'smooth'
          },
          plotOptions: {
            bar: {
              columnWidth: '2',
              backgroundBarOpacity: 1,
              borderRadius: 2,
              horizontal: false,
            },
          },
          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'top',
            horizontalAlign: "left",
            floating: false,
            fontSize: '14px',
            fontFamily: 'Open Sans',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ['#444444'],
              useSeriesColors: false
            },
            markers: {
              width: 12,
              height: 12,
              strokeWidth: 0,
              strokeColor: '#fff',
              fillColors: undefined,
              radius: 12,
              customHTML: undefined,
              onClick: undefined,
              offsetX: 0,
              offsetY: 0
            },
            itemMargin: {
              horizontal: 24,
              vertical: 0
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            },
          },
          colors: ['#707073', '#1B1B1C', '#019256', '#FAD546', '#EB4654'],
          fill: {
            colors: ['#707073', '#1B1B1C', '#019256', 'rgba(250, 213, 70, 0.16)', '#EB4654'],
          },
          grid: {
            show: true,
            borderColor: '#D5D5D8',
            borderBottom: false,
            strokeDashArray: 4,
            padding: {
              top: 0,
              right: 20,
              bottom: 0,
              left: 20
            },
          }
        },
        series: [
          {
            name: 'Proofs Sent',
            type: 'column',
            data: [],
          }, {
            name: 'Files Uploaded',
            type: 'column',
            data: []
          }, {
            name: 'Approved',
            type: 'line',
            data: [],
          },
          {
            name: 'Waiting for Review',
            type: 'area',
            data: [],
          },
          {
            name: 'Not Approved',
            type: 'line',
            data: [],
          }
        ],
      },
      chartMetrics: {
        categories: [],
        series: []
      },
      timelineEvents: [],
      loaded: false,
      isMobile: window.innerWidth < 991,
      curUserAssociatedProofs: [],
      EmailSent,
      AccountAlertEvent
    };
  },
  async mounted() {
    this.$A.ProductTourProcess("overview", this);
    this.chartMetrics = await this.$A.AccountService.GetChartMetrics(12);
    this.chartMetrics && this.setChartData();
    const timelineEvents = await this.$A.AccountService.GetTimelineEvents() || [];
    await this.filterTimelineEvents(timelineEvents);

    // debugger
    this.loaded = true;
  },
  computed: {
    welcomeTitle: function () {
      return `Welcome, ${_.get(this.$A, "Auth.name", "")}`;
    },
  },
  components: {
    reportcards,
    recentproofs,
    tutorials,
    VueApexCharts,
    viewEmailModal
  },
  methods: {
    tourStateUpdate(currentStep) {
      var dropdown = document.getElementById("account_profile_dropdown");
      if (currentStep >= 4 && currentStep <= 6) {
        this.$A.AddClass("show", dropdown);
      } else {
        this.$A.RemoveClass("show", dropdown);
      }
    },
    tourPreviousStepCallback(currentStep) {
      this.tourStateUpdate(currentStep);
    },
    tourNextStepCallback(currentStep) {
      this.tourStateUpdate(currentStep);
    },
    SaveCompletionOfProductTours() {
      window.$A.SaveCompletionOfProductTours("overview");
    },
    setChartData: function () {
      const availableCategories = _.get(this, 'chartMetrics.categories', [])
      const availableMonths = availableCategories.map(m => m.monthYearLabel) || [];
      if (availableMonths.length > 0) {
        // set categories
        const categories = availableMonths.length <= 12 ? availableMonths : availableMonths.slice(availableMonths.length - 12);
        _.set(this, 'chart.options.xaxis.categories', categories);
        // set series
        let availableSeries = {};
        for (const s of this.chartMetrics.series) {
          const sLength = s.dataPoints.length;
          const data = sLength <= 12 ? s.dataPoints : s.dataPoints.slice(sLength - 12);
          availableSeries[s.metric] = data.map(d => d.value);
        }
        for (let i in this.chart.series) {
          this.chart.series[i].data = availableSeries[this.chart.series[i].name];
        }
        if (availableMonths.length === 1) {
          this.chart.series.filter(s => s.name === 'Waiting for Review').map(s => {
            s.type = 'line';
          });
        }
      }
    },
    getIconFromStatus: function (event) {
      let proofEventType = event.eventType;
      let approverStatusTaken = _.get(event, 'appEventObject.statusTaken', 0);
      if ([ProofNoteEvent].indexOf(proofEventType) !== -1) {
        return "/img/pencil-timeline-icon.svg";
      } else if ([EmailSent, ProofSent].indexOf(proofEventType) !== -1) {
        return "/img/email-sent-timeline-icon.svg";
      } else if ([ProofNotApproved].indexOf(proofEventType) !== -1 ||
          approverStatusTaken === 3
      ) {
        return "/img/not-approved-timeline-icon.svg";
      } else if (proofEventType === ProofFileUploadedEvent) {
        return "/img/added-version-file.svg";
      } else if (proofEventType === ProofFileDeletedEvent) {
        return "/img/delete-version-file.svg";
      } else if (proofEventType === ProofVersionHiddenEvent) {
        return "/img/hidden-version-file.svg";
      } else if (proofEventType === ProofVersionRevealedEvent) {
        return "/img/updated-version-to-visible.svg";
      } else if (
          [ProofApprovedWithChanges, ProofApproved].indexOf(proofEventType) !== -1 ||
          approverStatusTaken > 3
      ) {
        return "/img/approved-timeline-icon.svg";
      } else if (proofEventType === ProofCreated) {
        return "/img/create-proof-timeline-icon.svg";
      } else if (proofEventType === AnnotationCommentThreadUpdated && event.appEventObject.commentAnnotation) {
        return "/img/comments-timeline-icon.svg";
      } else {
        return "img/view-timeline-icon.svg";
      }
    },
    viewProof(proofId) {
      this.$A.NavigateToView(`/proof-item?id=${proofId}`);
    },
    viewApprover(approverId) {
      this.$A.NavigateToView(`/approver-item?id=${approverId}`);
    },
    get(object, path, defaultValue) {
      return _.get(object, path, defaultValue);
    },
    isEmpty(arr) {
      return _.isEmpty(arr);
    },
    async getAssociatedProofs(curUserId = 0, teams = []) {
      let associatedProofs = [];
      if (_.isEmpty(teams)) {
        associatedProofs = await this.$A.ProofService.GetProofsByFilter({...FILTERS, senderId: curUserId}) || [];
      } else {
        let teamsProofs = [];  
        for (const each of teams) {
          const proofs = await this.$A.ProofService.GetProofsByFilter({...FILTERS, teamId: each.id});
          proofs.forEach(proof => {
            if (!teamsProofs.some(p => p.id === proof.id)) teamsProofs.push(proof)
          });
        }
        associatedProofs = teamsProofs;
      }

      return associatedProofs;
    },
    getAssociatedEvents(timelineEvents, associatedProofs) {
      return timelineEvents.filter((event) => associatedProofs.some(proof => proof.id === event.proofId));
    },
    async filterTimelineEvents(timelineEvents) {
      const curUser = await this.$A.UserService.GetUser();
      const proofAccess = _.get(curUser, 'permissions.proofAccess');

      switch (proofAccess) {
        case OWNED_ONLY:
          this.curUserAssociatedProofs = await this.getAssociatedProofs(curUser.id) || [];
          this.timelineEvents = this.getAssociatedEvents(timelineEvents, this.curUserAssociatedProofs);
          break;
        case TEAMS_ONLY:
          this.curUserAssociatedProofs = await this.getAssociatedProofs(curUser.id, curUser.teams) || [];
          this.timelineEvents = this.getAssociatedEvents(timelineEvents, this.curUserAssociatedProofs);
          break;
        case NONE:
          this.timelineEvents = [];
          break;
        default:
          this.timelineEvents = timelineEvents;
          break;
      }

      this.timelineEvents = this.sortEventsChronologically(this.timelineEvents);
    },
    sortEventsChronologically,
    getAppEventProperty,
    getPostMarkResponse(event) {
      return getAppEventProperty(event, "additionalData.postMarkResponse", "") || getAppEventProperty(event, "postMarkResponse", "");
    },
  },
};
</script>

<template>
  <div>
    <navigation/>
    <pageheader
        v-bind:title="welcomeTitle"
        buttonlink="/create"
        searchstyle="display: none;"
        demostyle="display: none;"
    />
    <div class="container-fluid mb-4">
      <div class="row">
        <!--  Chart Metrics -->
        <div class="col-lg-8 ">
          <b-card class="mr-1 px-0 chart-metrics-card">
            <b-card-header
                class="card-header-text bg-transparent border-bottom-0">
              Proofs Over Time
            </b-card-header>
            <b-card-body>
              <VueApexCharts v-if="get(chartMetrics, 'categories', []).length > 0" width="100%"
                             :type="chart.type"
                             :options="chart.options"
                             :series="chart.series"
              >
              </VueApexCharts>
            </b-card-body>
          </b-card>
        </div>
        <!--  Recent Activity -->
        <div class="col-lg-4 recent-card-columns">
          <div class="p-0 h-100">
            <b-card class="recent-activity-card h-100">
              <b-card-header class="bg-transparent card-header-text border-bottom-0 mx-4 my-4 activity-header-text">
                Recent Activity
              </b-card-header>
              <b-card-body class="scroll">
                <li v-if="!isEmpty(timelineEvents)" v-for="event in timelineEvents" style="list-style: none;">
                  <div class="event-wrapper border-top px-0 d-flex justify-content-between align-items-center p-3">
                    <!-- Event Icon -->
                    <div :class="isMobile ? 'event-icon col-md-1 pr-0 pl-1':'event-icon col-md-1 pl-0'">
                      <img :src="getIconFromStatus(event)"/></div>
                    <!-- Event Description and Event Date  -->
                    <div
                        :class="isMobile ? 'col-md-8 ml-3 event-no-view': !event.proofId ? 'col-md-10 ml-2': 'col-md-9 ml-2'">
                      <div v-if="event.eventType === AccountAlertEvent && event.appEventObject"
                           class="event-description text-left flex-column align-items-start">
                        <div style="font-weight: bold">{{ event.proofName ? event.proofName : '' }}</div>
                        <div>{{ getPostMarkResponse(event) }}</div>
                      </div>
                      <div v-else class="event-description text-left flex-column align-items-start">
                        <div style="font-weight: bold">{{ event.proofName ? event.proofName : '' }}</div>
                        <div>{{ event.description }}</div>
                      </div>
                      <div class="event-date">{{ $A.FormatDateToString(event.createdAt, true) }} at
                        {{ $A.FormatDateToTime(event.createdAt) }}
                      </div>
                    </div>
                    <!-- Event Button  -->
                    <div :class="isMobile ? 'mr-2 pl-0 text-right': 'col-md-2 pr-1 w-100 view-event'">
                      <!-- event === 33 -->
                      <span
                          class="event-preview-btn bg-transparent text-success"
                          v-if="event.eventType === EmailSent"
                          @click="$refs.emailModal.viewEmail(event.emailLogId)"
                      >
                         View
                      </span>
                      <!-- event.proofId > 0 -->
                      <span
                          v-if="event.proofId > 0"
                          class="event-preview-btn bg-transparent text-success"
                          @click="viewProof(event.proofId)"
                      >
                          View
                       </span>
                      <!--  event.proofId === undefined && event.approvedId > 0 -->
                      <span
                          v-if="!event.proofId && event.accountId > 0"
                          class="event-preview-btn bg-transparent text-success"
                          @click="viewApprover(event.approverId)">
                      >
                          View
                       </span>
                    </div>
                  </div>
                </li>
                <div v-if="loaded && isEmpty(timelineEvents)">
                  <div class="no-activity-gift m-auto">
                    <img src="/img/no-activity.gif" class="pb-0"/>
                  </div>
                  <div class="d-flex align-items-center flex-column">
              <span class="empty-activity-text">
              Check back later!
              </span>
                    <span>
              You have no recent activity
              </span>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <reportcards/>
    <recentproofs v-if="loaded" :associatedProofs="curUserAssociatedProofs"/>
    <v-tour
        name="overviewTour"
        :steps="steps"
        :callbacks="tourCallbacks"
    ></v-tour>
    <viewEmailModal ref="emailModal"/>
  </div>
</template>

<style scoped>

.card-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #575759;
  margin-bottom: 16px;
}

::v-deep .chart-metrics-card {
  padding: 24px !important;
  border-radius: 8px !important;
}

::v-deep .recent-activity-card {
  border-radius: 8px !important;
}

::v-deep .card-body {
  padding: 0px !important;
}

::v-deep .card-header {
  padding: 0px !important;
}

::v-deep .apexcharts-legend {
  margin-bottom: 16px !important;
}

::v-deep .apexcharts-legend-marker {
  margin-right: 8px !important;
}

.activity-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #575759;
}

.event-description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1B1B1C;
}

.event-date {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
}

.event-preview-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #006F41;
}

.event-preview-btn:hover {
  cursor: pointer;
}

.scroll {
  max-height: 560px;
  overflow-y: auto;
}

.no-activity-gift {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  max-width: 100%;
  height: 340px;
  overflow-y: scroll;
}

.empty-activity-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #575759;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #575759;
}

::v-deep .apexcharts-text tspan {
  font-size: 14px !important;
}

@media screen and (max-width: 450px) {

  .chart-metrics-card {
    padding: 0px !important;
    padding-top: 5px !important;
  }

  .card-header-text {
    margin-left: 15px;
    margin-top: 10px;
  }

  .event-wrapper {
    padding: 12px !important;
  }

}

@media screen and (min-width: 1118px) {

  .recent-card-columns {
    padding-left: 0px !important;
  }

}

@media screen and (max-width: 1118px) {

  .event-wrapper {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

}

@media screen and (max-width: 1118px) {
  .chart-metrics-card {
    margin-bottom: 24px;
  }

  .event-icon {
    width: 20% !important;
  }

  .event-wrapper {
    padding: 10px !important;
  }

}

@media screen and (max-width: 992px) {

  .view-event {
    padding-left: 80px !important;
  }

  .event-no-view {
    padding-left: 0px !important;
  }

}

@media screen and (max-width: 767px){
  .view-event {
    text-align: end !important;
    padding-right: 10px !important;
  }
}

@media (min-width: 992px) and (max-width: 1051px) {

  .view-event {
    padding-left: 0px !important;
  }
}

</style>