<script>
export default {
  name: "ViewEmailModal",
  components: {},
  data() {
    return {
      email: {},
    };
  },
  methods: {
    viewEmail: async function (emailLogId) {
      let email = await this.$A.EmailService.GetEmailLog(emailLogId);
      if (email != null) {
        this.email = email;
        this.$bvModal.show("ViewEmailModal");
      }
    },
  },
};
</script>

<template>
  <!-- Add Note Modal -->
  <b-modal id="ViewEmailModal" size="lg" scrollable>
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <div class="row">
          <div class="col-md-12" style="margin-bottom: 25px">
            <h4>Email Preview</h4>
          </div>
          <div class="col-md-6">
            <p><b>Email Delivery Status:</b> {{ email.sendStatus }}</p>
            <p><b>Email Subject:</b> {{ email.emailSubject }}</p>
          </div>
          <div class="col-md-6">
            <p><b>Email To:</b> {{ email.emailTo }}</p>
            <p><b>Email Sent At:</b> {{ $A.FormatDate(email.createdAt, true) }}</p>
          </div>
          <div class="col-md-12" style="margin-top:25px;">
            <div class="help-note">
              <h4>Note</h4>
              <p>Please note the email preview below is read-only and links are not clickable.</p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!--    <div v-html="email.htmlBody"></div>-->

    <iframe
        id="emailViewBox"
        :srcdoc="email.htmlBody"
        allowfullscreen
        class="email_frame"
        frameborder="0"
    ></iframe>

    <template v-slot:modal-footer="{ cancel }">
      <div>
        <b-button
            class="gray-btn mt-0"
            @click="cancel()"
        >Close
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.email_frame {
  width: 100%;
  min-height: 800px;
}

#emailViewBox {
  pointer-events: none;
}

.help-note {
  position: relative;
  margin: 0 0 25px;
  padding: 1.5em;
  background: #d4ead9;
  border-radius: 10px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.778em;
}

.help-note h4 {
  display: inline-block;
  position: absolute;
  top: -1.45em;
  left: 1.25em;
  margin: .625em 62.5em 0 0;
  padding: .313em;
  font-size: 16px;
  font-size: 1rem;
  border: 2px solid #267048;
  border-radius: 5px;
  color: #267048;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  min-width: 100px;
  text-align: center;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #DEE3EC;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 14px;
  cursor: pointer;
}


.help-note p {
  margin-bottom: 0px;
}
</style>
