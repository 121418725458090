<script>
import Vue from 'vue';
import draggable from "vuedraggable";
import "vue-code-highlight/themes/prism-tomorrow.css";
import VueCodeHighlight from 'vue-code-highlight';
import warningConfirmationModal from "../../components/modals/modal-warning.vue";

Vue.use(VueCodeHighlight);
import ExifReader from 'exifreader';

const {PDFDocument, PDFName, PDFRawStream, EncryptedPDFError} = require("pdf-lib");

export default {
  components: {
    draggable,
    warningConfirmationModal
  },
  props: {
    isManageFilesModal: {
      required: false,
      type: Boolean
    },
    isSendNewVersionProofModal: {
      required: false,
      type: Boolean
    },
    isCreateProofPage: {
      required: false,
      type: Boolean
    },
    existingVersionFiles: {
      required: false,
      type: Array,
    },
    existingFilesToDelete: {
      required: false,
      type: Array
    },
    isSelectedCurrentVersion: {
      required: false,
      type: Boolean
    },
    isVersionSelectedHidden: {
      required: false,
      type: Boolean
    }
  },
  name: "filepicker",
  methods: {
    debugMe: function (file) {
      return true
    },
    GetIndex: function () {
      return ++this.findex;
    },
    UploadFiles: async function (proofId, proofVersionId) {
      let self = this;
      this.isUploading = true;
      if (proofId === 0 || proofVersionId === 0) {
        throw new Error("Proof Ids Not Set");
      }
      for (let i = 0; i < this.uploading.length; i++) {
        let file = this.uploading[i];
        if ((file.id !== undefined && file.id > 0) || file.skip) {
        } else {
          let completeFunc = function () {
            if (
                document.getElementById("SendNewVersion___BV_modal_body_") !==
                null
            ) {
              self.$scrollTo(
                  document.getElementById("upload_file_" + file.uuid),
                  1000,
                  {container: "#SendNewVersion___BV_modal_body_"}
              );
            } else {
              self.$scrollTo(
                  document.getElementById("upload_file_" + file.uuid),
                  1000,
                  {offset: -60}
              );
            }
          };
          if (file.web === 1) {
            await this.$A.ProofFileService.UploadUrl(
                proofId,
                proofVersionId,
                file,
                completeFunc,
                file.isLiveWebProofing,
            );
          } else {
            await this.$A.ProofFileService.UploadFile(
                proofId,
                proofVersionId,
                file,
                completeFunc
            );
          }
          if (file.noFilesLeft !== undefined) {
            return this.uploading;
          }
        }
      }
      this.isUploading = false;
      // console.log("Uploading Files", this.uploading);
      return this.uploading;
    },
    DeleteFile: function (file) {
      this.uploading.splice(this.uploading.indexOf(file), 1);
    },
    DeleteVersionFile: function (file) {
      if (this.isManageFilesModal && file.id > 0) {
        this.existingVersionFiles.splice(this.existingVersionFiles.indexOf(file), 1);
        this.existingFilesToDelete.push(file);
      } else {
        this.DeleteFile(file);
      }
    },
    GetFileStatus: function () {
      return this.uploading;
    },
    AddUrl: async function ($event) {
      $event.preventDefault();
      if (!this.canAddMoreFiles()) {
        return;
      }
      if (
          this.urlToUpload.length === 0 ||
          !this.$A.IsWebUrl(this.urlToUpload)
      ) {
        this.$A.AlertUser("Please Enter A Valid URL");
        return;
      }
      let urlObject = null;
      try {
        if (!this.urlToUpload.startsWith("http")) {
          this.urlToUpload = "https://" + this.urlToUpload;
        }
        urlObject = new URL(this.urlToUpload);
      } catch (e) {
        this.$A.AlertUser("Unable to validate given URL. Please, check your URL and try again.");
        return;
      }
      if (this.urlToUpload.indexOf('docs.google.com') !== -1) {
        this.$A.AlertUser("Ashore does not currently support Google Docs. Please upload your proof as a Word document.");
        return;
      }
      let scriptDetected = undefined;
      let url = urlObject.href
      let protocol = urlObject.protocol;
      if (this.isLiveWebProofing) {
        let scriptStatus = await this.checkLiveWebProofingUrl(urlObject);
        if (!scriptStatus) {
          return;
        }
        scriptDetected = scriptStatus.scriptFound;
        url = scriptStatus.url;
      }
      this.urlToUpload = "";
      let self = this;
      this.uploading.push({
        uuid: this.GetIndex(),
        id: 0,
        file: {name: url},
        name: url,
        mb: 0,
        web: 1,
        video: 0,
        document: 0,
        image: 0,
        uploadProgress: 0,
        error: "",
        skip: false,
        isLiveWebProofing: self.isLiveWebProofing,
        scriptDetected: scriptDetected,
        protocol: protocol,
        checking: false,
      });
    },
    checkLiveWebProofingUrl: async function (urlObject) {
      this.checkingWebpage = true;
      let url = urlObject.href;
      let scriptStatus = await this.checkScriptOnWebsite(url);
      if (!scriptStatus.scriptFound && urlObject.protocol === "http:") {
        urlObject.protocol = "https:"
        scriptStatus = await this.checkScriptOnWebsite(urlObject.href);
        if (!scriptStatus.loaded) {
          this.$A.AlertUser("Unable to validate URL. Please check the URL and try again.");
          this.checkingWebpage = false;
          return null;
        }
        if (scriptStatus.scriptFound) {
          url = urlObject.href
        }
      }
      this.checkingWebpage = false;
      return {
        url: url,
        scriptFound: scriptStatus.scriptFound
      }
    },
    contains: function (type, array) {
      let has = false;
      array.forEach(function (i) {
        if (type.toLowerCase() === i) {
          has = true;
        }
      });
      return has;
    },
    withFileReader: function (file, callback) {
      let fileReader = new FileReader();
      fileReader.onload = callback;
      fileReader.readAsArrayBuffer(file);
    },
    checkIfImageHasCMYKColor: async function (rec) {
      let self = this;
      this.withFileReader(rec.file, (e) => {
        try {
          let tags = ExifReader.load(e.target.result)
          if (tags.ColorMode && tags.ColorMode.value && tags.ColorMode.value === '4') { // CMYK
            rec.warning = "Warning! You are uploading a file with a CMYK color profile";
            self.$refs.confirmUploadCmykModal.open(rec);
          } else {
            self.uploading.push(rec);
          }
        } catch (err) {
          console.log("error", err);
          self.uploading.push(rec);
        }
      })
    },
    checkPdfForProblems: function (rec) {
      let self = this;
      this.withFileReader(rec.file, async (e) => {
        let hasCMYKColor = false;
        let hasMissingFonts = false;
        try {
          const pdfDoc = await PDFDocument.load(e.target.result);

          hasMissingFonts = self.checkIfPdfHasFontIssues(pdfDoc);

          pdfDoc.getPages().forEach(page => {
            const {Contents, XObject, Font} = page.node.normalizedEntries();

            let refs = []
            XObject.entries().forEach(([, ref]) => refs.push(ref));
            Contents.asArray().forEach(ref => refs.push(ref));

            hasCMYKColor = self.checkIfPdfHasCMYKColor(page, refs);
          })
          if (hasCMYKColor) {
            rec.warning = "Warning! You are uploading a file with a CMYK color profile";
            self.$refs.confirmUploadCmykModal.open(rec);
          } else {
            self.uploading.push(rec);
          }
        } catch (err) {
          let errorMessage = `${err}`;
          const encryptedErrorMessage = "Error: Input document to `PDFDocument.load` is encrypted";
          if (err instanceof EncryptedPDFError || errorMessage.startsWith(encryptedErrorMessage)) {
            $A.AlertUser(
                "Pdf is encrypted and can't be processed.",
                "warning",
                100000
            );
            return;
          }
          self.uploading.push(rec);
        }
      })
    },
    checkIfPdfHasCMYKColor: function (page, refs) {
      return refs.some(ref => {
        const pdfObject = page.doc.context.lookup(ref);
        if (!(pdfObject instanceof PDFRawStream)) return;
        // console.log("pdfObject", pdfObject)
        const colorSpace = pdfObject.dict.lookup(PDFName.of('ColorSpace'))
        if (colorSpace && colorSpace.encodedName)
            // console.log("colorSpace.encodedName", colorSpace.encodedName)
          return (colorSpace && colorSpace.encodedName && colorSpace.encodedName === '/DeviceCMYK');
      });
    },
    checkIfPdfHasFontIssues: function (pdfDoc) {
      console.log("font", pdfDoc.fonts);
    },
    fileChanged: function (event) {
      let self = this;
      let contains = this.contains;
      setTimeout(function () {
        let files = _.get(self, 'filelist', []);
        files.forEach(function (f) {
          if (!self.canAddMoreFiles()) {
            return;
          }
          let type = f.type;
          if (type === "" && f.name.indexOf(".docx")) {
            type =
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          }

          let rec = {
            uuid: self.GetIndex(),
            id: 0,
            file: f,
            name: f.name,
            mb: Math.ceil(f.size / 1000 / 1000),
            audio: contains(type, self.supportedFileTypes.audio),
            web: contains(type, self.supportedFileTypes.html),
            video: contains(type, self.supportedFileTypes.video),
            image: contains(type, self.supportedFileTypes.image),
            document:
                contains(type, self.supportedFileTypes.application) ||
                contains(type, self.supportedFileTypes.unknown) ||
                contains(type, self.supportedFileTypes.text),
            unsupported: false,
            uploadProgress: 0,
            error: "",
            skip: false,
          };
          if ((rec.audio || rec.video) && self.$A.Auth.account.subscriptionState.sku === "free") {
            rec.error = "Upgrade To Access Audio And Video Proofing";
            rec.skip = true;
          }
          if (rec.mb > 2000) {
            rec.error = "File Size Limit is 2gb";
            rec.skip = true;
          }
          if (
              !rec.audio &&
              !rec.web &&
              !rec.video &&
              !rec.image &&
              !rec.document
          ) {
            rec.error = "'" + type + "' is not supported";
            rec.unsupported = true;
            rec.skip = true;
          }

          if (rec.image && type !== 'image/svg+xml' && type !== 'image/gif') {
            self.checkIfImageHasCMYKColor(rec);
          } else if (rec.document && type === 'application/pdf') {
            self.checkPdfForProblems(rec);
          } else {
            self.uploading.push(rec);
          }
        });
        self.filelist = null;
      }, 100);
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      } else {
        return `Uploading ${files.length} Files`;
      }
    },
    canAddMoreFiles: function () {
      let curTotal =
      _.get(this.subState, "remainingFilesOnPlanThisCycle", 0) - this.uploading.length;
      let newTotal = curTotal - this.uploading.length;
      if (newTotal < 0) {
        this.$A.AlertUser(
            "You Have Run Out Files To Send. Upgrade Now To Add More",
            "warning",
            100000
        );
        return false;
      }
      return true;
    },
    checkScriptOnWebsite: function (url) {
      return this.$A.LiveProofService.checkUrl(url);
    },
    checkScriptOnWebsiteAgain: function (event, file) {
      event.preventDefault();
      file.uploadProgress = 1;
      file.uploadProgress = 25;
      file.checking = true;
      this.checkScriptOnWebsite(file.name).then(status => {
        file.scriptDetected = status.scriptFound
        file.uploadProgress = 100;
        setTimeout(() => {
          file.checking = false;
          file.uploadProgress = 0;
        }, 100);
      })
      file.uploadProgress = 100;
    },
    reset: function () {
      this.uploading = [];
    },
    GetFileIDsOrdered: function () {
      return this.allFiles.map(f => f.id);
    },
    refreshIsMobile: function () {
      this.isMobile = window.innerWidth < 992;
    }
  },
  data() {
    return {
      instanceId: this.$A.GetUUID(),
      urlToUpload: "",
      state: 0,
      isUploading: false,
      findex: 0,
      filelist: null,
      uploading: [],
      allFiles: [],
      subState: null,
      supportedFileTypes: {
        video: [
          "video/x-quicktime",
          "video/quicktime",
          "video/xquicktime",
          "video/webm",
          "video/ogg",
          "video/mp4",
        ],
        audio: ["audio/wav", "audio/x-wav", "audio/xwav", "audio/mpeg", "audio/ogg", "audio/mp3"],
        image: [
          "image/jpeg",
          "image/tiff",
          "image/webp",
          "image/png",
          "image/bmp",
          "image/gif",
          "image/svg+xml",
        ],
        html: ["text/html"],
        text: [
          "text/css",
          "text/plain",
          "text/richtext",
          "text/yaml",
          "text/rtf",
          "text/csv",
        ],
        application: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/msword",
          "application/vnd.ms-word.document.macroEnabled.12",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/pdf",
        ],
        unknown: ["application/octet-stream"],
      },
      webpageProofType: "live",
      checkingWebpage: false,
      isMobile: window.innerWidth <= 831
    };
  },
  computed: {
    isLiveWebProofing: function () {
      return this.webpageProofType === "live";
    }
  },
  async mounted() {
    this.subState = await this.$A.BillingService.GetCurrentSubscriptionState();
    window.onresize = this.refreshIsMobile;
  },
  watch: {
    existingVersionFiles: function (newValue) {
      this.allFiles = [...(newValue || []), ...this.uploading];
    },
    uploading: function (newValue) {
      this.allFiles = [...(this.existingVersionFiles || []), ...newValue];
    }
  }
};
</script>

<template>
  <div :id="instanceId">
    <div
        :class=" isCreateProofPage ? 'col-md-12': (isManageFilesModal) || (isSendNewVersionProofModal) ? 'col-md-12 mt-0 px-0 mb-2':''">
      <b-card class="ashore-filepicker">
        <div :class="isManageFilesModal && isVersionSelectedHidden ? 'disabledContext': ''">
          <b-tabs pills card :vertical="!isMobile" class="border-bottom border"
                  :style="isManageFilesModal || isSendNewVersionProofModal ? 'margin: 0px': 'margin: 24px 24px 0px 24px'">
            <b-tab title="Upload Files" active class="upload-switch">
              <b-form-file
                  ref="fileUploadInput"
                  :disabled="isUploading"
                  multiple
                  v-model="filelist"
                  @change="fileChanged($event)"
                  :file-name-formatter="formatNames"
                  data-cy="uploadFilesInput"
              >
                <template slot="placeholder" slot-scope="{ names }">
                  <div :class="isManageFilesModal ? 'col-sm-12': ''">
                    <b-img
                        src="./img/upload-icon.png"
                        style="height: 36px; width: 36px">
                    </b-img>
                    <p class="mb-0">
                      <span class="upload-text">Upload a file</span>
                      <span class="file-text">
                      or drag and drop
                    </span>
                    </p>
                  </div>
                </template>
                <template slot="drop-placeholder" slot-scope="{ names }">
                  <div>
                    <b-img src="./img/upload-icon.png" style="height: 36px; width: 36px"></b-img>
                    <p>
                      <span class="file-text">Drop files to upload</span>
                    </p>
                  </div>
                </template>
              </b-form-file>
              <warning-confirmation-modal
                  ref="confirmUploadCmykModal"
                  name="confirmUploadCmykModal"
                  confirm-button-text="Continue"
                  question="Warning! You are uploading a file with a CMYK color profile"
                  body="Unfortunately, files with CMYK color profile do not always render correctly in internet browsers.
                If the file is a PDF, Ashore will attempt to convert your file to sRGB. Learn more at the link bellow."
                  checkboxText="Don't Show Me This Warning Again"
                  @confirmed="(file) => this.uploading.push(file)"
              />
              <div class="col-md-12 info-upload-text mx-2 mb-sm-3">
                We are currently supporting PDFs, images, Word documents, and HTML files. Business plans also support
                MP4 videos, MP3 and WAV audio files.
              </div>
            </b-tab>
            <b-tab title="Capture Websites">
              <div class="row mb-2 ml-2"
                   :style="isMobile ? 'padding: 24px 20px 0px; margin-left: 0px !important': 'padding: 24px'">
                <div class="custom-control custom-radio col-lg-6 col-md-12">
                  <input
                      type="radio"
                      id="webpage-live-option"
                      name="live"
                      value="live"
                      class="custom-control-input"
                      v-model="webpageProofType"
                  />
                  <label
                      class="custom-control-label pb-2 header-text"
                      for="webpage-live-option">Live Webpage</label>
                  <p class="text-justify checkbox-text">
                    Ashore will display your live webpage in interactive and
                    comment mode, as well as allow approvers to select the
                    resolution.
                  </p>
                </div>
                <div class="custom-control custom-radio col-lg-6 col-md-12">
                  <input
                      type="radio"
                      id="webpage-static-option"
                      name="static"
                      value="static"
                      class="custom-control-input"
                      v-model="webpageProofType"
                  />
                  <label
                      class="custom-control-label pb-2 header-text"
                      for="webpage-static-option"
                  >Webpage Screenshot</label
                  >
                  <p class="text-left text-sm-left checkbox-text">
                    Ashore will capture a static screenshot of your webpage at the
                    following resolutions: 1200px, 992px, 768px, and 576px.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                <span
                    class="ml-4 header-text"
                    :style="(!isLiveWebProofing ? 'opacity: 0.75' : 'opacity: 0')"
                >
                  Take a screenshot of a URL:
                </span>
                </div>
                <div :class="isMobile ? 'col-md-12': 'col-md-9 col-sm-12'">
                  <input
                      type="text"
                      class="form-control website-input"
                      placeholder="Add a Website URL"
                      v-model="urlToUpload"
                  />
                </div>
                <div :class="isManageFilesModal ? 'ml-md-3': isMobile ? 'col-md-12 px-3 mr-0':'col-md-2 col-sm-12 ml-1'">
                  <button class="button add-webpage-btn" @click="AddUrl($event)">
                    <b-spinner
                        variant="success"
                        v-show="checkingWebpage"
                        type="grow"
                        small
                    ></b-spinner>
                    Add Website
                  </button>
                </div>
                <div class="col-md-12 mt-2 mb-1">
                  <p
                      class="info-upload-text ml-4 mb-3"
                      :style="(!isLiveWebProofing ? 'opacity: 0.9' : 'opacity: 0')"
                  >
                    *URL will be captured at 1200px, 992px, 768px, and 576px
                  </p>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <!-- Files to Upload -->
          <div class="col-md-12">
            <!-- Draggable -->
            <draggable
                v-model="allFiles"
                handle=".order-handle"
                :sort="true"
                ghost-class="drag-ghost"
                data-cy="uploadFilesContainer"
                :class="isManageFilesModal || isSendNewVersionProofModal ? 'pt-4': 'p-4'"
            >
              <div :class="file.isLiveWebProofing && file.web > 0 && file.scriptDetected !== undefined && !file.scriptDetected
                   ?  'row uploaded-proof-item-web': file.error ? 'file-error-class uploaded-proof-item-noweb row': 'uploaded-proof-item-noweb row'"
                   v-for="(file, index) in allFiles"
                   :id="'modal_file_' + index"
                   :key="'modal_file_' + index"
              >
                <!-- Draggable + File Name -->
                <div
                    :class="isMobile ?  'col-md-8 flex-row d-flex align-items-center': file.error || file.warning ? 'col-md-6 d-flex justify-content-start align-items-center':'col-md-7 d-flex justify-content-start align-items-center'">
                  <div class="order-handle" style="cursor: grab">
                    <b-img src="/img/move.svg"/>
                  </div>
                  <div :class="file.web > 0 && file.scriptDetected !== undefined ? 'file-data': 'file-data'">
                    <span>{{ file.name }}</span>
                    <!-- LiveWebProofing Section -->
                  </div>
                </div>
                <!--  Web Warnings Messages + LiveWebProofing Script Check Section -->
                <div
                    :class=" file.error || file.warning ? 'col-md-4 d-flex justify-content-end align-items-center':''">
                  <!--  File Warnings -->
                  <span v-if="file.scriptDetected === undefined">
                      <span :class="isMobile ? 'mt-3 mb-2 text-justify':'text-justify file-warning'"
                            v-if="!file.error && file.warning !== undefined && file.warning.length > 0">
                        {{ file.warning }} (<a class="font-weight-bold" style="color:black; opacity: 70%;"
                                               href="https://kb.ashoreapp.com/should-i-upload-cmyk-or-rgb-files/"
                                               target="_blank">Learn More</a>)
                      </span>
                    </span>
                  <!--  File Error -->
                  <span v-if="file.error !== undefined && file.error.length > 0"
                        style="color: #EB4654; padding: 10px">
                    {{ file.error }}
                  </span>
                </div>
                <div
                    :class="file.isLiveWebProofing && file.web > 0 ? 'd-flex': 'd-flex ml-auto align-items-center'">
                    <span v-if="file.web > 0 && file.scriptDetected !== undefined">
                      <span
                          :class="'rounded-circle mr-1 d-inline-block  ' + (file.scriptDetected ? 'bg-success ' : 'bg-danger ')"
                          style="width: 7px; height: 7px;">
                      </span>
                      <span v-if="file.scriptDetected">Script Detected</span>
                      <span v-else>Script Not Detected</span>
                   </span>
                  <span :disabled="isUploading || file.id > 0"
                        @click="DeleteVersionFile(file)"
                        :class="isManageFilesModal && isMobile ? 'col-md-2 delete-file':'ml-3 mr-3 delete-file'"
                  >
                    <b-icon-trash-fill variant="danger"></b-icon-trash-fill>
                  </span>
                </div>
                <!-- LiveWebProofing Script -->
                <div class="w-100 pb-2 px-4 pt-2"
                     v-if="file.isLiveWebProofing && file.web > 0 && file.scriptDetected !== undefined && !file.scriptDetected">
                  <span style="margin-top: 12px">Copy and paste this tracking code into every page of your site, just before the &lt;/body&gt; tag.</span>
                  <br/>
                  <!--Script Code-->
                  <div v-highlight class="rounded mt-2">
                  <pre class="language-markup">
                    <code class="language-markup py-2">
1 &lt;!-- Ashore Embed Code --&gt;
2 &lt;script src="{{ $A.LiveProofService.scriptUrl }}"&gt;&lt;/script&gt;
                    </code>
                  </pre>
                  </div>
                  <!--Script Check -->
                  <div>
                    <button
                        class="check-btn w-100 mt-2 button"
                        :pressed="file.checking"
                        @click="e => checkScriptOnWebsiteAgain(e, file)">
                      <span class="pr-2 ">
                        <img src="/img/check-again-icon.svg"/></span>
                      Check Again
                    </button>
                  </div>
                </div>
                <b-progress v-show="isUploading || file.isLiveWebProofing "
                            :value="file.uploadProgress"
                            class="w-100 modal-progress d-block"></b-progress>
              </div>
            </draggable>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<style scoped>

pre[class*=language-] > code {
  border-left: none !important;
  box-shadow: none !important;
  background: #2d2d2d !important;
  border-radius: 4px !important;
}

:not(pre) > code[data-v-3329659d], pre[data-v-3329659d] {
  margin-bottom: 0px !important;
}

code[class*="language-"], pre[class*="language-"] {
  color: #ccc !important;
}

pre[class*=language-] {
  margin: 0px !important;
}

.drag-ghost {
  opacity: 0.3 !important;
}

.order-handle:active {
  background: #eef2f5;
}

.order-handle {
  cursor: grab;
}

.drop-file {
  border-radius: 6px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #F9F9F9;
  padding: 20px 30px;
  margin-bottom: 10px;
}

.file-warning {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.field-item h3 {
  font-size: 18px;
}

.uploaded-proof-item-noweb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
  margin-bottom: 12px;
  /*padding: 16px 24px;*/
  padding: 0px;
  padding-top: 16px;
}

.uploaded-proof-item-noweb-warnings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
  margin-bottom: 12px;
  padding: 10px 24px;
}

.file-error-class {
  background: linear-gradient(0deg, rgba(235, 70, 84, 0.25), rgba(235, 70, 84, 0.25)), #FFFFFF;
  border: 1px solid #EB4654;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
}

.uploaded-proof-item-web {
  margin-bottom: 12px;
  padding: 0px;
  background: white;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  padding-top: 16px;
}

.upload-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #006F41;
}

.progress {
  height: 5px;
  border-radius: 0px;
  margin-top: 15px;
  padding-right: 0px;
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.modal-progress {
  height: 2px;
  border-radius: 0px;
  padding-top: 16px;
  padding-right: 0px;
  margin-bottom: 0px !important;
  background-color: #F4F4F6;
}

.button-delete {
  margin-right: 10px;
}

.delete-file {
  cursor: pointer;
}

.delete-file:hover {
  cursor: pointer;
}

.disabledContext {
  pointer-events: none;
  opacity: 0.4;
}

.check-btn {
  padding: 9px 17px 9px 15px;
  height: 38px;
  background: #F9F9F9;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
  border: 1px solid #B5B5B8;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-btn:hover {
  color: #3E3E40;
  cursor: pointer;
}


.file-data {
  display: inline-block;
}

.file-errors {
  background: linear-gradient(0deg, rgba(235, 70, 84, 0.25), rgba(235, 70, 84, 0.25)), #FFFFFF;
  border: 1px solid #EB4654;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
}

.website-input {
  padding: 9px 13px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  margin-left: 25px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #019255 !important;
}

.custom-control-input ~ .custom-control-label::before {
  border-color: #019255 !important;
}

.file-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

::v-deep .card.ashore-filepicker {
  border: 0px !important;
  border-radius: 0px !important;
}


::v-deep .custom-file-label, .form-control-file {
  border: 2px dashed #dee2e6 !important;
  border-radius: 6px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #F9F9F9;
  padding: 20px 30px;
  margin-bottom: 10px;
}

.info-upload-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #909092;
}

::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0;
}


::v-deep .card-header {
  border-right: 1px solid #dee2e6;
}

.add-webpage-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 100%;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
}

.checkbox-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

@media screen and (max-width: 500px) {
  .website-input {
    width: 80%;
  }

  .info-upload-text {
    margin-bottom: 10px !important;
    margin-left: 20px !important;
  }

  .add-webpage-btn {
    margin-left: 4rem;
    margin-top: 1rem;
  }

  .header-text {
    margin-left: 14px;
    margin-bottom: 10px;
  }

}

@media (min-width: 500px) and (max-width: 912px) {
  .website-input {
    width: 85%;
  }

  .add-webpage-btn {
    margin-left: 20px;
    margin-top: 1rem;
  }


  ::v-deep .card-header-pills {
    margin-right: -0.625rem;
    display: flex;
    margin-left: -0.625rem;
    align-items: center;
    justify-content: center;
  }

}

@media screen and (max-width: 992px) {

  /deep/ .card-header-pills {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  /deep/ .card-header {
    padding: 24px 24px 14px 24px !important;
    background: #F4F4F6 !important;
    border-bottom: 1px solid #D5D5D8 !important;
    height: 100% !important;
    border-right: 0px !important;
  }

  /deep/ .nav-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    height: 36px;
    background: #EBEBED;
    border-radius: 6px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1B1B1C;
  }

  /deep/ .nav-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    height: 36px;
    background: #EBEBED;
    border-radius: 6px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1B1B1C;
    width: -webkit-fill-available !important;
  }

  /deep/ li.nav-item {
    margin-bottom: 10px !important;
  }

  /deep/ .custom-file-label, .form-control-file {
    border: 2px dashed #dee2e6 !important;
    border-radius: 6px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #F9F9F9;
    padding: 20px 3px;
    margin-bottom: 10px;
  }

  .info-upload-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #909092;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 24px !important;
  }

  .website-input {
    width: 90% !important;
  }

  .checkbox-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707073;
    text-align: justify !important;
  }

  .add-webpage-btn {
    margin-left: 24px !important;
    width: 90% !important;
  }

}


</style>
