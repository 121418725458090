<script>
import modalEditApprovers from "../../components/modals/modal-editapprovers.vue";
import ApproverThumbnails from "./approver-thumbnails";

export default {
  name: "approvers",
  components: {
    ApproverThumbnails,
    modalEditApprovers,
  },
  async mounted() {
  },
  data() {
    return {
      fields: [
        {
          key: 'stagesinfo',
          sortable: false,
          label: "STAGE",
        },
        {
          key: 'status',
          sortable: false,
          label: "STATUS",
        },
        {
          key: 'all_users',
          sortable: false,
          label: "REVIEWERS",
        },
        {
          key: 'details',
          sortable: false,
          label: "",
          thStyle: {width: "20%"}
        },
      ],
      colorPerUserId: {},
    }
  },
  props: {
    proof: {
      required: true,
      type: Object,
    },
    activeIds: {
      type: Object,
      default: {},
    },
  },
  methods: {
    getStatusBg: function (curStage) {
      let statusColors = "";
      if (curStage) {
        switch (curStage.statusType) {
          case 1:
            statusColors = "background-color:#EBEBED; color:#252526";
            break;
          case 3:
            statusColors = "background-color:#FDF0BD;color:#574500 ";
            break;
          case 4:
            statusColors = "background-color:#FABBC0;color:#A62934";
            break;
          default:
            statusColors = "background-color:#D1F6D4; color:#264C29";
            break;
        }
      }
      return statusColors;
    },
    getColor: function (userId, luminosity = "dark") {
      if (this.colorPerUserId[userId]) {
        return this.colorPerUserId[userId]
      }
      let seed = Object.keys(this.colorPerUserId).length * 1000;
      let color = this.$randomColor({luminosity: luminosity, seed: seed})
      this.colorPerUserId[userId] = color;
      return color;
    },
    formatStatusLabels: function (status) {
       return status.replace('/', ' of ');
    }
  },
  computed: {
    curStageId: function () {
      return this.proof.currentVersion.currentStage === undefined
          ? 0
          : this.proof.currentVersion.currentStage.id;
    },
    stages: function () {
      const _stages = this.proof.currentVersion.stages !== undefined
          ? this.proof.currentVersion.stages
          : [];

      _stages.forEach((s) => {
        s.approvers.forEach((a) => {
          a.color = this.getColor(a.id);
        });
        s.reviewers.forEach((r) => {
          r.color = this.getColor(r.id);
        });
        s.all_users = [...s.approvers, ...s.reviewers];
      });

      return _stages;
    },
  },
  watch: {
    proof: function () {
      let curStageId =
          this.proof.currentVersion.currentStage === undefined
              ? 0
              : this.proof.currentVersion.currentStage.id;
      let self = this;
      (this.proof.currentVersion.stages !== undefined
              ? this.proof.currentVersion.stages
              : []
      ).forEach(function (s) {
        if (s.id === curStageId) {
          setTimeout(function () {
            self.$scrollTo("#approverStageRow_" + s.id, 1000, {
              container: "#approver-list",
            });
          }, 100);
        }
      });
    },
  },
};
</script>

<template>
  <!--Approvers Status -->
  <div class="col-md-12">
    <div class="jumbotron approver-stages-list jumbotron-fluid" id="approver-list">
      <span class="workflow-card-header">
        Workflow {{ proof.currentVersion.currentStage === undefined ? "" : proof.currentVersion.currentStage.name }}
      </span>
      <p class="workflow-card-sub-text"
         v-show="
          proof.currentVersion.currentStage !== undefined
            ? proof.currentVersion.currentStage.approvers
            : [] === []
        "
      >
        Add a contact to this proof by sharing your review link, or editing
        the workflow.
      </p>
      <!-- Stages Table -->
      <b-table class="mt-4 stages-table table-borderless mb-0"
               :items="stages"
               :fields="fields"
               responsive
               thead-class="stages-table-bg"
      >
        <template #head()="data">
          <span class="ml-auto header-table">{{ data.label }}</span>
        </template>
        <template #cell(stagesinfo)="data">
          <div class="col-md-12 d-flex align-items-center pl-0">
            <span class="stage-dot-black" v-if="data.item.id === curStageId"></span>
            <span class="stage-dot-white" v-if="data.item.id != curStageId"></span>
            <span class="column-header-text pl-12 text-gray-900">{{ data.item.name }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="col-md-6 col-sm-12 px-0">
            <div class="status-rounded-pill" :style="getStatusBg(data.item)">
              {{ formatStatusLabels(data.item.stageStatus) }}
            </div>
          </div>
        </template>
        <template #cell(all_users)="data">
          <approver-thumbnails :approvers="data.item.all_users" :active-ids="activeIds"/>
        </template>
        <template #cell(details)="data">
          <div style="text-align: end">
            <a class="view-details-text pr-md-3"
               @click="proof.archived ? $A.AlertUser('Proof Is Archived. Can not Edit.')
                 : $refs.editApproversModal.open(proof, data.item)"
            >
              View Details
            </a>
          </div>
        </template>
      </b-table>
    </div>
    <modalEditApprovers ref="editApproversModal"/>
  </div>
</template>

<style scoped>

.workflow-card-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.workflow-card-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
  margin-top: 8px;
}

/deep/ .stages-table-bg {
  background: #F4F4F6;
}

.stages-table {
  border: 1px solid #D5D5D8;
  border-radius: 8px;
}

.view-details-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #006F41;
  cursor: pointer;
}

.status-rounded-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 20px;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #264C29;
  width: max-content;
}

::v-deep .stages-table .table, .table-responsive > .table, [class*=table-responsive-] > .table {
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0px;
}

::v-deep .stages-table .table thead th {
  border-bottom: 1px solid #D5D5D8;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #707073;
  padding: 12px 24px 12px 24px;
}

::v-deep .stages-table .table thead th:first-child {
  padding: 12px 24px 12px 44px;
}

::v-deep .stages-table table td {
  padding: 20px 0px 16px 24px !important;
  vertical-align: middle !important;
}

.stage-dot-black {
  width: 8px;
  height: 8px;
  background: #444444;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 0px;
}

.stage-dot-white {
  width: 8px;
  height: 8px;
  background-color: #F9F9F9;
  border-radius: 50%;
  display: inline-block;
}

.approver-stages-list {
  padding: 24px !important;
  width: 100%;
  margin-bottom: 0;
  box-shadow: 0px 1px 3px 0px rgba(8, 11, 13, 0.1);
}

@media screen and (max-width: 578px) {

  ::v-deep .stages-table .table, .table-responsive > .table, [class*=table-responsive-] > .table {
    width: 600px !important;
  }

  .view-details-text {
    padding-right: 10px;
  }

}


</style>
