import { render, staticRenderFns } from "./modal-delete-property.vue?vue&type=template&id=2b9f5787&scoped=true&"
import script from "./modal-delete-property.vue?vue&type=script&lang=js&"
export * from "./modal-delete-property.vue?vue&type=script&lang=js&"
import style0 from "./modal-delete-property.vue?vue&type=style&index=0&id=2b9f5787&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9f5787",
  null
  
)

/* custom blocks */
import block0 from "./modal-delete-property.vue?vue&type=custom&index=0&blockType=editPropertyModal&ref=editpropertyModal&v-on%3ApropertySaved=getAllProperties"
if (typeof block0 === 'function') block0(component)
import block1 from "./modal-delete-property.vue?vue&type=custom&index=1&blockType=confirm-modal&ref=confirmDeleteModal&v-on%3A=true&body=This%20action%20can't%20be%20undone&question=Are%20you%20sure%20to%20delete%20this%20property%3F&name=confirmModalId&confirmButtonText=Yes%2C%20Delete&buttonCss=bg-success"
if (typeof block1 === 'function') block1(component)

export default component.exports