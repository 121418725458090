<script>
import redactor from "../../components/form/redactor.vue";
import SelectApprovers from "../form/selectApprovers";

const DisplayModeSendMessage = 1;
const DisplayModeManageVersions = 2;

export default {
  name: "modalUpdatedProofOptions",
  components: {
    SelectApprovers,
    redactor,
  },
  props: {
    proof: {
      required: false,
      type: Object,
    },
    curUser: {},
  },
  data() {
    return {
      subjectLine: "",
      templates: [],
      curTemplate: 0,
      sendMessageOptionSelected: 'Send message to contacts',
      sendMessageOptions: [
        {name: 'Send message to contacts'},
        {name: 'Do not send message'}
      ],
      selectedApprovers: [],
      selectedReviewers: [],
      alertMessage: "",
      alertType: "",
      displayMode: DisplayModeManageVersions
    };
  },
  async mounted() {
    let self = this;
    this.$root.$on("show::send::message::modal", (displayMode) => {
      self.displayMode = displayMode || 2;
    });
    this.clearAlert();
    await this.getTemplates();
  },
  methods: {
    sendMessageToSelectedApprovers: async function () {
      if (this.sendMessageOptionSelected !== 'Do not send message') {
        let selectedApprovers = this.$refs.selectApproversInput.getSelectedApprovers();
        let selectedReviewers = this.$refs.selectReviewersInput.getSelectedApprovers();
        if (selectedApprovers.length === 0 && selectedReviewers.length === 0) {
          this.$A.AlertUser("Please add a contact before submitting the message.");
          return;
        }
        let selectedApproverIds = selectedApprovers.map(a => a.id);
        let selectedReviewersIds = selectedReviewers.map(a => a.id);
        let body = this.$refs.redactor.getTextBody();
        if (body.length === 0) {
          this.$A.AlertUser("Please add a message to the approvers before submitting the message.");
          return;
        }
        let response = await this.$A.ProofService.SendMessageApprover(
            this.$props.proof.id,
            selectedApproverIds,
            selectedReviewersIds,
            3,
            this.subjectLine,
            body
        );
        if (response.success) {
          this.$refs.redactor.setTextBody("");
          this.$bvModal.hide("UpdatedProofOptions");
          this.$root.$emit("proof::refresh");
        }
      } else {
        this.$bvModal.hide("UpdatedProofOptions");
      }
    },
    getTemplates: async function () {
      this.templates = await this.$A.TemplateService.GetAll();
      let defaultOption = {
        name: "Select A Template",
        id: 0,
        html: "",
        subject: "",
      };
      this.templates.push(defaultOption);
    },
    cancel: function () {
      this.curTemplate = 0;
      this.subjectLine = "";
      this.clearAlert();
    },
    useTemplate: async function () {
      let selectedTemplate = this.templates.filter(
          (f) => f.id === this.curTemplate
      )[0];
      this.subjectLine = selectedTemplate.subject;
      this.$refs.redactor.setTextBody(selectedTemplate.html);
    },
    AlertUser(message, type) {
      let self = this;
      self.alertMessage = message;
      self.alertType = type;
      setTimeout(function () {
        self.alertMessage = "";
        self.alertType = "";
      }, 5000);
    },
    clearAlert: function () {
      this.alertMessage = "";
      this.alertType = "";
    },
    shouldDisplayModalBody: function () {
      return this.displayMode === 2 && this.sendMessageOptionSelected !== 'Do not send message' ||
          this.displayMode === 1;
    },
    setAlertClassColor: function () {
      if (this.alertType && this.alertType === 'warning') {
        return 'alert-warning';
      } else if (this.alertType && this.alertType === 'success') {
        return 'alert-success';
      } else if (this.alertType && this.alertType === 'error') {
        return 'alert-error';
      }
      return '';
    },
    setDefaults: function() {
      this.clearAlert();
      this.selectedApprovers = this.availableApprovers;
      this.selectedReviewers = this.availableReviewers;
    }
  },
  computed : {
    commentFileApi: function () {
      if (this.proof) {
        const apiToken = _.get(this, 'curUser.apiToken.token', "")
        let apiUrl = `/annotation/comment-file/${this.proof.publicId}?u=${apiToken}`;
        return apiUrl;
      }
      return "";
    },
    availableApprovers : function(){
      if (this.proof.currentVersion.currentStage && this.proof.currentVersion.currentStage.approvers.length > 0) {
        return this.proof.currentVersion.currentStage.approvers;
      }
      return [];
    },
    availableReviewers : function(){
      if (this.proof.currentVersion.currentStage && this.proof.currentVersion.currentStage.approvers.length > 0) {
        return this.proof.currentVersion.currentStage.reviewers;
      }
      return [];
    }
  }
};
</script>

<template>
  <!-- Send Message Modal -->
  <b-modal id="UpdatedProofOptions" size="lg" @hidden="cancel" @show="setDefaults">
    <template v-slot:modal-header>
      <div v-if="alertMessage" :class="'alert mb-0 w-100 text-center alert-modal-class ' + setAlertClassColor()"
           style="z-index: 3000" role="alert">
        <span v-html="alertMessage" class="ml-auto"></span>
        <span @click="clearAlert()" class="close-alert">X</span>
      </div>
      <alertHeader/>
      <div class="w-100">
        <!-- Header -->
        <div class="option-title">
          {{ displayMode === 1 ? 'Send a Message to Current Workflow Stage' : 'Update Proof Options' }}
        </div>
        <span class="option-sub-text">{{
            displayMode === 1 ? 'Your message will include a link to review the proof unless you include {% assign hideReviewButton = true %}' : 'You have updated the current proof version with new files. Would you like to notify your approvers and commenters?'
          }}</span>
      </div>
    </template>
    <!--  Send Message Radio Options -->
    <div v-show="displayMode === 2">
      <b-form-radio-group
          v-model="sendMessageOptionSelected"
          :options="sendMessageOptions"
          class="mb-3 send-msg-text"
          value-field="name"
          text-field="name"
          disabled-field="notEnabled"
      ></b-form-radio-group>
    </div>
    <div v-show="shouldDisplayModalBody()">
      <div class="message-text">Message</div>
      <div class="input-text">Send message to approvers</div>
      <selectApprovers
          ref="selectApproversInput"
          placeholder="Search and Select..."
          class="w-100 col-12 px-0"
          style="margin-bottom: 10px"
          :is-update-proof-options-modal="true"
          :selected-approvers="selectedApprovers"
          :available-approvers="availableApprovers"
      />
      <div class="input-text">Send message to commenters</div>
      <selectApprovers
          ref="selectReviewersInput"
          placeholder="Search and Select..."
          class="w-100 col-12 px-0"
          style="margin-bottom: 10px"
          :is-update-proof-options-modal="true"
          :selected-approvers="selectedReviewers"
          :available-approvers="availableReviewers"
      />
      <div class="input-text">Select email template</div>
      <form role="form" style="margin-bottom: 10px">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <select
                  class="proof-option-email-template w-100"
                  @change="useTemplate()"
                  v-model="curTemplate"
              >
                <option :value="template.id" v-for="template in templates">
                  {{ template.name }}
                </option>
              </select>
            </div>
            <div class="input-text">Subject line</div>
            <div class="form-group">
              <input
                  name="Subject Line"
                  class="subject-line w-100"
                  v-model="subjectLine"
              />
            </div>
            <div class="input-text">Email body</div>
            <redactor ref="redactor"
                      :commentApiUrl="commentFileApi"
            />
          </div>
        </div>
      </form>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end">
        <b-button
            class="footer-gray-btn mr-2"
            @click="cancel()"
        >Cancel
        </b-button
        >
        <b-button
            class="green-btn"
            @click="sendMessageToSelectedApprovers()"
        > {{ displayMode === 1 ? 'Send Now' : 'Confirm' }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.option-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  margin-bottom: 20px;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 130px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}


.footer-gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.footer-gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

::v-deep .proof-option-email-template {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 13px 1px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

::v-deep .modal-footer {
  border-top: none !important;
}

.option-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
  margin-bottom: 20px;
}

::v-deep .send-msg-text label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  background: #006F41;
  border-radius: 8px;
}

.message-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

.input-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
  margin-bottom: 4px;
}

.subject-line {
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  padding: 9px 13px;
}

::v-deep .modal-header {
  padding: 24px 24px 0px 24px !important;
}

::v-deep .modal-body {
  padding: 24px !important;
}

::v-deep .subject-line:focus {
  border-color: #B5B5B8 !important;
}

.close-alert {
  cursor: pointer;
  float: right;
}

.alert-modal-class {
  position: absolute;
  min-height: 0;
  margin: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 888;
}

</style>