<script>
import {PrismEditor} from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-css";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-coy.css";

export default {
  components: {
    PrismEditor,
  },
  name: "styleEditor",
  mounted: async function () {
    let storedCss = await this.$A.AccountService.GetReviewStylesheet();
    this.oldCss = storedCss && storedCss.stringValue ? storedCss.stringValue : this.defaultCss;
    this.newCss = this.oldCss;
  },
  methods: {
    highlighter(css) {
      return highlight(css, {...languages['css'], ...languages['markup'], ...languages['js']}, 'markup');
    },
    resetEditorStyle() {
      this.newCss = this.defaultCss;
      try {
        this.$A.AccountService.SaveReviewStylesheet(this.newCss);
        self.$A.AlertUser(
            "Custom Style Saved",
            "success",
            10000
        );
      } catch (err) {
        console.log("error", err);
        self.$A.AlertUser(
            "Failed to save style. Please, try again.",
            "warning",
            10000
        );
      }
    },
    onSave() {
      if (this.oldCss !== this.newCss) {
        try {
          this.$A.AccountService.SaveReviewStylesheet(this.newCss);
          this.oldCss = this.newCss;
          self.$A.AlertUser(
              "Custom Style Saved",
              "success",
              10000
          );
        } catch (err) {
          console.log("error", err);
          self.$A.AlertUser(
              "Failed to save style. Please, try again.",
              "warning",
              10000
          );
        }
      }
    },
  },
  data() {
    return {
      user: this.$A.Auth,
      oldCss: "",
      newCss: "",
      defaultCss: require('./default-style.js').default,
      lineNumbers: true,
      visibleEditor: false,
      show: false,
    }
  },

};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="text-dark d-flex justify-content-center flex-md-wrap px-md-5 row">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10">
      <b-card-body class="mb-4">
        <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 pl-lg-3 text-dark on-mobile header-text">Custom Review Screen
          Styles
        </b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 pl-lg-3 on-mobile description-text">Customize your review screen with your
          own CSS.
        </b-card-text>
        <div class="px-4">
          <prism-editor
              class="style-editor rounded"
              v-model="newCss"
              :highlight="highlighter"
              :line-numbers="lineNumbers"
              data-cy="cssEditor"
          >
          </prism-editor>
        </div>
      </b-card-body>
      <div class="style-footer">
        <div class="d-flex col-sm-6 col-md-2 pr-lg-0 ml-auto justify-content-sm-around justify-content-lg-end">
          <b-button
              id="resetStyle"
              class="button button-dark button-gray button-style"
              style="background-color: #EEF2F5;"
              variant="secondary"
              @click="resetEditorStyle"
          >Reset
          </b-button>
          <b-button
              class="button button-green button-style ml-2 mr-sm-4"
              @click="onSave"
              data-cy="saveCssBtn"
          >Save
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style scoped>

.style-editor {
  background-color: #EBEBED;
  font-style: normal;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  padding: 5px 10px;
  height: 200px;
  border-radius: 6px !important;
}

div > .prism-editor-wrapper >>> textarea {
  outline: none !important;
  box-shadow: none !important;
}

.checklist-item p {
  margin-bottom: 0 !important;
}

.user-button img {
  height: 15px;
  margin-left: 10px;
}

.checklist li {
  padding: 30px 0 30px;
  border-bottom: 1px solid rgba(68, 68, 68, 0.5);
  overflow: auto;
  font-weight: normal;
  margin-bottom: 25px;
}

.button-style {
  padding: 8px !important;
  border-radius: 5px;
  font-weight: normal !important;
  display: block;
  font-weight: normal;
  background-color: #019256;
  width: 108px;
  height: 38px;
}

::v-deep .card-body {
  padding: 0px;
}

.style-footer {
  padding: 12px 24px;
  background-color: #F4F4F6;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

@media (max-width: 500px) {
  .button-style {
    width: 100%;
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
    margin-right: 10px;
  }

  .on-mobile {
    padding-left: 18px;
  }
}

</style>
