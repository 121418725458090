<script>
const _ = require('lodash');

export default {
  name: "productCodeRedemption",
  methods: {
    getCurrentSub: async function () {
      this.currentSubscription = await this.$A.BillingService.GetCurrentSubscription();
      this.currentSubscription.plan.isUpgrade = false;
      this.currentSubscription.plan.isDownGrade = false;
      this.plans = [this.currentSubscription.plan];
      await this.$A.UpdateCurrentSubscriptionState(
        this.currentSubscription.subscriptionState
      );
    },
    getAllCodes: async function () {
      this.productCodes = await this.$A.ProductCodeService.GetAllCodesOnAccount();
    },
    redeemProductCode: async function () {
      if (this.productCode.length === 0) {
        this.$A.AlertUser("Product Code Needs To Be Set");
        return;
      }

      let response = await this.$A.ProductCodeService.RedeemProductCode(
        this.productCode
      );
      if (response != null && response.success === true) {
        this.$A.AlertUser("Product Code Has Been Redeemed!");
      } else {
        this.$A.AlertUser(response.message);
      }
      await this.getCurrentSub();
      await this.getAllCodes();
    },
    featureModifications: (feature) => {
      if (feature.indexOf("GB Storage") !== -1) {
        let int = parseInt(feature.split()[0]);
        if (int > 999) {
          int = (int / 1000).toPrecision(2);
          feature = int + " TB Storage";
        }
      }
      return feature;
    },
  },
  data() {
    return {
      productCode: "",
      currentSubscription: {
        planSku: this.$A.Auth.account.subscriptionState.sku,
        plan: {
          publicCyclePrice: "",
        },
        subscriptionState: {
          remainingProofsOnPlan: 0,
        },
      },
      user: this.$A.Auth,
      plans: [],
      productCodes: [],
    };
  },
  async mounted() {
    await this.$A.GetCurAccountData();

    let self = this;
    if (this.$route.query.c !== undefined) {
      this.productCode = this.$route.query.c.toUpperCase();
    }
    await this.getCurrentSub();
    await this.getAllCodes();
  },
  computed: {},
  components: {},
};
</script>

<template>
  <div class="row">
    <div class="col-md-12 mb-25">
      <h2>Available Plans</h2>
    </div>
    <div class="col-md-7">
      <div v-for="plan in plans" :key="plan.id" class="current_plan">
        <div class="pricing-plan row">
          <div class="col-md-4">
            <img :src="plan.iconUri" />
            <h3>{{ plan.publicName }}</h3>
            <p class="mt-10 pricing-stat">{{ plan.publicCyclePrice }}</p>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <h4>Features</h4>
                <ul class="price-details">
                  <li v-for="feature in plan.features">
                    {{ featureModifications(feature) }}
                  </li>
                </ul>
              </div>
              <div class="col-md-6" v-show="plan.sku !== 'suspended'">
                <h4>Proof Types</h4>
                <ul class="price-details">
                  <li v-for="type in plan.proofTypes">{{ type }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table style="width: 100%">
        <thead>
          <tr>
            <td><strong>Product Code</strong></td>
            <td><strong>Redeemed On</strong></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="code in productCodes" :key="code.id">
            <td>{{ code.codeString }}</td>
            <td>{{ $A.FormatDate(code.redeemedAt) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-md-4 offset-md-1">
      <div class="plan-details">
        <!--        <div class="active-plan" v-show="currentSubscription.active!==undefined">-->
        <!--          <h4 style="opacity:.5;">Your Plan:</h4>-->
        <!--          <h2>{{currentSubscription.plan.publicName}}</h2>-->
        <!--        </div>-->

        <div class="form-group">
          <label class="">Redeem Product Code:</label>
          <input type="text" v-model="productCode" class="form-control" />
        </div>

        <button
          class="button button-light button-green button-block mt-10"
          @click="redeemProductCode()"
        >
          Redeem Code
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.current_plan .pricing-plan {
  border: 4px solid #019255 !important;
}

.selected_plan .pricing-plan {
  border: 4px solid #f4bf78;
}
.pricing-plan {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #6c737e;
  transition: all 0.3s ease;
  padding: 30px 15px;
  margin-bottom: 25px;
  cursor: pointer;
}

.price-details {
  margin-bottom: 30px;
}

.price-details img {
  height: 60px;
}

.pricing-plan h4 {
  font-size: 12px;
  font-weight: 900;
  color: #4a4a4a !important;
  text-transform: uppercase;
}

.pricing-plan h2 {
  margin-bottom: 30px;
  color: #4a4a4a !important;
  font-weight: 900;
}

.pricing-plan .list li {
  font-size: 12.4px;
  margin-top: 16px;
  color: #4a4a4a !important;
}

.field-item span {
  opacity: 1;
  display: block;
  margin-top: 0px;
}

.ashore-toggle .slider {
  background-color: #dee3ec;
}

.plan-toggle span {
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
}

.plan-toggle img {
  height: 20px;
  float: right;
}

.plan-toggle img:hover {
  cursor: pointer;
}

.pricing-plan h3 {
  color: #494949;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 35px;
  font-size: 14px;
  text-transform: uppercase;
}

.pricing-plan ul,
.pricing-plan li {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.pricing-plan li,
.pricing-plan p {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
}

.pricing-plan span {
  font-size: 12px;
  line-height: 1.5;
  padding: 5px 0;
  margin-bottom: 0;
}

.pricing-stat {
  opacity: 0.75;
  text-transform: uppercase;
}

.active-plan {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
}
</style>
