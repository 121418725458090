<script>
import ReminderFrequency from "../form/reminderFrequency";

export default {
  name: "approverSettings",
  components: {
    ReminderFrequency,
  },
  methods: {
    edit: async function (approver) {
      let approverId = Number.isInteger(approver)
          ? approver
          : approver.id !== undefined
              ? approver.id
              : 0;
      if (approverId > 0) {
        this.mode = 2;
        let approver = await this.$A.ApproverService.GetWithStats(approverId);
        const curApproverId  = _.get(approver, 'approver.id', 0);
        if (curApproverId > 0) {
          this.editingApprover = approver.approver;
          this.editingApprover.avgResponseRate = approver.avgResponseRate;
          this.editingApprover.avgRoundsOfRevision =
              approver.avgRoundsOfRevision;
          this.$emit("loadedApprover", this.editingApprover);
          if (
              this.editingApprover.frequency !== undefined &&
              this.editingApprover.timeOfDayToSend !== undefined
          ) {
            this.$refs.reminderFrequency.setData(
                this.editingApprover.timeOfDayToSend,
                this.editingApprover.frequency,
                this.editingApprover.timeZone
            );
          }
        }
      }
    },
    save: async function (instanceId) {
      // console.log("Saving Approver");
      this.editingApprover.frequency = this.$refs.reminderFrequency.frequency;
      this.editingApprover.timeOfDayToSend = this.$refs.reminderFrequency.time_of_day_to_send;
      this.editingApprover.utcOffsetMin = this.$refs.reminderFrequency.utcOffsetMin;
      this.editingApprover.timeZone = this.$refs.reminderFrequency.timeZone;

      if (!this.$A.IsEmailValid(this.editingApprover.email)) {
        return;
      }
      let approverResponse = await this.$A.ApproverService.Save(
          this.editingApprover
      );
      // console.log("Approver Saved",approverResponse);
      if (
          approverResponse != null &&
          approverResponse.id > 0 &&
          this.mode === 1
      ) {
        this.editingApprover.id = approverResponse.id;
        // console.log("Approver created",approverResponse);
        this.$emit("approverCreated", this.editingApprover);
        // console.log("Approver created event emitted",approverResponse);
      }
      if (
          approverResponse != null &&
          approverResponse.id > 0 &&
          this.mode === 2
      ) {
        this.$root.$emit("approver::updated", {
          approver: this.editingApprover,
          instanceId: instanceId,
        });
        // console.log("Approver updated",approverResponse);
      }
    },
  },
  mounted() {
    this.mode = 1;
    let self = this;
    if (
        this.$A.viewToViewData != null &&
        this.$A.viewToViewData.approver !== undefined
    ) {
      this.edit(this.$A.viewToViewData.approver.id);
    }

    this.$root.$on("delete::approver::confirmed", async () => {
      if (self.editingApprover.id > 0) {
        await self.$A.ApproverService.DeleteSingle(self.editingApprover.id);
        setTimeout(function () {
          self.$A.NavigateToView("/contact-archive", {});
        }, 1000);
      }
    });
  },
  data() {
    return {
      user: this.$A.Auth,
      mode: 0,
      editingApprover: {
        id: null,
        name: "",
        email: "",
      },
      isMobile: window.innerWidth <= 767,
    };
  },
};
</script>

<template>
  <div class="pb-4 m-3">
    <div class="row">
      <div :class="isMobile ? 'col-md-6 px-0': 'col-md-6 pl-0'">
        <div class="form-group">
          <label class="label-text">Name</label>
          <input
              name="name"
              type="text"
              placeholder="Contact Name"
              class="form-control contact-input"
              v-model="editingApprover.name"
              data-cy="contactName"
          />
        </div>
      </div>
      <div :class="isMobile ? 'col-md-6 px-0': 'col-md-6 pr-0'">
        <div
            :class="[
            'form-group',
            $A.IsEmailValid(editingApprover.email)
              ? 'has-success'
              : 'has-error',
          ]"
        >
          <label class="label-text">Email</label>
          <input
              name="email"
              type="email"
              placeholder="Email"
              class="form-control contact-input"
              v-model="editingApprover.email"
              :style="{
              color: $A.IsEmailValid(editingApprover.email) ? '' : 'red',
            }"
              data-cy="approverEmail"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-15 mt-25 px-0">
        <div class="sub-header-text">Approver Settings</div>
        <hr>
      </div>
    </div>
    <reminder-frequency ref="reminderFrequency"/>
  </div>

  <!-- /Field -->
</template>

<style>

.field-item h3 {
  font-size: 18px;
}

.label-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.sub-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.contact-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

::v-deep .modal-body {
  padding: 0px !important;
}


</style>
