<script>
import modalEditUser from "../../components/modals/modal-edituser.vue";
import deleteUserModal from "../../components/modals/modal-delete-user.vue";
import createUserSideBar from "../../components/settings/setting-create-user-sidebar.vue";

export default {
  name: "accountUsers",
  components: {
    modalEditUser,
    deleteUserModal,
    createUserSideBar
  },
  methods: {
    editUser: function (user) {
      this.$refs.userEditor.edit(user);
    },
    deleteUser: function (user) {
      this.$refs.delUserModal.open(user, this.users);
    },
    CopyInviteUrlToClipBoard: async function (user) {
      let invite = this.pendingInvites[user.id];
      if (invite) {
        this.$A.CopyStringToClipboard(invite.inviteUrl);
        this.$A.AlertUser(
            invite.inviteUrl + " has been copied to your clipboard.",
            "success",
            10000
        );
      }
    },
    ResendUserInvite: function (user) {
      user && this.$A.UserService.UpdateUser(user.id)
      this.$A.AlertUser(
          "Invitation Expired",
          "success",
          10000
      );
    },
    updateUsers: async function (emitUpdate = true) {
      await this.loadPendingInvites();
      this.users = await this.$A.AccountService.All() || [];
      if (emitUpdate) this.$emit('updated');
    },
    loadPendingInvites: async function () {
      let pendingInvites = await this.$A.AccountService.GetAllPendingInvites() || [];
      let self = this;
      pendingInvites.forEach(function (invite) {
        self.pendingInvites[invite.userId] = invite;
      });
    },
    loadData: async function () {
      await this.loadPendingInvites();
      this.users = await this.$A.AccountService.All() || [];
      this.roles = await this.$A.RoleService.GetRoles() || [];
      this.teams = await this.$A.TeamService.GetAccountTeams() || [];

    },
    setRoles: function (roles) {
      this.roles = roles;
    },
    setTeams: function (teams) {
      this.teams = teams;
    }
  },
  data() {
    return {
      users: [],
      teams: [],
      roles: [],
      pendingInvites: {},
      fields: [
        {
          key: 'userinfo',
          sortable: false,
          label: "NAME"
        },
        {
          key: 'contact',
          label: "CONTACT",
        },
        {
          key: 'role',
          sortable: false,
          label: "ROLE"
        },
        {
          key: 'edit',
          sortable: false,
          label: "",
          thStyle: {width: "3%"}
        },
        {
          key: 'delete',
          sortable: false,
          label: "",
          thStyle: {width: "3%"}
        },
      ]
    };
  },
  computed: {
    canCreateMoreUsers: function () {
      const userLimit = _.get(this, '$A.Auth.account.subscriptionState.userLimit', 0);
      return !userLimit || this.users.length < userLimit;
    }
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10 account-user-card">
      <b-card-body class="rounded">
        <b-card-text class="mb-2 mx-md-4 mt-3 pl-md-2 text-dark account-users-header rounded">Users</b-card-text>
        <b-card-text class="mx-md-4 pl-md-2 description-text">Add and manage users on your account.</b-card-text>
        <b-table class="px-4 pb-4 pt-2 rounded user-table"
                 :items="users"
                 :fields="fields"
                 responsive
                 hover
                 outlined
                 thead-class="user-table-bg">
          <template #head()="data">
            <span class="table-header-text">{{ data.label }}</span>
          </template>
          <template #cell(userinfo)="data">
            <div class="d-flex">
              <b-avatar :src="data.item.profileImageUrl"/>
              <div class="d-flex flex-column ml-2">
                <span class="ml-2 column-header-text">{{ data.item.name }}</span>
                <div class="row-cols-sm-8 row-cols-lg-3 ml-sm-2 ml-md-0">
                  <span class="ml-2 mt-1 column-sub-text">
                    {{ data.item.title }}
                  </span>
                  <span style="color: #707073"
                        v-show="!data.item.emailVerified && pendingInvites[data.item.id]">|</span>
                  <span
                      v-b-tooltip.hover
                      title="Click to copy invite link"
                      @click="CopyInviteUrlToClipBoard(data.item)"
                      v-show="!data.item.emailVerified && pendingInvites[data.item.id]"
                      class="invite_link"
                      style="color: #019256 ; text-decoration-line: underline;">Resend Invite</span>
                  <span
                      v-b-tooltip.hover
                      title="Invitation Expired"
                      @click="ResendUserInvite(data.item)"
                      v-show="!data.item.emailVerified && !pendingInvites[data.item.id]"
                      class="invite_link" style="color: #CC333F"> | Invite Expired </span>
                </div>
              </div>
            </div>
          </template>
          <template #cell(contact)="data">
            <div class="d-flex flex-column text-justify">
              <div class="column-header-text"> {{ data.item.email }}</div>
              <div class="mt-1 column-sub-text"> {{ data.item.phone }}</div>
            </div>
          </template>
          <template #cell(role)="data">
            <div class="column-sub-text">
              {{ data.item.permissions.roleName }}
            </div>
          </template>
          <template #cell(edit)="data">
            <div>
              <a class="font-weight-normal edit-delete-user" @click="editUser(data.item)">Edit</a>
            </div>
          </template>
          <template #cell(delete)="data">
            <div>
              <a class="font-weight-normal edit-delete-user user text-right" @click="deleteUser(data.item)">Delete</a>
            </div>
          </template>
        </b-table>
      </b-card-body>
      <div
          class="border-top-0 d-flex mb-0 justify-content-lg-between justify-content-md-around table-footer flex-sm-wrap">
        <div class="ml-auto" v-if="canCreateMoreUsers">
          <a
              v-b-toggle.create-user-sidebar
              class="button button-light button-green users-btn"
          >
            Add User
          </a>
        </div>
        <div class="col-sm-4 col-lg-12" v-else>
          <button
              class="button button-locked-users feature-lock-btn ml-auto"
              @click="$A.BuyNow()"
              v-b-tooltip.hover="'Upgrade to add additional users'"
          >
            Feature Locked <img src="/img/feature-lock.svg" class="feature-icon"/>
          </button>
        </div>
        <create-user-side-bar ref="userEditor" :available-teams="teams" :available-roles="roles" @saved="updateUsers"/>
      </div>
      <deleteUserModal ref="delUserModal" @deleted="loadData"/>
    </b-card>
  </div>
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

.user-button {
  cursor: pointer;
  float: right;
  margin-left: 35px;
}

.user-button img {
  height: 15px;
  margin-left: 10px;
}

.invite_link {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  opacity: 1;
  margin-top: 0 !important;
  cursor: pointer;
}

.button-locked-users {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #F44271;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.feature-icon {
  margin-bottom: 2px;
  margin-left: 4px;
  height: 22px;
}

.feature-lock-btn {
  width: 160px;
}

.users-btn {
  padding: 9px 17px !important;
  font-weight: normal;
  width: 108px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.account-user-card ::v-deep .card-body {
  padding: 0px;
}

.account-users-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

/deep/ .user-table {
  border-radius: 6px !important;
}

::v-deep .user-table .table, .table-responsive > .table, [class*=table-responsive-] > .table {
  margin-bottom: 0 !important;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
}

::v-deep .b-table-sticky-header, .table-responsive, [class*=table-responsive-] {
  margin-bottom: 0px !important;
}

::v-deep .user-table table thead th {
  border-bottom: 0px;
  padding: 12px 24px !important;
}

::v-deep .user-table table td {
  padding: 16px 24px !important;
  vertical-align: middle !important;
}


/deep/ .user-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.table-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  color: #575759;
}

.column-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.column-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
}

.edit-delete-user {
  color: #006F41;
}

.edit-delete-user:hover {
  cursor: pointer;
}

.unlimited-users {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #575759;
}

@media (max-width: 500px) {

  .button-locked-users {
    background: #F44271;
    color: #ffffff;
    transition: all .5s ease;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .users-btn {
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .unlimited-users {
    display: block;
  }


}


</style>
