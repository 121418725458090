<script>
export default {
  name: "recentitems",
  props: {
    associatedProofs: {
      type: Array,
      default: () => []
    },
  },
  async mounted() {
    const notApprovedProofs = await this.$A.ProofService.GetProofsByFilter(
        {
          proofId: 0,
          senderId: 0,
          workflowId: 0,
          approverId: 0,
          overdue: false,
          sortByDeadlineDesc: false,
          tagId: 0,
          text: "",
          status: 4,
        },
        1
    );
    this.notApproved = this.filterAssociatedProofs(notApprovedProofs);
    const waitingForReviewProofs = await this.$A.ProofService.GetProofsByFilter(
        {
          proofId: 0,
          senderId: 0,
          workflowId: 0,
          approverId: 0,
          overdue: false,
          sortByDeadlineDesc: false,
          tagId: 0,
          text: "",
          status: 1,
        },
        1
    );
    this.waitingForReview = this.filterAssociatedProofs(waitingForReviewProofs);
  },
  data() {
    return {
      notApproved: [],
      waitingForReview: [],
    };
  },
  methods: {
    getAvatarSize: function (proof) {
      let fileTypes = {};
      proof.docTypesUsed.forEach(type => fileTypes[type] = true);
      return (Object.keys(fileTypes).length > 1) ? '24px' : '0px';
    },
    ViewProof(proof) {
      this.$A.NavigateToView("/proof-item", {
        proof: proof,
      });
    },
    filterAssociatedProofs(proofs = []) {
      let filteredProofs = [];
      if (_.isEmpty(this.associatedProofs)) filteredProofs = proofs;
      else filteredProofs = proofs.filter(proof =>
        this.associatedProofs.some(associatedProof => associatedProof.id === proof.id)
      );
      return filteredProofs;
    }
  }
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Not Approved List -->
      <div class="col-md-6 list-items">
        <b-card class="overview-proof-list">
          <div class="card-header-text">Not Approved</div>
          <ul>
            <li
                v-for="(proof, index) in notApproved"
                :class="'proof-list-item line-item-large ' + (index === 0 ? 'border-first' : (index === notApproved.length-1) ? 'border-last' : '')"
                v-bind:key="proof.id"
            >
              <div class="row">
                <!--   Proof Name -->
                <div class="col-lg-6 col-md-12 proof-status">
                  <div>
                    <div class="d-flex justify-content-start" style="margin-top: 16px">
                      <span class="proof-details-item-text" @click="ViewProof(proof)">{{ proof.name }}</span>
                    </div>
                    <div class="d-flex justify-content-start">
                      <b-badge variant="success" class="proof-status-badge">
                        <span style="margin-right: 4px; height: 6px; width: 6px;background-color:#EB4654"
                              class="rounded-pill"></span>
                        Not Approved
                      </b-badge>
                      <span class="proof-version-text">Version {{ proof.currentVersion }}</span>
                    </div>
                  </div>
                </div>
                <!--  Avatars -->
                <div
                    class="col-lg-4 col-md-12 d-flex align-items-center justify-content-sm-start justify-content-lg-end">
                  <b-avatar-group
                      :size="getAvatarSize(proof)">
                    <!-- Web -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('web_url') >= 0 || proof.docTypesUsed.indexOf('html') >= 0"
                        icon="globe"
                        style="background-color:#EB4654"
                        class="avatar-color"
                        size="32px"
                    >
                    </b-avatar>
                    <!-- Image -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('image') >= 0"
                        icon="card-image"
                        class="avatar-color"
                        style="background-color:#EB4654"
                        size="32px"
                    >
                    </b-avatar>
                    <!-- Documents -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('document') >= 0"
                        icon="file-earmark-fill"
                        class="avatar-color"
                        style="background-color:#EB4654"
                        size="32px"
                    >
                    </b-avatar>
                    <!-- Video -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('video') >= 0"
                        icon="film"
                        class="avatar-color"
                        style="background-color:#EB4654"
                        size="32px"
                    >
                    </b-avatar>
                    <!-- Audio -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('audio') >= 0"
                        icon="music-note-beamed"
                        class="avatar-color"
                        style="background-color:#EB4654"
                        size="32px"
                    >
                    </b-avatar>
                  </b-avatar-group>
                </div>
                <div class="col-lg-2 col-md-12 d-flex justify-content-lg-around justify-content-sm-between">
                  <b-button class="view-proof-details-btn w-100" @click="ViewProof(proof)">View</b-button>
                </div>
              </div>
            </li>
            <a v-if="notApproved.length > 0" class="overview-gray-btn mt-4"
               @click="$A.NavigateToView('/proof-archive', { filter: { status: 4 } })">
              View All
            </a>
            <li
                class="null-item"
                v-if="notApproved.length == 0"
            >
              <div class="row">
                <div class="col-md-12">
                  <img src="/img/hook-icon.svg" style="height: 80px !important;"/>
                  <div class="card-header-text">There are not currently any proofs marked as "Not Approved"</div>
                </div>
              </div>
            </li>
          </ul>
        </b-card>
      </div>
      <!-- Waiting For Review List -->
      <div class="col-md-6 list-items">
        <b-card class="overview-proof-list">
          <div class="card-header-text">Waiting for Review</div>
          <ul>
            <li
                v-for="(proof, index) in waitingForReview"
                :class="'proof-list-item line-item-large ' + (index === 0 ? 'border-first' : (index === waitingForReview.length-1) ? 'border-last' : '')"
                v-bind:key="proof.id"
            >
              <div class="row">
                <!--   Proof Name -->
                <div class="col-lg-6 col-md-12 proof-status">
                  <div>
                    <div class="d-flex justify-content-start" style="margin-top: 16px">
                      <span class="proof-details-item-text" @click="ViewProof(proof)">{{ proof.name }}</span>
                    </div>
                    <div class="d-flex justify-content-start">
                      <b-badge variant="success" class="proof-status-badge">
                        <span style="margin-right: 4px; height: 6px; width: 6px;background-color:#D9B116"
                              class="rounded-pill"></span>
                        Waiting for Review
                      </b-badge>
                      <span class="proof-version-text">Version {{ proof.currentVersion }}</span>
                    </div>
                  </div>
                </div>
                <!--  Avatars -->
                <div
                    class="col-lg-4 col-md-12 d-flex align-items-center justify-content-sm-start justify-content-lg-end">
                  <b-avatar-group
                      :size="getAvatarSize(proof)">
                    <!-- Web -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('web_url') >= 0 || proof.docTypesUsed.indexOf('html') >= 0"
                        icon="globe"
                        style="background-color:#D9B116"
                        class="avatar-color"
                        size="32px"
                    >
                    </b-avatar>
                    <!-- Image -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('image') >= 0"
                        icon="card-image"
                        class="avatar-color"
                        style="background-color:#D9B116"
                        size="32px"
                    >
                    </b-avatar>
                    <!-- Documents -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('document') >= 0"
                        icon="file-earmark-fill"
                        class="avatar-color"
                        style="background-color:#D9B116"
                        size="32px"
                    >
                    </b-avatar>
                    <!-- Video -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('video') >= 0"
                        icon="film"
                        class="avatar-color"
                        style="background-color:#D9B116"
                        size="32px"
                    >
                    </b-avatar>
                    <!-- Audio -->
                    <b-avatar
                        v-show="proof.docTypesUsed.indexOf('audio') >= 0"
                        icon="music-note-beamed"
                        class="avatar-color"
                        style="background-color:#D9B116"
                        size="32px"
                    >
                    </b-avatar>
                  </b-avatar-group>
                </div>
                <div class="col-lg-2 col-md-12 d-flex justify-content-lg-around justify-content-sm-between">
                  <b-button class="view-proof-details-btn w-100" @click="ViewProof(proof)">View</b-button>
                </div>
              </div>
            </li>
            <a v-if="waitingForReview.length > 0" class="overview-gray-btn mt-4"
               @click="$A.NavigateToView('/proof-archive', { filter: { status: 1 } })">
              View All
            </a>
            <li
                class="null-item"
                v-if="waitingForReview.length == 0"
            >
              <div class="row">
                <div class="col-md-12">
                  <img src="/img/hook-icon.svg" style="height: 80px !important;"/>
                  <div class="card-header-text">There are not currently any proofs waiting for review</div>
                </div>
              </div>
            </li>
          </ul>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style scoped>

.null-item img {
  display: inline-block;
  height: 50px;
  vertical-align: top;
}

.null-item h3 {
  display: inline-block;
  font-size: 16px;
  line-height: 50px;
  padding-left: 25px;
  margin-bottom: 0;
  opacity: 0.75;
}

.overview-proof-list {
  filter: drop-shadow(0px 1px 3px rgba(8, 11, 13, 0.1)) drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.06));
  border-radius: 6px;
  margin-bottom: 24px;
}

@media screen and (max-width: 1300px) {
  .null-item img {
    display: block;
    height: 50px;
    vertical-align: top;
    margin: 0 auto 10px;
  }

  .null-item h3 {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    padding-left: 0px;
    margin-bottom: 0;
    opacity: 0.75;
  }
}

/**/


.view-proof-details-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin: 24px;
}

.proof-list-card {
  filter: drop-shadow(0px 1px 3px rgba(8, 11, 13, 0.1)) drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.06));
  border-radius: 6px;
}

.avatar-color {
  color: #F9F9F9 !important;
  border: 2px solid #F9F9F9 !important;
}

.visible-check-group {
  margin-bottom: 24px;
  display: flex;
  padding-left: 16px;
}

.proof-check-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  color: black;
}

.visible-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  opacity: 1 !important;
}

.null-item img {
  height: 100px;
  margin-bottom: 25px;
  animation: hook 5s infinite;
}

.null-item h4 {
  margin-bottom: 15px;
}

.null-item {
  list-style-type: none;
  text-align: center;
}

.proof-status-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 13px;
  height: 26px;
  border: 1px solid #B5B5B8;
  border-radius: 13px;
  background-color: white;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 8px;
}

.proof-list-item {
  background: #ffffff;
  margin-bottom: 1px;
  padding: 1px 24px;
  overflow: auto;
  box-shadow: 0px 1px 3px rgb(8 11 13 / 10%), 0px 1px 2px rgb(8 11 13 / 6%);
}

.card-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #575759;
  margin-bottom: 24px;
}

.overview-gray-btn {
  display: flex;
  flex-direction: row;
  margin-top: ;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.overview-gray-btn:hover {
  cursor: pointer;
}

.proof-version-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #707073;
  margin-left: 7px;
}

.proof-details-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #707073;
}

.proof-details-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.gray-outline-btn {
  padding: 9px 17px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  margin-bottom: 16px;
}

.button-outline-red:hover {
  background: #EB4654;
  color: white;
}

.button-outline-green:hover {
  background-color: #019255;
  color: white;
}

/deep/ .proof-item-check .custom-control-label::before {
  background-color: #F9F9F9;
  border: 1px solid #B5B5B8;
  border-radius: 4px;
}

/deep/ .proof-list-item-checkbox .custom-control-label::before {
  top: 0.2rem;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid #B5B5B8;
  border-radius: 4px;
  color: black;
}

.border-first {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important
}

.border-last {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

/deep/ .proof-list-item-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

/deep/ .btn-secondary:focus, .btn-secondary.focus {
  background-color: white;
  border: white;
}

/deep/ .proof-list-item-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #006F41;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #006F41 !important;
}

/deep/ [data-v-50dde638] .custom-control-label:before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #006F41 !important;
  border: 1px solid #006F41 !important;
}

/deep/ .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background: #95D6B0 !important;
  border: 1px solid #006F41 !important;
  border-radius: 4px !important;
}

/deep/ .custom-checkbox .custom-control-input:not(checked) ~ .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:indeterminate ~ .custom-control-label::before {
  background: #95D6B0 !important;
  border: 1px solid #006F41 !important;
  border-radius: 4px !important;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #006F41;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
  border: 1px solid #B5B5B8 !important;
}

/deep/ [data-v-a1fc98a6] .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ .proof-list-item-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ .card {
  padding: 24px !important;
}

/deep/ .card-body {
  padding: 0px !important;
}

/deep/ .b-avatar {
  height: 32px !important;
  width: 32px !important;
}

/deep/ .custom-control-label::before {
  top: 0.2rem;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid #006F41;
  border-radius: 4px;
}

/deep/ .custom-control-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

@-webkit-keyframes hook {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .view-proof-details-btn[data-v-a1fc98a6] {
    margin-left: 0px !important;
  }

  .proof-status {
    margin-bottom: 16px;
  }

  .proof-details-columns {
    flex-direction: column !important;
    flex-wrap: wrap !important;
    margin-left: 0px !important;
  }
}


</style>
