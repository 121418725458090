<script>
import redactor from "../../components/form/redactor.vue";

export default {
  name: "modalSendMessage",
  components: {
    redactor,
  },
  props: {
    proof: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      subjectLine: "",
      templates: [],
      curTemplate: 0,
    };
  },
  async mounted() {
    await this.getTemplates();
  },
  methods: {
    sendMessageToCurrentApprovers: async function () {
      let body = this.$refs.redactor.getTextBody();
      if (body.length === 0) {
        this.$A.AlertUser("Please add a note before submitting a note.");
        return;
      }
      let response = await this.$A.ProofService.SendMessageToCurrentApprovers(
          this.$props.proof.id,
          this.subjectLine,
          body
      );
      if (response.success) {
        this.$refs.redactor.setTextBody("");
        this.$bvModal.hide("SendMessage");
        this.$root.$emit("proof::refresh");
      }
    },
    getTemplates: async function () {
      this.templates = await this.$A.TemplateService.GetAll();
      let defaultOption = {
        name: "Select A Template",
        id: 0,
        html: "",
        subject: "",
      };
      this.templates.push(defaultOption);
    },
    cancel: function () {
      this.curTemplate = 0;
      this.subjectLine = "";
    },
    useTemplate: async function () {
      let selectedTemplate = this.templates.filter(
          (f) => f.id === this.curTemplate
      )[0];
      this.subjectLine = selectedTemplate.subject;
      this.$refs.redactor.setTextBody(selectedTemplate.html);
    },
  },
};
</script>

<template>
  <!-- Send Message Modal -->
  <b-modal id="SendMessage" size="lg" @hidden="cancel">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <h4>Send Message to Current Contact</h4>
        Your message will include a link to review this proof.
      </div>
    </template>

    <form role="form">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <select
                class="form-control"
                @change="useTemplate()"
                v-model="curTemplate"
            >
              <option :value="template.id" v-for="template in templates">
                {{ template.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <input
                name="Subject Line"
                placeholder="Subject Line"
                class="form-control"
                v-model="subjectLine"
            />
          </div>
          <redactor
              ref="redactor"/>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
            class="button button-dark button-gray normal-button"
            @click="cancel()"
        >Cancel
        </b-button
        >
        <b-button
            class="button button-light button-green normal-button"
            @click="sendMessageToCurrentApprovers()"
        >Send Message
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>
