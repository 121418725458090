<script>
import viewEmailModal from "../../components/modals/modal-viewemail";
import commentThread from './commentThread';
import PROOF_EVENTS from '../../utils/proofEvents.js';
import { sortEventsChronologically, getAppEventProperty, hasCommentAnnotation } from "../../utils/helpers"

const {
    ProofCreated,
    ProofSent,
    ProofViewedByApprover,
    ProofApprovedWithChanges,
    ProofApproved,
    ProofNotApproved,
    ProofDeleted,
    ProofArchived,
    ProofUnArchived,
    ProofNoteEvent,
    EmailSent,
    NewProofVersion,
    UserOverWroteApproverStatus,
    ProofViewedPublicly,
    ProofVersionFilesDownloaded,
    ApproverRegisteredToStageFromPublicLink,
    ApproverRespondedWithFeedbackForProof,
    WorkflowProofStageStarted,
    ApproverAutoReminderQueued,
    AccountAlertEvent,
    ProofViewedByUser,
    AnnotationCreated,
    AnnotationCommentThreadUpdated,
    AnnotationUpdated,
    MentionedInAnnotationCommentThread,
    ProofFileUploadedEvent,
    ProofFileDeletedEvent,
    ProofVersionHiddenEvent,
    ProofVersionRevealedEvent,
    FileProcessedEvent,
} = PROOF_EVENTS;

export default {
  name: "timeline",
  components: {
    viewEmailModal,
    commentThread,
  },
  props: {
    proof: {
      required: true,
      type: Object,
    },
    allUsers: {
      required: true,
      type: Array,
      default: () => []
    },
  },
  async mounted() {
  },
  methods: {
    ByEvent: function (eventType) {
      this.eventFilter.eventGroupType = eventType;
      switch (eventType) {
        case 1:
          this.eventFilter.eventGroupTypesSelected = [
            ProofCreated,
            ProofSent,
            ProofViewedByApprover,
            ProofDeleted,
            ProofArchived,
            ProofUnArchived,
            ProofNoteEvent,
            EmailSent,
            NewProofVersion,
            UserOverWroteApproverStatus,
            ProofViewedPublicly,
            ProofVersionFilesDownloaded,
            ApproverRegisteredToStageFromPublicLink,
            ApproverRespondedWithFeedbackForProof,
            WorkflowProofStageStarted,
            ApproverAutoReminderQueued,
            AccountAlertEvent,
            ProofViewedByUser,
            AnnotationCommentThreadUpdated,
            AnnotationUpdated,
            ProofFileUploadedEvent,
            ProofFileDeletedEvent,
            ProofVersionHiddenEvent,
            ProofVersionRevealedEvent
          ];
          break;
        case 2:
          this.eventFilter.eventGroupTypesSelected = [EmailSent, ApproverAutoReminderQueued, AccountAlertEvent];
          break;
        case 3:
          this.eventFilter.eventGroupTypesSelected = [
            ProofCreated,
            ProofSent,
            ProofViewedByApprover,
            ProofApprovedWithChanges,
            ProofApproved,
            ProofNotApproved,
            ProofDeleted,
            NewProofVersion,
            UserOverWroteApproverStatus,
            ProofViewedPublicly,
            ProofVersionFilesDownloaded,
            ApproverRegisteredToStageFromPublicLink,
            ApproverRespondedWithFeedbackForProof,
            WorkflowProofStageStarted,
            ApproverAutoReminderQueued,
            ProofViewedByUser,
          ];
          break;
        case 4:
          this.eventFilter.eventGroupTypesSelected = [AnnotationCommentThreadUpdated, AnnotationUpdated];
          break;
        case 5:
          this.eventFilter.eventGroupTypesSelected = [ProofNoteEvent];
          break;
      }
    },
    getIconFromStatus: function (event) {
      let proofEventType = event.eventType;
      let approverStatusTaken = _.get(event, "appEventObject.statusTaken", 0);
      if ([ProofNoteEvent].indexOf(proofEventType) !== -1) {
        return "/img/pencil-timeline-icon.svg";
      } else if ([EmailSent, ProofSent].indexOf(proofEventType) !== -1) {
        return "/img/email-sent-timeline-icon.svg";
      } else if ([ProofNotApproved].indexOf(proofEventType) !== -1 ||
          approverStatusTaken === 3
      ) {
        return "/img/not-approved-timeline-icon.svg";
      } else if (proofEventType === ProofFileUploadedEvent) {
        return "/img/added-version-file.svg";
      } else if (proofEventType === ProofFileDeletedEvent) {
        return "/img/delete-version-file.svg";
      } else if ([ProofVersionHiddenEvent, ProofArchived].indexOf(proofEventType) !== -1) {
        return "/img/hidden-version-file.svg";
      } else if ([ProofVersionRevealedEvent, ProofUnArchived].indexOf(proofEventType) !== -1) {
        return "/img/updated-version-to-visible.svg";
      } else if ([ProofApprovedWithChanges, ProofApproved].indexOf(proofEventType) !== -1 || approverStatusTaken > 3) {
        return "/img/approved-timeline-icon.svg";
      } else if (proofEventType === ProofCreated) {
        return "/img/create-proof-timeline-icon.svg";
      } else if (proofEventType === AnnotationCommentThreadUpdated && this.hasCommentAnnotation(event)) {
        return "/img/comments-timeline-icon.svg";
      } else {
        return "img/view-timeline-icon.svg";
      }
    },
    getClass: function (event) {
      let proofEventType = event.eventType;
      let approverStatusTaken = _.get(event, "appEventObject.statusTaken", 0);

      return {
        sent: 1,
        overdue: 0,
        not_approved:
            approverStatusTaken === 3 ||
            proofEventType === ProofNotApproved ||
            proofEventType === AccountAlertEvent,
        approved:
            approverStatusTaken > 3 ||
            proofEventType === ProofApproved ||
            proofEventType === ProofApprovedWithChanges,
      };
    },
    isTrialPlanAccount: function () {
      return this.$A.Auth.account.currentSubscriptionSku === 'trial';
    },
    getEventStyle: function (index, length) {
      return index + 1 === length ? 'border-bottom: 0px; margin-bottom: 0px' : 'border-bottom: 1px solid #dee3ec;'
    },
    groupFilesByEvent: function (userEvents, eventType) {
      let prevEvent = null;
      return userEvents.map(event => {
        if (event.eventType !== eventType || !prevEvent || prevEvent.eventType !== event.eventType || prevEvent.proofVersionId != event.proofVersionId) {
          if (event.eventType === eventType) {
            event.appEventObject = event.appEventObject || {};
            event.appEventObject.files = [_.get(event, 'appEventObject.file.name') || _.get(event, 'appEventObject.additionalData.fileName')];
            prevEvent = event;
          } else {
            prevEvent = null;
          }
          return event;
        }

        prevEvent.appEventObject.files.push(_.get(event, 'appEventObject.file.name') || _.get(event, 'appEventObject.additionalData.fileName'));
        return null;
      }).filter((event) => event != null);
    },
    shouldDisplayFileEvent: function (event) {
      return (event.eventType === ProofFileUploadedEvent || event.eventType === ProofFileDeletedEvent);
    },
    setActiveTab(tab, eventType) {
      this.activeTab = tab;
      this.ByEvent(eventType);
    },
    get(object, path, defaultValue) {
      return _.get(object, path, defaultValue);
    },
    getFormatted(object, path, formatter, defaultValue) {
      let v = this.get(object, path);
      if (v) return formatter(v);
      return defaultValue;
    },
    hasConfirmedChecklistItems(event) {
      const checklistItems = this.getAppEventProperty(event, 'confirmedChecklistItems');
      return checklistItems && checklistItems.length > 0;
    },
    getUserName(event) {
      const eventType = event.eventType;
      const keywords = {
            3: "created",
            4: "Started",
            9: "Deleted ",
            35: "Set",
            39: "marked",
            55: "added",
            56: "removed",
            57: "updated",
            58: "updated"
          };
      let userName = "";
      let curUser, index, description, keyword;

      switch (eventType) {
        case ProofViewedByApprover:
          userName = this.getAppEventProperty(event, "viewedByApprover.name", "") || this.getAppEventProperty(event, "additionalData.viewedByApprover.name", "");
          break;

        case ProofNoteEvent:
          curUser = this.allUsers.find(
            (user) => user.id === this.getAppEventProperty(event, "userId", "")
          );
          userName = curUser ? curUser.name : "";
          break;

        case ProofViewedByUser:
          userName = this.getAppEventProperty(event, "viewedByUser.name", "");
          break;

        case AnnotationCommentThreadUpdated:
        case AnnotationUpdated:
          if (this.getAppEventProperty(event, 'commentAnnotation')) userName = this.getAppEventProperty(event, "proofAnnotation.ownerName", "");
          else userName = this.getAppEventProperty(event, "additionalData.commentAnnotation.ownerName", "");
          break;

        case ProofCreated:
        case ProofSent:
        case ProofDeleted:
        case UserOverWroteApproverStatus:
        case ApproverRespondedWithFeedbackForProof:
        case ProofFileUploadedEvent:
        case ProofFileDeletedEvent:
        case ProofVersionHiddenEvent:
        case ProofVersionRevealedEvent:
          keyword = keywords[eventType];
          description = this.get(event, "description", "");
          if (!description) break;
          index = description.indexOf(keyword);
          userName = index !== -1 ? description.substring(0, index).trim() : description;
          break;

        case ApproverRegisteredToStageFromPublicLink:
          userName = this.getAppEventProperty(event, "approver.name", "") || this.get(event, "description", "").split('Registered')[0].trim();
          break;

        case ProofUnArchived:
          userName = this.getAppEventProperty(event, "unArchiverUser.name", "");
          break;

        case ProofArchived:
          userName = this.getAppEventProperty(event, "archiver.name", "");
          break;

        case ProofVersionFilesDownloaded:
          userName = this.getAppEventProperty(event, "userWhoDownloadedSimple.name", "");
          break;

        case NewProofVersion:
          userName = this.getAppEventProperty(event, "proofVersionCreated.sender.name", "") || this.get(event, "description", "").split('created')[0].trim();;
          break;

        default:
          userName = "";
          break;
      }

      return userName;
    },
    isAutomatedEvent: function (event) {
      return [EmailSent, ApproverAutoReminderQueued, AccountAlertEvent, ProofApprovedWithChanges, ProofApproved, ProofNotApproved].indexOf(event.eventType) !== -1;
    },
    hasCommentAnnotation,
    sortEventsChronologically,
    getAppEventProperty,
  },
  computed: {
    events: function () {
      if (this.eventFilter === undefined) {
        return [];
      }
      if (!this.$props.proof || !this.$props.proof.proofEvents) {
        return [];
      }
      let allEvents = this.$props.proof.proofEvents;

      let userEvents = allEvents.filter(event =>
          !this.isTrialPlanAccount() || event.eventType !== AccountAlertEvent)

      let annotationCreatedEvents = allEvents.filter(event => event.eventType === AnnotationCreated);
      let annotationCreatedEventsLookup = {};
      annotationCreatedEvents.forEach(event => {
        let commentAnnotation = _.get(event, 'appEventObject.commentAnnotation')
        if (commentAnnotation) {
          annotationCreatedEventsLookup[commentAnnotation.id] = event;
        }
      })

      let eventsInThread = {}
      userEvents = userEvents.filter(event => {
        let commentAnnotation = _.get(event, 'appEventObject.commentAnnotation')
        let shouldIncludeEvent = event.eventType !== AnnotationCommentThreadUpdated || !commentAnnotation || !eventsInThread[commentAnnotation.id];
        const isFileConversionEvent = event.eventType === FileProcessedEvent;

        if (shouldIncludeEvent && event.eventType === AnnotationCommentThreadUpdated && commentAnnotation) {
          eventsInThread[commentAnnotation.id] = true;
        }

        return shouldIncludeEvent && !isFileConversionEvent;
      });
      userEvents = userEvents.map(event => {
        if (event.eventType === AnnotationCommentThreadUpdated) {
          let commentAnnotation = _.get(event, 'appEventObject.commentAnnotation')
          if (commentAnnotation) {
            let annotationEvent = annotationCreatedEventsLookup[commentAnnotation.id]
            if (annotationEvent) {
              commentAnnotation.contextImageUrl = _.get(annotationEvent, 'appEventObject.commentAnnotation.contextImageUrl') || commentAnnotation.contextImageUrl;
            }
          }
        }
        return event;
      });

      // group file upload events next to each other
      userEvents = this.groupFilesByEvent(userEvents, ProofFileUploadedEvent);

      // group file download events next to each other
      userEvents = this.groupFilesByEvent(userEvents, ProofFileDeletedEvent);

      if (this.eventFilter.eventGroupTypesSelected.length === 0) return userEvents;

      userEvents = userEvents.filter((s) => this.eventFilter.eventGroupTypesSelected.indexOf(s.eventType) > -1 && _.get(this, '$props.proof.id', -1) === s.proofId);

      userEvents = userEvents.filter((s) => {
        if(s.eventType === AnnotationCommentThreadUpdated) {
          return s.proofFileId === this.getAppEventProperty(s, 'proofVersionFile.id') 
        } else return true;
      });
      
      return this.sortEventsChronologically(userEvents);
    },
  },
  data() {
    return {    
      ProofCreated,
      ProofSent,
      ProofViewedByApprover,
      ProofApprovedWithChanges,
      ProofApproved,
      ProofNotApproved,
      ProofDeleted,
      ProofArchived,
      ProofUnArchived,
      ProofNoteEvent,
      EmailSent,
      NewProofVersion,
      UserOverWroteApproverStatus,
      ProofViewedPublicly,
      ProofVersionFilesDownloaded,
      ApproverRegisteredToStageFromPublicLink,
      ApproverRespondedWithFeedbackForProof,
      WorkflowProofStageStarted,
      ApproverAutoReminderQueued,
      AccountAlertEvent,
      ProofViewedByUser,
      AnnotationCreated,
      AnnotationCommentThreadUpdated,
      AnnotationUpdated,
      MentionedInAnnotationCommentThread,
      ProofFileUploadedEvent,
      ProofFileDeletedEvent,
      ProofVersionHiddenEvent,
      ProofVersionRevealedEvent,
      FileProcessedEvent,
      eventFilter: {
        eventGroupType: 1,
        eventGroupTypesSelected: [
          ProofCreated,
          ProofSent,
          ProofViewedByApprover,
          ProofDeleted,
          ProofArchived,
          ProofUnArchived,
          ProofNoteEvent,
          EmailSent,
          NewProofVersion,
          UserOverWroteApproverStatus,
          ProofViewedPublicly,
          ProofVersionFilesDownloaded,
          ApproverRegisteredToStageFromPublicLink,
          ApproverRespondedWithFeedbackForProof,
          WorkflowProofStageStarted,
          ApproverAutoReminderQueued,
          AccountAlertEvent,
          ProofViewedByUser,
          AnnotationCommentThreadUpdated,
          AnnotationUpdated,
          ProofFileUploadedEvent,
          ProofFileDeletedEvent,
          ProofVersionHiddenEvent,
          ProofVersionRevealedEvent
        ],
      },
      fields: [
        {
          key: 'filesinfo',
          sortable: false,
          label: "FILE APPROVAL"
        },
        {
          key: 'filestatus',
          sortable: false,
          label: "",
        },
      ],
      ackfields: [
        {
          key: 'ackinfo',
          sortable: false,
          label: "APPROVER ACKNOWLEDGED",
        },
      ],
      uploadedFields: [
        {
          key: 'uploadedfilesinfo',
          sortable: false,
          label: "FILES",
        },
      ],
      activeTab: "All Events",
    };
  },
};
</script>

<template>
  <div class="col-md-12 timeline h-auto p-0" id="proof_timeline">
    <!-- Navigation -->
    <b-nav class="d-flex flex-column flex-sm-row gap-2 justify-content-between border-bottom px-4 py-4 align-items-center">
      <div class="text-gray-700" style="font-size: 16px; font-family: 'Open Sans'; font-weight: 600">
        <div class="d-flex align-items-center" name="real-time-header">
          <span class="real-time-feed mr-3"></span>Newsfeed
        </div>
      </div>
      <div class="d-flex flex-wrap justify-content-between gap-2">
        <b-nav-item class="p-0"
                    :class="{ 'status-rounded-pill-active': activeTab === 'All Events', 'status-rounded-pill-inactive': activeTab !== 'All Events' }"
                    @click="setActiveTab('All Events', 1)">All Events
        </b-nav-item>
        <b-nav-item class="p-0"
                    :class="{ 'status-rounded-pill-active': activeTab === 'Emails', 'status-rounded-pill-inactive': activeTab !== 'Emails' }"
                    @click="setActiveTab('Emails', 2)">Emails
        </b-nav-item>
        <b-nav-item class="p-0"
                    :class="{ 'status-rounded-pill-active': activeTab === 'Status Updates', 'status-rounded-pill-inactive': activeTab !== 'Status Updates' }"
                    @click="setActiveTab('Status Updates', 3)">Status Updates
        </b-nav-item>
        <b-nav-item class="p-0"
                    :class="{ 'status-rounded-pill-active': activeTab === 'Comments', 'status-rounded-pill-inactive': activeTab !== 'Comments' }"
                    @click="setActiveTab('Comments', 4)">Comments
        </b-nav-item>
        <b-nav-item class="p-0"
                    :class="{ 'status-rounded-pill-active': activeTab === 'Notes', 'status-rounded-pill-inactive': activeTab !== 'Notes' }"
                    @click="setActiveTab('Notes', 5)">Notes
        </b-nav-item>
      </div>
    </b-nav>
    <!-- Timeline Info -->
    <div class="" data-cy="timelineRow">
      <div v-bind:key="event.id"
          class="timeline-event d-flex flex-row mx-0 gap-2"
          v-for="(event, index) in events"
          :style="getEventStyle(index, events.length)"
          :data-cy="`row_${index}`"
      >
        <!-- Timeline Icon -->
        <div class="timeline-icon-parent">
          <div class="timeline-icon">
            <img :src="getIconFromStatus(event)" />
          </div>
        </div>
        <div class="flex-fill" v-if="event.eventType === AnnotationCommentThreadUpdated && hasCommentAnnotation(event)">
          <div class="text-gray-900 fw-semibold">{{ getUserName(event) }}</div>
          <div class="pb-12">
            <span class="event-time">{{$A.FormatDateToTime(getAppEventProperty(event, "proofAnnotation.createdAt") || getAppEventProperty(event, "additionalData.commentAnnotation.createdAt"))}}
              <span class="event-span"> on {{$A.FormatDateToString(getAppEventProperty(event, "proofAnnotation.createdAt") || getAppEventProperty(event, "additionalData.commentAnnotation.createdAt"),true)}}</span>
            </span>
          </div>
          <comment-thread :event="event" :proofId="get(proof, 'id', -1)"></comment-thread>
        </div>
        <div class="flex-fill" v-show="event.eventType !== AnnotationCommentThreadUpdated || !hasCommentAnnotation(event)"
             data-cy="emailWrapStatus">
          <div class="d-flex flex-column">
            <div class="text-gray-900 fw-semibold" v-if="getUserName(event) && !isAutomatedEvent(event)">
              {{ getUserName(event) }}
            </div>
            <div class="pb-12" v-if="(!isAutomatedEvent(event) && event.eventType !== AnnotationCommentThreadUpdated && !getAppEventProperty(event, 'commentAnnotation')) || (event.eventType === AnnotationUpdated && getAppEventProperty(event, 'commentAnnotation'))">
              <span class="event-time">{{ $A.FormatDateToTime(event.createdAt) }}
                <span class="event-span"> on {{$A.FormatDateToString(event.createdAt, true)}}</span>
              </span>
            </div>
            <div data-cy="emailSentConfirmation" :class="[isAutomatedEvent(event) ? 'text-gray-900 fw-semibold' : 'description-text text-gray-600']">
              {{ getAppEventProperty(event, "description") }}
            </div>

            <div class="pb-12" v-if="isAutomatedEvent(event) && !getAppEventProperty(event, 'commentAnnotation')">
              <span class="event-time">{{ $A.FormatDateToTime(event.createdAt) }}
                <span class="event-span"> on {{$A.FormatDateToString(event.createdAt, true)}}</span>
              </span>
            </div>
            <div class="d-block events">
              <span class="text-gray-500" v-show="event.eventType === ApproverRegisteredToStageFromPublicLink">
                IP Address: {{ getAppEventProperty(event, "ipAddress") }}
              </span>
              <span
                class="text-gray-500 mb-15 text-justify mt-1"
                v-show="getAppEventProperty(event, 'textNote')"
                v-html="getAppEventProperty(event, 'textNote')"
              ></span>
              <span
                class="text-gray-500 mb-15 text-justify mt-1"
                v-show="getAppEventProperty(event, 'userNote')"
                v-html="getAppEventProperty(event, 'userNote')"
              ></span>
              <span
                class="text-gray-500"
                data-cy="emailStatus"
                v-show="[EmailSent, ApproverAutoReminderQueued].indexOf(event.eventType) !== -1"
              >
                {{ getAppEventProperty(event, "sendStatus") || getAppEventProperty(event, "additionalData.sendStatus") }}</span
              >
              <span
                class="text-gray-500"
                v-show="[AnnotationCommentThreadUpdated, MentionedInAnnotationCommentThread].indexOf(event.eventType) !== -1"
              >
                <div v-html="getAppEventProperty(event, 'commentEdited.text', '')"></div>
              </span>
              <span class="text-gray-500 mb-15 text-justify mt-1" v-show="event.eventType === AccountAlertEvent">
                {{ getAppEventProperty(event, "additionalData.postMarkResponse", "") }}</span>
              <span class="text-gray-500" v-show="event.eventType === ApproverAutoReminderQueued">
                <span class="text-gray-500">Sending on</span>
                {{ getFormatted(event, "appEventObject.queuedActionAt", (v) => $A.FormatDate(v, true), '')}}
              </span>
              <!-- File Approval Table -->
              <b-table
                class="mt-12 mb-0 files-approval-table pb-0"
                :items="getAppEventProperty(event, 'filesApprovalStatus')"
                :fields="fields"
                responsive hover outlined
                thead-class="template-table-bg"
                v-show="event.eventType === ApproverRespondedWithFeedbackForProof"
                v-if="getAppEventProperty(event, 'filesApprovalStatus')"
              >
                <template #head()="data">
                  <span class="table-header-text">{{ data.label }}</span>
                  <span v-if="data.label" class="dropdown-icon ml-2"></span>
                </template>
                <template #cell(filesinfo)="data">
                  <div class="row-cols-9">
                    <span class="file-info-text">{{ data.item.fileName }}</span>
                  </div>
                </template>
                <template #cell(filestatus)="data">
                  <div class="row-cols-3 float-right">
                    <span class="status-text"
                          :style="{ color: data.item.approved ? '#006F41' : '#CC333F' }"
                          v-if="!data.item.approvedWithChanges">
                      {{ data.item.approved ? "Approved" : "Not Approved" }}
                    </span>
                    <span class="status-text"
                          :style="{ color: data.item.approvedWithChanges ? '#006F41' : '#CC333F' }"
                          v-if="data.item.approvedWithChanges">{{
                        data.item.approvedWithChanges ? "Approved With Changes" : "Not Approved"
                      }}
                    </span>
                  </div>
                </template>
              </b-table>
              <!--  Approver acknowledged Table  -->
              <b-table
                v-if="hasConfirmedChecklistItems(event)"
                class="files-approval-table pb-0 mb-0 mt-12"
                :items="getAppEventProperty(event, 'confirmedChecklistItems')"
                :fields="ackfields"
                responsive hover outlined
                thead-class="template-table-bg"
                v-show="event.eventType === ApproverRespondedWithFeedbackForProof"
              >
                <template #head()="data">
                  <span class="table-header-text">{{ data.label }}</span>
                  <span v-if="data.label" class="dropdown-icon ml-2"></span>
                </template>
                <template #cell(ackinfo)="data">
                  <div class="row-cols-9">
                    <span class="file-info-text" v-html="data.item"></span>
                  </div>
                </template>
              </b-table>
              <!--  File File Uploaded Table-->
              <b-table
                class="mt-3 files-approval-table pb-0"
                :fields="uploadedFields"
                :items="getAppEventProperty(event, 'files').filter(e => !!e)"
                responsive hover outlined
                thead-class="template-table-bg"
                v-if="shouldDisplayFileEvent(event) && getAppEventProperty(event, 'files')"
              >
                <template #head()="data">
                  <span class="table-header-text">{{ data.label }}</span>
                </template>
                <template #cell()="data">
                  <div class="row-cols-9">
                    <span class="file-info-text">{{ data.item }}</span>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="" v-if="events && event.eventType === EmailSent">
          <div class="dropdown show">
              <span
                  class="view-email"
                  @click="$refs.emailModal.viewEmail(event.emailLogId)"
                  :data-cy="`viewEmailRow_${events.indexOf(event)}`"
              >
                View
              </span>
          </div>
        </div>
      </div>
    </div>
    <!-- View Email Modal -->
    <viewEmailModal ref="emailModal"/>
  </div>
</template>

<style scoped>

.timeline {
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  padding-top: 20px;
  margin-bottom: 96px;
}

.timeline-filter {
  /*border-bottom: 1px solid #dee3ec;*/
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.dropdown-icon {
  display: inline-block !important;
  vertical-align: middle;
  background-image: url(/img/caret.svg);
  height: 8px;
  width: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: right;
}
.filter-item {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  opacity: 0.5;
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
}

.filter-item-active {
  padding-bottom: 15px;
  /*border-bottom: 2px solid #019256;*/
  opacity: 1;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #019256;
}

.timeline-event {
  padding: 24px;
}

.timeline-event p {
  line-height: 30px;
  display: inline-block;
  margin-bottom: 0px;
}

.timeline-icon-parent {
  height: 40px;
  width: 40px;
}

.event-list {
  background-color: #eef2f5;
  padding: 25px 25px 10px 25px;
  border-radius: 10px;
  display: block;
  margin: 15px 0;
}

.event-file-approval {
  margin-bottom: 10px;
  list-style-type: none;
  padding-left: 0;
  opacity: 1;
}

.event-file-approval li {
  /*border-bottom: 1px solid #dee3ec;*/
  padding-bottom: 10px;
  opacity: 1;
}

.timeline-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.timeline-wrapper {
  border-radius: 8px;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.view-email {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #006f41;
  cursor: pointer;
}

.event-time {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
  text-transform: uppercase;
}

.event-span {
  text-transform: none !important;
}

::v-deep .files-approval-table .table {
  border-color: #D5D5D8 !important;
}

::v-deep .files-approval-table .table thead th {
  border-bottom: 1px solid #D5D5D8;
  border-top: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px 24px;
  background: #f4f4f6;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #707073;
}

::v-deep .files-approval-table table tbody tr:last-child td {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.file-info-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1b1b1c;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

::v-deep .files-approval-table table td {
  padding: 16px 24px !important;
  vertical-align: middle !important;
  border-top : none;
  background: #F9F9F9;
}

.status-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

::v-deep .template-table-bg {
  background-color: #f4f4f6 !important;
}

::v-deep .files-approval-table .table, .table-responsive > .table, [class*="table-responsive-"] > .table {
  margin-bottom: 0;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
}

.status-rounded-pill-active {
  background-color: rgba(209, 246, 212, 1);
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 2px 10px !important;
}

.status-rounded-pill-active a {
  color: rgba(38, 76, 41, 1) !important;
  padding: 0px !important;
}

.status-rounded-pill-inactive {
  background-color: rgba(235, 235, 237, 1);
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 2px 10px !important;
}

.status-rounded-pill-inactive a {
  color: rgba(37, 37, 38, 1) !important;
  padding: 0px !important;
}

.real-time-feed {
  margin: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(52, 162, 98, 1);
  animation: timelinepulse 2s infinite ease-in-out;
  transition: all 0.3s ease;
}

::v-deep .events img {
  max-width: 100%;
}

@media screen and (max-width: 577px) {
  .filter-item {
    padding: 20px !important;
    cursor: pointer !important;
  }

  ::v-deep .card-body {
    padding-top: 0px !important;
  }
}

</style>
