<template>
  <div>
    <navigation></navigation>
    <div class="container-fluid nav-margin">
      <div class="row mt-50">
        <sidebarCreate v-if="!isCustomDomain"/>
        <div :class="[isCustomDomain ? 'col-md-12' : 'col-md-9']">
          <div class="proof-name-card">
            <!-- Header -->
            <div class="p-4 create-proof-header">
              <div class="card-header-text">New Proof</div>
              <div class="card-header-subtext">Get started by filling in the information below to create your new
                proof.
              </div>
            </div>
            <!-- Proof Name -->
            <div class="d-flex justify-content-start align-items-center proof-name-container">
              <span class="header-text-card col-md-4 pl-0">Proof Name</span>
              <input
                  id="proof_name"
                  type="text"
                  class="form-control proof-name-input mt-1 col-md-8"
                  placeholder=""
                  v-model="proof.name"
              />
            </div>
            <!-- Teams Multiselect -->
            <div class="d-flex justify-content-start align-items-center proof-name-container border-bottom pt-0" v-if="teams.length > 0">
              <span class="header-text-card col-md-4 pl-0">Team</span>
              <multiselect
                  v-if="teams.length > 0"
                  v-model="usersTeamIds"
                  :options="teams"
                  :placeholder="optionsPlaceholder"
                  :multiple="true"
                  :show-labels="false"
                  :hide-selected="true"
                  :clear-on-select="true"
                  :searchable="true"
                  label="name"
                  track-by="id"
                  class="align-items-center w-100"
              />
            </div>
          </div>
          <filepicker
              id="proof_filepicker"
              ref="filePicker"
              :is-create-proof-page="true"
          />
          <proofOptions ref="PO" :teams="teams"/>
          <div class="col-md-12" style="margin-bottom: 100px">
            <div class="px-4 py-3 d-flex justify-content-end col-md-12 bg-white proof-filepicker-styles">
              <a v-show="canUserSendFile && !submitted"
                 id="proof_link"
                 class="button create-proof-btn"
                 @click="createProof()"
              >
                Create Proof
              </a>
              <a v-show="canUserSendFile && submitted && erroredOut"
                 class="button create-proof-btn"
                 @click="createProof()"
              >
                Continue Uploading
              </a>
              <button
                  v-show="!canUserSendFile"
                  class="button button-locked mt-10"
                  style="min-width: 250px"
                  @click="$A.BuyNow()"
                  v-b-tooltip.hover="'Upgrade To Send More Proofs'"
              >
                Feature Locked <img src="/img/feature-lock.svg"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <copyright/> -->
    <v-tour
        name="createTour"
        :steps="steps"
        :callbacks="tourCallbacks"
    ></v-tour>
    <!--   <modalDeleteItem/>   -->
    <proofCreated :proof="proof"/>
    <newUserWelcomeModal name="NewUserWelcomeModal"/>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import sidebarCreate from "../components/create/sidebar-create.vue";
import filepicker from "../components/create/filepicker.vue";
import proofOptions from "../components/create/proofOptions.vue";
import proofCreated from "../components/modals/modal-proof-created.vue";
import newUserWelcomeModal from "../components/modals/modal-new-user-welcome.vue";
import Multiselect from 'vue-multiselect'
import { isCustomDomain } from "../utils/helpers";


export default {
  components: {
    sidebarCreate,
    filepicker,
    proofOptions,
    proofCreated,
    newUserWelcomeModal,
    Multiselect
  },
  data() {
    return {
      user: this.$A.Auth,
      proofId: 0,
      proofVersionId: 0,
      submitted: false,
      erroredOut: false,
      proof: {
        name: "",
        dueDate: "",
        password: "",
        allowDownload: false,
        allowEdit: false,
        allowApproveWithChanges: false,
        allowViewAllComments: false,
        tagIds: [],


      },
      steps: [
        {
          target: "#proof_name",
          content: `Before you create your first proof, here’s what you need to know. First, every proof needs a good name.`,
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#proof_filepicker",
          content:
              "Upload as many files as you’d like. All of the files you upload will be contained in this new proof!",
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#proof_options",
          content: `You can adjust the settings of your contacts when they view this proof.`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#proof_toggle_download",
          content: `This toggle determines whether your contacts can download the files in this proof.`,
          params: {
            placement: "left",
          },
        },
        {
          target: "#proof_toggle_approve_ind_files",
          content: `By default, Ashore asks for one approval for all files in a proof. If you rather ask for approval on each individual file, toggle this switch!`,
          params: {
            placement: "left",
            enableScrolling: false,
          },
        },
        {
          target: "#proof_toggle_comments",
          content: `If you’d like for your contacts to be able to view other contact comments, toggle this switch!`,
          params: {
            placement: "left",
            enableScrolling: false,
          },
        },
        {
          target: "#proof_toggle_changes",
          content: `Ashore will ask your approvers to approve or not approve your proof. If you’d like to give a third option, Approve With Changes, toggle that here! We’ll treat it the same as a normal approval.`,
          params: {
            placement: "left",
          },
        },
        {
          target: "#proof_link",
          content: `When you’ve finished, select this link. We’ll generate a review link that you can share with your contacts.`,
          params: {
            placement: "top",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("create");
        },
      },
      tourLabels: {
        buttonSkip: this.getLabel('tour_labels', 'skip_tour_btn'),
        buttonPrevious: this.getLabel('tour_labels', 'previous_btn'),
        buttonNext: this.getLabel('tour_labels', 'next_btn'),
        buttonStop: this.getLabel('tour_labels', 'finish_btn'),
      },
      teams: [],
      usersTeamIds: [],
      optionsPlaceholder: "Search and Select ...",
      isCustomDomain
    };
  },
  async mounted() {
    if (this.$route.query.welcome !== undefined) {
      this.$nextTick(() => {
        // open modal here
        this.$bvModal.show("NewUserWelcomeModal");
      });
    }
    this.$A.ClearViewData();
    // if (this.user.productTourState.indexOf("create") === -1) {
    //   console.log("starting create proof tour");
    //   this.$tours["createTour"].start();
    // }
    await this.$A.GetCurAccountData();
    await this.loadTeams();
  },
  computed: {
    canUserSendFile: function() {
      return _.get(this.user, 'account.subscriptionState.canSendANewFile', false)
    }
  },
  methods: {
    createProof: async function () {
      if (this.proof.name.length === 0) {
        this.$A.AlertUser(
            "Please Enter A Proof Name Before Continuing",
            "warning",
            10000
        );
        return;
      }

      try {
        let proofSettings = this.$refs.PO.getCurrentSettings();
        this.proof.allowDownload = proofSettings.downloadFiles;
        this.proof.allowApproveIndividualFiles = proofSettings.approveIndividualFiles;
        this.proof.allowApproveWithChanges = proofSettings.approveWithChanges;
        this.proof.allowViewAllComments = proofSettings.viewAllComments;
        this.proof.limitVersionVisibility = proofSettings.limitVersionVisibility;
        this.proof.blockCommentingAfterProofDecision = proofSettings.blockCommentingAfterProofDecision;
        this.proof.dueDate = this.$refs.PO.getCurrentDueDate();
        this.proof.tagIds = this.$refs.PO.getCurrentTags();
        this.proof.customFields = this.$refs.PO.getCurrentCustomProperties();
        this.proof.usersTeamIds = this.usersTeamIds.map(t => t.id);

        if (
            this.$refs.filePicker.GetFileStatus().filter((f) => !f.skip)
                .length === 0
        ) {
          this.$A.AlertUser("Please Select Files To Upload", "warning", 10000);
          return;
        }
      } catch (e) {
        console.log(e);
        return;
      }

      this.submitted = true;

      if (this.proof.id === undefined) {
        let proof = await this.$A.ProofService.CreateUpdateProof(
            this.proofId,
            this.proof
        );
        if (proof && proof.id > 0) {
          this.proofId = proof.id;
          this.proofVersionId = proof.currentVersion.id;
          this.proof = proof;
          this.proof.password = "";
        } else {
          this.submitted = false;
          return;
        }
      }

      this.$A.AlertUser("Uploading Files. Please Wait...", "success", 100000);

      await this.$refs.filePicker.UploadFiles(
          this.proofId,
          this.proof.currentVersion.id
      );
      if (
          this.$refs.filePicker
              .GetFileStatus()
              .filter((f) => f.uploadProgress === 100).length !==
          this.$refs.filePicker.GetFileStatus().length
      ) {
        this.$A.AlertUser(
            "There Was A Problem Uploading Your Files",
            "warning",
            10000
        );
        this.erroredOut = true;
        return;
      }
      // console.log(this.$refs.filePicker.GetFileStatus().filter(f=>f.noFilesLeft!==undefined));
      if (
          this.$refs.filePicker
              .GetFileStatus()
              .filter((f) => f.noFilesLeft !== undefined).length > 0
      ) {
        this.$A.AlertUser(
            "You Have Run Out Of Files, Upgrade Now To Unlock More",
            "warning",
            10000
        );
        return;
      }

      const orderedFiles = this.$refs.filePicker.GetFileIDsOrdered();
      await this.$A.ProofFileService.UpdateOrderForProofVersionFiles(this.proofId, this.proof.currentVersion.id, orderedFiles);

      this.$A.AlertUser("All Files Uploaded", "success", 10000);
      this.$bvModal.show("proofCreated");
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    loadTeams: async function () {
      this.teams = await this.$A.TeamService.GetAccountTeams();
      // switch (_.get(this.user, 'permissions.proofAccess')) {
      //   case 3:
      //     // user can see all teams
      //     break;
      //   case 2:
      //     // user can only see its assigned teams
      //     this.teams = this.teams.filter(t => t.users.find(u => u.userId === this.user.id));
      //     break
      //   default:
      //     this.teams = [];
      // }
      const userTeams = await this.$A.TeamService.GetTeams();
      const team = userTeams.find(t => t.users.find(u => u.userId === this.user.id && u.defaultTeamForUser));
      if (team) this.usersTeamIds = [{...team}];
    },
  },
};
</script>

<style scoped>

.create-proof-btn {
  padding: 9px 17px !important;
  width: fit-content;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  color: #F9F9F9;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  marging-right: 40px;
}

.proof-name-card {
  margin: 0px 15px 0px 15px;
  border-bottom: 0px;
}

.header-text-card {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.proof-name-input {
  padding: 9px 13px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.proof-filepicker-styles {
  border-top: 1px solid #D5D5D8;
  box-shadow: 0px 20px 25px -5px rgb(8 11 13 / 10%), 0px 10px 10px -5px rgb(8 11 13 / 4%);
}

.team-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.team-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
  font-weight: normal;
}

/deep/ .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0rem;
}

::v-deep .card-header {
  padding: 24px;
  height: 100px;
  background: #F4F4F6;
  border-bottom: 1px solid #D5D5D8;
}

.create-proof-header {
  background-color: #F4F4F6;
  border-bottom: 1px solid #D5D5D8;

}

.proof-name-container {
  padding: 20px 24px;
  /*border-bottom: 1px solid #D5D5D8;*/
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-radius: 0px;
  background-color: white;
}

.card-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #252526;
}

.card-header-subtext {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
[ '' ] color: #707073;
}

::v-deep .multiselect__tag {
  background: #D1F6D4 !important;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3E3E40 !important;
}

::v-deep .multiselect__tags {
  border: 0px!important;
}

::v-deep .multiselect {
  border: 1px solid #B5B5B8 !important;
  border-radius: 6px !important;
}

</style>

