<script>
import modalEditNonApprovers from "../modals/modal-editnonapprover.vue";
import proofSent from "../modals/modal-proof-sent.vue";
import prooflist from "../proof-archive/proof-list.vue";
import draggable from "vuedraggable";
import selectSender from "../form/selectSender.vue";
import selectNonApprovers from "../form/selectNonApprovers.vue";
import selectApprovers from "../form/selectApprovers.vue";
import redactor from "../form/redactor.vue";
import selectTemplate from "../form/selectTemplate.vue";

export default {
  name: "create-workflow-item",
  components: {
    selectTemplate,
    modalEditNonApprovers,
    proofSent,
    prooflist,
    draggable,
    selectSender,
    selectNonApprovers,
    selectApprovers,
    redactor,
  },
  props: {
    workflowId: {
      required: false,
      type: Number
    },
    inputProof: {
      required: false,
      type: Object
    },
    hideCurrentStageActive: {
      type: Boolean,
      required: false
    }
  },
  async mounted() {
    let self = this;
    window.$A.ProductTourProcess("workflow-item", this);
    self.checklistOptions = await self.$A.ProofChecklistService.GetAllProofChecklistsItems();
    self.setAllWorkflows();

    //if coming from the proof create page and need a workflow (create new or edit from template)
    //if coming from the proof item page (edit or add)

    //coming from create, edit
    if (this.inputProof && this.inputProof.id > 0) {
      await this.loadWorkflowFromProof();
    }

    if (this.$A.viewToViewData && this.$A.viewToViewData.selectedWorkflow) {
      self.mode = "proof"
      self.proofWorkflowVisible = true;
      self.displayWorkflowTemplates = true;
      self.displayWorkflowName = false;
      self.showSideBar = true;
    }

    this.$root.$on("template::email::chosen", async function (obj) {
      let stage = self.workflow.stages.filter(function (s) {
        return s.componentId === obj.componentId;
      });

      if (stage.length === 1) {
        stage = stage[0];
        stage.subjectLine = obj.template.subject;
        stage.message = obj.template.html;
        stage.templateId = obj.template.id;
        obj.template.bccUserIds && stage.bccUserIds.push(...obj.template.bccUserIds);
      }
    });

    this.$root.$on("toggle::edit::workflow::sidebar", function () {
      if (self.shouldDisplayWorkflowTemplates()) {
        self.mode = "proof"
        self.displayWorkflowTemplates = true;
      } else {
        self.mode = "proof_edit"
        self.displayWorkflowName = false;
        self.displayWorkflowTemplates = false;
      }
      self.proofWorkflowVisible = true;
      self.showSideBar = true;
      self.alertMessage = "";
    })

    this.$root.$on("edit::workflow::from::automation", function () {
      self.mode = "workflow_template"
      self.displayWorkflowName = true;
      self.alertMessage = "";
    })

    this.$root.$on("create::workflow::from::automation", function () {
      self.displayWorkflowName = true;
      self.mode = "workflow_template";
      self.alertMessage = "";
      self.setStageChecklistToDefault(0);
    })

    this.$root.$on("alert::user", (message, type) => {
      // self.AlertUser(message, type);
      self.$A.AlertUser(message, type);
    });

    this.$root.$on("confirm::delete::proofRequirement", async function () {
      self.checklistOptions = await self.$A.ProofChecklistService.GetAllProofChecklistsItems();
    });

    this.$root.$on("check-list-item:saved:success", async function () {
      self.checklistOptions = await self.$A.ProofChecklistService.GetAllProofChecklistsItems();

    });

    this.$root.$on("proof::refresh", async function () {
      if (self.showSideBar) {
        return;
      }
      if (self.inputProof && self.inputProof.id > 0) {
        await self.loadWorkflowFromProof(true);
      }
    });

  },
  computed: {
    curStageId: function () {
      if (!this.workflow.stages[0].id) {
        return 0
      }
      if (this.proof !== undefined && this.proof.currentVersion !== undefined) {
        return this.proof.currentVersion.currentStage === undefined
            ? 0
            : this.proof.currentVersion.currentStage.id;
      } else {
        return 0;
      }
    },
    commentFileApi: function () {
      let apiUrl = "";
      if (this.inputProof) {
        apiUrl = "/annotation/comment-file/" + this.inputProof.publicId + "?u=" + this.$A.Auth.apiToken.token;
      } else if (this.$A.Auth) {
        apiUrl = "/account/public-file" + "?access_token=" + this.$A.Auth.apiToken.token;
      } else {
        apiUrl = "";
      }
      return apiUrl;
    },
  },
  methods: {
    getAndSetDataOnStagesObj: function () {
      // console.log("Stages", this.stages);
      for (let h = 0; h < this.workflow.stages.length; h++) {
        let i = this.workflow.stages[h];

        if (this.$refs[i.componentId + "selectApprovers"] && this.$refs[i.componentId + "selectApprovers"].length === 1) {
          let approvers = this.$refs[
          i.componentId + "selectApprovers"
              ][0].getSelectedApprovers();
          i.approvers = approvers;
        }
        if (this.$refs[i.componentId + "selectReviewers"] && this.$refs[i.componentId + "selectReviewers"].length === 1) {
          let approvers = this.$refs[
          i.componentId + "selectReviewers"
              ][0].getSelectedApprovers();
          i.reviewers = approvers;
        }
        if (this.$refs[i.componentId + "selectNonApprovers"] && this.$refs[i.componentId + "selectNonApprovers"].length === 1) {
          let nonApproverIds = this.$refs[
          i.componentId + "selectNonApprovers"
              ][0].getIdArrayOfSelectedUsers();
          i.bccUserIds = nonApproverIds;
        }
        if (this.$refs[i.componentId + "redactor"].length === 1) {
          let message = this.$refs[i.componentId + "redactor"][0].getTextBody();
          i.message = message;
        }
        if (this.$refs[i.componentId + "selectSender"].length === 1) {
          let senderId = this.$refs[
          i.componentId + "selectSender"
              ][0].getSender();
          i.senderUserId = senderId;
        }
      }
      this.$root.$emit("proof::refresh", {});
    },
    updateOrder: async function () {
      //should we lock the order on in progress and completed stages? self.mode = "proof_edit";
      // this.getAndSetDataOnStagesObj();
      let shouldSaveToDb = true;
      let orderIds = [];
      this.workflow.stages.forEach(function (s, index) {
        s.stageOrder = index + 1;
        if (s.id > 0) {
          orderIds.push(s.id);
        } else {
          shouldSaveToDb = false;
        }
      });
      if (shouldSaveToDb && this.workflow.id > 0) {
        await this.$A.WorkflowService.ReorderWorkflowStages(
            this.workflow.id,
            orderIds
        );
      }
    },
    async registerDeleteWorkflowStageEvent(stage, index) {
      let self = this;
      this.$root.$on(
          "workflow_item_page::delete::workflow_stage::confirmed" +
          stage.componentId,
          async function () {
            await self.deleteWorkflowStage(stage, index);
          }
      );
    },
    async deleteWorkflowStage(stage, index) {
      if (this.workflow.id > 0 && stage.id > 0) {
        let result = await this.$A.WorkflowService.DeleteWorkflowStage(
            this.workflow.id,
            stage.id
        );
        if (result.success === true) this.workflow.stages.splice(index, 1);
      } else {
        this.workflow.stages.splice(index, 1);
      }
    },
    removeWorkflowApprovers: async function (curServerSideStage, localStage) {
      let self = this;
      //if there are approvers currently on the sage that are now not in the current stage then delete
      await this.$A.AsyncForEach(
          curServerSideStage.approvers,
          async function (sr) {
            let existsInLocalState = false;
            localStage.approvers.forEach(function (lr) {
              if (lr.id === sr.id) {
                existsInLocalState = true;
              }
            });

            if (!existsInLocalState) {
              // console.log(curServerSideStage.id,  sr.id, self.workflow.id)
              await self.$A.WorkflowService.DeleteWorkflowApprover(
                  self.workflow.id,
                  curServerSideStage.id,
                  sr.id
              );
            }
          }
      );
    },
    saveWorkflowApprovers: async function (curServerSideStage, localStage) {
      let self = this;
      //save new approvers
      let approversIdsNeedingToBeCreated = [];
      await this.$A.AsyncForEach(localStage.approvers, async function (lr) {
        let existsInServer = false;
        curServerSideStage.approvers.forEach(function (sr) {
          if (lr.id === sr.id) {
            existsInServer = true;
          }
        });
        if (!existsInServer) approversIdsNeedingToBeCreated.push(lr.id);
      });

      if (approversIdsNeedingToBeCreated.length > 0)
        curServerSideStage = await this.$A.WorkflowService.CreateWorkflowApprover(
            self.workflow.id,
            localStage.id,
            approversIdsNeedingToBeCreated
        );

      return curServerSideStage;
    },
    removeWorkflowReviewers: async function (curServerSideStage, localStage) {
      let self = this;
      if (!curServerSideStage.reviewers) {
        curServerSideStage.reviewers = [];
      }

      //if there are approvers currently on the sage that are now not in the current stage then delete
      await this.$A.AsyncForEach(
          curServerSideStage.reviewers,
          async function (sr) {
            let existsInLocalState = false;
            localStage.reviewers.forEach(function (lr) {
              if (lr.id === sr.id) {
                existsInLocalState = true;
              }
            });

            if (!existsInLocalState) {
              // console.log(curServerSideStage.id,  sr.id, self.workflow.id)
              await self.$A.WorkflowService.DeleteWorkflowApprover(
                  self.workflow.id,
                  curServerSideStage.id,
                  sr.id
              );
            }
          }
      );
    },
    saveWorkflowReviewers: async function (curServerSideStage, localStage) {
      let self = this;
      if (!curServerSideStage.reviewers) {
        curServerSideStage.reviewers = [];
      }

      //save new reviewers
      let reviewersIdsNeedingToBeCreated = [];
      await this.$A.AsyncForEach(localStage.reviewers, async function (lr) {
        let existsInServer = false;
        curServerSideStage.reviewers.forEach(function (sr) {
          if (lr.id === sr.id) {
            existsInServer = true;
          }
        });
        if (!existsInServer) reviewersIdsNeedingToBeCreated.push(lr.id);
      });

      if (reviewersIdsNeedingToBeCreated.length > 0)
        curServerSideStage = await this.$A.WorkflowService.CreateWorkflowReviewer(
            self.workflow.id,
            localStage.id,
            reviewersIdsNeedingToBeCreated
        );

      return curServerSideStage;
    },
    saveWorkflowStage: async function () {
      let failedStages = [];
      let self = this;
      let validationText = "";
      for (let i = 0; i < self.workflow.stages.length; i++) {
        let stage = self.workflow.stages[i];
        let workflowStage = null;
        try {
          //save/update
          workflowStage = await self.$A.WorkflowService.SaveWorkflowStage(
              self.workflow.id,
              stage
          );
          // console.log("WORKFLOW-STAGE", workflowStage);
          if (workflowStage.id > 0) {
            stage.id = workflowStage.id;
            // delete removed approvers
            await self.removeWorkflowApprovers(workflowStage, stage);
            // delete removed reviewers
            await self.removeWorkflowReviewers(workflowStage, stage);
            //save approvers for this stage
            await self.saveWorkflowApprovers(workflowStage, stage);
            //save reviewers for this stage
            await self.saveWorkflowReviewers(workflowStage, stage);
          }
        } catch (e) {
          console.log(e);
          failedStages.push({
            stage: stage,
            serverResponse: workflowStage,
          });
          validationText +=
              "Stage #" +
              (i + 1) +
              " Failed to save. Please try again later.<br/>";
        }
      }

      if (failedStages.length > 0) {

        // self.AlertUser(validationText.trimEnd("<br/>"), "warning", 5000);
        self.$A.AlertUser(validationText.trimEnd("<br/>"));
      }
    },
    updateWorkflowForProof: async function () {
      if (await this.saveWorkflow(true)) {
        await this.$A.ProofService.UpdateWorkflowForProof(this.proof.id);
        this.clearAlert();
        this.$root.$emit("proof::refresh");
      }
    },
    saveWorkflow: async function (preventAlert) {
      let self = this;
      let validated = true;

      self.getAndSetDataOnStagesObj();

      let validationText = "";
      let validations = [];

      if (this.workflow.name.length === 0 && this.mode === 'workflow_template' && this.mode != "proof_edit") {
        validationText += "Please Enter The Workflow Name<br/>";
        validations.push("Please Enter The Workflow Name");
        validated = false;
      }

      //todo scroll to first problem.
      //todo highlight problems in red.
      this.workflow.stages.forEach(function (s, index) {

        if (s.name.length === 0) {
          validationText +=
              "Please Enter A Stage Name On Stage #" + (index + 1) + "<br/>";
          validations.push("Please Enter A Stage Name On Stage #" + (index + 1));
          validated = false;
        }
        if ((s.approvers.length + s.reviewers.length) === 0) {
          validationText +=
              "Please Select At Least One Approver or Commenter On Stage #" +
              (index + 1) +
              "<br/>";
          validations.push("Please Select At Least One Approver or Commenter On Stage #" + (index + 1));
          validated = false;
        }
        if (s.senderUserId === 0) {
          validationText +=
              "Please Select A Sender On Stage #" + (index + 1) + "<br/>";
          validations.push("Please Select A Sender On Stage #" + (index + 1));
          validated = false;
        }
        if (s.subjectLine.length === 0) {
          validationText +=
              "Please Enter A Subject On Stage #" + (index + 1) + "<br/>";
          validations.push("Please Enter A Subject On Stage #" + (index + 1));
          validated = false;
        }
        if (s.message.length === 0) {
          validationText +=
              "Please Enter A Message On Stage #" + (index + 1) + "<br/>";
          validations.push("Please Enter A Message On Stage #" + (index + 1));
          validated = false;
        }
      });

      if (!validated) {
        // self.AlertUser(validationText.trimEnd("<br/>"), "warning", 5000);
        const message = "<ul><li>" + validations.join("</li><li>") + "</li></ul>";
        this.$A.AlertUser(message);
        return false;
      }

      //save / update workflow
      let workflow =
          this.workflow.id > 0
              ? await this.$A.WorkflowService.Get(this.workflow.id)
              : {id: 0, name: -1};

      //new or updated name of workflow
      if (workflow.name !== this.workflow.name || this.workflow.id == null) {
        workflow = await this.$A.WorkflowService.Save(this.workflow);
        if (workflow.id > 0) this.workflow.id = workflow.id;
      }

      if (
          Number.isNaN(this.workflow.id) ||
          this.workflow.id <= 0 ||
          this.workflow.id == null
      ) {
        this.$A.AlertUser("An error occurred, please try again later.", "error");
        return false;
      }


      //save / update stages
      await this.saveWorkflowStage();

      //save stage order
      await this.updateOrder();

      if (preventAlert === undefined)
        this.$A.AlertUser(workflow.name + " has been saved!", "success");

      this.$root.$emit("workflow_item:saved:successfully");
      this.$root.$emit("proof::refresh");
      this.alertMessage = "";
      this.showSideBar = false;
      return true;
    },
    addStage: function () {
      let self = this;
      let order = this.workflow.stages.length + 1;
      let firstStageBcc = (this.workflow.stages && this.workflow.stages.length) ? this.$refs[this.workflow.stages[0].componentId + "selectNonApprovers"][0].getIdArrayOfSelectedUsers() : [];
      this.workflow.stages.push({
        componentId: this.$A.GetUUID(),
        name: "Approval Stage " + order,
        stageOrder: order,
        message: "",
        subjectLine: "",
        bccUserIds: firstStageBcc,
        approvers: [],
        reviewers: [],
        senderUserId: self.$A.Auth.id,
        checklistId: this.checklistOptions[0].id
      });
      const index = this.workflow.stages.length - 1;
      this.setStageChecklistToDefault(index);
      this.openStage = index;
      setTimeout(function () {
        let refIndex = "ws_" + (self.workflow.stages.length - 1);
        let el = document.getElementById(refIndex);
        let cancelFunction = self.$scrollTo(el, 1000);
      }, 100);
    },
    loadWorkflow: async function () {
      if (this.mode !== "workflow_template") {
        return;
      }
      if (this.$props.workflowId && this.$props.workflowId > 0) {
        let workflow = await this.$A.WorkflowService.Get(this.$props.workflowId);

        if (workflow.id > 0) {
          workflow.stages.forEach(function (stage) {
            stage.componentId = self.$A.GetUUID();
          });
          this.workflow = workflow;
        }
      } else {
        this.workflow = {
          componentId: this.$A.GetUUID(),
          id: null,
          name: "",
          stages: [
            {
              name: "Approval Stage 1",
              stageOrder: 1,
              message: "",
              subjectLine: "",
              bccUserIds: [],
              approvers: [],
              reviewers: [],
              senderUserId: this.$A.Auth.id,
              templateId: 0,
              checklistId: 0
            },
          ],
        };
        this.setStageChecklistToDefault(0);
        this.openStage = 0;
      }
    },
    loadWorkflowFromProof: async function () {
      //if coming from create and selected a new workflow
      this.proof = await this.$A.ProofService.GetFullProof(this.inputProof.id);

      const curWorkflow = _.get(this, 'proof.currentWorkflow', {});

      if (curWorkflow.id > 0) {
        curWorkflow.stages.forEach(function (stage) {
          stage.componentId = self.$A.GetUUID();
        });
        this.workflow = curWorkflow;
      }

      if (!this.workflow.stages) {
        this.workflow.stages = [
          {
            name: "Approval Stage 1",
            stageOrder: 1,
            message: "",
            subjectLine: "",
            bccUserIds: [],
            approvers: [],
            reviewers: [],
            senderUserId: this.$A.Auth.id,
            templateId: 0,
          },
        ];
      }
    },
    setStageChecklistToDefault: function (stageIndex = 0) {
      let defaultChecklist = this.checklistOptions.find(checklist => checklist.accountWide);
      if (defaultChecklist) {
        this.workflow.stages[stageIndex].checklistId = defaultChecklist.id;
      }
    },
    cancel: function () {
      this.$root.$emit("workflow_item:canceled");
      if (this.mode !== "workflow_template") {
        return;
      }
      this.alertMessage = "";
      this.workflow = {
        componentId: this.$A.GetUUID(),
        id: null,
        name: "",
        stages: [
          {
            name: "Approval Stage 1",
            stageOrder: 1,
            message: "",
            subjectLine: "",
            bccUserIds: [],
            approvers: [],
            reviewers: [],
            senderUserId: this.$A.Auth.id,
            templateId: 0,
            checklistId: 0
          },
        ],
      }
    },
    startSaveWorkflowActive: async function () {
      let self = this;
      //save workflow
      const success = await self.saveWorkflow(true);
      if (!success) {
        return;
      }

      //start workflow for current version
      let startedResponse = await self.$A.ProofService.StartWorkflowForProofVersion(
          self.proof.id
      );
      if (!startedResponse.success) {

        return;
      }
      // self.$A.NavigateToView("/proof-item", {proof: self.proof});
      if (self.$A.viewToViewData && self.$A.viewToViewData.selectedWorkflow) {
        delete self.$A.viewToViewData.selectedWorkflow;
        self.$A.SetViewData(this.$A.viewToViewData);
        self.mode = "proof_edit";
        self.$root.$emit("proof::refresh");
      }
    },
    AlertUser(message, type) {
      this.alertMessage = message;
      this.alertType = type;
      setTimeout(this.clearAlert, 5000);
    },
    clearAlert: function () {
      this.alertMessage = "";
      this.alertType = "";
    },
    setAllWorkflows: async function () {
      var workflows = await this.$A.WorkflowService.GetAll();
      if (Array.isArray(workflows)) this.workflows = workflows;
      // console.log("Workflows", this.workflows);
    },
    associateToWorkflow: async function (templateId) {
      //attach workflow to proof
      const proofId = _.get(this, 'proof.id', 0);
      if (proofId > 0 && templateId) {
        let response = await self.$A.ProofService.AttachWorkflowToProof(
          proofId,
          templateId
        );
        _.get(response, 'success', false) && this.loadWorkflowFromProof();
      }
    },
    shouldDisplayWorkflowTemplates: function () {
      if (this.inputProof && this.inputProof.currentVersion) {
        const started = this.inputProof.currentVersion.stages.find(stage => stage.statusType > 1);
        return !started;
      }
      return false;
      // return (this.inputProof && this.inputProof.currentVersion) && (this.inputProof.currentVersion.currentStage.statusType === 1)
      //     && (this.inputProof.currentVersion.stages.length <= 1);
    },
    setAlertClassColor: function () {
      if (this.alertType && this.alertType === 'warning') {
        return 'alert-warning';
      } else if (this.alertType && this.alertType === 'success') {
        return 'alert-success';
      } else if (this.alertType && this.alertType === 'error') {
        return 'alert-error';
      }
      return '';
    }
  },
  data() {
    return {
      proof: {},
      user: this.$A.Auth,
      proofs: [],
      mode: "workflow_template",
      workflow: {
        componentId: this.$A.GetUUID(),
        id: null,
        name: "",
        stages: [
          {
            name: "Approval Stage 1",
            stageOrder: 1,
            message: "",
            subjectLine: "",
            bccUserIds: [],
            approvers: [],
            reviewers: [],
            senderUserId: _.get(this, '$A.Auth.id', 0),
            templateId: 0,
            checklistId: 0
          },
        ],
      },
      steps: [
        {
          target: ".workflow_stagename",
          content:
              "This is your first approval stage. If you want, you can optionally label it.",
          params: {
            enableScrolling: false,
            placement: "right",
          },
        },
        {
          target: ".workflow_sender",
          content:
              "By default, we’ve selected you as the proof sender. If the proof needs to be sent from another Ashore user on your account, you can select them here.",
          params: {
            enableScrolling: false,
            placement: "right",
          },
        },
        {
          target: ".workflow_approvers",
          content:
              "Use the plus button to create a new contact, or use this field to select existing contacts. Proofs will be sent to all contacts in this stage at the same time.",
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".workflow_reviewers",
          content:
              "Add contacts as commenter when you do not need sign-off, but still want their feedback.",
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".workflow_nonapprovers",
          content:
              "If you want your Ashore users to receive notifications about status updates to this proof, add them here.",
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
        },
        {
          target: ".workflow_message",
          content:
              "You can customize the email message that will be sent to contacts. You can also select from your predefined email templates.",
          params: {
            placement: "right",
            enableScrolling: false,
          },
        },
        {
          target: "#workflow_button",
          content:
              "You can add additional approval stages. When all approvers in the first stage have approved the proof, Ashore will send the proof to contacts in the next stage.",
          params: {
            placement: "top",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("workflow-item");
        },
      },
      showSideBar: false,
      proofWorkflowVisible: false,
      modalShow: false,
      displayWorkflowName: false,
      displayWorkflowTemplates: false,
      checklistOptions: [],
      alertMessage: "",
      openStage: 0,
      workflows: []
    };
  },
  watch: {
    workflowId: function (newValue, oldValue) {
      this.loadWorkflow()
    },
    inputProof: function (newValue, oldValue) {
      if (!oldValue || oldValue.id === 0) {
        this.$nextTick(this.loadWorkflowFromProof);
      }
    }
  }
};
</script>

<template>
  <b-sidebar
      id="create-workflow-sidebar-right"
      right
      shadow
      v-model="showSideBar"
      @hidden="cancel"
      :no-header-close="true"
  >
    <div v-if="alertMessage"
         :class="'alert mb-0 position-absolute w-100 text-center d-flex flex-row font-weight-normal ' + setAlertClassColor()"
         style="z-index: 3000" role="alert">
      <span v-html="alertMessage" class="ml-auto font-weight-normal"></span>
      <span @click="clearAlert()" class="ml-auto close-alert">X</span>
    </div>
    <!-- Create Workflow Header -->
    <div class="workflow-header-container">
      <div class="sidebar-workflow-header">Manage Workflow</div>
      <span class="sub-text">Automate your approval process with workflow stages</span>
    </div>
    <!-- Workflow Name -->
    <div class="workflow-field-container d-flex flex-direction-row align-items-center border-bottom"
         v-if="displayWorkflowName">
      <span class="field-text col-md-3 px-0">Workflow Name</span>
      <b-input type="text"
               class="field-input col-md-9"
               name="WorkflowName"
               v-model="workflow.name">
      </b-input>
    </div>
    <!-- Workflow Template -->
    <div class="workflow-field-container d-flex flex-direction-row align-items-center border-bottom"
         v-if="displayWorkflowTemplates">
      <span class="field-text col-md-3 px-0 mt-2">Workflow Template</span>
      <v-select
          :options="workflows"
          label="name"
          :reduce="option => option.id"
          placeholder="Select Template"
          class="mt-1 checklist-select col-md-9 px-0"
          :searchable="false"
          :clearable="true"
          @input="associateToWorkflow"
      >
      </v-select>
    </div>
    <!-- Approver Stages -->
    <div v-if="showSideBar">
      <!-- Draggable Field -->
      <draggable
          :list="workflow.stages"
          class="col-md-12 stages-padding"
          draggable=".stage"
          handle=".handle"
          @change="updateOrder()"
      >
        <div
            class="field-item stage mb-0"
            v-for="(stage, index) in workflow.stages"
            :id="'ws_' + index"
            :ref="'ws_' + index"
            :data-cy="`approval_stage_${index + 1}`"
        >
          <!--  Stages Container Card -->
          <b-card class="stages-container-card">
            <div class="d-flex justify-content-md-start flex-row col-md-12">
              <!-- Drag Stage Img -->
              <b-img class="handle" src="/img/move.svg"/>
              <!-- Stage # -->
              <div class="col-md-10 pl-0 stages">
                <span class="stage-dot-white" v-if="hideCurrentStageActive"></span>
                <span class="stage-dot-black" v-else-if="stage.id === curStageId || curStageId === index"></span>
                <span class="stage-dot-white" v-else></span>
                <div class="stage-number ml-2">
                  {{ stage.name || `Approval Stage ${index + 1}` }}
                </div>
              </div>
              <div class="d-flex ml-auto">
                <!-- Delete Stage Trash Icon -->
                <a
                    v-b-modal="'deleteWorkflowStageModal' + stage.componentId"
                    v-if="stage.stageOrder !== 1"
                    @click="registerDeleteWorkflowStageEvent(stage, index)"
                >
                  <img src="/img/trash-icon.svg"/>
                </a>
                <!-- Collapse Stage Details -->
                <div class="col-md-2 ml-2"
                     v-b-toggle="'collapse-stage-' + index"
                >
                  <span class="when-open"><img src="/img/stage-open.svg"/></span>
                  <span class="when-closed"><img src="/img/stage-close.svg"/></span>
                </div>
              </div>
            </div>
          </b-card>
          <!-- Stage Details -->
          <b-collapse :id="`collapse-stage-${index}`"
                      :visible="(openStage === index && (openStage > 0|| curStageId === 0)) || (stage.id === curStageId)">
            <b-card class="overview-card">
              <b-card-body class="p-0">
                <!-- Overview -->
                <div class="text-bold text-dark header-text">Overview</div>
                <div class="mb-1 small-text">Stage name</div>
                <b-form-input
                    type="text"
                    class="field-input"
                    name="WorkflowName"
                    v-model="stage.name"
                    :data-cy="`approvalStage-${stage.stageOrder}`"
                >
                </b-form-input>
                <div class="mb-1 small-text">Send emails from</div>
                <selectSender :index="index+1"
                              :key="stage.componentId + 'selectSender'"
                              :ref="stage.componentId + 'selectSender'"
                              :SelectedSenderId="stage.senderUserId"
                              class="workflow_sender mb-4"
                />
                <hr>
                <!-- Contacts -->
                <div class="text-bold text-dark header-text mt-4">Contacts</div>
                <div class="pb-1 small-text">Ask for approval from</div>
                <!--  Approvers -->
                <selectApprovers :index="index + 1"
                                 placeholder="Search and Select..."
                                 testingId="addApprover"
                                 :key="stage.componentId + 'selectApprovers'"
                                 :ref="stage.componentId + 'selectApprovers'"
                                 :SelectedApprovers="stage.approvers"
                                 style="margin-bottom: 10px"
                                 @contactsChanged="getAndSetDataOnStagesObj"
                />
                <!-- Reviewers -->
                <div class="mb-1 small-text">Ask for comments only from</div>
                <selectApprovers :index="index + 1"
                                 placeholder="Search and Select..."
                                 testingId="addCommenter"
                                 :key="stage.componentId + 'selectReviewers'"
                                 :ref="stage.componentId + 'selectReviewers'"
                                 :SelectedApprovers="stage.reviewers"
                                 style="margin-bottom: 10px"
                                 @contactsChanged="getAndSetDataOnStagesObj"
                />
                <!--   Non Approvers -->
                <div class="mb-1 small-text">Blind copy these users from my team</div>
                <selectNonApprovers :index="index + 1"
                                    placeholder="Search and Select..."
                                    :key="stage.componentId + 'selectNonApprovers'"
                                    :ref="stage.componentId + 'selectNonApprovers'"
                                    :SelectedNonApproverIds="stage.bccUserIds"
                                    class="mb-4"
                                    @nonApproversChanged="getAndSetDataOnStagesObj"

                />
                <hr>
                <!-- Message -->
                <div class="text-bold text-dark header-text">Message</div>
                <!-- Select Email Template -->
                <div class="mb-1 small-text">Select email template</div>
                <selectTemplate :index="index + 1"
                                :key="stage.componentId + 'selectTemplate'"
                                :ref="stage.componentId + 'selectTemplate'"
                                :selected-template-id="stage.templateId"
                                :componentId="stage.componentId"
                                style="margin-bottom: 10px"
                />
                <!-- Subject Line -->
                <div class="mb-1 small-text">Subject line</div>
                <b-form-input
                    :data-cy="`subject-line-${stage.stageOrder}`"
                    type="text"
                    name="Subject Line"
                    class="field-input"
                    v-model="stage.subjectLine">
                </b-form-input>
                <div class="mb-1 small-text">Email body</div>
                <div>
                  <redactor :data-cy="`msgApprovers-${stage.stageOrder}`"
                            :text="stage.message"
                            :key="stage.componentId + 'redactor'"
                            :ref="stage.componentId + 'redactor'"
                            class="mb-4"
                            :comment-api-url="commentFileApi"
                  />
                </div>
                <hr>
                <!-- Advanced Settings -->
                <div class="text-bold text-dark header-text">Advanced Settings</div>
                <div class="mb-1 small-text">Select the checklist approvers must acknowledge in this stage</div>
                <v-select
                    v-model="stage.checklistId"
                    :options="checklistOptions"
                    label="name"
                    :reduce="option => option.id"
                    placeholder="Select"
                    class="mb-4 checklist-select"
                    :searchable="false"
                    :clearable="true"
                >
                </v-select>
                <hr>
              </b-card-body>
            </b-card>
          </b-collapse>
          <modal
              :key="stage.componentId + 'deleteWorkflowStageModal'"
              :name="'deleteWorkflowStageModal' + stage.componentId"
              :eventToEmitOnConfirmation="
                      'workflow_item_page::delete::workflow_stage::confirmed' +
                      stage.componentId
                    "
              question="Delete This Workflow Stage?"
              body="Are you sure you want to delete this workflow stage? You cannot undo this action."
              confirm-button-text="Yes, Delete"
          />
        </div>
      </draggable>
      <div class="px-4 pt-0 pb-3">
        <b-button
            id="stages"
            @click="addStage()"
            class="gray-button"
        >
          Add Stage
        </b-button>
      </div>
    </div>
    <!-- Footer -->
    <template #footer="{ hide }">
      <!--  Buttons  -->
      <div class="d-flex create-workflow-footer border-top col-md-12 justify-content-end pb-0">
        <b-button class="workflow-btn-white button ml-3 pb-0 mb-0"
                  @click="hide">
          Cancel
        </b-button>
        <b-button v-if="mode === 'proof'"
                  v-b-modal.confirmSend
                  class="workflow-btn-green button ml-3 pb-0 mb-0"
                  @click="startSaveWorkflowActive()"
        >
          Start Workflow
        </b-button>
        <b-button v-if="mode === 'proof_edit'"
                  class="workflow-btn-green button ml-3 pb-0 mb-0"
                  @click="updateWorkflowForProof()">
          Save Workflow
        </b-button>
        <!--  only from automation btn -->
        <b-button v-if="mode != 'proof_edit' && mode !='proof' && mode === 'workflow_template'"
                  class="workflow-btn-green button ml-3 pb-0 mb-0"
                  @click="saveWorkflow()"
        >
          Save Workflow
        </b-button>
      </div>
      <!-- End  Buttons -->
    </template>
    <!--  Modals  -->
    <proofSent/>
    <modal
        name="deleteWorkflowModal"
        eventToEmitOnConfirmation="workflow_item_page::delete::workflow::confirmed"
        question="Delete This Workflow?"
        body="Are you sure you want to delete this workflow? You cannot undo this action."
        confirm-button-text="Yes, Delete"
    />
  </b-sidebar>
</template>


<style scoped>

.close-alert {
  cursor: pointer;
}

/deep/ .b-sidebar {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 672px;
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-body {
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}

.workflow-header-container {
  background: #F4F4F6;
  padding: 24px;
  border-bottom: 1px solid lightgray;
}

.sidebar-workflow-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
  margin-top: 4px;
}

.small-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.field-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.stage {
  padding: 0px 0px 24px 0px !important;
}

.field-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

.workflow-field-container {
  padding: 20px 24px;
  background-color: white;
}

::v-deep .stages-container-card .card-body {
  padding: 16px 24px !important;
  display: flex;
  border: 0px;
  border-right: 0.25px;
  box-shadow: 0px 1px 2px 0px rgba(8, 11, 13, 0.06), 0px 1px 3px 0px rgba(8, 11, 13, 0.1);
  border-radius: 0px;
}

::v-deep .stages-container-card {
  border: 0px !important;
}


::v-deep .stages-container-card .card-body {
  padding: 16px 24px !important;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.gray-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  width: 100%;
}

.stage-dot-black {
  width: 8px;
  height: 8px;
  background: #444444;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 0px;
}

.stage-dot-white {
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.stages {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-workflow-footer {
  padding: 20px 24px !important;
  background: #f4f4f6;
  color: #575759;
}

.workflow-btn-green {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #019256;
}

.workflow-btn-white {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #f9f9f9;
  color: #3e3e40;
}

.workflow-btn-white:hover, .workflow-btn-green:hover {
  opacity: 0.5;
  transition: all 0.5s ease;
}

.overview-card {
  padding: 24px;
  border: 0px;
  border-radius: 0px !important;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
}

/deep/ .overview-card .card-body {
  padding: 0px !important;
}

.header-text {
  font-weight: 700;
  margin-bottom: 10px;

}


.stage-number {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.stages-padding {
  padding: 20px 24px 0px 24px;
}

.checklist-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .checklist-select .vs__dropdown-menu {
  font-weight: 400 !important;
  overflow-y: auto !important;
  max-height: 75px !important;
}

/deep/ .vs__clear {
  fill: rgba(60, 60, 60, .5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 4px;
}

/deep/ ul {
  font-weight: 400;
}

/deep/ .ti-autocomplete {
  border: 1px solid #B5B5B8 !important;
  border-top: 0px !important;
}

/deep/ .vs--single .vs__selected {
  margin-top: 7px;
}

/deep/ .vs__clear {
  margin-top: 2px;
}

/deep/ .vs__open-indicator {
  margin-top: 0px !important;
}

/deep/ .ti-tag span {
  line-height: 1.2;
}

/deep/ .vs__selected {
  padding: 0px 2px 4px 4px;
}

@media screen and (max-width: 991px) {

  .vs__search, .vs__search:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.4;
    font-size: 1em;
    border: 1px solid transparent;
    border-left: none;
    outline: none;
    margin: 0px;
    padding: 0 7px;
    background: none;
    box-shadow: none;
    width: 0;
    max-width: 100%;
    flex-grow: 1;
    z-index: 1;
  }

  /deep/ .ti-tag span {
    margin-bottom: 0px;
    opacity: 1;
    line-height: 1.2;
  }

  /deep/ .ti-icon-close {
    line-height: unset;
  }

  /*/deep/ .ti-tag {*/
  /*  padding: 3px 4px;*/
  /*  width: max-content;*/
  /*  text-align: left;*/
  /*}*/

}

@media screen and (max-width: 769px) {

  /deep/ .vs--single .vs__selected {
    width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

}

@media screen and (min-width: 770px) {

  /deep/ .vs--single .vs__selected {
    width: 400px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

}


</style>

