<script>
export default {
  name: "apiToken",
  props: {
    apiToken: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    CopyToClipBoard: function () {
      this.$A.CopyStringToClipboard(this.apiToken);
      this.$A.AlertUser(
        "The API Token Has Been Copied To Your Clipboard!",
        "success"
      );
      this.$bvModal.hide("apiToken");
    },
  },
  computed: {},
};
</script>

<template>
  <div class="w-100 copy-link-wrapper">
    <span id="apiToken" class="api-token">{{apiToken}}</span>
    <b-button
        variant="copy-link"
        class="copy-link-botton mb-1"
        v-b-tooltip.click
        title="Copied to Clipboard!"
        @click="CopyToClipBoard()"
    >Copy</b-button>
  </div>
</template>

<style scoped>
.btn-copy-link {
  background: white;
  padding: 0 25px;
  color: rgba(144, 144, 146, 1);
  border-color: rgba(144, 144, 146, 1);
}

.btn-copy-link:hover {
  color: #ffffff !important;
  background-color: #0c390e;
  transition: all 0.5s ease;
}


.copy-link-wrapper {
  position: relative;
  /*z-index: 1;*/
  /*width: 300px;*/
}

.copy-link-botton {
  position: absolute;
  right: 3px;
  top: 6px;
  bottom: 6px;
  width: 60px;
  background: white;
  padding: 0 10px;
  color: #909092;
  border-color: 1px solid #B5B5B8;
  z-index: 1000;
  font-size: small;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.api-token  {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  width: 100%;
  height: 36px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #444444;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid lightgray;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 5px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  padding: 6px 67px 6px 3px;
}

</style>
