<script>
export default {
  name: "modal-delete-role",
  data() {
    return {
      roleToDelete: {id: 0, name: ""},
      roleIdToTransferTo: null,
      roles: [],
      alert: "",
    };
  },
  async mounted() {
  },
  computed: {},
  methods: {
    open: function (roleToDelete, allRoles) {
      this.$set(this, 'roleToDelete', roleToDelete);
      this.roles = allRoles;
      this.$bvModal.show("deleteRoleModal");
    },
    close: function () {
      this.alert = "";
      this.$bvModal.hide("deleteRoleModal");
    },
    confirm: async function () {
      this.alert = "";
      if (this.roleIdToTransferTo > 0 && this.roleToDelete.id > 0) {
        await this.delete(this.roleToDelete.id, this.roleIdToTransferTo);
        this.$bvModal.hide("deleteRoleModal");
      } else {
        this.alert = "Please Select A Role To Transfer User Under This Role To";
      }
    },
    delete: async function (roleIdToDelete, roleIdToTransferTo) {
      let r = await this.$A.RoleService.ReAssignRole(roleIdToDelete, roleIdToTransferTo);
      if (!_.get(r, 'success', false)) return;
      await this.$A.RoleService.DeleteRole(roleIdToDelete);
      this.$emit("deleted")
    }
  },
};
</script>

<template>
  <b-modal id="deleteRoleModal" size="lg" class="p-4">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <span class=" delete-modal-header pb-2">
          Delete {{roleToDelete.name }}?
        </span>
      </div>
    </template>

    <form role="form">
      <div class="row">
        <div class="col-md-12">
          <div>
            <span
                class="sub-header-text mb-5">This action cannot be undone. Please select the user role to replace.</span>


            <select
                aria-placeholder="Select User Role"
                required="required"
                class="role-select w-100 mt-4"
                v-model="roleIdToTransferTo"
            >
              <option
                  :value="role.id"
                  v-for="role in roles"
                  v-if="role.id !== roleToDelete.id"
              >
                {{ role.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label v-if="alert.length > 0" style="color: red">{{
                alert
              }}</label>
          </div>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
            class="gray-btn"
            @click="close()"
        >Cancel
        </b-button
        >
        <b-button
            class="red-btn ml-2"
            @click="confirm()"
        >Delete Permanently
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.delete-modal-header {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.sub-header-text {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

/deep/ #deleteRoleModal .modal-header {
  padding: 24px !important;
}

/deep/ #deleteRoleModal .modal-body {
  padding-top: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.red-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  color: white;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #CC333F;
  border: 1px solid #EB4654;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #F4F4F6;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  cursor: pointer;
}

::v-deep .role-select {
  padding-left: 10px;
  width: 100%;
  height: 38px!important;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

</style>

