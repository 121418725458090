<script>
import editPropertyModal from "../modals/modal-edit-property";
import deletePropertyModal from "../../components/modals/modal-delete-property";

export default {
  name: "accountProperties",
  components: {
    editPropertyModal,
    deletePropertyModal,
  },
  methods: {
    editProperty: function (p, notEditable) {
      this.$refs.editpropertyModal.editProperty(p, notEditable);
    },
    getAllProperties: async function () {
      this.properties = await this.$A.AccountService.GetAllCustomProofProperties() || [];
    },
  },
  computed: {
    displayFeatureBtn: function () {
      const properties = _.get(this, 'properties', [])
      return !this.isEnterpriseAccount && properties.length > 0;
    }
  },
  data() {
    return {
      properties: [],
      fields: [
        {
          key: 'field',
          sortable: false,
          label: "CUSTOM FIELD"
        },
        {
          key: 'type',
          label: "FIELD TYPE",
        },
        {
          key: 'edit',
          sortable: false,
          label: ""
        },
        {
          key: 'delete',
          sortable: false,
          label: ""
        },
      ],
      isEnterpriseAccount: false,
    };
  },
  async mounted() {
    await this.getAllProperties()
    this.isEnterpriseAccount = _.get(this.$A, 'Auth.account.subscriptionState.sku', []).includes('enterprise') || _.get(this.$A, 'Auth.account.subscriptionState.sku', []).includes('premium');
  },
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10 account-property-card">
      <b-card-body>
        <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 text-dark account-property-header">Proof Properties</b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 description-text">Properties are used to collect and store information about your proofs. 
          For example, a proof might have properties like P.O. Number or Company.
        </b-card-text>
        <b-table class="mt-3 px-4 rounded property-table"
                 :items="properties"
                 :fields="fields"
                 responsive
                 hover
                 outlined
                 thead-class="property-table-bg"
        >
          <template #head()="data">
            <span class="table-header-text ml-2">{{ data.label }}</span>
          </template>
          <template #cell(field)="data">
            <div class="d-flex">
              <span class=" ml-2 column-header-text">{{ data.item.label }}</span>
            </div>
          </template>
          <template #cell(type)="data">
            <div class="d-flex">
              <span class="column-header-text align-center">{{ data.item.dataType }}</span>
            </div>
          </template>
          <template #cell(edit)="data">
            <div
                v-if="data.item.dataType === 'Dropdown Select' || data.item.dataType ==='Checkboxes (Multiple Options)'">
              <a class="font-weight-normal edit-delete-property property"
                 @click="editProperty(data.item, true)">Edit</a>
            </div>
          </template>
          <template #cell(delete)="data">
            <div>
              <a class="font-weight-normal edit-delete-property property"
                 @click="$refs.deletepropertyModal.open(data.item)">Delete</a>
            </div>
          </template>
        </b-table>
        <editPropertyModal ref="editpropertyModal" v-on:propertySaved="getAllProperties"/>
        <deletePropertyModal ref="deletepropertyModal" v-on:propertyDeleted="getAllProperties"/>
      </b-card-body>
      <div class="border-top-0 d-flex mb-0 justify-content-end table-footer flex-sm-wrap">
        <a @click="editProperty" class="button button-light button-green property-btn"
           v-if="!displayFeatureBtn">Create Property</a>
        <b-button v-if="displayFeatureBtn"
                  class="button button-block-organization"
                  @click="$A.BuyNow()"
                  v-b-tooltip.hover="'Upgrade to add more fields'">
          Feature Locked <img src="/img/feature-lock.svg" class="feature-icon"/>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

.property-button {
  cursor: pointer;
  float: right;
  margin-left: 35px;
}

.property-button img {
  height: 15px;
  margin-left: 10px;
}

.invite_link {
  text-decoration: underline;
  opacity: 1;
  margin-top: 0 !important;
  cursor: pointer;
}

.property-btn {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.account-property-card ::v-deep .card-body {
  padding: 0px;
}

.account-property-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

/deep/ .property-table {
  border-radius: 4px !important;
}

/deep/ .property-table .table td {
  padding: 16px 24px;
}


::v-deep .property-table table thead th {
  border-bottom: 0px;
}

/deep/ .property-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.table-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  color: #575759;
}

.column-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.edit-delete-property {
  color: #006F41;
}

.edit-delete-property:hover {
  cursor: pointer;
}

.button-block-organization {
  padding: 9px 17px !important;
  font-weight: normal;
  /*height: 38px;*/
  background: #F44271;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  color: #ffffff;
}


@media (max-width: 500px) {

  .button-locked-propertys {
    background: #F44271;
    color: #ffffff;
    transition: all .5s ease;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .propertys-btn {
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
  }

}


</style>
