<script>
export default {
  name: "invoices",
  data() {
    return {
      invoices: [],
    };
  },
  methods: {
    getAllInvoices: async function () {
      this.invoices = await this.$A.BillingService.GetAllInvoices();
    },
  },
  async mounted() {
    await this.getAllInvoices();
  },
};
</script>

<template>
  <div>
    <b-modal id="invoices" size="lg">
      <template v-slot:modal-header>
        <alertHeader />
        <div class="w-100">
          <h4>Download Invoices</h4>
        </div>
      </template>

      <ul class="invoice-list">
        <div class="row invoice-header">
          <div class="col-md-3">Invoice Number</div>
          <div class="col-md-2">Invoice Date</div>
          <div class="col-md-2">Status</div>
          <div class="col-md-3">Description</div>
          <div class="col-md-2">View Invoice</div>
        </div>
        <li v-for="invoice in invoices" v-bind:key="invoice.id">
          <div class="row">
            <div class="col-md-3">
              {{ invoice.id }}
            </div>
            <div class="col-md-2" style="opacity: 0.5">
              {{ $A.FormatDate(invoice.dueDate) }}
            </div>
            <div
              class="col-md-2"
              :class="{ paid: invoice.paid, pending: !invoice.paid }"
            >
              <div class="invoice-pill">
                {{ invoice.paid ? "Paid" : "Pending" }}
              </div>
            </div>
            <div class="col-md-3" style="opacity: 0.5">
              {{ invoice.billingReason ? invoice.billingReason.replace("_", " ") : " "}}
            </div>
            <div class="col-md-2">
              <a
                class="edit-button"
                :href="invoice.hostedInvoiceUrl"
                target="_blank"
              >
                View
                <img src="/img/download.svg" />
              </a>
            </div>
          </div>
        </li>
      </ul>

      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100">
          <b-button
            class="button button-dark button-gray normal-button"
            @click="cancel()"
            >Close</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>
.invoice-list {
  list-style-type: none;
  padding-left: 0;
}

.invoice-list li {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(68, 68, 68, 0.5);
  line-height: 25px;
  text-transform: capitalize;
}

.invoice-list li img {
  width: 15px;
}

.invoice-pill {
  width: 90%;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
}

.paid .invoice-pill {
  border: 2px solid #019255;
  color: #019255;
}

.pending .invoice-pill {
  border: 2px solid #f4bf78;
  color: #f4bf78;
}

.invoice-header {
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(68, 68, 68, 0.5);
  font-weight: bold;
}
</style>
