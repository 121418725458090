<script>
import sidebarAutomation from "../components/automation-archive/sidebar-automation.vue";
import workflowlist from "../components/automation-archive/workflow-list.vue";
import templatelist from "../components/automation-archive/template-list.vue";
import accountChecklists from "../components/settings/setting-accountChecklists";

export default {
  components: {
    sidebarAutomation,
    workflowlist,
    templatelist,
    accountChecklists
  },
  data() {
    return {
      user: this.$A.Auth,
      steps: [
        {
          target: "#automation_template",
          content: `You can customize the email templates that are sent from Ashore, or create your own. You can append these templates to any message you send in Ashore.`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#automation_workflow",
          content:
              "You can predefine your approval process using workflows. You can append a workflow to a proof at any time!",
          params: {
            placement: "right",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("automation-archive");
        },
      },
    };
  },
  mounted() {
    this.$A.ClearViewData();
    // window.$A.ProductTourProcess("automation-archive", this);
  },
  computed: {},
};
</script>

<template>
  <div class="nav-margin">
    <navigation></navigation>
      <div>
        <b-tabs
            active-nav-item-class="font-weight-normal border-bottom border-success border-left-0 border-right-0 border-top-0 text-success"
            active-tab-class="text-success"
            justified
            nav-class="bg-white pt-0 d-flex"
            content-class="container-fluid px-0"
        >
          <b-tab title="Workflows" active>
            <template #title>
              <span class="font-weight-normal">Workflows</span>
            </template>
            <workflowlist/>
          </b-tab>
          <b-tab title="Email Templates">
            <template #title>
              <span class="font-weight-normal">Email Templates</span>
            </template>
            <templatelist/>
          </b-tab>
          <b-tab title="Checklists">
            <template #title>
              <span class="font-weight-normal">Checklists</span>
            </template>
            <div>
              <accountChecklists />
            </div>
          </b-tab>
        </b-tabs>
    </div>
    <v-tour
        name="automation-archive"
        :steps="steps"
        :callbacks="tourCallbacks"
    ></v-tour>
  </div>
</template>

<style scoped>

::v-deep .nav-tabs .nav-link {
  margin-bottom: -2px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

::v-deep .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 0;
  list-style: none;
}

::v-deep .nav > li > a {
  position: relative;
  display: block;
  padding: 19px 15px;
  font-weight: bold;
  color: #444444;
}

@media (max-width: 500px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
  }
}

@media (max-width: 750px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  ::v-deep .nav-link {
    display: block;
    padding: 0.4px 0.8px 14px 0.8px !important;
  }

  ::v-deep .nav-tabs .nav-link {
    margin-top: 10px !important;
  }

}

@media (min-width: 1500px)  and (max-width: 1700px){
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1998px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
}

@media (min-width: 1998px) and (max-width: 2194px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 500px !important;
    padding-right: 500px !important;
  }
}

@media (min-width: 2194px) and (max-width: 4000px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 600px !important;
    padding-right: 600px !important;
  }
}


</style>


