<script>
import draggable from "vuedraggable";
import redactor from "../form/redactor.vue";
import proofToggles from "../global/proof-toggle.vue";
import reminderFrequency from "../form/reminderFrequency";

export default {
  name: "setting-create-user-sidebar",
  components: {
    draggable,
    redactor,
    proofToggles,
    reminderFrequency
  },
  props: {
    availableTeams: {
      type: Array
    },
    availableRoles: {
      type: Array
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    edit: function (user) {
      this.user = {...user};
      this.userRole = _.get(user, 'permissions.role.roleId', 0);
      this.userTeams = user.teams.map(t => ({...t, teamId: t.id}));
      const team = this.user.teams.find(t => t.users.find(u => u.userId === user.id && u.defaultTeamForUser));
      this.defaultTeam = _.get(team, 'id', 0);
      this.showSideBar = true;
    },
    hasTeamsDuplicateIds: function () {
      const idSet = new Set();
      for (const t of this.userTeams) {
        if (idSet.has(t.teamId)) {
          return true;
        }
        idSet.add(t.teamId);
      }
      return false;
    },
    cancel: function () {
      this.showSideBar = false;
      this.user = {
        id: 0,
        name: "",
        email: "",
        phone: "",
        title: "",
        role: 0,
        teams: []
      };
      this.userTeams = [];
      this.defaultTeam = 0;
    },
    save: async function () {
      if (!this.checkForm()) return;
      if (!this.hasTeamsDuplicateIds()) {
        this.userTeams.forEach(t => t.isDefault = t.id === this.defaultTeam);
        this.user.teams = this.userTeams;
        this.user.role = this.userRole;
        await this.$A.AccountService.SaveAccountUser(this.user);
        this.$emit('saved');
        this.cancel();
      } else {
        this.this.$A.AlertUser("Please select different teams");
      }
    },
    addTeam: function () {
      const team = this.availableTeams.find(t => !this.userTeams.find(ut => ut.id === t.id));
      if (team) {
        if (!this.userTeams.length) this.defaultTeam = team.id;
        this.userTeams.push({...team, teamId: team.id});
      }
    },
    deleteTeam: function (team) {
      this.userTeams = this.userTeams.filter(t => t.id !== team.id);
      this.defaultTeam = _.get(this.userTeams, [0, 'id'], 0);
    },
    checkForm: function () {
      this.errors = [];
      this.validated = true;

      if (!this.user.name) {
        this.errors.push("Name required.");
      }
      if (!this.user.title) {
        this.errors.push("Title required.");
      }
      if (!this.user.phone) {
        this.errors.push("Phone required.");
      } else if(!this.validatePhoneNumber(this.user.phone)) {
        this.errors.push('Please enter a valid phone number.');
      }
      if (!this.userRole) {
        this.errors.push("Role required.");
      }
      if (!this.user.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.user.email)) {
        this.errors.push('Please enter a valid email.');
      }

      if (!this.errors.length) {
        return true;
      }

      const errorMessage = "<ul>" + this.errors.map(e => "<li>" + e + "</li>").join("") + "</ul>";
      this.$A.AlertUser(errorMessage);
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validatePhoneNumber: function (phone) {
      let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(phone);
    }
  },
  data() {
    return {
      showSideBar: false,
      user: {
        id: 0,
        name: "",
        email: "",
        phone: "",
        title: "",
        teams: []
      },
      userTeams: [],
      defaultTeam: 0,
      userRole: 0
    };
  },
  watch: {}
};
</script>

<template>
  <b-sidebar
      id="create-user-sidebar"
      right
      shadow
      v-model="showSideBar"
      @hidden="cancel"
      :no-header-close="true"
  >

    <!-- Create User Header -->
    <div class="workflow-header-container">
      <div class="sidebar-workflow-header mb-2">Manage User</div>
      <span class="sub-text">Your user role can be applied to users on your account.</span>
    </div>

    <!--   User Name -->
    <div
        class="workflow-field-container d-flex flex-direction-row justify-content-around border-bottom align-items-center">
      <span class="font-weight-normal col-md-4 pl-0">Name</span>
      <b-input type="text"
               class="field-input col-md-8"
               name="name"
               v-model="user.name">
      </b-input>
    </div>

    <!-- Details -->
    <div class="workflow-field-container flex-row justify-content-start border-bottom">
      <div class="font-weight-bold text-normal mb-3 col-md-12 px-0">Details</div>
      <div class="col-md-12 px-0">
        <span class="field-text px-0">Email</span>
        <b-input type="text"
                 class="field-input"
                 name="email"
                 v-model="user.email">
        </b-input>
      </div>
      <div class="col-md-12 px-0">
        <span class="field-text px-0">Phone Number</span>
        <b-input type="text"
                 class="field-input"
                 name="phone"
                 v-model="user.phone">
        </b-input>
      </div>
      <div class="col-md-12 px-0">
        <span class="field-text px-0">Title</span>
        <b-input type="text"
                 class="field-input"
                 name="title"
                 v-model="user.title">
        </b-input>
      </div>
      <div class="col-md-12 px-0">
        <span class="field-text col-md-12 px-0">Role</span>
        <v-select
            name="user-role"
            v-model="userRole"
            :options="availableRoles"
            :reduce="option => option.id"
            :searchable="false"
            :clearable="false"
            class="team-select mt-2 px-0"
            label="name"
        />
      </div>
    </div>

    <!--  Teams  -->
    <div class="workflow-field-container flex-row justify-content-start">
      <div class="d-flex col-md-12 flex-column align-items-start pl-0">
        <span class="font-weight-bold text-normal mb-3 col-md-12 pl-0">Teams</span>
        <span class="text-normal font-weight-normal text-xs leading-5 font-medium mb-2" style="color: #707073">
          Add this user to one or many teams and select the user’s default team.
        </span>
      </div>
      <div class="d-flex justify-content-between col-md-12 align-items-center px-0" v-for="team in userTeams">
        <!-- Team Radio -->
        <b-form-radio
            v-model="defaultTeam"
            :value="team.id"
            name="default-team"
        >
        </b-form-radio>
        <!-- Teams -->
        <v-select
            v-model="team.teamId"
            :options="availableTeams"
            :reduce="option => option.id"
            placeholder="Select Team"
            :searchable="false"
            :clearable="false"
            class="mt-1 col-md-10 px-0 mb-0 pb-0 team-select"
            label="name"
        />
        <!-- Trash Icon -->
        <img src="/img/delete-team-icon.svg"
             @click="deleteTeam(team)"
             class="delete-icon"
        />
      </div>
      <b-button :disabled="!availableTeams.length||userTeams.length>=availableTeams.length"
                class="workflow-btn-white ml-auto mt-3 ml-auto"
                @click="addTeam()"
      >
        Add Team
      </b-button>
    </div>

    <!-- Footer -->
    <template #footer="{ hide }">
      <!--  Buttons  -->
      <div class="d-flex create-workflow-footer border-top col-md-12 justify-content-end pb-0">
        <b-button class="workflow-btn-gray button ml-3 pb-0 mb-0"
                  @click="hide">
          Cancel
        </b-button>
        <b-button
            class="workflow-btn-green button ml-3 pb-0 mb-0"
            @click="save()"
        >
          Save User
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>


<style scoped>

/deep/ .b-sidebar {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 672px;
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-body {
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}

.workflow-header-container {
  background: #F4F4F6;
  padding: 24px;
  border-bottom: 1px solid lightgray;
}

.sidebar-workflow-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
  margin-top: 4px;
}

.small-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.type-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.delete-icon {
  width: 16px;
  height: 18px;
  padding-left: 0px;
}

.role-select {
  padding: 0px 0px !important;
}

.team-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border-radius: 6px;
}

.team-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
  font-weight: normal;
  margin-bottom: 10px;
}

.team-select ::v-deep .vs__selected {
  padding: 4px 0px 8px 4px !important;
}

.team-select ::v-deep .vs__dropdown-option {
  font-weight: normal !important;
}

.field-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
}

.delete-icon:hover {
  cursor: pointer;
}

.setting-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */


  /* Gray/500 */

  color: #707073;
}

.stage {
  padding: 0px 0px 24px 0px !important;
}

.field-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

.property-form-input {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  display: block;
  width: 100%;
  height: 37px;
  padding: 2px 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #444444;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid lightgray;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 5px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}


.workflow-field-container {
  padding: 20px 24px;
  background-color: white;
}

::v-deep .stages-container-card .card-body {
  padding: 16px 24px !important;
  display: flex;
  border: 0px;
  border-right: 0.25px;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
  border-radius: 0px;
}

::v-deep .stages-container-card {
  border: 0px !important;
}


::v-deep .stages-container-card .card-body {
  padding: 16px 24px !important;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.gray-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  width: 100%;
}

.stage-dot-black {
  width: 8px;
  height: 8px;
  background: #444444;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 0px;
}

.stage-dot-white {
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.stages {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-workflow-footer {
  padding: 20px 24px !important;
  background: #f4f4f6;
  color: #575759;
}

.workflow-btn-green {
  display: block;
  cursor: pointer;
  padding: 9px 17px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #019256;
}

.workflow-btn-white {
  display: block;
  cursor: pointer;
  padding: 9px 17px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  height: 38px;
  transition: all .5s ease;
  background: #EEF2F5;
  color: #3e3e40;
}


.workflow-btn-gray {
  display: block;
  cursor: pointer;
  padding: 9px 17px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  height: 38px;
  transition: all .5s ease;
  background: #F9F9F9;
  color: #3e3e40;
  border: 1px solid #B5B5B8;
}

.workflow-btn-white:hover, .workflow-btn-green:hover {
  opacity: 0.5;
  transition: all 0.5s ease;
}

.overview-card {
  padding: 24px;
  border: 0px;
  border-radius: 0px !important;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
}

/deep/ .overview-card .card-body {
  padding: 0px !important;
}

.header-text {
  font-weight: 700;
  margin-bottom: 10px;

}


.stage-number {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.team-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 13px;
  background: white;
  border-radius: 6px;
}

.stages-padding {
  padding: 20px 24px 0px 24px;
}

.checklist-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .checklist-select .vs__dropdown-menu {
  font-weight: 400 !important;
  overflow-y: auto !important;
  max-height: 75px !important;
}

/deep/ .vs__clear {
  fill: rgba(60, 60, 60, .5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 4px;
}

/deep/ ul {
  font-weight: 400;
}

/deep/ .ti-autocomplete {
  border: 1px solid #B5B5B8 !important;
  border-top: 0px !important;
}

/deep/ .vs__clear {
  margin-top: 2px;
}

/deep/ .vs__open-indicator {
  margin-top: 0px !important;
}

/deep/ .ti-tag span {
  line-height: 1.2;
}

/deep/ .vs__selected {
  padding: 0px 2px 4px 4px;
}

/deep/ .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #006F41 !important;
  border-color: #006F41 !important;
}

@media screen and (max-width: 991px) {

  .vs__search, .vs__search:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.4;
    font-size: 1em;
    border: 1px solid transparent;
    border-left: none;
    outline: none;
    margin: 0px;
    padding: 0 7px;
    background: none;
    box-shadow: none;
    width: 0;
    max-width: 100%;
    flex-grow: 1;
    z-index: 1;
  }

  /deep/ .ti-tag span {
    margin-bottom: 0px;
    opacity: 1;
    line-height: 1.2;
  }

  /deep/ .ti-icon-close {
    line-height: unset;
  }

  /*/deep/ .ti-tag {*/
  /*  padding: 3px 4px;*/
  /*  width: max-content;*/
  /*  text-align: left;*/
  /*}*/

}

@media screen and (max-width: 769px) {

  /deep/ .vs--single .vs__selected {
    width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

}

@media screen and (min-width: 770px) {

  /deep/ .vs--single .vs__selected {
    width: 400px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

}


</style>

