<script>
import redactor from "../../components/form/redactor.vue";

export default {
  name: "modalAddNote",
  components: {
    redactor,
  },
  props: {
    proof: {
      required: true,
      type: Object,
    },
    curUser: {},
  },
  methods: {
    addNote: async function () {
      let body = this.$refs.redactor.getTextBody();
      if (body.length === 0) {
        this.$A.AlertUser("Please add a note before submitting a note.");
        return;
      }
      let response = await this.$A.ProofService.AddNoteToTimeline(
          this.$props.proof.id,
          body
      );
      if (response.success) {
        this.$refs.redactor.setTextBody("");
        this.$bvModal.hide("AddTimelineNote");
        this.$root.$emit("proof::refresh");
      }
    },
  },
  computed: {
    commentFileApi: function () {
      if (this.proof) {
        const apiToken = _.get(this, 'curUser.apiToken.token', "")
        let apiUrl = `/annotation/comment-file/${this.proof.publicId}?u=${apiToken}`;
        return apiUrl;
      }
      return "";
    },
  }
};
</script>

<template>
  <!-- Add Note Modal -->
  <b-modal id="AddTimelineNote" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <h4>Add Note to Timeline</h4>
        Add a note to your proof timeline. Your contacts won't see this note.
      </div>
    </template>

    <redactor ref="redactor"
              :public-proof-id="$props.proof.publicId"
              :comment-api-url="commentFileApi"
    />

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            class="button gray-btn"
            @click="cancel()"
        >Cancel
        </b-button
        >
        <b-button
            class="button green-btn"
            @click="addNote()"
        >Save to Timeline
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>


.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.5);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

::v-deep .modal-footer {
  border-top: none !important;
}

@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
  }
}

</style>
