<script>
import redactor from "../../components/form/redactor.vue";

export default {
  name: "emailSignature",
  components: {
    redactor,
  },
  data() {
    return {};
  },
  mounted: async function () {
    this.$refs.emailSig.setTextBody(await this.$A.UserService.GetSignature());
  },
  methods: {
    updateEmailSignature: async function () {
      let textBody = this.$refs.emailSig.getTextBody();
      await this.$A.UserService.UpdateUserEmailSignature(
          this.$A.Auth.id,
          textBody
      );
    },
  },
};
</script>

<template>
  <!-- Field -->
  <div class="on-mobile text-dark d-flex justify-content-center flex-md-wrap mt-4 px-2">
    <div class="field-item border col-md-10">
      <div class="row">
        <div class="col-md-12">
          <div class="header-text pt-4 px-4">Your Email Signature</div>
          <div class="sub-header-text px-4" style="margin-bottom: 16px; margin-top: 8px">Replace the default
            signature with your own
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="px-4 pb-4">
            <redactor ref="emailSig" data-cy="emailSignatureInput"/>
          </div>
        </div>
      </div>
      <div class="email-footer col-md-12 justify-content-end d-flex">
        <a class="green-btn" @click="updateEmailSignature" data-cy="updateSignatureBtn">Update</a>
      </div>
    </div>
  </div>
  <!-- /Field -->
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

.green-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019255;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.green-btn:hover {
  background-color: #019255 !important;
  opacity: 0.9 !important;
  color: white;
}

.profile-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.sub-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.email-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  height: 62px;
}

.field-item {
  padding: 0px;
}

</style>
