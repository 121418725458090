<script>
export default {
  name: "editNonApprover",
  props: {
    accountUsers: {
      required: true,
      type: Array,
    },
    instanceId: {
      required: false,
      type: String,
    },
  },
  data: function () {
    return {
      ActualinstanceId: this.$A.GetUUID(),
    };
  },
  mounted() {
    if (this.$props.instanceId !== undefined) {
      this.ActualinstanceId = this.$props.instanceId;
    }
  },
  methods: {
    update: function () {
      // console.log(this.accountUsers);
      this.$root.$emit(
          "confirmed::selecting::non_approver::" + this.ActualinstanceId
      );
      this.$emit("update:accountUsers", this.$props.accountUsers);
      this.$bvModal.hide(this.ActualinstanceId);
    },
  },
};
</script>

<template>
  <!-- Edit Non-Approvers Modal -->
  <b-modal :id="ActualinstanceId" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100 pt-4 px-4" style="margin-bottom: 18px">
        <div class="header-text">Select Non-Approvers</div>
        <span class="sub-text">Select the Ashore users who should receive notifications about this
          proof.</span>
      </div>
    </template>
    <ul id="SelectNonApprover" class="px-4 pb-3 mb-0">
      <li
          class="user-list-item row"
          @click="user.selected = !user.selected"
          v-for="user in accountUsers"
          v-bind:key="user.id"
      >
        <div class="col-md-8">
          {{ user.name }}
        </div>
        <div class="col-md-4">
          <div class="float-right non-approver-options">
            <div v-show="!user.selected">
              Add Non-Approver
              <img src="/img/arrow-right-icon.svg"/>
            </div>
            <div class="selected" v-show="user.selected">
              Selected
              <img id="" src="/img/selected-icon.svg"/>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
            class="gray-btn"
            @click="cancel()"
        >Cancel
        </b-button
        >
        <b-button
            class="green-btn"
            @click="update()"
        >Update
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  margin-bottom: 20px;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

::v-deep .modal-header {
  padding: 0px !important;
}

::v-deep .modal-body {
  padding: 0px !important;
}


.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  width: 18%;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  color: white;
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  width: 18%;
}

.gray-btn:hover {
  color: white;
}


</style>
