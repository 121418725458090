<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "modalEditTags",
  props: ["initalTagId"],
  components: {
    VueTagsInput,
  },
  data() {
    return {
      tags: [],
      curTagIdSelected: 0,
      curTagName: "",
    };
  },
  methods: {
    tagUpdated: function (tags) {
      this.$root.$emit("tags::reloaded", tags);
    },
    saveTag: async function () {
      let tag = await this.$A.TagsService.Save({
        id: this.curTagIdSelected,
        name: this.curTagName,
      });
      await this.loadTags();
      if (tag.id > 0) {
        this.curTagName = tag.name;
        this.curTagIdSelected = tag.id;
      }
    },
    deleteTag: async function () {
      await this.$A.TagsService.Delete(this.curTagIdSelected);
      await this.loadTags();
      this.curTagName = "";
      this.curTagIdSelected = 0;
    },
    loadTags: async function () {
      this.tags = await this.$A.TagsService.GetAll();
      this.tagUpdated(this.tags);
    },
  },
  async mounted() {
    if (Number.isInteger(this.curTagIdSelected) && this.curTagIdSelected > 0) {
      this.curTagIdSelected = this.initalTagId;
    }
    await this.loadTags();
  },
  computed: {
    editOrCreateText: function () {
      return this.curTagIdSelected > 0 ? "Update" : "Create";
    },
    editOrCreateSentence: function () {
      return this.curTagIdSelected > 0 ? "Create a New" : "Update existing";
    },
  },
  watch: {
    curTagIdSelected: function (newVal, oldValue) {
      let selected = {id: 0, name: ""};
      if (Number.isInteger(newVal) && newVal > 0) {
        selected = JSON.parse(
            JSON.stringify(this.tags.find((i) => i.id === newVal))
        );
      }
      // console.log(selected);
      this.curTagName = selected.name;
    },
  },
};
</script>

<template>
  <div>
    <b-modal id="ManageTags" size="lg" no-stacking scrollable>
      <template v-slot:modal-header>
        <alertHeader/>
        <div class="w-100">
          <div class="header-text">Manage Tags</div>
          <span class="sub-header-text">Categorize proofs into groups using tags. Rename or Delete a tag here.</span>
        </div>
      </template>

      <form role="form">
        <div class="row">
          <div class="col-md-12">
            <label class="sub-text">Select Tag To Update</label>
            <select
                type="text"
                class="ti-new-tag-input ti-valid ti-input"
                v-model="curTagIdSelected"
                style="padding-left: 13px"
            >
              <option value="0">Create New Tag</option>
              <option :value="tag.id" v-for="tag in tags" >
                {{ tag.name }}
              </option>
            </select>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-12">
            <label class="sub-text">{{ editOrCreateText }} Tag</label>
            <input
                type="text"
                class="ti-new-tag-input ti-valid ti-input"
                v-model="curTagName"
                placeholder="Tag Name"
                style="padding-left: 13px"
            />
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start px-0">
            <b-button
                class="button green-btn"
                @click="saveTag()"
                :disabled="!curTagName"
            >{{ editOrCreateText }} Tag
            </b-button>
            <b-button
                class="red-btn ml-2"
                @click="deleteTag()"
                v-show="curTagIdSelected > 0"
            >Delete Tag
            </b-button
            >
          </div>
        </div>
      </form>

      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100 text-right d-flex justify-content-end">
          <b-button
              class="button gray-btn"
              @click="cancel()"
          >Done
          </b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<style scoped>

.ti-input {
  outline: none !important;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  width: 18%;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  color: white;
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  width: 18%;
}

.gray-btn:hover {
  color: white;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  margin-bottom: 12px;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.red-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  color: #EB4654;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #F9F9F9;
  border: 1px solid #EB4654;
}



.sub-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
  .green-btn {
    width: 30%;
  }
}

</style>
