<template>
  <div>
    <navigation />
    <div class="container-fluid nav-margin">
      <div class="row mt-50">
        <div class="col-md-12 m-auto">
          <div class="row">
            <div class="col-md-10 m-auto">
              <pricePlan
                v-if="!subscriptionState.isLifeTime"
              />
              <productCodeRedemption
                v-if="!!subscriptionState.isLifeTime"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <copyright />-->
  </div>
</template>

<script>
import pricePlan from "../components/settings/setting-pricePlan.vue";
import productCodeRedemption from "../components/settings/setting-productCodeRedemption.vue";

export default {
  components: {
    pricePlan,
    productCodeRedemption,
  },
  data() {
    return {
      user: this.$A.Auth,
    };
  },
  computed: {
    subscriptionState: function() {
      return _.get(this.user, 'account.subscriptionState', {});
    }
  }
};
</script>

<style scoped>
.button-gray {
  min-width: 250px;
}
</style>
