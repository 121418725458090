import { render, staticRenderFns } from "./modal-proof-link.vue?vue&type=template&id=546a6798&scoped=true&"
import script from "./modal-proof-link.vue?vue&type=script&lang=js&"
export * from "./modal-proof-link.vue?vue&type=script&lang=js&"
import style0 from "./modal-proof-link.vue?vue&type=style&index=0&id=546a6798&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546a6798",
  null
  
)

export default component.exports