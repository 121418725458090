<script>
export default {
  name: "modalRenameItem",
};
</script>

<template>
  <div>
    <b-modal id="RenameItem" size="lg">
      <template v-slot:modal-header>
        <alertHeader />
        <div class="w-100">
          <h4>Rename Item</h4>
        </div>
      </template>

      <form role="form">
        <div class="row">
          <div class="col-md-6">
            <input
              name="name"
              type="text"
              placeholder="Item Name"
              class="form-control"
            />
          </div>
        </div>
      </form>

      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100">
          <b-button
            class="button button-dark button-gray normal-button"
            @click="cancel()"
            >Cancel</b-button
          >
          <b-button class="button button-light button-green normal-button"
            >Update</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
