<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "selectTags",
  components: {
    VueTagsInput,
  },
  data() {
    return {
      instanceId: this.$A.GetUUID(),
      curSelectedString: "",
      stringArray: [],
      autoComplete: [],
      tags: [],
    };
  },
  async mounted() {
    await this.loadTags();
  },
  methods: {
    getSelectedTags: function () {
      return this.tags.filter(function (a) {
        return a.selected;
      });
    },
    setSelectedTags: function (selectedTags) {
      for (let i = 0; i < this.tags.length; i++) {
        this.tags[i].selected = false;
      }
      for (let i2 = 0; i2 < selectedTags.length; i2++) {
        for (let i = 0; i < this.tags.length; i++) {
          if (selectedTags[i2].id === this.tags[i].id) {
            this.tags[i].selected = true;
          }
        }
      }
      this.calcTags();
    },
    calcTags: function () {
      let stringArray = [];
      this.tags.forEach(function (t) {
        if (t.selected) stringArray.push({ text: t.name.capitalize() });
      });
      this.stringArray = stringArray;
    },
    loadTags: async function (ignoreSelectionStatus, forceUpdate) {
      let tags = this.tags;
      if (tags.length === 0 || (forceUpdate !== undefined && forceUpdate))
        tags = await this.$A.TagsService.GetAll();

      let autoComplete = [];
      if (Array.isArray(tags)) {
        for (let i = 0; i < tags.length; i++) {
          autoComplete.push({ text: tags[i].name.capitalize() }); //+" - "+approvers[i].email
          if (!ignoreSelectionStatus) {
            tags[i].selected = false;
          } else {
            this.tags.forEach(function (i2) {
              if (i2.id === tags[i].id) {
                tags[i].selected = i2.selected;
              }
            });
          }
        }
        this.tags = tags;
        this.autoComplete = autoComplete;
        this.calcTags();
      }
    },
    selectionChanged: function (newTags) {
      for (let i = 0; i < this.tags.length; i++) {
        let selected = false;
        for (let i2 = 0; i2 < newTags.length; i2++) {
          if (
            this.tags[i].name.capitalize() === newTags[i2].text.capitalize()
          ) {
            this.tags[i].selected = true;
            selected = true;
          }
        }
        if (!selected) {
          this.tags[i].selected = false;
        }
      }
    },
  },
  computed: {
    getAutoComplete: function () {
      let curTag = this.curSelectedString;
      return this.autoComplete
        .filter((a) => a.text.toLowerCase().indexOf(curTag.toLowerCase()) > -1)
        .splice(0, 20);
    },
  },
};
</script>

<template>
  <div class="row mt-10">
    <div :id="instanceId" class="col-md-12">
      <vue-tags-input
        placeholder="Add Tags To Filter"
        v-model="curSelectedString"
        :tags="stringArray"
        :autocomplete-items="getAutoComplete"
        :add-only-from-autocomplete="true"
        @tags-changed="(newTags) => selectionChanged(newTags)"
      >
      </vue-tags-input>
    </div>
  </div>
</template>
