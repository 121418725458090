<script>
import Datepicker from "vuejs-datepicker";

export default {
  name: "modalEditDeadline",
  components: {
    Datepicker,
  },
  props: {},
  data() {
    return {
      dueDate: "",
    };
  },
  async mounted() {
  },
  methods: {
    saveAndClose: function () {
      this.$root.$emit("proof::save::dueDate", {dueDate: this.dueDate});
      this.$bvModal.hide("EditDeadline");
    },
  },
};
</script>

<template>
  <!-- Edit Deadline Modal -->
  <b-modal id="EditDeadline" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <h4>Edit Deadline</h4>
      </div>
    </template>

    <form role="form">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <datepicker placeholder="Set Proof Deadline" v-model="dueDate"/>
          </div>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            class="button gray-btn"
            @click="cancel()"
        >Cancel
        </b-button
        >
        <b-button
            class="button green-btn"
            @click="saveAndClose()"
        >Update
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>


<style scoped>

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
  }

}


</style>