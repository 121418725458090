<script xmlns="http://www.w3.org/1999/html">

import modalVerifyEmail from "../../components/modals/modal-verify-smtp-email";

export default {
  name: "setting-smtp-no-reply-email",
  data() {
    return {
      user: this.$A.Auth,
      oldNoReplyEmail: _.get(this, '$A.Auth.account.noReplyEmail', ""),
      newNoReplyEmail: _.get(this, '$A.Auth.account.noReplyEmail', ""),
      oldWhiteLabelDomainName: _.get(this, '$A.Auth.account.whiteLabelDomainName', ""),
      newWhiteLabelDomainName: _.get(this, '$A.Auth.account.whiteLabelDomainName', ""),
      emailConfiguration: _.get(this, '$A.Auth.account.emailConfiguration', ""),
      emailAuthorization: _.get(this, '$A.Auth.account.emailAuthorization', ""),
      emailSettings: {
        displayMethodType: "1",
        postmark: {
          returnPathDomainVerified: false,
          fromEmail: "",
          dkimVerified: false,
          dkimHost: "",
          returnPathDomainCNAMEValue: ""
        },
        smtpServer: {
          validated: false,
          fromEmail: "",
          smtpServerInfo: {
            userName: "",
            password: "",
            serverAddress: "",
            serverPort: 587,
          }
        }
      },
      validated: false,
      sendMethodStatus: {},
      displaySmtpInfoEditMode: false,
      isMobile: window.innerWidth < 991,
      serverWarningMsg: "",
      sendMethodStatusId: 0,
      displayDKIM: false,
      isProfileEmailAuthorize: false,
    };
  },
  components: {
    modalVerifyEmail
  },
  props: {
    isAccountSettings: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    isChangingProfileEmailAddressUponSaving: function () {
      return !this.$props.isAccountSettings && this.user.email !== this.newNoReplyEmail;
    },
    isChangingProfileEmailAddressUponSavingSmtp: function () {
      return !this.$props.isAccountSettings && this.emailSettings.smtpServer.fromEmail !== "" && this.user.email !== this.emailSettings.smtpServer.fromEmail;
    },
    supportedEmail: function () {
      const email = this.emailSettings.displayMethodType === '1' ? this.newNoReplyEmail : this.emailSettings.smtpServer.fromEmail;
      if (!email) return false;
      if (email.indexOf("gmail.com") !== -1) {
        return false;
      }
      if (email.indexOf("hotmail.com") !== -1) {
        return false;
      }
      if (email.indexOf("yahoo.com") !== -1) {
        return false;
      }
      if (email.indexOf("outlook.com") !== -1) {
        return false;
      }
      if (email.indexOf("icloud.com") !== -1) {
        return false;
      }
      if (email.indexOf("aol.com") !== -1) {
        return false;
      }
      return true;
    },
    smtpVerified: function () {
      return this.emailSettings.displayMethodType === "2" && this.emailSettings.smtpServer.validated;
    },
    isEmailAddressMatchingSettings: function () {
      if (this.$props.isAccountSettings)
        return true;
      if (this.emailSettings.displayMethodType === "1")
        return this.emailSettings.postmark.fromEmail === _.get(this, 'user.email', "");
      if (this.emailSettings.displayMethodType === "2")
        return this.emailSettings.smtpServer.fromEmail === _.get(this, 'user.email', "");
    },
    isDisabled: function () {
      return !_.get(this, 'user.account.subscriptionState.whiteLabelEnabled', false);
    },
    isPostmarkEmailAddressSameAsCurrentUserEmailAddress: function () {
      return this.newNoReplyEmail !== _.get(this, 'user.email', "");
    },
    isValidSubscriptionState: function () {
      const planType = _.get(this, "$A.Auth.account.subscriptionState.sku", "").split('-')[0];
      if (this.isAccountSettings) {
        return _.includes([
          'premium',
          'enterprise',
        ], planType);
      } else {
        return _.includes([
          'premium',
          'enterprise',
          'standard',
          'design',
          'freelancer',
        ], planType)
      }
    },
    isDkimVerified: function () {
      return this.emailSettings.postmark.dkimVerified;
    },
    hasDkimData: function () {
      return this.emailSettings.postmark.dkimPendingHost || this.emailSettings.postmark.dkimPendingTextValue;
    },
    isSpfVerified: function () {
      return this.emailSettings.postmark.spfVerified;
    },
    hasSpfData: function () {
      return this.emailSettings.postmark.domain || this.emailSettings.postmark.spfTextValue;
    },
    isReturnPathDomainVerified: function () {
      return this.emailSettings.postmark.returnPathDomainVerified;
    },
    hasReturnPathDomainData: function () {
      return this.emailSettings.postmark.domain || this.emailSettings.postmark.returnPathDomainCNAMEValue
    },
    isPostMarkEmailVerified: function () {
      return this.emailSettings.postmark.confirmed;
    },
    isSmtpServerValidated: function () {
      return this.emailSettings.smtpServer.emailValidated;
    },
    isAllVerifiedForPostmark: function () {
      return this.isDkimVerified && this.isReturnPathDomainVerified && this.isSpfVerified;
    },
    hasDomainConfigData: function () {
      return this.hasDkimData || this.hasReturnPathDomainData || this.hasSpfData;
    },
    displayDomainConfiguration: function () {
      return this.emailSettings.postmark.dkimPendingHost || this.emailSettings.postmark.dkimPendingTextValue ||
          this.emailSettings.postmark.domain || this.emailSettings.postmark.returnPathDomainCNAMEValue;
    },
    showRemoveBtn: function () {
      if (this.emailSettings.displayMethodType === "1") {
        return this.isProfileEmailAuthorize || this.emailSettings.postmark.id > 0;
      }
      return (this.isProfileEmailAuthorize || this.emailSettings.smtpServer.id > 0) && !this.displaySmtpInfoEditMode;
    },
    shouldHideSmtpAuthorizeBtn: function () {
      return this.isAccountSettings && this.emailSettings.displayMethodType === '2' && !this.displaySmtpInfoEditMode;
    },
    shouldDisabledAccountAuthorizeBtn: function () {
      return this.isAccountSettings && this.emailSettings.displayMethodType === '1' && this.newNoReplyEmail === this.oldNoReplyEmail && this.emailSettings.postmark.confirmed;
    }
  },
  methods: {
    resetEmailSettings: async function () {
      if (this.isAccountSettings) {
        await this.$A.AccountService.ResetAccountSendMethod(this.emailSettings.displayMethodType);
      } else {
        await this.$A.UserService.ResetUserSendMethod(this.emailSettings.displayMethodType);
      }
      this.emailSettings = {
        displayMethodType: "1",
        postmark: {
          returnPathDomainVerified: false,
          fromEmail: "",
          dkimVerified: false,
          dkimHost: "",
          returnPathDomainCNAMEValue: ""
        },
        smtpServer: {
          validated: false,
          fromEmail: "",
          smtpServerInfo: {
            userName: "",
            password: "",
            serverAddress: "",
            serverPort: 587,
          }
        }
      };
      await this.GetEmailSettings();
      this.isProfileEmailAuthorize = false;
    },
    updateEmailSettings: async function () {
      this.sendMethodStatusId = 0;
      if (this.emailSettings.displayMethodType === '1') {
        if (this.$props.isAccountSettings) {
          if (!this.isPostmarkEmailAddressSameAsCurrentUserEmailAddress) {
            return this.$A.AlertUser("No Reply Email Cannot be same as Account Email Address");
          }
          if (this.oldNoReplyEmail !== this.newNoReplyEmail) {
            this.displayDKIM = true;
          }
          this.validated = true;
          if (this.newNoReplyEmail !== this.oldNoReplyEmail) {
            let emailResult = await this.UpdateEmail(this.newNoReplyEmail);
            if (!emailResult || emailResult.noReplyEmail === undefined) {
              return;
            }
          }
        }
        this.sendMethodStatusId = await this.SavePostmarkSendMethod();
        if (this.sendMethodStatusId > 0) {
          this.isProfileEmailAuthorize = true;
        }
      } else if (this.emailSettings.displayMethodType === '2') {
        if (!this.displaySmtpInfoEditMode) {
          return this.$A.AlertUser("Please edit server info before saving");
        }

        let email = this.emailSettings.smtpServer.fromEmail;
        let username = this.emailSettings.smtpServer.smtpServerInfo.userName;
        let password = this.emailSettings.smtpServer.smtpServerInfo.password;
        let serverAddress = this.emailSettings.smtpServer.smtpServerInfo.serverAddress;
        let serverPort = this.emailSettings.smtpServer.smtpServerInfo.serverPort;

        if (!email || !username || !password || !serverAddress || !serverPort) {
          this.$A.AlertUser("Please Fill Out The SMTP Server Form First");
          return;
        }

        let accountResult = await this.UpdateEmail(email);
        if (!accountResult || !accountResult.noReplyEmail) {
          return;
        } else {
          this.sendMethodStatusId = await this.SaveSmtpMethod({email, username, password, serverAddress, serverPort});
        }
      }

      if (this.sendMethodStatusId > 0) {
        this.$bvModal.show("verifyEmailSignatureModal");
        this.VerifyEmailStatus();
      }
    },
    SavePostmarkSendMethod: async function () {
      return this.isAccountSettings ?
          this.$A.AccountService.SaveAccountPostmarkSendMethod() :
          this.$A.UserService.SaveUserPostmarkSendMethod();
    },
    SaveSmtpMethod: async function (smtpServerInfo) {
      return this.isAccountSettings ?
          this.$A.AccountService.SaveAccountSmtpSendMethod(smtpServerInfo) :
          this.$A.UserService.SaveUserSmtpSendMethod(smtpServerInfo);
    },
    UpdateEmail: async function (email) {
      return this.isAccountSettings ?
          this.UpdateAccountEmail(email) :
          this.UpdateUserEmail(email);
    },
    UpdateAccountEmail: async function (email) {
      const account = await this.$A.AccountService.UpdateAccount(
          null,
          email,
          null,
          null,
          null,
          null
      );

      if (account && _.get(account, 'success', true)) {
        this.$A.Auth.account = account;
        this.$A.SaveSessionData(this.$A.Auth);
      }

      return account;
    },
    UpdateUserEmail: async function (email) {
      let user = await this.$A.UserService.UpdateUser(
          this.$A.Auth.id,
          {email}
      );

      if (user && _.get(user, 'success', true)) {
        this.$A.Auth = user;
        this.$A.SaveSessionData(this.$A.Auth);
        user = {...user, noReplyEmail: user.email};
      }

      return user;
    },
    VerifyEmailStatus: function () {
      let self = this;
      setTimeout(async () => {
        const status = await self.GetSendMethodStatus(self.sendMethodStatusId, self.emailSettings.displayMethodType);
        const error = _.get(status, ['errors', 0]);
        if (error) {
          self.$bvModal.hide("verifyEmailSignatureModal");
          return this.$A.AlertUser(error);
        }
        const postMarkStatusVerified = self.emailSettings.displayMethodType === '1' && _.get(status, 'postMarkStatus.confirmed');
        const smtpStatusVerified = self.emailSettings.displayMethodType === '2' && _.get(status, 'smtpStatus.emailValidated');
        if (postMarkStatusVerified || smtpStatusVerified) {
          self.$bvModal.hide("verifyEmailSignatureModal");
          await self.GetEmailSettings();
          if (smtpStatusVerified) {
            this.displaySmtpInfoEditMode = false;
          }
        } else {
          self.setSendMethodStatus(status, self.emailSettings.displayMethodType);
          self.VerifyEmailStatus();
        }
      }, 5000);
    },
    setSendMethodStatus: function (status, type) {
      const typeName = this.TypeIdToName(type);
      if (typeName === 'postmark' && status.postMarkStatus) {
        this.emailSettings[typeName] = status.postMarkStatus;
        this.emailSettings[typeName].fromEmail = status.postMarkStatus.emailAddress;
      }
    },
    cancelEmailVerification: function () {
      this.sendMethodStatusId = 0;
    },
    GetSendMethodStatus: function (methodStatusId, methodType) {
      return this.isAccountSettings ?
          this.$A.AccountService.GetAccountSendMethodStatus(methodStatusId, methodType) :
          this.$A.UserService.GetUserSendMethodStatus(methodStatusId, methodType);
    },
    EditSmtpServerInfo: function () {
      this.displaySmtpInfoEditMode = true;
    },
    TypeIdToName: function (type) {
      return (type === 1 || type === '1') ? 'postmark' : 'smtpServer';
    },
    GetEmailSettings: async function () {
      const allSendMethods = await (this.isAccountSettings ? this.$A.AccountService.GetAccountAllSendMethods() : this.$A.UserService.GetUserAllSendMethods());
      if (allSendMethods) {
        for (const s of allSendMethods) {
          if (s.isSelected) {
            this.emailSettings.displayMethodType = `${s.type}`;
            this.emailSettings[this.TypeIdToName(s.type)] = s;
            if (s.type === 1) {
              let status = await this.GetSendMethodStatus(s.settingId, s.type);
              this.setSendMethodStatus(status, s.type);
            }
          }
        }
      }

      if (this.emailSettings.postmark.fromEmail) {
        this.newNoReplyEmail = this.emailSettings.postmark.fromEmail;
        this.oldNoReplyEmail = this.emailSettings.postmark.fromEmail;
      }
      if (!this.emailSettings.smtpServer.smtpServerInfo) {
        this.emailSettings.smtpServer.smtpServerInfo = {
          userName: "",
          password: "",
          serverAddress: "",
          serverPort: 587,
        };
      }
      if (!this.emailSettings.smtpServer.smtpServerInfo.userName) {
        this.displaySmtpInfoEditMode = true;
      }
    },
  },
  async mounted() {
    await this.GetEmailSettings();
    const auth = _.get(this, '$A.Auth');
    if (auth) { 
      if (!this.$props.isAccountSettings) {
        this.emailSettings.smtpServer.fromEmail = auth.email;
        this.emailSettings.smtpServer.smtpServerInfo.userName = auth.email;
        this.newNoReplyEmail = auth.email;
      } else {
        const noReplyEmail = _.get(auth, 'account.noReplyEmail', '');
        this.emailSettings.smtpServer.fromEmail = noReplyEmail;
        this.emailSettings.smtpServer.smtpServerInfo.userName = noReplyEmail;
        this.newNoReplyEmail = noReplyEmail;
      }
    }
  },
}
</script>

<template>
  <div class="on-mobile text-dark d-flex justify-content-center flex-md-wrap mt-4">
    <div :class="isAccountSettings ? ' px-0':' col-md-10 px-0'">
      <b-card class="p-0 mt-3">
        <b-card-text class="px-4 pt-4 mb-0 text-dark card-header-text">
          {{ isAccountSettings ? 'Account Email Settings' : 'Profile Email Settings' }}
        </b-card-text>
        <b-card-body class="px-4 py-4">
          <div>
            <div class="input-text mb-3">{{ isAccountSettings ? 'No-Reply Email Address' : 'Your Profile Email' }}</div>
            <div class="col-12 d-flex pt-0 m-auto flex-wrap justify-content-start px-0">
              <div class="custom-control custom-radio pr-0 mr-3">
                <input
                    type="radio"
                    id="postmark-email-link"
                    name="postmark-link"
                    value="1"
                    class="custom-control-input pb-0 pl-0"
                    v-model="emailSettings.displayMethodType"
                    :disabled="!isValidSubscriptionState"
                />
                <label class="custom-control-label pb-1 label-text" for="postmark-email-link">Integrate Your Email With
                  Postmark</label>
              </div>
              <div class="custom-control custom-radio pr-0">
                <input
                    type="radio"
                    id="email-server-link"
                    name="server-link"
                    value="2"
                    class="custom-control-input pb-0"
                    v-model="emailSettings.displayMethodType"
                    :disabled="!isValidSubscriptionState"
                />
                <label class="custom-control-label pb-1 label-text" for="email-server-link">
                  Send Email From Your Own Server
                </label>
              </div>
            </div>
            <!--  postmark email active  -->
            <div class="mt-3 text-justify font-weight-bold"
                 v-show="!supportedEmail && emailSettings.displayMethodType === '1'">
              Your email provider is not currently supported. Business emails are required to integrate your email with
              Ashore.
            </div>
            <div class="row" v-show="emailSettings.displayMethodType === '1'">
              <!-- No-Reply Email Input -->
              <div class="form-group col-12 mt-3" @click="isDisabled ? $A.BuyNow(): null" v-show="isAccountSettings">
                <input
                    type="text"
                    :class="'form-control organization-settings '  + (validated ? (isPostmarkEmailAddressSameAsCurrentUserEmailAddress ? 'is-valid':'is-invalid') : '') + (isDisabled ? 'disabled-input': '')"
                    placeholder=""
                    v-model="newNoReplyEmail"
                    :disabled="isDisabled"
                />
              </div>
              <div v-show="!isPostmarkEmailAddressSameAsCurrentUserEmailAddress && $props.isAccountSettings"
                   class="invalid-feedback">Cannot be same as Account Address.
              </div>
              <!--  Profile Badges -->
              <div v-show="!isAccountSettings" class="col-12">
                <div :class="emailSettings.postmark.confirmed ? ' verified-banner': ' not-verified-banner'"
                     v-if="!isAccountSettings && emailSettings.displayMethodType === '1' && isValidSubscriptionState">
                  <div class="d-flex justify-content-start flex-row">
                    <div class="pb-0 mt-0 pl-0" style="padding-top: 2px">
                      <div v-if="emailSettings.postmark.confirmed" class="d-flex flex-row">
                        <img src="/img/verified-icon.svg" class="not-verified-icon mr-2"/>
                        <span class="pt-0">Email Sender Signature is Verified</span>
                      </div>
                      <div style="color: #7D1F27" v-if="!emailSettings.postmark.confirmed"
                           class="d-flex justify-content-start">
                        <img src="/img/not-verified-icon.svg" class="not-verified-icon mr-2"/>
                        <span class="ml-2">Email Sender Signature is Not Verified</span>
                      </div>
                      <div v-show="!emailSettings.postmark.confirmed" class="mt-1 pl-5" style="line-height: 20px">
                        <li style="color: #A62934">Emails will be sent from proofs@ashoreapp.org until you authorize
                          your email
                        </li>
                        <li style="color: #A62934">Select authorize below to begin the verification process</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Account Badges -->
              <div class="px-3 col-12">
                <div
                    :class="emailSettings.postmark.confirmed || oldNoReplyEmail === 'proofs@ashoreapp.org' ? ' verified-banner': ' not-verified-banner'"
                    v-show="isAccountSettings">
                  <div class="d-flex justify-content-start flex-row">
                    <img
                        :src="emailSettings.postmark.confirmed || oldNoReplyEmail === 'proofs@ashoreapp.org' ? ' /img/verified-icon.svg' :' /img/not-verified-icon.svg'"
                        class="not-verified-icon'"
                    />
                    <span class="pb-0 ml-2 mt-0 pl-0" style="padding-top: 2px"
                          :class="emailSettings.postmark.confirmed || oldNoReplyEmail === 'proofs@ashoreapp.org' ? ' verified-banner':' not-verified-banner'">
                    {{
                        emailSettings.postmark.confirmed || oldNoReplyEmail === 'proofs@ashoreapp.org' ? ' Email Sender Signature is Verified' : 'Email Sender Signature Not Verified'
                      }}
                  </span>
                  </div>
                </div>
              </div>
              <!-- Profile and Account -->
              <div class="px-3 col-12" v-show="!isAllVerifiedForPostmark && hasDomainConfigData">
                <div class="domain-text mt-3 mb-2">Domain Configuration</div>
                <span class="sub-domain-text">Add these records to your DNS to improve email deliverability.</span>
                <div :class="isDkimVerified ? ' verified-banner mt-3': ' not-verified-banner mt-3'"
                     v-show="hasDkimData">
                  <div class="d-flex justify-content-start flex-row">
                    <div v-show="isDkimVerified">
                      <img src="/img/verified-icon.svg " class="not-verified-icon"/>
                    </div>
                    <div v-show="!isDkimVerified"><img src="/img/not-verified-icon.svg" class="not-verified-icon"/>
                    </div>
                    <span class="ml-2">{{ isDkimVerified ? ' DKIM Verified' : ' DKIM Not Verified' }}</span>
                  </div>
                  <div class="d-flex justify-content-between flex-row mt-3">
                    <span class="banner-text ml-4">Hostname</span>
                    <span>{{ emailSettings.postmark.dkimPendingHost }}</span>
                  </div>
                  <div class="d-flex justify-content-between flex-row mt-3">
                    <span class="banner-text ml-4">TEXT</span>
                    <span style="overflow-wrap: anywhere;">{{ emailSettings.postmark.dkimPendingTextValue }}</span>
                  </div>
                </div>
                <div :class="isReturnPathDomainVerified ? 'verified-banner':'not-verified-banner'"
                     v-show="hasReturnPathDomainData">
                  <div class="d-flex justify-content-start flex-row">
                            <span v-show="isReturnPathDomainVerified">
                              <img src="/img/verified-icon.svg " class="not-verified-icon"/>
                            </span>
                    <span v-show="!isReturnPathDomainVerified">
                        <img src="/img/not-verified-icon.svg" class="not-verified-icon"/>
                      </span>
                    <span class="ml-2">{{
                        isReturnPathDomainVerified ? 'Return-Path' : 'Return-Path Inactive'
                      }}</span>
                  </div>
                  <div class="d-flex justify-content-between flex-row mt-3">
                    <span class="banner-text ml-4">Hostname</span>
                    <span>pm-bounces.{{ emailSettings.postmark.domain }}</span>
                  </div>
                  <div class="d-flex justify-content-between flex-row mt-3">
                    <span class="banner-text ml-4">CNAME</span>
                    <span>{{ emailSettings.postmark.returnPathDomainCNAMEValue }}</span>
                  </div>
                </div>
                <div :class="isSpfVerified ? 'verified-banner':'not-verified-banner'"
                     v-show="hasSpfData">
                  <div class="d-flex justify-content-start flex-row">
                            <span v-show="emailSettings.postmark.spfVerified">
                              <img src="/img/verified-icon.svg " class="not-verified-icon"/>
                            </span>
                    <span v-show="!emailSettings.postmark.spfVerified">
                        <img src="/img/not-verified-icon.svg" class="not-verified-icon"/>
                      </span>
                    <span class="ml-2">
                        {{ emailSettings.postmark.spfVerified ? 'SPF' : 'SPF Inactive' }}
                      </span>
                  </div>

                  <div class="d-flex justify-content-between flex-row mt-3">
                    <span class="banner-text ml-4">Hostname</span>
                    <span>{{ emailSettings.postmark.domain }}</span>
                  </div>
                  <div class="d-flex justify-content-between flex-row mt-3">
                    <span class="banner-text ml-4">TEXT</span>
                    <span>{{ emailSettings.postmark.spfTextValue }}</span>
                  </div>
                  <div class="d-flex justify-content-between flex-row mt-3">
                    <span class="banner-text ml-4">Note</span>
                    <span>Merge this with your spf and don't overwrite.</span>
                  </div>
                </div>
              </div>
            </div>
            <!--  server email active -->
            <div class="row" v-if="emailSettings.displayMethodType === '2'">
              <div class="col-md-12 d-flex justify-content-start align-items-center flex-wrap w-100 mt-3">
                <div @click="isDisabled ? $A.BuyNow(): null" class="col-md-12 p-0 profile-mobile-input">
                  <div class="col-md-12"
                       v-if="(emailSettings.smtpServerInfo === null && emailSettings.displayMethodType === '2') || (emailSettings.smtpServerInfo !== null && emailSettings.displayMethodType === '2' && displaySmtpInfoEditMode)">
                    <div class="flex-column d-flex">
                      <!-- Email -->
                      <div class="form-group">
                        <span class="labels">Email Address</span>
                        <input type="text" name="email" class="form-control email-input "
                               v-model="emailSettings.smtpServer.fromEmail"/>
                      </div>
                      <div v-show="isChangingProfileEmailAddressUponSavingSmtp" class="not-verified-banner mb-2 mt-0">
                        <img src="/img/not-verified-icon.svg" class="not-verified-icon"/>
                        <span class="ml-2 mt-1">If you use a different email address as you have in your profile this will change your email
                        address for your profile. Please remember that when logging in.</span>
                      </div>
                      <!-- UserName -->
                      <div class="form-group">
                        <span class="labels">Username (Optional)</span>
                        <input type="text" name="email" class="form-control email-input "
                               v-model="emailSettings.smtpServer.smtpServerInfo.userName"/>
                      </div>
                      <!-- Password -->
                      <div class="form-group">
                        <span class="labels">Password</span>
                        <input type="password" name="password" class="form-control email-input"
                               v-model="emailSettings.smtpServer.smtpServerInfo.password"/>
                      </div>
                      <!-- Outgoing Mail -->
                      <span class="labels pl-0">Outgoing Mail</span>
                      <div class="d-flex flex-wrap justify-content-between">
                        <!-- Server Address -->
                        <div class="form-group pl-0 mobile-view col-md-9">
                          <input type="text" name="outgoing-mail" class="form-control email-input"
                                 placeholder="smtp.example.com"
                                 v-model="emailSettings.smtpServer.smtpServerInfo.serverAddress"/>
                        </div>
                        <!-- Port -->
                        <div class="form-group  pr-0 mobile-view col-md-3 mb-0 pl-0">
                          <input type="text" name="port" class="form-control email-input" placeholder="465"
                                 v-model="emailSettings.smtpServer.smtpServerInfo.serverPort"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 bg-light p-4 flex-column d-flex border rounded"
                       v-if="emailSettings.smtpServerInfo !== null
                       && emailSettings.displayMethodType === '2' && !displaySmtpInfoEditMode"
                  >
                    <div class="d-flex justify-content-between mb-3">
                      <span class="info-text">Email Address</span>
                      <span class="ml-4 info-sub-text">{{ emailSettings.smtpServer.fromEmail }}</span>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <span class="info-text">Outgoing Mail</span>
                      <span class="ml-4 info-sub-text">{{
                          emailSettings.smtpServer.smtpServerInfo.serverAddress
                        }}</span>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <span class="info-text">Port</span>
                      <span class="ml-4 info-sub-text">{{ emailSettings.smtpServer.smtpServerInfo.serverPort }}</span>
                    </div>
                    <div class="d-flex justify-content-between mb-4">
                      <span class="info-text">Status</span>
                      <span :class="smtpVerified ? 'custom-badge-success ml-4':'custom-badge-danger'">{{
                          smtpVerified ? ' Email is Verified' : 'Email is Not Verified'
                        }}</span>
                    </div>
                    <div class="d-flex justify-content-end flex-column">
                      <b-button class="light-btn mt-1 ml-auto mb-3"
                                @click="EditSmtpServerInfo">Edit
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Verify Email Modal -->
            <modal-verify-email name="verifyEmailSignatureModal" @cancel-verification="cancelEmailVerification"/>
          </div>
        </b-card-body>
        <div class="org-footer col-12">
          <!--  Profile Settings Remove -->
          <a v-if="!isAccountSettings && showRemoveBtn"
             class="button-light button-red org-btn float-right mb-3 text-center mobile-btn font-weight-normal mr-2"
             @click="resetEmailSettings"
          > Reset
          </a>
          <!--  Account Settings Authorize -->
          <a v-if="isValidSubscriptionState && isAccountSettings"
             data-cy="updateAccountInfoBtn"
             :class="shouldHideSmtpAuthorizeBtn ? ' d-none':''"
             class="button-light button-green org-btn float-right mb-3 text-center mobile-btn font-weight-normal"
             :disabled="shouldDisabledAccountAuthorizeBtn || !supportedEmail"
             :style="shouldDisabledAccountAuthorizeBtn ? ' pointer-events: none; opacity: 0.5':''"
             @click="updateEmailSettings"
          > Authorize
          </a>
          <a v-if="isValidSubscriptionState && !isAccountSettings && !showRemoveBtn"
             data-cy="updateAccountInfoBtn"
             class="button-light button-green org-btn float-right mb-3 text-center mobile-btn font-weight-normal"
             :class="showRemoveBtn ? ' d-none':' '"
             :enabled="supportedEmail"
             @click="updateEmailSettings"
          >
            Authorize
          </a>
          <!--  Feature Locked Btn -->
          <a v-if="!isValidSubscriptionState"
             class="button button-block-organization"
             @click="$A.BuyNow()"
             v-b-tooltip.hover="'Upgrade to rebrand Ashore'">
            Feature Locked <img src="/img/feature-lock.svg" class="feature-icon ml-2"/>
          </a>
        </div>
      </b-card>
    </div>
  </div>
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0rem;
}

.button-green-organization {
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9
}

.card-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.organization-settings {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  display: block;
  width: 100%;
  height: 37px;
  padding: 2px 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #444444;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid lightgray;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 5px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.not-verified-icon {
  width: 16px;
  vertical-align: center
}

.org-footer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 12px 24px;
  background: #F4F4F6;
  height: 62px;
}

.org-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  position: static;
  width: 108px;
  height: 38px;
  left: 457px;
  top: 12px;
  background: #019256;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  cursor: pointer;
}

.org-btn:hover {
  opacity: 0.9;
}

.disabled-input {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.light-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.button-block-organization {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #F44271;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gray-btn:hover {
  cursor: pointer;
}

.verified-banner {
  background: #E6F6E8;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #172D19;
  padding: 16px;
  margin-top: 4px;
}

.not-verified-banner {
  background: #FBE2E4;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #172D19;
  padding: 16px;
  margin-top: 4px;
}

.banner-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #46664A;
  min-width: 150px;
}

.labels {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.domain-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.sub-domain-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.email-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  border: 1px solid #B5B5B8;
}

@media (max-width: 768px) {

  .mobile-btn {
    margin-top: 18px !important;
    margin-left: 0px !important;
  }

  .profile-mobile-input {
    padding-left: 0px;
  }
}

@media screen and (max-width: 991px) {

  .button-block-organization {
    width: 100% !important;
  }

  .button-green-organization {
    width: 100%;
  }

  .button-green {
    width: 100%;
  }

  .button-block {
    width: 100%;
  }

  .delete-button {
    width: 100%;
  }

}

.info-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #1B1B1C;
}

.info-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.field-item h3 {
  font-size: 18px;
}

.badge-success {
  background-color: #6a8b94;
}

.input-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
  background: #EEF2F5;
}

.label-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.custom-badge-success {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 20px;
  background: #D1F6D4;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #264C29;
  width: fit-content;
}

.custom-badge-danger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 20px;
  background: #FBE2E4;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #7D1F27;
  width: fit-content;
}

.custom-badge-warning {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 20px;
  background: lightyellow;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #7D1F27;
  width: fit-content;
}


.custom-control-input ~ .custom-control-label::before {
  border-color: #019255 !important;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #019255 !important;
}

@media screen and (max-width: 768px) {

  .mobile-view {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .badge-mobile {
    margin-top: 10px;
  }

}

</style>