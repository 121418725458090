<script>
export default {
  name: "warningConfirmationModal",
  props: {
    name: {
      required: true,
      type: String,
    },
    question: {
      required: true,
      type: String,
    },
    body: {
      required: true,
      type: String,
    },
    confirmButtonText: {
      required: false,
      type: String,
    },
    checkboxText: {
      required:false,
      type: String,
    },
    buttonCss: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      confirmButton: "Confirm",
      buttonCssType: "button-green",
      dontShow: false,
      file: null,
      isMobile: window.innerWidth <= 992
    };
  },
  methods: {
    refreshIsMobile: function () {
      this.isMobile = window.innerWidth < 992;
    },
    open(file) {
      this.file = file;
      if(!this.dontShow)
        this.$bvModal.show(this.name);
      else
        this.modalConfirmed();
    },
    modalConfirmed() {
      this.$emit("confirmed", this.file);
      this.$bvModal.hide(this.name);
    },
    openLink() {
      return window.open('https://kb.ashoreapp.com/should-i-upload-cmyk-or-rgb-files/','_blank');
    },
    cancel() {
        this.file = null;
    }
  },
  mounted() {
    window.onresize = this.refreshIsMobile;

    if (this.confirmButtonText !== undefined) {
      this.confirmButton = this.confirmButtonText;
    }
    if (this.buttonCss !== undefined) {
      this.buttonCssType = this.buttonCss;
    }
  },
};
</script>

<template>
  <div>
    <b-modal :id="name" size="lg" body-class="p-0">
      <template v-slot:modal-header>
        <div class="w-100 text-center pb-0">
          <div :class="isMobile ? 'mb-4 header-text' : 'mb-4 header-text text-center'">{{ question }}</div>
          <div :class="isMobile ? 'mb-4 sub-text' : 'mb-4 sub-text text-center'">{{ body }}</div>
          <button
              :class="isMobile ? 'w-100 desktop-btn-link' : 'desktop-btn-link w-50'"
              @click="openLink()">
            Learn More
          </button>
          <b-form-checkbox
              id="dontShowAgainCheckbox"
              v-model="dontShow"
              name="dontShowAgainCheckbox"
              class="text-bold pb-1 mt-4 checkbox-text pl-4"
          >
            {{ checkboxText }}
          </b-form-checkbox>
        </div>
      </template>
      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100 d-flex justify-content-end align-items-center">
          <b-button
              class="gray-btn mt-0"
              @click="cancel"
          >Cancel</b-button
          >
          <b-button
              class="green-btn"
              :class="buttonCssType"
              @click="modalConfirmed"
          >{{ confirmButtonText }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 14px;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 10px !important;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  text-align: justify;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
  text-align: justify;
}

.checkbox-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 24px;
  color: #252526;
  text-align: center;
}


/deep/ .modal-header {
  padding: 24px !important;
}

.desktop-btn-link {
  padding: 9px 17px;
  height: 48px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

</style>
