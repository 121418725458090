<script>
import { NOTIFICATION_FREQUENCY_OPTIONS } from '../../utils/constants'
export default {
    name: "settingEmailNotification",
    components: {},
    data: function () {
        return {
            user: this.$A.Auth,
            NOTIFICATION_FREQUENCY_OPTIONS
        };
    },
    computed: {
        userSettings: function () {
            return _.get(this, 'user.userSettings', {});
        },
        notifyMeNewCommentsEnabled: {
            get() {
                return this.userSettings.notifyMeNewComments !== 0;
            },
            set(value) {
                this.userSettings.notifyMeNewComments = value ? 1 : 0;
            },
        },
        notifyMyApproversNewCommentsEnabled: {
            get() {
                return this.userSettings.notifyMyApproversNewComments !== 0;
            },
            set(value) {
                this.userSettings.notifyMyApproversNewComments = value ? 1 : 0;
            },
        },
    },
    methods: {
        updateEmailNotificationSettings: async function () {
            let user = await this.$A.UserService.UpdateUserDefaultSettings(
                this.$A.Auth.id,
                this.userSettings.downloadFiles,
                this.userSettings.approveIndividualFiles,
                this.userSettings.viewAllComments,
                this.userSettings.approveWithChanges,
                this.userSettings.notifyFilesSent,
                this.userSettings.notifyFilesApproved,
                this.userSettings.notifyNewComments,
                this.userSettings.notifyMeNewComments,
                this.userSettings.notifyMyApproversNewComments,
                this.userSettings.limitVersionVisibility,
                this.userSettings.blockCommentingAfterProofDecision,
                this.userSettings.reminderTimeOfDay,
                this.userSettings.reminderDays,
                this.userSettings.reminderTimeZone
            );
            if (user.id !== undefined && user.id > 0) {
                this.user = user;
                this.$A.UpdateUserObjectAndBustCache(user);
            }
        }
    },
};
</script>

<template>
    <!-- Field -->
    <div class="on-mobile text-dark d-flex justify-content-center flex-md-wrap mt-4 px-2">
        <div class="field-item border col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <div class="header-text pt-4 px-4">Email Notification Settings</div>
                    <div class="sub-header-text px-4" style="margin-bottom: 16px; margin-top: 8px">
                        Default Email Settings
                    </div>
                    <div class="px-4 pb-4 d-flex flex-column gap-3">
                        <div class="ashore-toggle">
                            <label class="switch">
                                <input type="checkbox" v-model="userSettings.notifyFilesSent" />
                                <div class="slider round"></div>
                            </label>Notify me if a proof has been successfully sent
                        </div>
                        <div class="ashore-toggle">
                            <label class="switch">
                                <input type="checkbox" v-model="userSettings.notifyFilesApproved" />
                                <div class="slider round"></div>
                            </label>Notify me if proof has been approved
                        </div>
                        <div class="ashore-toggle d-flex flex-column">
                            <div class="d-flex">
                                <label class="switch">
                                    <input type="checkbox" v-model="notifyMyApproversNewCommentsEnabled" />
                                    <div class="slider round"></div>
                                </label><div>
                                    Notify my reviewers about new comments on proofs I own
                                </div>
                            </div>
                            <div v-if="notifyMyApproversNewCommentsEnabled" class="frequency-select">
                                <label class="sub-header-text" for="notifyMyApproversNewCommentsFrequency">Select New Comment Notification Frequency</label>
                                <select v-model="userSettings.notifyMyApproversNewComments" class="form-control contact-input"
                                    id="notifyMyApproversNewCommentsFrequency">
                                    <option v-for="(value, label) in NOTIFICATION_FREQUENCY_OPTIONS" :key="label" :value="value">
                                        {{ label.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="ashore-toggle d-flex flex-column">
                            <div class="d-flex">
                                <label class="switch">
                                    <input type="checkbox" v-model="notifyMeNewCommentsEnabled" />
                                    <div class="slider round"></div>
                                </label><div>
                                    Notify me about new comments contacts make on proofs I own
                                </div>
                            </div>
                            <div v-if="notifyMeNewCommentsEnabled" class="frequency-select">
                                <label for="notifyMeNewCommentsFrequency" class="sub-header-text">Select New Comment Notification Frequency</label>
                                <select v-model="userSettings.notifyMeNewComments"
                                    class="form-control contact-input" id="notifyMeNewCommentsFrequency">
                                    <option v-for="(value, label) in NOTIFICATION_FREQUENCY_OPTIONS" :key="label" :value="value">
                                        {{ label.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="email-footer col-md-12 justify-content-end d-flex">
                <a class="green-btn" @click="updateEmailNotificationSettings" data-cy="updateSignatureBtn">Update</a>
            </div>
        </div>
    </div>
    <!-- /Field -->
</template>

<style scoped>
.field-item h3 {
    font-size: 18px;
}

.green-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 17px !important;
    height: 38px;
    background: #019255;
    border: 1px solid #B5B5B8;
    box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
    border-radius: 6px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: white;
    cursor: pointer;
}

.green-btn:hover {
    background-color: #019255 !important;
    opacity: 0.9 !important;
    color: white;
}

.profile-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 13px;
    height: 38px;
    background: white;
    border: 1px solid #B5B5B8;
    box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
    border-radius: 6px;
}

.header-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #252526;
}

.sub-header-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #707073;
}

.email-footer {
    padding: 12px 24px;
    background: #F4F4F6;
    height: 62px;
}

.field-item {
    padding: 0px;
}

.frequency-select {
    padding-left: 60px;
    width: 428px;
}
@media (max-width: 500px) {
    .frequency-select {
        padding-left: 60px;
        width: auto;
    }
}
</style>
