<script>
import VueTagsInput from "@johmun/vue-tags-input";
import modalEditNonApprovers from "../../components/modals/modal-editnonapprover.vue";

export default {
  name: "selectNonApprovers",
  props: ["SelectedNonApproverIds", "index"],
  data() {
    return {
      instanceId: this.$A.GetUUID(),
      tag: "",
      tags: [],
      accountUsers: [],
      autoComplete: [],
    };
  },
  components: {
    VueTagsInput,
    modalEditNonApprovers,
  },
  async mounted() {
    let self = this;
    this.registerListeners();
    await this.setSelectedUsers(this.$props.SelectedNonApproverIds);
  },
  watch: {
    SelectedNonApproverIds: function (newVal, oldVal) {
      // console.log("Changed")
      if (Array.isArray(newVal)) this.setSelectedUsers(newVal);
    },
  },
  methods: {
    registerListeners: function () {
      // console.log("registerListeners")
      let self = this;
      this.$root.$on(
          "confirmed::selecting::non_approver::" + this.instanceId,
          async (selectedUsersFromModal) => {
            self.updateTags();
          }
      );
    },
    setSelectedUsers: async function (bccUserIds) {
      let self = this;
      let users = await this.$A.AccountService.All();
      this.accountUsers = [];
      users && users.forEach(function (u) {
        self.accountUsers.push({
          name: u.name + " (" + u.email.toLowerCase() + ")",
          id: u.id,
          selected: false,
        });
      });

      let accountUsers = this.accountUsers;
      for (let i = 0; i < accountUsers.length; i++) {
        accountUsers[i].selected = false;
        if (Array.isArray(bccUserIds)) {
          for (let i2 = 0; i2 < bccUserIds.length; i2++) {
            if (accountUsers[i].id === bccUserIds[i2]) {
              accountUsers[i].selected = true;
            }
          }
        }
      }
      this.accountUsers = accountUsers;
      this.updateTags();
    },
    updateTags: function () {
      let tags = [];
      let autoComplete = [];
      this.accountUsers.forEach(function (t) {
        if (t.selected) {
          tags.push({text: t.name});
        }
        autoComplete.push({text: t.name});
      });
      this.autoComplete = autoComplete;
      this.tags = tags;
    },
    getIdArrayOfSelectedUsers: function () {
      // console.log("getIdArrayOfSelectedUsers")
      let bccUserIds = [];
      for (let i = 0; i < this.accountUsers.length; i++) {
        if (this.accountUsers[i].selected) {
          bccUserIds.push(this.accountUsers[i].id);
        }
      }
      return bccUserIds;
    },
    selectedUserChanged: function (newTags) {
      // console.log("selectedUserChanged", newTags);
      for (let i = 0; i < this.accountUsers.length; i++) {
        this.accountUsers[i].selected = false;
        for (let i2 = 0; i2 < newTags.length; i2++) {
          if (this.accountUsers[i].name === newTags[i2].text) {
            this.accountUsers[i].selected = true;
          }
        }
      }
      this.$emit("nonApproversChanged");
    },
  },
  computed: {
    getAutoComplete: function () {
      let curTag = this.tag;

      return this.autoComplete
          .filter((a) => a.text.toLowerCase().indexOf(curTag.toLowerCase()) > -1)
          .splice(0, 20);
    },
  },
};
</script>

<template>
  <div>
    <div :id="'workflow_bcc_' + instanceId" class="d-flex justify-content-md-start col-md-12 px-0">
      <vue-tags-input
          v-model="tag"
          :tags="tags"
          placeholder="Search and Select..."
          :autocomplete-items="getAutoComplete"
          @tags-changed="(newTags) => selectedUserChanged(newTags)"
          :add-only-from-autocomplete="true"
          :data-cy="`addNonApprover-stage-${index}`"
          class="px-0 w-100 col-11"
      >
      </vue-tags-input>
      <a v-b-modal="instanceId" class="add-icon col-md-1 pl-2">
        <b-img src="/img/add-member-button.svg" style="margin-top: 2px"></b-img>
      </a>
    </div>
    <modalEditNonApprovers :instanceId="instanceId" :accountUsers.sync="accountUsers"/>
  </div>
</template>


<style scoped>

/deep/ .ti-new-tag-input-wrapper input::placeholder {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #909092;
}

/deep/ .ti-tag {
  background: #D1F6D4 !important;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3E3E40 !important;
}

</style>