<script>
import sidebarApprover from "../components/contact-archive/sidebar-approver.vue";
import tableApprover from "../components/contact-archive/table-approver.vue";
import sort from "fast-sort";

export default {
  components: {
    sidebarApprover,
    tableApprover,
  },
  data() {
    return {
      user: this.$A.Auth,
      searchValue: "",
      alertMessage: "",
      alertType: "success",
      approvers: [],
      selectedApproverIds: {},
      steps: [
        {
          target: "#approver_table_header",
          content: `This table will list all of your contacts. Each of your contacts have their own settings.`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#approver_button",
          content:
              "You can add contacts to your account in multiple ways, like when an contact opens a review link or when you create a workflow. You can also add contacts here.",
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("contact-archive");
        },
      },
    };
  },
  mounted() {
    let self = this;
    (async () => await self.loadAllApprovers())();

    this.$A.viewToViewData = null; //clear this so when the user navagates to the add new approver view they start from scratch

    this.$root.$on("delete::approver::confirmed", async () => {
      const approverIds = Object.keys(self.selectedApproverIds);
      await self.$A.ApproverService.Delete(approverIds);
      self.selectedApproverIds = {};
      await self.loadAllApprovers();
    });

    this.$root.$on("page::search::value::updated", (searchValue) => {
      self.searchValue = searchValue;
    });

    this.$root.$on("contacts::sort::most::recent", () => {
      self.sortByMostRecent();
    });

    this.$root.$on("contacts::sort::most::used", () => {
      self.sortByMostUsed();
    });

  },
  computed: {
    filteredApprovers: function () {
      let self = this;
      // console.log("APPROVERS", this.approvers);
      const approvers = _.get(this, 'approvers', []);
      return approvers.filter(function (approver) {
        let searchValueLower = self.searchValue.toLowerCase();
        let lowerName = approver.name.toLowerCase();
        let lowerEmail = approver.email.toLowerCase();
        if (searchValueLower == "") return true;
        return (
            lowerName.indexOf(searchValueLower) !== -1 ||
            lowerEmail.indexOf(searchValueLower) !== -1
        );
      });
    },
  },
  methods: {
    loadAllApprovers: async function () {
      this.approvers = await this.$A.ApproverService.All() || [];
      this.sortByMostRecent();
      window.$VI.$emit("page::search::value::updated", this.searchValue);
      this.$A.ProductTourProcess("contact-archive", this);
    },
    sortByMostRecent: function () {
      sort(this.approvers).desc((a) => a.id);
    },
    sortByMostUsed: function () {
      sort(this.approvers).desc((a) => a.totalProofs);
    },
    approverSelected: function (approverId) {
      this.selectedApproverIds[approverId] = true;
    },
    approverUnselected: function (approverId) {
      delete this.selectedApproverIds[approverId];
    },
    resetSelectedApprovers: function() {
      this.selectedApproverIds = {};
    },
    selectAllApprovers: function (selectAll, currentPage, perPage) {
      this.selectedApproverIds = {};
      const start = (currentPage-1) * perPage;
      if (selectAll) {
        this.filteredApprovers
            .slice(start, start + perPage)
            .map(a => this.selectedApproverIds[a.id] = true);
      }
    }
  },
};
</script>
<template>
  <div>
    <navigation/>
    <pageheader
        title="Contacts"
        buttonlink="/approver-item"
        buttonfloat="float: right;"
        demostyle="display: none;"
        :searchValue="searchValue"
    />
    <div class="container-fluid">
      <b-card class="contacts-table">
        <sidebarApprover :selectedApproverIds="selectedApproverIds" title="Contacts" buttonlink="/approver-item"
                         :searchValue="searchValue"/>
        <tableApprover :tableApprovers="filteredApprovers" :selectedApproverIds="selectedApproverIds"/>
      </b-card>
    </div>
    <modal
        name="deleteApproverModal"
        eventToEmitOnConfirmation="delete::approver::confirmed"
        question="Delete This Contact?"
        body="Are you sure you want to delete this contact? You cannot undo this action."
        confirm-button-text="Yes, Delete"
    />
    <v-tour
        name="contact-archive"
        :steps="steps"
        :callbacks="tourCallbacks"
    ></v-tour>
  </div>
</template>

<style scoped>

::v-deep .contacts-table .card-body {
  padding: 24px !important;
}

</style>