<script>
export default {
  name: "accountLabels",
  data() {
    return {
      user: this.$A.Auth,
      languages: ['en-us', 'es-us'],
      labels: {
        review_screens_labels: {
          approve_btn: {},
          not_approved_btn: {},
          approve_with_changes_btn: {},
          submit_decision_btn: {},
        },
        approver_decision_modal_labels: {
          approve_modal: {},
          reject_modal: {},
          approve_with_changes: {},
          submit_decision_header: {},
          feedback_box: {},
          approver_acknowledge_checklist: {},
          approve_individual_file: {}
        },
        identify_yourself_modal_labels: {
          identify_yourself_header: {},
          identify_yourself_before_proofing: {},
          begin_review_btn: {}
        },
        contact_email_labels: {
          review_now: {}
        }
      }
    };
  },
  components: {},
  computed: {},
  methods: {
    loadLanguages: async function() {
      for (const lang of this.languages) {
        const accountId = _.get(this, 'user.accountId', 0);
        accountId > 0 && await this.$A.LangService.loadLanguage(accountId, lang);
      }
    },
    loadLabels: async function () {
      let self = this;
      await self.loadLanguages();
      Object.keys(self.labels).forEach(group => {
        Object.keys(self.labels[group]).forEach(key => {
          self.languages.forEach(lang => {
            self.labels[group][key][lang] = self.$A.LangService.getLabel(group, key, lang);
          })
        })
      })
      this.$forceUpdate();
    },
    saveLabels: async function (group) {
      let self = this;
      let langEntries = [];
      Object.keys(self.labels[group]).forEach(key => {
        Object.keys(self.labels[group][key]).forEach(lang => {
          langEntries.push({key: key, language: lang, value: self.labels[group][key][lang]});
        })
      })
      await this.$A.LangService.saveLanguage(langEntries);
    }
  },
  mounted() {
    this.loadLabels();
  },
};
</script>


<template>
  <div style="background-color: #eef2f5!important" class="pb-5">
    <div class="text-dark d-flex justify-content-center flex-md-wrap px-md-5 row">
      <!-- Review Screen Labels -->
      <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 col-sm-10 label-card">
        <b-card-body>
          <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 pl-lg-3 text-dark header-text">Review
            Screen Labels
          </b-card-text>
          <b-card-text class="mx-md-3 pl-md-2 pl-lg-3 description-text">Change the labels that display for contacts when
            they are reviewing a proof:
          </b-card-text>
          <b-card-text class="mb-2 mx-md-3 mt-5 pl-md-2 pl-lg-3 header-text">All Review
            Screens
          </b-card-text>
          <b-form>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap">
              <div class="col-md-6 mb-3 col-sm-12 pl-md-5">
                <label class="input-account-labels">The button text for “Approved”:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.review_screens_labels.approve_btn["en-us"]'>
                  </b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input" class="account-label-lang border-left-0"
                                v-model='labels.review_screens_labels.approve_btn["es-us"]'>
                  </b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-6 mb-3 col-sm-12 pr-md-5">
                <label class="input-account-labels">The button text for “Not Approved”:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.review_screens_labels.not_approved_btn["en-us"]'></b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class=" account-label-lang border-left-0"
                                v-model='labels.review_screens_labels.not_approved_btn["es-us"]'></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap mt-3">
              <div class="col-md-6 mb-3 col-sm-12 pl-md-5">
                <label class="input-account-labels">The button text for “Approve With Changes”:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.review_screens_labels.approve_with_changes_btn["en-us"]'>
                  </b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.review_screens_labels.approve_with_changes_btn["es-us"]'
                  >
                  </b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-6 mb-3 col-sm-12 pr-md-5">
                <label class="input-account-labels">The button text for “Submit Decision”:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.review_screens_labels.submit_decision_btn["en-us"]'>

                  </b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.review_screens_labels.submit_decision_btn["es-us"]'>

                  </b-form-input>
                </b-input-group>
              </div>
            </div>
          </b-form>
          <b-card-footer class="border-top-0 mt-4" style="background-color: #F4F4F6">
            <b-button class="label-btn ml-auto" @click="saveLabels('review_screens_labels')">Save</b-button>
          </b-card-footer>
        </b-card-body>
      </b-card>
      <!-- Approver Decision Modal Labels -->
      <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 col-sm-10 label-card">
        <!--  Headers  -->
        <b-card-body>
          <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 pl-lg-3 header-text">Approver
            Decision Modal Labels
          </b-card-text>
          <b-card-text class="mx-md-3 pl-md-2 pl-lg-3 description-text">Change the labels that display for approvers when
            they are approving or rejecting a proof:
          </b-card-text>
          <b-card-text class="mb-2 mx-md-3 mt-5 pl-md-2 pl-lg-3 header-text">Headers
          </b-card-text>
          <b-form>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap">
              <div class="col-md-6 mb-3 col-sm-12 pl-md-5">
                <label class="input-account-labels">This header text that display when an approver is about to approve a
                  proof:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.approve_modal["en-us"]'></b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.approve_modal["es-us"]'></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-6 mb-3 col-sm-12 pr-md-5">
                <label class="input-account-labels">This header text that displays when an approver is about to reject a
                  proof:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.reject_modal["en-us"]'></b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.reject_modal["es-us"]'></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap mt-3">
              <div class="col-md-6 mb-3 col-sm-12 pl-md-5">
                <label class="input-account-labels">This header text that display when an approver is about to approve
                  with changes:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.approve_with_changes["en-us"]'>
                  </b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.approve_with_changes["es-us"]'>
                  </b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-6 mb-3 col-sm-12 pr-md-5">
                <label class="input-account-labels">This header text that displays when an approver is about to submit a
                  decision</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.submit_decision_header["en-us"]'></b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.submit_decision_header["es-us"]'></b-form-input>
                </b-input-group>
              </div>
            </div>
          </b-form>
        </b-card-body>
        <hr class="mx-4"/>
        <!-- Instructions -->
        <b-card-body>
          <b-card-text class="mb-2 mx-md-3 mt-5 pl-md-2 pl-lg-3 text-dark header-text">
            Instructions
          </b-card-text>
          <b-form>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap">
              <div class="col-md-6 mb-3 col-sm-12 pl-md-5">
                <label class="input-account-labels">This instruction text that displays above the feedback box:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.feedback_box["en-us"]'></b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.feedback_box["es-us"]'></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-6 mb-3 col-sm-12 pr-md-5">
                <label class="input-account-labels">This instruction text that displays when an approver must
                  acknowledge your checklist:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.approver_acknowledge_checklist["en-us"]'></b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.approver_acknowledge_checklist["es-us"]'></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap mt-3">
              <div class="col-md-6 mb-3 col-sm-12 pl-md-5">
                <label class="input-account-labels">This instruction text that displays when an approver must approve
                  each file individually:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.approve_individual_file["en-us"]'></b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.approver_decision_modal_labels.approve_individual_file["es-us"]'></b-form-input>
                </b-input-group>
              </div>
            </div>
          </b-form>
        </b-card-body>
        <b-card-footer class="border-top-0 mt-4" style="background-color: #F4F4F6">
          <b-button class="label-btn ml-auto" @click="saveLabels('approver_decision_modal_labels')">Save</b-button>
        </b-card-footer>
      </b-card>
      <!-- Please Identify Yourself Modal -->
      <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 col-sm-10 label-card">
        <b-card-body>
          <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 pl-lg-3 header-text">Please
            Identify Yourself Modal
          </b-card-text>
          <b-card-text class="mx-md-3 pl-md-2 pl-lg-3 description-text">Change the labels that display for contacts when
            they are registering to a proof
          </b-card-text>
          <b-form>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap">
              <div class="col-md-6 mb-3 col-sm-12 pl-md-5">
                <label class="input-account-labels">This header text that displays when a contact is
                  registering:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.identify_yourself_modal_labels.identify_yourself_header["en-us"]'></b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.identify_yourself_modal_labels.identify_yourself_header["es-us"]'></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-6 mb-3 col-sm-12 pr-md-5">
                <label class="input-account-labels">This instruction text that displays when a contact is
                  registering:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.identify_yourself_modal_labels.identify_yourself_before_proofing["en-us"]'>
                  </b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input" class="account-label-lang border-left-0"
                                v-model='labels.identify_yourself_modal_labels.identify_yourself_before_proofing["es-us"]'>
                  </b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap mt-3">
              <div class="col-md-6 mb-3 col-sm-12 pl-md-5">
                <label class="input-account-labels">This button text that displays when a contact is
                  registering:</label>
                <b-input-group prepend="English" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.identify_yourself_modal_labels.begin_review_btn["en-us"]'>
                  </b-form-input>
                </b-input-group>
                <b-input-group prepend="Spanish" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang mt-2">
                  <b-form-input id="inline-form-input"  class="account-label-lang border-left-0"
                                v-model='labels.identify_yourself_modal_labels.begin_review_btn["es-us"]'>
                  </b-form-input>
                </b-input-group>
              </div>
            </div>
          </b-form>
          <b-card-footer class="border-top-0 mt-4" style="background-color: #F4F4F6">
            <b-button class="label-btn ml-auto" @click="saveLabels('identify_yourself_modal_labels')">Save</b-button>
          </b-card-footer>
        </b-card-body>
      </b-card>
      <!-- Contact Email Labels -->
      <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 col-sm-10 label-card mb-5" v-if="false">
        <b-card-body>
          <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 pl-lg-3 header-text">Contact
            Email Labels
          </b-card-text>
          <b-card-text class="mx-md-3 pl-md-2 pl-lg-3 description-text">Change the labels that display for contacts when
            they receive an email from Ashore:
          </b-card-text>
          <b-card-text class="mb-2 mx-md-3 mt-5 pl-md-2 pl-lg-3 header-text">Button
          </b-card-text>
          <b-form>
            <div class="row flex-lg-nowrap justify-content-between flex-sm-wrap">
              <div class="col-md-10 mb-3 col-sm-10 pl-md-5">
                <label class="input-account-labels">This button text displays in every email sent from Ashore to open
                  the review screens:</label>
                <b-input-group prepend="" class="mb-2 mr-sm-2 mb-sm-0 account-label-lang">
                  <b-form-input id="inline-form-input"  class="account-label-lang "
                                v-model='labels.contact_email_labels.review_now["en-us"]'></b-form-input>
                </b-input-group>
              </div>
            </div>
          </b-form>
          <b-card-footer class="border-top-0 mt-4" style="background-color: #F4F4F6">
            <b-button class="label-btn ml-auto" @click="saveLabels('contact_email_labels')">Save</b-button>
          </b-card-footer>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>


<style scoped>

.input-account-labels {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

/deep/ label.input-account-labels{
  opacity: 1;
}

.label-btn {
  display: block;
  background-color: #019256;
  padding: 9px 22px;
  border-radius: 5px;
  width: 108px;
  height: 38px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.account-label-lang {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
  border-radius: 5px;
  height: 38px;
}

.account-label-lang ::v-deep .input-group-text {
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid lightgray;
  border-right: 0px;
}

.account-label-lang ::v-deep .form-control, form-select {
  border: 1px solid lightgray;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  transition: none;
}

.account-label-lang ::v-deep .form-control:focus, .redactor-focus.redactor-styles-on, .redactor-focus:focus.redactor-styles-on {
    border-color: lightgray !important;
}

.label-card ::v-deep .card-body {
  padding: 0px;
}

@media (max-width: 500px) {
  .label-card ::v-deep .card-body {
    padding: 1.25rem;
  }
}

</style>