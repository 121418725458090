<template>
  <div>
    <div class="login-page registration-page">
      <div class="container-fluid">
        <div class="row justify-content-md-center">
          <!-- Sidebar -->
          <div class="col-md-4">
            <div class="generic-sidebar text-center" style="max-height: 762px">
              <img
                  style="height: 100px; margin-bottom: 25px"
                  src="/img/ship-icon.svg"
              />
              <h4>Email Could Not Be Validated</h4>
              <p class="mt-25">
                If you believe you encountered this page in error, please let us
                know by emailing or contacting the in app support.
                <a href="mailto:info@ashoreapp.com">info@ashoreapp.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.login-page {
  background-color: #019255;
}
</style>
