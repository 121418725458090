<script>
import {Carousel, Slide} from "vue-carousel";

export default {
  name: "thumbnails",
  props: {
    proof: {
      required: true,
      type: Object,
    },
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      imagesWaitingFor: [],
    };
  },
  watch: {
    "proof.currentVersion.versionFiles": async function () {
      await this.checkIfNeedsToTriggerLongPoll();
    },
  },
  methods: {
    getThumbnailUrl: function(thumbnail) {
      if (thumbnail.thumbnailFileKey !== '' &&
          thumbnail.thumbnailFileKey.indexOf('static') === -1 &&
          thumbnail.thumbnailFileKey.indexOf('public') === -1) {
        return 'url(https://cdn.ashoreapp.com/' + thumbnail.thumbnailFileKey + ')';
      } else if (thumbnail.thumbnailFileKey !== '') {
        if (thumbnail.thumbnailFileKey.startsWith('public/icon/'))
          return 'url(' + thumbnail.thumbnailFileKey.replace('public/icon/', '/img/') + ')';
        return 'url(' + thumbnail.thumbnailFileKey + ')';
      }
      return 'url(/img/thumbnail-generating.svg)';
    },
    checkIfNeedsToTriggerLongPoll: async function () {
      let self = this;
      if (
          this.$props.proof &&
          this.$props.proof.currentVersion &&
          this.$props.proof.currentVersion.versionFiles &&
          this.$props.proof.currentVersion.versionFiles.length > 0 &&
          this.imagesWaitingFor.length === 0
      ) {
        this.$props.proof.currentVersion.versionFiles.forEach(function (i) {
          if (
              self.imagesWaitingFor.indexOf(i.id) === -1 &&
              i.thumbnailFileKey === ""
          ) {
            self.imagesWaitingFor.push(i.id);
            self.longPollForUpdate(i);
          }
        });
      }
    },
    longPollForUpdate: async function (proofFile) {
      let proofFileId = proofFile.id;
      let self = this;
      if (this.imagesWaitingFor.indexOf(proofFileId) > -1) {
        let proofFile = await this.$A.ProofFileService.WaitForThumbnailToLoad(
            proofFileId
        );
        if (
            proofFile !== false &&
            proofFile.thumbnailFileKey !== "" &&
            proofFile.id > 0
        ) {
          this.$props.proof.currentVersion.versionFiles.filter(
              (f) => f.id === proofFileId
          )[0] = proofFile;
          this.imagesWaitingFor.splice(
              this.imagesWaitingFor.indexOf(proofFileId),
              1
          );

          let allConverted = true;
          this.$props.proof.currentVersion.versionFiles.forEach(function (i) {
            if (i.conversionJobCompletedAt === null) {
              allConverted = false;
            }
          });

          if (allConverted) {
            this.$root.$emit("proof::refresh", {});
            self.initFileThatIsNotLoaded = null;
          }
        }
      }
    },
  },
  computed: {
    versionFiles: function() {
      return _.get(
          this.proof,
          'currentVersion.versionFiles',
          [{ thumbnailFileKey: '', id: 0, conversionJobCompletedAt: null }]
      );
    }
  }
};
</script>

<template>
  <!-- Thumbnails -->
  <div class="col-md-12 px-0" >
    <b-carousel ref="carousel" id="carousel-fade" slide indicators>
      <b-carousel-slide v-for="thumbnail in versionFiles" :key="thumbnail.id" :interval="4000">
        <template v-slot:img>
          <div class="carousel-ashore-image" :style="{backgroundImage: getThumbnailUrl(thumbnail)}"></div>
          <p class="loading-text"
              v-if="thumbnail.thumbnailFileKey === '' || thumbnail.thumbnailFileKey.indexOf('static') > 0">
            File Processing
          </p>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<style scoped>

.carousel-ashore-image {
  width: 100%;
  height: 280px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

::v-deep .carousel {
  border-radius: 8px;
  height: 300px;
  width: 100%;
  padding: 10px;
  background: #EEF2F5;
}

.loading-text {
  font-weight: bold;
  text-align: center;
  background: #ffffff;
  padding: 10px;
  width: 90%;
  margin: -75px auto 0;
  border-radius: 4px;
  box-shadow: 0 -3px 10px 0 rgba(106, 139, 148, 0.2);
}


</style>
