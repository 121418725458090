<script>
export default {
  name: "hubspot-calendar",
  mounted() {
    let script = document.createElement("script");
    script.setAttribute("src", "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js");
    document.head.appendChild(script);
  }
}
</script>

<template>
  <!-- Start of Meetings Embed Script -->
  <div class="meetings-iframe-container"
       data-src="https://meetings.hubspot.com/deborah93/45-demo?embed=true">
  </div>
</template>