<script>
export default {
  name: "modal-delete-team",
  data() {
    return {
      teamToDelete: {id: 0, name: ""},
      teamIdToTransferTo: null,
      teams: [],
      alert: "",
    };
  },
  async mounted() {
  },
  computed: {
    teamOptions: function () {
      return this.teams
          .filter((team) => team.id !== this.teamToDelete.id)
          .map((team) => ({
            value: team.id,
            text: team.name,
          }));
    }
  },
  methods: {
    open: function (teamToDelete, allteams) {
      this.$set(this, 'teamToDelete', teamToDelete);
      this.teams = allteams;
      this.$bvModal.show("deleteTeamModal");
    },
    close: function () {
      this.alert = "";
      this.$bvModal.hide("deleteTeamModal");
    },
    confirm: async function () {
      this.alert = "";
      if (this.teamIdToTransferTo > 0 && this.teamToDelete.id > 0) {
        await this.reAssignUsers(this.teamToDelete.id, this.teamIdToTransferTo);
        this.$bvModal.hide("deleteTeamModal");
      } else {
        this.alert = "Please Select A team To Transfer To";
      }

      if (!this.teamIdToTransferTo) {
        await this.deleteTeam(this.teamToDelete.id);
        this.$bvModal.hide("deleteTeamModal");
      }
    },
    reAssignUsers: async function (teamIdToDelete, teamIdToTransferTo) {
      const r = await this.$A.TeamService.ReAssignUsersToAnotherTeam(teamIdToDelete, teamIdToTransferTo);
      if (!_.get(r, 'success', false)) return;
      await this.$A.TeamService.DeleteTeam(teamIdToDelete, teamIdToTransferTo);
      this.$emit("deleted");
    },
    deleteTeam: async function (teamIdToDelete) {
      await this.$A.TeamService.DeleteTeam(teamIdToDelete);
      this.$emit("deleted");
    }
  },
};
</script>

<template>
  <b-modal id="deleteTeamModal" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <span class="delete-modal-header">
          Delete {{ teamToDelete.name }}?
        </span>
      </div>
    </template>

    <form team="form">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="sub-header-text mt-0">This action cannot be undone. <span v-if="teamOptions.length > 0">Please select the team to replace</span>
            </div>

          </div>
        </div>
      </div>  <b-form-select
        v-model="teamIdToTransferTo"
        :options="teamOptions"
        required
        class="team-select mt-3"
        placeholder="Select Team"
        v-if="teamOptions.length > 0"
    ></b-form-select>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label v-if="alert.length > 0" style="color: red">{{
                alert
              }}</label>
          </div>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
            class="gray-btn"
            @click="close()"
        >Cancel
        </b-button
        >
        <b-button
            class="red-btn ml-2"
            @click="confirm()"
        >Delete Permanently
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.delete-modal-header {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.sub-header-text {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #707073;
}

/deep/ #deleteRoleModal .modal-header {
  padding: 24px !important;
}

/deep/ #deleteRoleModal .modal-body {
  padding-top: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.red-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  color: white;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #CC333F;
  border: 1px solid #EB4654;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #F4F4F6;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  cursor: pointer;
}

::v-deep .team-select {
  padding: 9px 13px;
  height: 42px;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
  border: 1px solid #B5B5B8;
}


</style>