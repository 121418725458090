import { render, staticRenderFns } from "./template-list.vue?vue&type=template&id=2b29c508&scoped=true&"
import script from "./template-list.vue?vue&type=script&lang=js&"
export * from "./template-list.vue?vue&type=script&lang=js&"
import style0 from "./template-list.vue?vue&type=style&index=0&id=2b29c508&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b29c508",
  null
  
)

export default component.exports