import { render, staticRenderFns } from "./approver-thumbnails.vue?vue&type=template&id=010e78d1&scoped=true&"
import script from "./approver-thumbnails.vue?vue&type=script&lang=js&"
export * from "./approver-thumbnails.vue?vue&type=script&lang=js&"
import style0 from "./approver-thumbnails.vue?vue&type=style&index=0&id=010e78d1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010e78d1",
  null
  
)

export default component.exports