<script>
import draggable from "vuedraggable";
import Datepicker from "vuejs-datepicker";
import selectSender from "../../components/form/selectSender.vue";
import filepicker from "../../components/create/filepicker.vue";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import confirmationModal from "../../components/modals/modal-confirmation.vue";
import modalUpdateProofOptions from "./modal-updated-proof-options";


export default {
  name: "modalManageFiles",
  components: {
    modalUpdateProofOptions,
    draggable,
    Datepicker,
    selectSender,
    filepicker,
    confirmationModal
  },
  props: {
    proof: {
      required: true,
      type: Object,
    },
    curUser: {},
    curApprover: {},
  },
  mounted() {
    let self = this;

    this.$root.$on("alert::user", (message, type, timeout) => {
      if (timeout === undefined) {
        timeout = 5000;
      }
      self.alertMessage = message;
      self.alertType = type;
    });
  },
  data: function () {
    return {
      proofVersionSelected: {id: 0, label: "", hidden: false, versionFiles: [], editingVersionFiles: []},
      existingFilesToDelete: [],
      templates: [],
      proofVersionFileIdsToPromoteToTheNextVersion: [],
      curTemplate: 0,
      sendToWorkflowStageId: 0,
      proofWithAllVersions: null,
      loadingData: false,
      working: 0,
      alertMessage: "",
      alertType: "",
      remainingFiles: 0,
      filesUploadError: false,
    }
  },
  methods: {
    getTemplates: async function () {
      this.templates = await this.$A.TemplateService.GetAll();
      let curStage = {
        name: "Stage Selected's Template",
        id: 0,
        html: "",
        subject: "",
      };
      this.templates.push(curStage);
    },
    getAllVersions: async function (proofId) {
      this.proofWithAllVersions = proofId && await this.$A.ProofService.GetFullProof(proofId, 0, true);
    },
    modalVisible: function () {
      let self = this;
      setTimeout(async function () {
        self.proofName = self.$props.proof.name;
        self.curTemplate = self.$props.proof.templateWorkflowId;
        self.sendToWorkflowStageId = self.$props.proof.currentWorkflowStageId;
        await self.getTemplates();
        // await self.useTemplate();
        self.loadingData = true;
        await self.getAllVersions(self.$props.proof.id);
        self.setCurrentVersionAsSelected();
        self.loadingData = false;
      }, 200);
    },
    setCurrentVersionAsSelected: function () {
      const currentVersion = _.get(this, 'proofWithAllVersions.currentVersion', {});
      if (_.isEmpty(currentVersion)) return;
      this.proofVersionSelected = {
        id: currentVersion.id,
        versionNumber: currentVersion.versionNumber,
        label: 'Version ' + currentVersion.versionNumber + ' (Current)',
        hidden: false,
        versionFiles: currentVersion.versionFiles,
        editingVersionFiles: [...currentVersion.versionFiles]
      }
    },
    downloadAllFiles: function (e) {
      e.preventDefault();
      if (this.proof === null) {
        return;
      }
      let name =
          this.proof.name +
          "_" +
          this.proof.currentVersion.versionNumber +
          ".zip";
      if (this.curApprover && this.curApprover.token !== undefined) {
        this.$A.ProofFileService.DownloadAllFromProofFileVersion(
            this.proof.publicId,
            this.proofVersionSelected.id,
            0,
            this.curApprover.token,
            name
        );
      }
      if (this.curUser && this.curUser.apiToken !== undefined) {
        this.$A.ProofFileService.DownloadAll(
            this.proof.publicId,
            this.proofVersionSelected.id,
            this.curUser.apiToken.token,
            "",
            name
        );
      }
    },
    uploadVersionFiles: async function () {
      const filePicker = _.get(this, '$refs.filePicker', {});
      if (_.isEmpty(filePicker)) return;
      await filePicker.UploadFiles(
          this.proof.id,
          this.proofVersionSelected.id
      );
      const hasAllFilesUploaded = filePicker.GetFileStatus().filter((f) => f.uploadProgress === 100).length === filePicker.GetFileStatus().length;
      if (!hasAllFilesUploaded) {
        this.$A.AlertUser(
            "There Was A Problem Uploading Your Files",
            "warning",
            10000
        );
        this.filesUploadError = true;
        return;
      }
      const isInsufficientFilesQuota = filePicker.GetFileStatus().filter((f) => f.noFilesLeft !== undefined).length > 0
      if (isInsufficientFilesQuota) {
        this.$A.AlertUser(
            "You Have Run Out Of Files, Upgrade Now To Unlock More",
            "warning",
            10000
        );
        return;
      }

      this.$A.AlertUser("All Files Uploaded", "success", 3000);
    },
    updateVersion: async function () {
      await this.uploadVersionFiles();
      if (this.isOkToSave()) {
        await this.deleteFiles();
        const orderedFiles = this.$refs.filePicker.GetFileIDsOrdered();
        await this.$A.ProofFileService.UpdateOrderForProofVersionFiles(this.proof.id, this.proofVersionSelected.id, orderedFiles);
        this.$bvModal.hide("ManageFiles");
        this.alertMessage = "";
        if (this.shouldDisplayNotifyApproversModal()) {
          this.$root.$emit("show::send::message::modal", 2);
          this.$bvModal.show("UpdatedProofOptions");
        }
      } else {
        this.$A.AlertUser("Must Have At Least One File in Order To Save. Please, Try Again!");
      }
    },
    hideVersion: async function () {
      await this.$A.ProofService.HideProofVersionDisplay(this.proof.id, this.proofVersionSelected.id, true);
      this.proofVersionSelected.hidden = true;
      this.proofVersionSelected.label = this.getVersionLabel(this.proofVersionSelected);
      await this.getAllVersions(this.proof.id);
    },
    unHideVersion: async function () {
      await this.$A.ProofService.HideProofVersionDisplay(this.proof.id, this.proofVersionSelected.id, false);
      this.proofVersionSelected.hidden = false;
      this.proofVersionSelected.label = this.getVersionLabel(this.proofVersionSelected);
      await this.getAllVersions(this.proof.id);
    },
    deleteFiles: async function () {
      await this.existingFilesToDelete.forEach(file => this.$A.ProofFileService.DeleteProofVersionFile(this.proof.id, this.proofVersionSelected.id, file.id));
      await this.getAllVersions(this.proof.id);
    },
    shouldDisplayNotifyApproversModal: function () {
      return this.$refs.filePicker.GetFileStatus().length > 0 && this.isCurrentVersionSelected;
    },
    isOkToSave: function () {
      this.remainingFiles = this.proofVersionSelected.versionFiles.length - this.existingFilesToDelete.length + this.$refs.filePicker.GetFileStatus().length;
      return this.remainingFiles > 0;
    },
    AlertUser(message, type) {
      let self = this;
      self.alertMessage = message;
      self.alertType = type;
      setTimeout(function () {
        self.alertMessage = "";
        self.alertType = "";
      }, 5000);
    },
    clearAlert: function () {
      this.alertMessage = "";
      this.alertType = "";
    },
    setAlertClassColor: function () {
      if (this.alertType && this.alertType === 'warning') {
        return 'alert-warning';
      } else if (this.alertType && this.alertType === 'success') {
        return 'alert-success';
      } else if (this.alertType && this.alertType === 'error') {
        return 'alert-error';
      }
      return '';
    },
    getVersionLabel: function (v) {
      let versionLabel = 'Version ' + v.versionNumber;
      let displayLabel = "";

      if (this.proofWithAllVersions.currentVersion.id === v.id) {
        displayLabel = versionLabel + ' (Current)';
      } else if (v.hidden) {
        displayLabel = versionLabel + ' (Hidden)';
      } else {
        displayLabel = versionLabel;
      }
      return displayLabel;
    },
  },
  computed: {
    isCurrentVersionSelected: function () {
      return (this.proofVersionSelected && this.proofVersionSelected.id && this.proofWithAllVersions) ?
          this.proofVersionSelected.id === this.proofWithAllVersions.currentVersion.id : false;
    },
    isVersionSelectedHidden: function () {
      return (this.proofVersionSelected && this.proofVersionSelected.hidden);
    },
    proofVersionFileIdsToPromoteToTheNextVersionOptions: function () {
      if (this.proofWithAllVersions != null
          && this.proofWithAllVersions.id > 0
          && this.proofWithAllVersions.versions !== undefined
          && this.proofWithAllVersions.versions !== null
          && this.proofWithAllVersions.versions.length > 0) {

        let self = this;
        let files = this.proofWithAllVersions.versions
            .map(v => {
              return {
                id: v.id,
                versionNumber: v.versionNumber,
                label: this.getVersionLabel(v),
                hidden: v.hidden,
                versionFiles: v.versionFiles,
                editingVersionFiles: [...v.versionFiles]
              }
            });

        return files;
      } else {
        return [];
      }
    }
  },
  watch: {
    proofVersionSelected: function (newValue, oldValue) {
      oldValue.editingVersionFiles = [...oldValue.versionFiles];
      this.existingFilesToDelete = [];
      if (this.$refs.filePicker) this.$refs.filePicker.reset();
    }
  }
};
</script>

<template>
  <!-- Manage Files Modal -->
  <b-modal id="ManageFiles"
           class="p-4"
           ref="manageProofFiles"
           size="lg"
           @show="modalVisible"
  >
    <!-- Header -->
    <template v-slot:modal-header>
      <div v-if="alertMessage" :class="'alert mb-0 w-100 text-center alert-modal-class ' + setAlertClassColor()"
           style="z-index: 3000" role="alert">
        <span v-html="alertMessage" class="ml-auto"></span>
        <span @click="clearAlert()" class="close-alert">X</span>
      </div>
      <alertHeader/>
      <div class="w-100">
        <span class="header-text">Manage Versions</span>
      </div>
    </template>
    <!-- Body -->
    <form role="form">
      <div class="select-version-text">Select Version</div>
      <div class="d-flex justify-content-start align-items-center">
        <v-select
            v-model="proofVersionSelected"
            :options="proofVersionFileIdsToPromoteToTheNextVersionOptions"
            :searchable="false"
            :clearable="false"
            class="col-md-9 pl-0 versions-select"
        />
        <b-button
            v-if="!isVersionSelectedHidden"
            :class="isCurrentVersionSelected ? 'w-100 red-light-btn col-md-3': 'w-100 red-btn col-md-3'"
            :disabled="isCurrentVersionSelected"
            @click="hideVersion()"
        >
          Hide Version
        </b-button>
        <b-button
            v-else
            class="light-green-btn col-md-3"
            @click="unHideVersion()"
        >
          Un-Hide Version
        </b-button>
      </div>
      <label class="files-text" :style="isVersionSelectedHidden ? 'opacity: 0.4':''">Files</label>
      <filepicker
          :is-manage-files-modal="true"
          :is-version-selected-hidden="isVersionSelectedHidden"
          :existing-version-files="proofVersionSelected.editingVersionFiles"
          :is-selected-current-version="isCurrentVersionSelected"
          :existing-files-to-delete="existingFilesToDelete"
          ref="filePicker"
      />
    </form>
    <b-button
        class="w-100 gray-btn mt-1"
        @click="downloadAllFiles"
        :disabled="isVersionSelectedHidden"
    >Download Files
    </b-button>
    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            v-show="!isVersionSelectedHidden"
            class="footer-gray-btn mr-2"
            @click="cancel()">Cancel
        </b-button>
        <b-button
            v-show="isVersionSelectedHidden"
            class="footer-gray-btn mr-2"
            @click="cancel()">Close
        </b-button>
        <b-button
            v-show="!isVersionSelectedHidden"
            class="dark-green-btn"
            @click="updateVersion">Update
        </b-button>
      </div>
      <modalUpdateProofOptions
          ref="UpdatedProofOptions"
          :proof="proof"
          :cur-user="curUser"
      />
    </template>
  </b-modal>
</template>

<style scoped>

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.files-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
  margin-top: 20px;
  margin-bottom: 14px;
}

::v-deep .modal-header {
  padding: 24px 24px 0px 24px;
}

::v-deep .modal-body {
  padding: 20px 24px 0px 24px;
}

::v-deep .modal-footer {
  border-top: none !important;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 752px;
  height: 42px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 14px;
}

.red-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A62934;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 15px 9px 17px;
  gap: 8px;
  width: 752px;
  height: 42px;
  /*margin-bottom: 24px;*/
  background: #FCF0F1;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.red-light-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 15px 9px 17px;
  gap: 8px;
  width: 156px;
  height: 42px;
  background: #FCF0F1;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A62934;
}

::v-deep .btn-secondary.disabled, .btn-secondary:disabled {
  border: 0px;
}

.light-green-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 15px 9px 17px;
  height: 42px;
  background: #E3F9E5;
  border: 1px solid #34A262;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #045634;
}

.dark-green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.select-version-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
  margin-bottom: 4px;
}

.alert-modal-class {
  position: absolute;
  min-height: 0;
  margin: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 888;
}

.footer-gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.footer-gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

.close-alert {
  cursor: pointer;
  float: right;
}

::v-deep .vs__dropdown-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 42px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}


@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
}


</style>