<script>
import modalDeleteRole from "../../components/modals/modal-delete-role.vue";
import createUserRoleSidebar from "./setting-create-user-role-sidebar";
import _ from 'lodash';

export default {
  name: "usersRoles",
  components: {
    createUserRoleSidebar,
    modalDeleteRole,
  },
  methods: {
    loadUserRoles: async function (preventEvent) {
      this.roles = await this.$A.RoleService.GetRoles() || [];
      if (!preventEvent) {
        this.$emit("updated", this.roles);
      }
    },
    editUserRole: function (role) {
      this.$refs.userRoleEditor.edit(role);
    },
    duplicateUserRole: function (role) {
      let newRole = {...role, id: 0};
      newRole.name = newRole.name + ' copy';
      this.$refs.userRoleEditor.edit(newRole);
    },
    createUserRole: function () {
      console.log("waiting for create user role api....");
    },
    deleteUserRole: async function (role) {
      this.$refs.deleteRoleModal.open(role, this.roles);
    },
    canBeDeleted: function (role) {
      return !['Admin', 'User'].includes(role.name);
    },
    isAdmin: function (role){
      return role.proofPermissions === 3
          && role.manageUsers
          && role.manageAccount
          && role.manageBilling
    }
  },
  computed: {
    canCreateUserRole: function () {
      const userLimit = _.get(this, '$A.Auth.account.subscriptionState.userLimit', 0);
      return !userLimit || this.users.length < userLimit;
    },
    hasMoreThanOneAdminRole: function () {
      const adminRoles = this.roles.filter(this.isAdmin);
      return adminRoles.length > 1;
    }
  },
  data() {
    return {
      roles: [],
      fields: [
        {
          key: 'roleinfo',
          sortable: false,
          label: "USER ROLES"
        },
        {
          key: 'edit',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'duplicate',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'delete',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
      ],
      users: []
    };
  },
  async mounted() {
    await this.loadUserRoles(true);
    this.users = await this.$A.AccountService.All() || [];
  },
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10 account-user-card">
      <b-card-body>
        <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 text-dark account-users-header">Users Roles</b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 description-text">Create and manage custom user roles for your
          organization.
        </b-card-text>
        <b-table class="px-4 pb-4 pt-2 rounded user-table"
                 :items="roles"
                 :fields="fields"
                 responsive
                 hover
                 outlined
                 borderless
                 thead-class="user-table-bg">
          <template #head()="data">
            <span class="table-header-text">{{ data.label }}</span>
          </template>
          <template #cell(roleinfo)="data">
            <div class="ml-2 column-header-text">{{ data.item.name }}</div>
          </template>
          <template #cell(edit)="data">
            <div>
              <a class="font-weight-normal edit-delete-user" @click="editUserRole(data.item)"
                 v-if="!isAdmin(data.item) || hasMoreThanOneAdminRole">Edit</a>
            </div>
          </template>
          <template #cell(duplicate)="data">
            <div>
              <a class="font-weight-normal edit-delete-user" @click="duplicateUserRole(data.item)"
                 v-if="canCreateUserRole">
                Duplicate
              </a>
            </div>
          </template>
          <template #cell(delete)="data">
            <div>
              <a v-if="canBeDeleted(data.item)" class="font-weight-normal edit-delete-user user"
                 @click="deleteUserRole(data.item)">Delete</a>
            </div>
          </template>
        </b-table>
        <modal-delete-role ref="deleteRoleModal" @deleted="loadUserRoles"/>
      </b-card-body>
      <div
          class="border-top-0 d-flex mb-0 justify-content-lg-between justify-content-md-around table-footer flex-sm-wrap">
        <div class="ml-auto" v-if="canCreateUserRole">
          <a @click="createUserRole()"
             v-b-toggle.create-user-role-sidebar
             class="button button-light button-green users-btn"
          >
            Create User Role
          </a>
        </div>
        <create-user-role-sidebar ref="userRoleEditor" @saved="loadUserRoles"/>
        <div class="col-sm-4 col-lg-12" v-if="!canCreateUserRole">
          <button
              class="button button-locked-users feature-lock-btn ml-auto"
              @click="$A.BuyNow()"
              v-b-tooltip.hover="'Upgrade to add additional users'"
          >
            Feature Locked <img src="/img/feature-lock.svg" class="feature-icon"/>
          </button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style scoped>


.field-item h3 {
  font-size: 18px;
}

.user-button {
  cursor: pointer;
  float: right;
  margin-left: 35px;
}

.user-button img {
  height: 15px;
  margin-left: 10px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}


.invite_link {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  opacity: 1;
  margin-top: 0 !important;
  cursor: pointer;
}

.button-locked-users {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #F44271;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.feature-icon {
  margin-bottom: 2px;
  margin-left: 4px;
  height: 22px;
}

.feature-lock-btn {
  width: 160px;
}

.users-btn {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.account-user-card ::v-deep .card-body {
  padding: 0px;
}

.account-users-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

/deep/ .user-table {
  border-radius: 6px !important;
}

::v-deep .user-table .table, .table-responsive > .table, [class*=table-responsive-] > .table {
  margin-bottom: 0 !important;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
}

::v-deep .b-table-sticky-header, .table-responsive, [class*=table-responsive-] {
  margin-bottom: 0px !important;
}

::v-deep .user-table table thead th {
  border-bottom: 1px solid lightgray;
  padding: 12px 24px !important;
}

::v-deep .user-table td {
  padding: 16px 24px !important;
  vertical-align: middle !important;
}


/deep/ .user-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.table-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  color: #575759;
}

.column-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.column-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
}

.edit-delete-user {
  color: #006F41;
}

.edit-delete-user:hover {
  cursor: pointer;
}

@media (max-width: 500px) {

  .button-locked-users {
    background: #F44271;
    color: #ffffff;
    transition: all .5s ease;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .users-btn {
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
  }


}

.edit-delete-user {
  color: #006F41;
}

.edit-delete-user:hover {
  cursor: pointer;
}

</style>
