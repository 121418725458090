<script>
import proofToggles from "../../components/global/proof-toggle.vue";
import Datepicker from "vuejs-datepicker";
import tags from "../../components/form/tags.vue";


export default {
  name: "proofOptions",
  props: {
    teams: Array,
    required: true
  },
  components: {
    proofToggles,
    Datepicker,
    tags,
  },
  data() {
    return {
      dueDate: "",
      team: 0,
      customProperties: [],
      customPropertyValues: {},
    };
  },
  mounted: async function () {
    await this.loadCustomProperties();
  },
  methods: {
    getCurrentSettings: function () {
      return this.$refs.PT.getCurrentSettings();
    },
    getCurrentDueDate: function () {
      return new Date(this.dueDate).toJSON();
    },
    getCurrentTags: function () {
      return this.$refs.tags.getSelectedTagIds();
    },
    getCurrentCustomProperties: function () {
      return this.customProperties.map(prop => {
        prop.value = this.customPropertyValues[prop.id];
        if (prop.value) {
          if (prop.dataType === 'Datepicker') {
            prop.value = [new Date(prop.value).toJSON()];
          } else if (prop.dataType === 'Single Line Text' || prop.dataType === 'Dropdown Select') {
            prop.value = [prop.value];
          }
        } else {
          prop.value = [];
        }
        return {
          accountId: prop.accountId,
          proofPropertyValue: prop.value,
          accountProofPropertyId: prop.id,
          accountProofPropertyEnum: prop.enum,
          accountProofPropertyLabel: prop.label,
          accountProofPropertyDataType: prop.dataType
        };
      });
    },
    loadCustomProperties: async function () {
      this.customProperties = await this.$A.AccountService.GetAllCustomProofProperties();
      _.isArray(this.customProperties) && this.customProperties.forEach(prop => {
        this.customPropertyValues[prop.id] = null;
        if (prop.dataType === "Checkboxes (Multiple Options)") {
          this.customPropertyValues[prop.id] = [];
        }
      });
    }
  },
};
</script>

<template>
  <!-- Field -->
  <div class="col-md-12">
    <!-- Proof Settings -->
    <div class="row field-item-proof-option d-flex flex-wrap pt-4 px-4" id="proof_options">
      <span class="header-card-text col-md-3">Proof Settings</span>
      <div class="col-md-9">
        <span class="mb-15 sub-text-proof-option">Allow:</span>
        <proofToggles ref="PT"/>
      </div>
    </div>
    <!-- Properties -->
    <div class="row d-flex flex-wrap field-item-proof-option pt-4 px-4">
      <span class="mb-15 sub-text-proof-option col-md-3">Properties</span>
      <div class="col-md-7">
        <span class="property-sub-text">Set Deadline</span>
        <datepicker
            placeholder="MM-DD-YYYY"
            v-model="dueDate"
            data-cy="datepickerInput"
            class="mb-3"
        />
        <span class="property-sub-text">Add Tags to Proof</span>
        <tags ref="tags" class="mb-3"/>
        <div v-for="property in customProperties">
          <!-- Datepicker Custom Label -->
          <div v-if="property.dataType === 'Datepicker' ">
            <span class="property-sub-text">{{ property.label }}</span>
            <datepicker
                v-model="customPropertyValues[property.id]"
                class="d-block mb-3"
                placeholder="MM-DD-YYYY"
            />
          </div>
          <!-- Single Line Text Custom Label -->
          <div v-if="property.dataType === 'Single Line Text'">
            <span class="property-sub-text">{{ property.label }}</span>
            <input type="text"
                   class="single-text mb-3"
                   v-model="customPropertyValues[property.id]">
          </div>
          <!-- Dropdown Custom Label -->
          <div v-if="property.dataType === 'Dropdown Select'">
            <span class="property-sub-text">{{ property.label }}</span>
            <v-select
                :options="property.enum"
                placeholder="Select"
                class="proof-select mb-3"
                @input="(value) => customPropertyValues[property.id] = value"
            >
            </v-select>
          </div>
          <!-- Checkboxes Custom Label -->
          <div class="proof-item-checkboxes">
            <b-form-group :label="property.label"
                          v-if="property.dataType === 'Checkboxes (Multiple Options)'"
                          class="text-normal mb-0">
              <b-form-checkbox-group
                  v-model="customPropertyValues[property.id]"
                  :options="property.enum"
                  name="flavour-4a"
                  inline
                  checked="true"
                  class="d-flex flex-column"
                  style="margin-bottom: 10px"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Field -->
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

.header-card-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.property-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.single-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  width: 100%;
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.proof-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.proof-select ::v-deep .vs__dropdown-menu {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1B1B1C;
}

.proof-item-checkboxes {
  font-weight: normal;
  margin-top: 10px;
}

/deep/ .custom-checkbox {
  padding-bottom: 4px;
}

/deep/ .proof-item-checkboxes span {
  font-weight: normal;
  margin-top: 0px;
}

/deep/ .proof-item-checkboxes .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: 0px;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

/deep/ .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #019256 !important;
  border-radius: 0.25rem;
}

/deep/ .row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/deep/ .custom-control-label::before {
  top: 0.2rem !important;
}

/deep/ .custom-control {
  min-height: 0rem !important;
}

.field-item-proof-option {
  background: #fff;
  font-weight: 700;
  width: 100%;
  position: relative;
  border-top: 1px solid #D5D5D8;
  padding-bottom: 20px;
  box-shadow: 0px 20px 25px -5px rgb(8 11 13 / 10%), 0px 10px 10px -5px rgb(8 11 13 / 4%);
}

.field-item-proof-option span {
  display: block;
}

.sub-text-proof-option {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.team-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.team-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.team-select ::v-deep .vs__selected {
  padding: 4px 0px 8px 4px !important;
}

</style>
