<script>
import Datepicker from "vuejs-datepicker";

export default {
  name: "editSideBarPropertyModal",
  components: {
    Datepicker
  },
  data: function () {
    return {
      proofId: 0,
      property: {},
      value: null,
      dataType: "Single Line Text",
      errors: [],
      validated: false,
    };
  },
  methods: {
    checkForm: function () {
      this.errors = [];
      if (!this.errors.length) {
        return true;
      }
    },
    resetEditingLabel: function () {
      this.validated = false;
      this.property = {};
    },
    editProperty: function (proofId, property) {
      this.proofId = proofId;
      this.property = property;
      this.dataType = this.property.accountProofPropertyDataType;
      this.value = this.getPropertyValue();
      this.$bvModal.show("editSideBarProperty");
    },
    getPropertyValue: function () {
      if (this.dataType === 'Checkboxes (Multiple Options)') {
        return this.property.proofPropertyValue
      }
      let hasValue = this.property.proofPropertyValue && this.property.proofPropertyValue.length > 0;
      if (hasValue) {
        return this.property.proofPropertyValue[0];
      }
      return null;
    },
    getPropertyValueAsArray: function () {
      if (this.dataType === 'Datepicker') {
        return [new Date(this.value).toJSON()];
      } else if (this.dataType === 'Single Line Text' || this.dataType === 'Dropdown Select') {
        return [this.value];
      }
      return this.value;
    },
    saveProperty: async function () {
      if (this.checkForm()) {
        this.property.proofPropertyValue = this.value ? this.getPropertyValueAsArray() : [];
        await this.$A.ProofService.UpdateCustomFieldValue(this.proofId, this.property);
        this.$root.$emit("proof::custom-props::updated");
        this.$bvModal.hide("editSideBarProperty");
        this.resetEditingLabel();
      }
    },
  },
};
</script>

<template>
  <b-modal id="editSideBarProperty" size="lg" :no-close-on-backdrop="true">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <span class="header-text-card pb-0">Edit Property: <span
            class="font-weight-normal">{{ property.accountProofPropertyLabel }}</span></span>
      </div>
    </template>
    <form role="form" @submit="saveProperty">
      <!-- Datepicker Custom Label -->
      <div v-if="dataType === 'Datepicker'"
      >
        <datepicker
            v-model="value"
            style="display: block; margin: 10px 0 10px"
            placeholder="MM-DD-YYYY"
        />
      </div>
      <!-- Single Line Text Custom Label -->
      <div v-if="dataType === 'Single Line Text'">
        <input type="text" class="single-text" v-model="value">
      </div>
      <!-- Dropdown Custom Label -->
      <div v-if="dataType === 'Dropdown Select'">
        <v-select
            v-model="value"
            :options="property.accountProofPropertyEnum"
            placeholder="Select"
            class="proof-select"
        >
        </v-select>
      </div>
      <!-- Checkboxes Custom Label -->
      <div class="proof-item-checkboxes">
        <b-form-group v-if="dataType === 'Checkboxes (Multiple Options)'" class="text-normal">
          <b-form-checkbox-group
              v-model="value"
              :options="property.accountProofPropertyEnum"
              name="flavour-4a"
              inline
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </form>
    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button class="cancel-btn cancel-btn-text mr-3" @click="cancel()">Cancel</b-button>
        <b-button class="submit-btn green-btn" @click="saveProperty()">Save</b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.header-text-card {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  margin-bottom: 12px;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.submit-btn, cancel-btn {
  padding: 9px 17px;
  width: 130px;
  height: 38px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.cancel-btn-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 108px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.cancel-btn-text:hover {
  color: #F9F9F9;
}

.green-btn {
  background: #019256;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 130px;
  height: 38px;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 14px;
}

.single-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  width: 100%;
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.delete-icon {
  width: 22px;
  height: 22px;
  margin-top: 23px;

}

.delete-icon:hover {
  cursor: pointer;
}

.property-form-input {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  display: block;
  width: 100%;
  height: 37px;
  padding: 2px 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #444444;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid lightgray;
  box-shadow: inset 0 1px 1px rgb(0 0 0/ 8%);
  border-radius: 5px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

::v-deep .property-form-input .vs__dropdown-toggle {
  border: 0px;
}

::v-deep .modal-header {
  padding-bottom: 0px;
}

</style>
