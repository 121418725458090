<script>
import selectNonApprovers from "../form/selectNonApprovers.vue";
import redactor from "../form/redactor";
import PreviewEmailTemplate from '../modals/modal-previewemailtemplate';

export default {
  name: "create-email-template-item",
  components: {
    redactor,
    selectNonApprovers,
    PreviewEmailTemplate,
  },
  props: {
    templateToEdit: {
      required: false,
      type: Object
    }
  },
  computed: {
    isEditable() {
      return this.template.name === "Automatic Reminder";
    },
    commentFileApi: function () {
      if (this.$A.Auth) {
        let apiUrl= "/account/public-file" + "?access_token=" + this.$A.Auth.apiToken.token;
        return apiUrl;
      }
      return "";
    },

  },
  data() {
    return {
      user: this.$A.Auth,
      template: {
        name: "",
        subject: "",
        bccUserIds: [],
        html: "<p></p>"
      },
      previewHtml: "",
      steps: [
        {
          target: "#template_name",
          content: `Before you create your first email template, here’s what you need to know. First, every email template needs a good name.`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#template_message",
          content:
              "You can customize the email message that will be sent to contacts here. Once this email template is saved, you can append it anytime you send a message in Ashore.",
          params: {
            placement: "right",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("template-item");
        },
      },
      displayEmailSideBar: false,
      templateVariables: [
        {
          id: 1,
          text: "Show the proof's name",
          variable: "proofName"
        },
        {
          id: 2,
          text: "Show the proof's due date",
          variable: "dueDate"
        },
        {
          id: 3,
          text: 'Show the approval status of the proof. Example: "Waiting" or "Approved"',
          variable: "proofStatus"
        },
        {
          id: 4,
          text: 'Show the approval status of the current stage. Example: "Waiting" or "2/3 Approvals"',
          variable: "currentStageStatus"
        },
        {
          id: 5,
          text: "Show the approver's name",
          variable: "approverName"
        },
        {
          id: 6,
          text: "Show the approver's email",
          variable: "approverEmail"
        },
        {
          id: 7,
          text: "Show the days the approver will receive reminders",
          variable: "reminderFrequency"
        },
        {
          id: 8,
          text: "Show the days the approver will receive reminders",
          variable: "timeOfDayToSend"
        },
        {
          id: 9,
          text: "Shows the proof thumbnail image URL",
          variable: "proofThumbNailUri"
        },
        {
          id: 10,
          text: "Show the URL of the proof review link",
          variable: "buttonLink"
        },
        {
          id: 11,
          text: "Show the URL of the proof review link button text",
          variable: "buttonText"
        },
      ],
      alertMessage: "",
      showEmailRedactor: false
    };
  },
  watch: {
    templateToEdit: function () {
      this.$nextTick(this.loadTemplate);
    },
    displayEmailSideBar: function (newValue) {
      if (!newValue) {
        this.cancel();
      }
    }
  },
  async mounted() {
    let self = this;

    // if (this.user.productTourState.indexOf("template-item") === -1) {
    //   // console.log("starting template item tour");
    //   // this.$tours["templateTour"].start();
    // }


    this.$root.$on("template_item_page::delete::template::confirmed", async function () {
          if (self.template.id !== undefined && self.template.id != null) {
            await self.$A.TemplateService.Delete(self.template.id);
            setTimeout(function () {
              self.$A.NavigateToView("/automation-archive");
            }, 1000);
          }
        }
    );

    this.$root.$on("alert::user", (message, type, timeout) => {
      if (timeout === undefined) {
        timeout = 4000;
      }
      self.alertMessage = message;
      self.alertType = type;
    });

    this.$root.$on("edit::template::email", function () {
      self.showEmailRedactor = true;
    })

    this.$root.$on("create::template::email", function () {
      self.showEmailRedactor = true;
    })
  },
  methods: {
    save: async function () {
      let t = this.template;
      this.template.error = undefined;

      t.html = this.$refs.redactorTextArea.getTextBody();
      t.bccUserIds = this.$refs.selectNonApprovers.getIdArrayOfSelectedUsers();

      if (!Array.isArray(t.bccUserIds)) {
        t.bccUserIds = [];
      }
      let response = await this.$A.TemplateService.Save(t);
      if (response.id !== undefined && response.id > 0) {
        this.template = response;
        this.$root.$emit("email::template::saved")
        this.cancel();
      } else {
        this.template.error = response.message;
      }
    },
    preview: async function () {
      this.template.html = this.$refs.redactorTextArea.getTextBody();
      let response = await this.$A.EmailService.GetEmailPreview(this.template.subject, this.template.html);
      if (response) {
        this.previewHtml = response;
        this.$refs.previewModal.open();
      }
    },
    cancel: function () {
      this.$root.$emit("template_item:canceled")
      this.displayEmailSideBar = false;
      this.alertMessage = "";
      this.template = {
        name: "",
        subject: "",
        bccUserIds: [],
        html: "<p></p>"
      };
      this.previewHtml = "";
    },
    loadTemplate: async function () {
      this.template = this.templateToEdit;
      this.alertMessage = "";
      if (this.template == null) {
        this.template = {
          name: "",
          subject: "",
          bccUserIds: [],
          html: "<p></p>"
        };
      }
      await this.$refs.selectNonApprovers.setSelectedUsers(
          this.template.bccUserIds
      );
      this.setTextBody();
    },
    setTextBody: function () {
      if (!this.$refs || !this.$refs.redactorTextArea) {
        let self = this;
        this.$nextTick(() => setTimeout(self.setTextBody, 500));
        return;
      }
      this.$refs.redactorTextArea.setTextBody(this.template.html);
    },
    copy: function (text) {
      this.$A.CopyStringToClipboard(text);
      this.$A.AlertUser("The Email Template Variable Has Been Copied To Your Clipboard!", "success")
    },
    AlertUser(message, type) {
      let self = this;
      self.alertMessage = message;
      self.alertType = type;
      setTimeout(function () {
        self.alertMessage = "";
        self.alertType = "";
      }, 3000);
    },
    setAlertClassColor: function () {
      if (this.alertType && this.alertType === 'warning') {
        return 'alert-warning';
      } else if (this.alertType && this.alertType === 'success') {
        return 'alert-success';
      } else if (this.alertType && this.alertType === 'error') {
        return 'alert-error';
      }
      return '';
    }
  },
};
</script>

<template>
  <!-- Create Email Template Sidebar -->
  <b-sidebar id="create-template-sidebar-right"
             right
             shadow
             v-model="displayEmailSideBar"
             @hidden="cancel"
             :no-header-close="true"
  >
    <div v-if="alertMessage"
         :class="'alert mb-0 position-absolute w-100 text-center font-weight-normal ' + setAlertClassColor()"
         style="z-index: 3000" role="alert">
      {{ alertMessage }}
    </div>
    <div class="workflow-header-container">
      <div class="sidebar-workflow-header">Manage Email Template</div>
      <span class="sub-text">Your email templates are available when sending messages or creating workflows.</span>
    </div>
    <!-- Template Name -->
    <div class="workflow-field-container d-flex flex-direction-row align-items-center border-bottom" id="template_name">
      <span class="field-text col-md-3 px-0">Template Name</span>
      <b-input type="text"
               class="field-input col-md-9"
               name="templateName"
               v-model="template.name"
               :disabled="isEditable"
      >
      </b-input>
    </div>
    <div class="template-wrapper">
      <!--  Message -->
      <div id="template_bcc">
        <div class="text-bold text-dark header-text mt-0">Message</div>
        <!-- Subject Line -->
        <div class="mb-1 small-text">Subject line</div>
        <b-form-input
            type="text"
            class="field-input"
            name="templateName"
            v-model="template.subject"
        >
        </b-form-input>
        <!-- Blind Copy Users -->
        <div class="mb-1 small-text">Blind copy these users from my team</div>
        <selectNonApprovers
            ref="selectNonApprovers"
            placeholder="Search and Select..."
            style="margin-bottom: 10px"
        />
      </div>
      <!-- Email Body -->
      <div id="template_message">
        <div class="mb-1 small-text">Email body</div>
        <redactor :subject="template.subject"
                  class="mb-4"
                  v-if="showEmailRedactor"
                  v-model="template.html"
                  ref="redactorTextArea"
                  :show-preview="true"
                  @preview="preview"
                  :comment-api-url="commentFileApi"
        />
        <preview-email-template ref="previewModal" :html-body="previewHtml"/>
      </div>
      <!--  Template Error -->
      <div class="mt-10" style="color: red;" v-if="template.error!==undefined">
        {{ template.error }}
      </div>
    </div>
    <!-- Email Template Variables -->
    <div
        class="field-item template mb-0 px-4"
    >
      <!--  Template Container Card -->
      <b-card class="template-variables-container-card">
        <div class="d-flex align-items-center">
          <div class="template-header">Email Template Variables</div>
          <!-- Collapse Template Variables Details -->
          <div class="ml-auto" v-b-toggle.email_template>
            <span class="when-open"><b-icon-chevron-up style="width: 18px; height: 18px"></b-icon-chevron-up></span>
            <span class="when-closed"><b-icon-chevron-down
                style="width: 18px; height: 18px"></b-icon-chevron-down></span>
          </div>
        </div>
      </b-card>
      <!-- template Details -->
      <b-collapse id="email_template">
        <b-card class="template-variables-card">
          <b-card-body>
            <div class="template-variable" v-for="(v, index) in templateVariables" v-bind:key="v.id">
              <div class="mb-1 small-text">{{ templateVariables[index].text }}</div>
              <b-input-group>
                <b-form-input id="email_template_variable"
                              :value="'{{ ' + templateVariables[index].variable + ' }}'"
                              class="variable variable-input"
                              :disabled="true">
                </b-form-input>
                <b-input-group-append class="copy_icon">
                  <img
                      src="/img/copy.svg"
                      @click="copy('{{ ' + templateVariables[index].variable + ' }}')"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-card-body>
        </b-card>
      </b-collapse>
    </div>
    <!-- Footer -->
    <template #footer="{ hide }">
      <!--  Buttons  -->
      <div class="d-flex create-workflow-footer border-top col-md-12 justify-content-end">
        <b-button class="workflow-btn-white button ml-3 mb-0"
                  @click="hide">Cancel
        </b-button>
        <b-button
            class="workflow-btn-green button ml-3 mb-0"
            @click="save()"
        >Save Template
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<style scoped>

.template-wrapper {
  padding: 20px 24px;
}

/deep/ .b-sidebar {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 672px;
  overflow-y: scroll;
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-body {
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}

.workflow-header-container {
  background: #F4F4F6;
  padding: 24px;
  border-bottom: 1px solid lightgray;
}

.sidebar-workflow-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
  margin-top: 4px;
}

.small-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.field-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.field-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

.workflow-field-container {
  padding: 20px 24px;
  background-color: white;
}

.template-variables-container-card {
  padding: 16px 24px;
  display: flex;
  border: 0px;
  border-right: 0.25px;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
}

/deep/ .template-variables-container-card {
  border-radius: 0px;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.create-workflow-footer {
  padding: 20px 24px;
  background: #f4f4f6;
  color: #575759;
}

.workflow-btn-green {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #019256;
}

.workflow-btn-white {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #f9f9f9;
  color: #3e3e40;
}

.workflow-btn-white:hover, .workflow-btn-green:hover {
  opacity: 0.5;
  transition: all 0.5s ease;
}

.template-variables-card {
  padding: 24px;
  border: 0px;
  border-radius: 0px !important;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
}

.header-text {
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 10px;
}


.template-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.template-variable {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.variable {
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.variable-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  height: 40px;
  background: #EBEBED;
  border-radius: 4px;
  border: 0px !important;
  box-shadow: none;
}

.copy_icon {
  background: #EBEBED;
  padding: 10px;
  padding-top: 12px;
}

.copy_icon:hover {
  cursor: pointer;
}

@media screen and (max-width: 850px) {

  .field-item span {
    margin-bottom: 0px !important;
  }
}


</style>


