<script>
export default {
  name: "tableApprover",
  props: ["title", "tableApprovers", "selectedApproverIds"],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'select',
          label: ''
        },
        {
          key: 'name',
          label: 'NAME',
          sortable: true
        },
        {
          key: 'email',
          label: 'EMAIL',
          sortable: true
        },
        {
          key: 'frequency',
          label: 'FREQUENCY',
          sortable: true
        },
        {
          key: 'totalProofs',
          label: 'TOTAL PROOFS',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'DATE CREATED',
          sortable: true
        },
        {
          key: 'view',
          label: ''
        }
      ],
      selectAllModel: false,
      someSelectedModel: false
    };
  },
  methods: {
    nav: function (path, data) {
      this.$A.NavigateToView(path, data);
    },
    onSelectAllChange: function (checked) {
      this.$parent.selectAllApprovers(checked, this.currentPage, this.perPage);
      this.someSelectedModel = false;
    },
    onSelectChange: function (id, checked) {
      if (!checked) {
        this.$parent.approverUnselected(id);
      }
      this.refreshSelectModel();
    },
    refreshSelectModel: function () {
      for (const [key, value] of Object.entries(this.selectedApproverIds)) {
        if (!value) {
          delete this.selectedApproverIds[key];
        }
      }
      const selectedCount = Object.keys(this.selectedApproverIds).length;
      if (selectedCount > 0) {
        if (this.perPage === selectedCount) {
          this.selectAllModel = true;
          this.someSelectedModel = false;
        } else {
          this.selectAllModel = false;
          this.someSelectedModel = true;
        }
      } else {
        this.selectAllModel = false;
        this.someSelectedModel = false;
      }
    },
    viewApproverDetails: function (approver) {
      return this.$A.NavigateToView('/approver-item', {approver});
    }
  },
  watch: {
    tableApprovers: function () {
      this.refreshSelectModel();
    },
    currentPage: function () {
      this.selectAllModel = false;
      this.someSelectedModel = false;
      this.$parent.resetSelectedApprovers();
    },
    perPage: function () {
      this.selectAllModel = false;
      this.someSelectedModel = false;
      this.$parent.resetSelectedApprovers();
    }
  }
};
</script>

<template>
  <div>
    <b-table v-if="tableApprovers.length" id="approver_table"
             class="rounded"
             :bordered="true"
             :borderless="true"
             :items="tableApprovers"
             :fields="fields"
             responsive outlined bordered borderless
             :no-provider-paging="true"
             :no-provider-sorting="true"
             :per-page="perPage"
             :current-page="currentPage"
             thead-class="table-bg"
    >
      <template #head()="data">
        <span class="table-header-text">{{ data.label }}</span>
      </template>
      <template #head(select)="data">
        <b-form-checkbox
            id="approver_table_header"
            class="item-checkbox"
            @change="onSelectAllChange"
            v-model="selectAllModel"
            :indeterminate="someSelectedModel">
        </b-form-checkbox>
      </template>
      <template #cell(select)="data">
        <b-form-checkbox
            class="item-checkbox"
            @change="(checked) => onSelectChange(data.item.id, checked)"
            v-model="selectedApproverIds[data.item.id]">
        </b-form-checkbox>
      </template>
      <template #cell(createdAt)="data">
        {{ $A.FormatDateToString(data.item.createdAt) }}
      </template>
      <template #cell(view)="data">
        <a class="view-link" @click="viewApproverDetails(data.item)">View</a>
      </template>
      <template slot="custom-foot">
        <b-tr class="border">
          <b-td colspan="3">
            <div class="d-flex align-items-center">
              <span class="pr-2">Rows Per Page</span>
              <b-form-select v-model="perPage" :options="[10, 20, 50, 100]" size="sm"
                             style="width: fit-content"></b-form-select>
            </div>
          </b-td>
          <b-td colspan="4">
            <b-pagination
                v-model="currentPage"
                :total-rows="tableApprovers.length"
                :per-page="perPage"
                aria-controls="approver_table"
                align="right"
                size="md"
                page-class="customPage"
                class="p-0 m-0"
            >
              <template #page="{ page, active }">
                <span class="customPage">{{ page }}</span>
              </template>
            </b-pagination>
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <div class="null-item" v-if="!tableApprovers.length">
      <div class="row">
        <div class="col-md-12">
          <img src="/img/hook-icon.svg"/>
          <h4>You Do Not Currently Have Any Contacts</h4>
          <p>
            You can add a contact by selecting the "Add New Contact" button
            above.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.customPage {
  color: #212529 !important;
}

::v-deep .customPage {
  color: #212529 !important;
}

::v-deep .page-item .page-link {
  color: #019256 !important;
}

::v-deep .page-item.disabled .page-link {
  color: #6c757d !important;
}

::v-deep .customPage.page-item.active .page-link {
  background-color: #EBEBED;
  border-color: #019256;
  color: #019256;
}

::v-deep .customPage.page-item.active .page-link .customPage {
  color: #019256 !important;
}

.view-link {
  color: #019256 !important;
  cursor: pointer;
}

/deep/ .table-bg {
  background: #F4F4F6;
  opacity: 0.50;
  border-bottom: 0.2px solid #EBEBED;
}

.null-item img {
  height: 100px;
  margin-bottom: 25px;
  animation: hook 5s infinite;
}

.null-item h4 {
  margin-bottom: 15px;
}

.null-item {
  list-style-type: none;
  text-align: center;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .item-checkbox .custom-control-label::before {
  top: 0.2rem;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid #B5B5B8;
  border-radius: 4px;
  color: black;
}

/deep/ .item-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

/deep/ .item-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #006F41;
}

/deep/ .item-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background: #95D6B0 !important;
}

/deep/ .item-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ .b-table-sticky-header, .table-responsive, [class*=table-responsive-] {
  margin-bottom: 0px !important;
}

/deep/ .table th, .table td {
  padding: 12px 24px !important;
}

/deep/ .table td {
  padding: 12px 24px !important;
}

/*/deep/ .table.b-table > thead > tr > [aria-sort=none], .table.b-table > tfoot > tr > [aria-sort=none] {*/
/*  background-image: url('/img/caret-dark.svg');*/
/*}*/


@-webkit-keyframes hook {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
