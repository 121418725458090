<template>
  <div>
    <div class="forgotpassword-page registration-page">
      <div class="container-fluid">
        <div class="row">
          <!-- Sidebar -->
          <div class="col-lg-4 col-md-5 m-auto">
            <div class="generic-sidebar-reset-password">
              <div v-if="accountLogo" class="ashore-logo-registration">
                <img :src="accountLogo" :class="{ 'account-logo': !isCustomDomain }"/>
              </div>
              <div class="reset-password">Reset Your Password</div>
              <div class="form-group">
                <label class="label-text mt-4">Your Email</label>
                <input
                    type="text"
                    name="Username"
                    class="form-control email-input"
                    placeholder="Your Email"
                    v-model="email"
                    v-on:keyup.enter="ResetPassword()"
                />
              </div>
              <a
                  v-on:click="ResetPassword()"
                  class="button green-btn mt-4"
              >
                <b-spinner
                    variant="success"
                    v-show="working"
                    type="grow"
                    small
                ></b-spinner
                >
                Send Password Reset Link</a
              >
              <b-alert
                  :show="dismissCountDown"
                  dismissible
                  :variant="variant"
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                  style="margin-top: 25px"
              >
                <p>{{ errorMessage }}</p>
                <b-progress
                    :variant="variant"
                    :max="dismissSecs"
                    :value="dismissCountDown"
                    height="4px"
                ></b-progress>
              </b-alert>
              <a href="/" class="button white-btn mt-3">Login Instead</a>
            </div>
          </div>
          <!-- /Sidebar -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccountLogo, isCustomDomain } from "../utils/helpers";

export default {
  components: {},
  data() {
    return {
      email: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      errorMessage: "",
      variant: "warning",
      working: false,
      locked: false,
      isCustomDomain
    };
  },
  mounted() {
    let self = this;
    
    this.$root.$on("alert::user", (message, type, timeout) => {
      self.errorMessage = message;
      self.variant = type;
      self.showDismissibleAlert = true;
      self.dismissCountDown = self.dismissSecs;
    });
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    inputValid: function (val) {
      return val ? "has-success" : "has-error";
    },
    allFormElementsValid: function () {
      return this.passwordValid && this.passwordMatch;
    },
    ResetPassword() {
      if (this.locked) {
        return;
      }
      if (this.email == "") {
        this.errorMessage = "Please enter your email";
        this.showDismissibleAlert = true;
        this.variant = "warning";
        this.dismissCountDown = this.dismissSecs;
        return;
      }

      let body = this.$A.UserService.SendResetPasswordLink(this.email);
      if (body.success) {
        self.locked = true;
      }
    },
  },
  computed: {
    accountLogo: function () {
      return getAccountLogo();
    }
  }
};
</script>

<style scoped>
.account-logo {
  width: 50px;
  height: 50px;
}

.generic-sidebar-reset-password {
  background: #ffffff;
  border-radius: 10px;
  padding: 32px 40px;
}

.forgotpassword-page {
  background-color: #019255;
}

.reset-password {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.label-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.email-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.green-btn {
  background: #019256;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 38px;
}

.white-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 14px;
}

.green-btn, white-btn:hover {
  opacity: 0.9;
}

</style>
