<script>
import smtpNoReplyEmail from "../components/settings/setting-smtp-no-reply-email";
import personalInformation from "../components/settings/setting-personalInformation.vue";
import emailSignature from "../components/settings/setting-emailsignature.vue";
import resetPassword from "../components/settings/setting-resetPassword.vue";
import profilePreferences from "../components/settings/setting-profilePreferences.vue";
import profileIntegrations from "../components/settings/setting-profileIntegrations.vue";
import settingEmailNotification from "../components/settings/setting-emailNotification.vue";

export default {
  components: {
    smtpNoReplyEmail,
    personalInformation,
    emailSignature,
    resetPassword,
    profilePreferences,
    profileIntegrations,
    settingEmailNotification
  },
  async mounted() {
    await this.$A.UpdateCurrentSubscriptionState();
  },
};
</script>

<template>
  <div class="nav-margin">
    <navigation></navigation>
    <div>
      <b-tabs
          active-nav-item-class="font-weight-normal border-bottom border-success border-left-0 border-right-0 border-top-0 text-success"
          active-tab-class="text-success"
          nav-class="bg-white pt-0 d-flex"
          content-class="container-fluid px-0"
          justified
      >
        <b-tab title="My Information" active>
          <template #title>
            <span class="font-weight-normal w-50">My Information</span>
          </template>
          <personalInformation/>
        </b-tab>
        <b-tab title="Email Settings">
          <template #title>
            <span class="font-weight-normal w-50">Email Settings</span>
          </template>
          <settingEmailNotification/>
          <emailSignature/>
          <smtp-no-reply-email :is-account-settings="false"/>
        </b-tab>
        <b-tab title="Preferences">
          <template #title>
            <span class="font-weight-normal w-50">Preferences</span>
          </template>
          <profilePreferences/>
        </b-tab>
        <!-- <profileIntegrations/> will come back to this -->
        <b-tab title="Password">
          <template #title>
            <span class="font-weight-normal w-50">Password</span>
          </template>
          <resetPassword/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<style scoped>

::v-deep .nav-tabs .nav-link {
  margin-bottom: -2px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

::v-deep .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 0;
  list-style: none;
}

::v-deep .nav > li > a {
  position: relative;
  display: block;
  padding: 19px 15px;
  font-weight: bold;
  color: #444444;
}

@media (max-width: 420px) {
  ::v-deep .nav > li > a {
    padding: 10px 10px !important;
  }
}

@media (max-width: 750px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    align-items: end;
  }
}

@media (min-width: 1500px)  and (max-width: 1700px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1998px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
}

@media (min-width: 1998px) and (max-width: 2194px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 500px !important;
    padding-right: 500px !important;
  }
}

@media (min-width: 2194px) and (max-width: 4000px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 600px !important;
    padding-right: 600px !important;
  }
}


</style>

