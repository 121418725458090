<template>
  <div>
    <navigation></navigation>
    <div class="container-fluid nav-margin">
      <div class="row mt-50">
        <div class="col-md-3">
          <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/oxZeWTViysQ?modestbranding=1&autohide=1&showinfo=0&controls=0”"
              allowfullscreen
          ></b-embed>
          <!--Options Sidebar -->
          <div class="sidebar options-sidebar workflowitem-sidebar">
            <h4>Email Templates</h4>
            <p>
              You can change the text on automated reminder emails in Ashore, or
              create your own templates that you can load in messages or
              workflows.
            </p>
            <h5 class="mt-25">BCC Users (Non-Approvers)</h5>
            <p>
              You can add Ashore users to the BCC line at each approval stage.
              This will allow them to see every status update to proofs assigned
              to this workflow.
            </p>
            <hr
                v-if="template.name !== 'Automatic Reminder' && template.id > 0"
            />
            <b-button
                v-if="template.name !== 'Automatic Reminder' && template.id > 0"
                v-b-modal.deleteTemplateModal
                class="button button-dark button-outline button-block button-delete"
            >
              Delete Item?
            </b-button>
          </div>

          <div class="sidebar options-sidebar">
            <h4>Template Variables</h4>
            <p>You can use the following variables in your email template:</p>

            <div class="code-example">
              <code v-pre>
                {{ proofName }}
              </code>
              <p>Show the proof's name</p>
              <code v-pre>
                {{ dueDate }}
              </code>
              <p>Show the proof's due date</p>
              <code v-pre>
                {{ proofStatus }}
              </code>
              <p style="margin-bottom: 5px">
                Show the approval status of the proof
              </p>
              <p>Example: "Waiting" or "Approved"</p>
              <code v-pre>
                {{ currentStageStatus }}
              </code>
              <p style="margin-bottom: 5px">
                Show the approval status of the current stage
              </p>
              <p>Example: "Waiting" or "2/3 Approvals"</p>
              <code v-pre>
                {{ approverName }}
              </code>
              <p>Show the approver's name</p>
              <code v-pre>
                {{ approverEmail }}
              </code>
              <p>Show the approver's email</p>
              <code v-pre>
                {{ reminderFrequency }}
              </code>
              <p>Show the days the approver will receive reminders</p>
              <code v-pre>
                {{ timeOfDayToSend }}
              </code>
              <p>Show the time when the approver will receive reminders</p>
              <code v-pre>
                {{ proofThumbNailUri }}
              </code>
              <p style="margin-bottom: 5px">
                Shows the proof thumbnail image URL
              </p>
            </div>
          </div>
          <!-- /Options Sidebar -->
        </div>

        <div class="col-md-9">
          <div class="col-md-12">
            <div class="field-item" id="template_name">
              <div class="row">
                <div class="col-md-12">
                  <h3>Template Name</h3>
                </div>
              </div>
              <div class="row mt-25">
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                        type="text"
                        name="templateName"
                        v-model="template.name"
                        class="form-control"
                        placeholder="Email Template Name"
                        :disabled="isEditable"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <!-- Field -->
            <div id="template_bcc" class="field-item">
              <selectNonApprovers ref="selectNonApprovers"/>
              <div class="row">
                <div class="col-md-6 mt-10">
                  <input
                      type="text"
                      name="templateName"
                      class="form-control"
                      placeholder="Subject Line"
                      v-model="template.subject"
                  />
                </div>
              </div>
              <div class="mt-10" id="template_message">
                <redactor v-model="template.html"
                          ref="redactorTextArea"
                          preview="true"
                          :subject="template.subject"
                          @preview="preview"/>
                <preview-email-template ref="previewModal" :html-body="previewHtml"/>
              </div>
              <div class="mt-10" style="color: red;" v-if="template.error!==undefined">
                {{ template.error }}
              </div>
            </div>
            <!-- /Field -->
          </div>

          <div class="col-md-12">
            <a
                class="button button-light button-green normal-button"
                @click="save()"
            >Save</a
            >
          </div>
        </div>
      </div>
    </div>
<!--    <copyright/>-->
    <modal
        name="deleteTemplateModal"
        eventToEmitOnConfirmation="template_item_page::delete::template::confirmed"
        question="Delete This Template?"
        body="Are you sure you want to delete this template? You cannot undo this action."
        confirm-button-text="Yes, Delete"
    />
    <v-tour
        name="templateTour"
        :steps="steps"
        :callbacks="tourCallbacks"
    ></v-tour>
  </div>
</template>

<style scoped>
.code-example code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace;
  background: rgba(95, 193, 255, 0.1);
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  display: block;
}

.code-example p {
  opacity: 0.8;
  font-size: 10px;
}

.field-item h3 {
  font-size: 18px;
}

.workflowitem-sidebar {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>

<script>
import selectNonApprovers from "../components/form/selectNonApprovers.vue";
import redactor from "../components/form/redactor.vue";
import PreviewEmailTemplate from '../components/modals/modal-previewemailtemplate';

export default {
  components: {
    redactor,
    selectNonApprovers,
    PreviewEmailTemplate,
  },
  computed: {
    isEditable() {
      return this.template.name === "Automatic Reminder";
    },
  },
  data() {
    return {
      user: this.$A.Auth,
      template: {
        name: "",
        subject: "",
        bccUserIds: [],
      },
      previewHtml: "",
      steps: [
        {
          target: "#template_name",
          content: `Before you create your first email template, here’s what you need to know. First, every email template needs a good name.`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#template_message",
          content:
              "You can customize the email message that will be sent to contacts here. Once this email template is saved, you can append it anytime you send a message in Ashore.",
          params: {
            placement: "right",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("template-item");
        },
      },
    };
  },
  async mounted() {
    let self = this;

    if (this.user.productTourState.indexOf("template-item") === -1) {
      this.$tours["templateTour"].start();
    }

    if (
        this.$A.viewToViewData != null &&
        this.$A.viewToViewData.template !== undefined
    ) {
      this.template = this.$A.viewToViewData.template;
      this.$refs.redactorTextArea.setTextBody(this.template.html);
      await this.$refs.selectNonApprovers.setSelectedUsers(
          this.template.bccUserIds
      );
    }

    this.$root.$on(
        "template_item_page::delete::template::confirmed",
        async function () {
          if (self.template.id !== undefined && self.template.id != null) {
            await self.$A.TemplateService.Delete(self.template.id);
            setTimeout(function () {
              self.$A.NavigateToView("/automation-archive");
            }, 1000);
          }
        }
    );
  },
  methods: {
    save: async function () {
      let t = this.template;
      this.template.error == undefined;

      t.html = this.$refs.redactorTextArea.getTextBody();

      t.bccUserIds = this.$refs.selectNonApprovers.getIdArrayOfSelectedUsers();

      if (!Array.isArray(t.bccUserIds)) {
        t.bccUserIds = [];
      }

      let response = await this.$A.TemplateService.Save(t);
      if (response.id !== undefined && response.id > 0) {
        this.template = response;
      } else {
        this.template.error = response.message;
      }
    },
    preview: async function () {
      this.template.html = this.$refs.redactorTextArea.getTextBody();
      let response = await this.$A.EmailService.GetEmailPreview(this.template.subject, this.template.html);
      if (response) {
        this.previewHtml = response;
        this.$refs.previewModal.open();
      }
    },
  },
};
</script>
