<script>
import tags from "../../components/form/tags.vue";

export default {
  name: "modalEditTags",
  components: {
    tags,
  },
  props: {
    proof: {
      required: true,
      type: Object,
    },
  },
  methods: {
    saveTags: async function () {
      let proof = this.$props.proof;
      let curTags = this.$props.proof.tags;
      let updatedTags = this.$refs.tags.getSelectedTags();

      for (let i = 0; i < curTags.length; i++) {
        let tagShouldBeUnlinked = true;
        for (let h = 0; h < updatedTags.length; h++) {
          if (curTags[i].id === updatedTags[h].id) {
            tagShouldBeUnlinked = false;
            updatedTags[h].alreadyExists = true;
          }
        }
        if (tagShouldBeUnlinked) {
          await this.$A.TagsService.UnlinkProofByTaggedItemId(
              curTags[i].id,
              proof.id,
              1,
              true
          );
        }
      }

      for (let h = 0; h < updatedTags.length; h++) {
        let tag = updatedTags[h];
        if (!tag.alreadyExists) {
          await this.$A.TagsService.LinkProof(tag.id, proof.id, false);
        }
      }
      this.$root.$emit("proof::tags::updated", updatedTags);
      this.$bvModal.hide("EditTags");
    },
    openModal: function () {
      this.$bvModal.show("EditTags");
      let self = this;
      setTimeout(function () {
        let tagIdsArray = [];
        self.$props.proof.tags.forEach(function (t) {
          tagIdsArray.push(t.id);
        });
        self.$refs.tags.setCurrentTags(tagIdsArray);
      }, 10);
    },
  },
};
</script>

<template>
  <!-- Edit Tag -->
  <b-modal id="EditTags" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <h4>Add this Proof to a Tag</h4>
        Use tags to categorize proofs.
      </div>
    </template>

    <div role="form">
      <div class="row">
        <div class="col-md-12">
          <tags ref="tags"/>
        </div>
      </div>
    </div>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            class="button gray-btn"
            @click="cancel()">
          Cancel
        </b-button>
        <b-button
            class="button green-btn"
            @click="saveTags()"
        >Update
        </b-button
        >
      </div>
    </template>
  </b-modal>
  <!-- / Edit Tag -->
</template>


<style scoped>

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  color: white !important;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}


@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
  }

}


</style>