<script>
export default {
  name: "selectSender",
  props: ["SelectedSenderId", "index"],
  data() {
    return {
      instanceId: this.$A.GetUUID(),
      accountUsers: [],
      senderId: 0,
    };
  },
  methods: {
    getSender: function () {
      return this.senderId;
    },
  },
  async mounted() {
    let accountUsers = await this.$A.AccountService.All();
    if (Array.isArray(accountUsers)) {
      this.accountUsers = accountUsers;
    }
    // console.log(this.$props.SelectedSenderId);
    this.senderId = this.$props.SelectedSenderId;
  },
  watch: {
    SelectedSenderId: function (newval, oldval) {
      this.senderId = newval;
    },
  },
};
</script>

<template>
  <v-select
      :id="instanceId"
      required="required"
      v-model="senderId"
      label="name"
      :reduce="sender => sender.id"
      class="sender-select"
      :data-cy="`selectSender-${index}`"
      :options="accountUsers"
  >
    <template #option="{name, profileImageUrl}">
      <div class="d-flex align-center">
        <b-avatar :src="profileImageUrl" class="sender-avatar"/>
        <div class="sender-text"> {{ name }}</div>
      </div>
    </template>

    <template #selected-option="{name, profileImageUrl}">
      <div class="d-flex align-center">
        <b-avatar :src="profileImageUrl" class="sender-avatar"/>
        <div class="sender-text"> {{ name }}</div>
      </div>
    </template>

  </v-select>
</template>


<style scoped>

.sender-select {
  margin-bottom: 10px;
}

.sender-select ::v-deep .vs__dropdown-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

/deep/ .vs__selected {
  margin: 0px !important;
  padding: 0px !important;
}

/deep/ .vs__actions {
  padding: 0px !important;
}

/deep/ .vs__open-indicator {
  margin-top: 3px !important;
}

.sender-avatar {
  padding: 0px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 12px;
}

.sender-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
  padding-top: 2px;
}

</style>