<script>
import modalUploadImage from "../modals/modal-upload-image";

export default {
  name: "personalInformation",
  components: {
    modalUploadImage,
  },
  data() {
    return {
      user: this.$A.Auth,
      isMobile: window.innerWidth < 991,
      emailChanged: false
    };
  },
  methods: {
    showUploadImageModal: function () {
      this.$refs.uploadImageModal.openModal();
    },
    updateUserInfo: async function () {
      const curUser = _.get(this, 'user', {});
      let user = await this.$A.UserService.UpdateUser(
          curUser.id,
          {
            name : curUser.name,
            email : curUser.email,
            phone : curUser.phone,
            title : curUser.title,
          }
      );
      if (user && user.id > 0) {
        this.$A.Auth = user;
        this.$A.SaveSessionData(this.$A.Auth);
      }
      this.emailChanged = false;
    },
    setEmailChanged: function () {
      this.emailChanged = true
    }
  },
  mounted() {
    let self = this;
    this.$root.$on("profile::photo::updated", function (image) {
      _.set(self, 'user.profileImageUrl', image);
    });
  },
};
</script>

<template>
  <!-- Field -->
  <div class="on-mobile text-dark d-flex justify-content-center justify-content-md-center flex-wrap pb-5">
      <!-- Profile Photo -->
      <div class="col-md-5 col-lg-5 mt-3 ml-lg-4 p-2">
        <b-card class="field-item p-0 overflow-hidden">
          <b-card-text class="pt-4 px-4 text-dark card-header-text">Profile Photo</b-card-text>
          <b-card-body class="d-flex w-100 px-4 pb-4 align-items-center">
            <div v-if="user" class="profile-image" :style="{ backgroundImage: 'url(' + user.profileImageUrl + ')' }"></div>
          </b-card-body>
          <div class="border-top-0 col-12 org-footer">
            <b-button class="green-btn" @click="showUploadImageModal">Upload Profile Photo</b-button>
          </div>
        </b-card>
      </div>
      <!-- My Information -->
      <div class="col-md-6 col-lg-5 mt-3 ml-lg-5 p-2">
        <b-card class="field-item p-0 border">
          <b-card-text class="px-4 pt-4 mb-0 text-dark card-header-text">My Information</b-card-text>
          <b-card-body class="px-4 py-4">
            <div class="form-group">
              <label class="header-text">Name</label>
              <input
                  v-if="user"
                  type="text"
                  name="profileName"
                  class="form-control profile-input mb-3 mt-0"
                  placeholder="Your Name"
                  v-model="user.name"
              />
            </div>
            <!-- Email -->
            <div class="form-group">
              <label class="header-text">Email</label>
              <div class="form-group">
                <input
                    v-if="user"
                    type="text"
                    name="profileEmail"
                    class="form-control profile-input mb-3"
                    placeholder="Your Email"
                    v-model="user.email"
                    @change="setEmailChanged"
                />
              </div>
            </div>
            <div class="alert-banner d-flex justify-content-start mb-2" v-if="emailChanged">
               <img src="/img/not-verified-icon.svg" class="not-verified-icon mr-2 mt-1" />
               <span class="mb-2" style="color: #A62934">Updating your email address will require re-verification for any existing email settings.</span>
            </div>
            <!-- Phone -->
            <div class="form-group">
              <label class="header-text">Phone</label>
              <input
                  v-if="user"
                  type="text"
                  name="profilePhoneNumber"
                  class="form-control profile-input mb-3"
                  placeholder="Your Phone Number"
                  v-model="user.phone"
              />
            </div>
            <!-- Title -->
            <div class="form-group mb-0">
              <label class="header-text">Title</label>
              <input
                  v-if="user"
                  type="text"
                  name="profileTitle"
                  class="form-control profile-input"
                  placeholder="Your Title"
                  v-model="user.title"
              />
            </div>
          </b-card-body>
          <!-- Save -->
          <div class="org-footer col-12">
            <a data-cy="updatedUserInfoBtn"
               class="green-btn"
               @click="updateUserInfo">
              Update
            </a>
          </div>
        </b-card>
      </div>
      <modalUploadImage type="profile" ref="uploadImageModal"/>

  </div>
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

.field-item {
  height: fit-content !important;
}

.profile-image {
  width: 191px;
  height: 191px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.green-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019255;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.green-btn:hover {
  opacity: 0.9;
}

.profile-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.org-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 24px;
  background: #F4F4F6;
  height: 62px;
}

::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 345px !important;
  padding: 0rem;
}

.card-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.card-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

/deep/ label.header-text {
  opacity: 1;
}

.alert-banner {
  background: #FBE2E4;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #172D19;
  padding: 16px;
  margin-top: 4px;
}

.not-verified-icon {
  height: 16px;
  width: 16px;
  vertical-align: center
}


</style>
