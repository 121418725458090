<script>
export default {
  name: "editPropertyModal",
  data: function () {
    return {
      editingId: null,
      editingLabel: "",
      editingAccountId: null,
      propertiesTypes: [
        'Single Line Text',
        'Datepicker',
        'Dropdown Select',
        'Checkboxes (Multiple Options)'
      ],
      propertySelected: "Single Line Text",
      dropdownOptions: ["", ""],
      checkboxOptions: ["", ""],
      propertyOptions: [],
      errors: [],
      validated: false,
      notEditable: false,
      lockedOptionsCount: 0,
    };
  },
  methods: {
    checkForm: function () {
      this.errors = [];
      this.validated = true;
      if (!this.editingLabel) {
        this.errors.push("Label required.");
      }
      if (this.propertySelected === 'Dropdown Select' || this.propertySelected === 'Checkboxes (Multiple Options)') {
        if (this.propertyOptions.length < 2) {
          this.errors.push("More than two options required with a label");
          this.$A.AlertUser("Please Input At Least Two Options");
        }
        let hasEmptyOptions = this.propertyOptions.filter(opt => !opt || opt === "").length > 0;
        if (hasEmptyOptions) {
          this.errors.push("All options require a label");
          this.$A.AlertUser("Please Enter a Valid Input for All Options");
        }
        if (this.hasDuplicatedOptions()) {
          this.errors.push("Please Enter a Valid Input for All Options");
          this.$A.AlertUser("Please Enter a Valid Input for All Options");
        }
      }
      if (!this.errors.length) {
        return true;
      }
    },
    resetEditingLabel: function () {
      this.validated = false;
      this.editingLabel = "";
      this.editingId = null;
      this.editingAccountId = null;
      this.propertyOptions = [];
      this.errors = [];
    },
    editProperty: function (property, notEditable) {
      notEditable ? this.notEditable = true : this.notEditable = false
      this.resetEditingLabel();
      this.editingId = property.id;
      this.editingLabel = property.label;
      this.editingAccountId = property.accountId;
      this.propertySelected = property.dataType || this.propertySelected;
      this.propertyOptions = property.enum ? property.enum : [];
      this.lockedOptionsCount = this.propertyOptions.length;
      this.$bvModal.show("editProperty");
    },
    saveProperty: async function () {
      if (this.checkForm()) {
        let propertyCreated = await this.$A.AccountService.SaveCustomProperty(
            this.editingLabel, this.propertySelected, this.propertyOptions, this.editingId, this.editingAccountId
        );
        this.$emit("property_user_updated", propertyCreated);
        this.$bvModal.hide("editProperty");
        this.resetEditingLabel();
        this.$emit("propertySaved")
      }
    },
    addPropertyOption: function () {
      this.propertyOptions.push("");
    },
    deletePropertyOption: function (index) {
      let options = this.propertyOptions;
      if (options.length > 2) {
        return this.propertyOptions.splice(index, '1');
      }
    },
    hasDuplicatedOptions: function () {
      let options = this.propertyOptions
      let uniqueOptions = new Set(Object.values(options));
      return (uniqueOptions.size < options.length);
    },
  },
  computed: {}
};
</script>

<template>
  <b-modal id="editProperty" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <span class="header-text-card pb-0">{{ notEditable ? 'Edit' : 'Create ' }} Property</span>
      </div>
    </template>
    <p v-show="errors && errors.length" class="text-danger">{{ errors[0] }}</p>
    <form role="form" @submit="checkForm">
      <div class="form-group">
        <input
            name="label"
            type="text"
            placeholder="Label"
            :class="'property-form-input form-control ' + (validated ? (editingLabel !== '' ? 'is-valid':'is-invalid') : (!validated && editingLabel !== '' ? 'is-valid':'') + (notEditable ? ' input-not-editable':''))"
            v-model="editingLabel"
            maxlength="50"
            required
        />
        <div v-show="editingLabel === ''"
             class="invalid-feedback ml-1">
          Please provide a label.
        </div>
        <!--  Properties Types Dropdown -->
        <v-select
            v-model="propertySelected"
            :options="propertiesTypes"
            :searchable="false"
            :clearable="false"
            placeholder="Please select a property type"
            :class="'property-form-input mt-3 ' + (notEditable ? 'input-not-editable':'')"
        >
        </v-select>
        <!--  Dropdown Options  -->
        <div v-show="propertySelected === 'Dropdown Select'" class="ml-1" style="padding-top: 16px">
          <span class="header-text">Dropdown Options</span>
          <div
              class="d-flex justify-content-between"
              v-for="(option, index) in propertyOptions"
          >
            <input
                name="label"
                type="text"
                :placeholder="`Label ${index+1}`"
                :class="'mt-3 mr-2 property-form-input form-control '
                + (validated ? (editingLabel !== '' && propertyOptions[index]!== '' ? 'is-valid':'is-invalid') : (!validated && editingLabel !== '' ? 'is-valid':'')
                + ((index < lockedOptionsCount) ? ' disabled-input': ''))"
                v-model="propertyOptions[index]"
                maxlength="40"
                :disabled="index < lockedOptionsCount"
                required
            />
            <b-icon
                v-show="index >= lockedOptionsCount"
                icon="trash"
                variant="danger"
                @click="deletePropertyOption(index)"
                v-model="propertyOptions[index]"
                class="delete-icon"
            >
            </b-icon>
          </div>
          <b-button class="gray-btn ml-auto mt-3" @click="addPropertyOption()">
            Add Dropdown Option
          </b-button>
        </div>
        <!--  Checkboxes (Multiple Options)  -->
        <div v-show="propertySelected === 'Checkboxes (Multiple Options)'" class="ml-1" style="padding-top: 16px">
          <span class="header-text">Checkbox Options</span>
          <div
              class="d-flex justify-content-between"
              v-for="(option, index) in propertyOptions"
          >
            <input
                name="label"
                type="text"
                :placeholder="`Label ${index+1}`"
                :class="'mt-3 mr-2 property-form-input form-control '
                + (validated ? (editingLabel !== '' ? 'is-valid':'is-invalid') : (!validated && editingLabel !== '' ? 'is-valid':'') + ((index < lockedOptionsCount) ? ' disabled-input': ''))"
                v-model="propertyOptions[index]"
                maxlength="30"
                :disabled="index < lockedOptionsCount"
                required
            />
            <b-icon
                v-show="index >= lockedOptionsCount"
                icon="trash"
                variant="danger"
                @click="deletePropertyOption(index)"
                v-model="propertyOptions[index]"
                class="delete-icon"
            >
            </b-icon>
          </div>
          <b-button class="gray-btn w-md-25 w-sm-100 ml-auto mt-2" @click="addPropertyOption()">
            Add Checkbox Option
          </b-button>
        </div>
      </div>
    </form>
    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button class="cancel-btn cancel-btn-text mr-3" @click="cancel()">Cancel</b-button>
        <b-button class="submit-btn green-btn" @click="saveProperty()">Submit</b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.header-text-card {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.submit-btn, cancel-btn {
  padding: 9px 17px;
  width: 130px;
  height: 38px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.cancel-btn-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 108px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.cancel-btn-text:hover {
  color: #F9F9F9;
}

.green-btn {
  background: #019256;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 130px;
  height: 38px;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 14px;
}

.delete-icon {
  width: 22px;
  height: 22px;
  margin-top: 23px;
}

.disabled-input {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}

.delete-icon:hover {
  cursor: pointer;
}

.property-form-input {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  display: block;
  width: 100%;
  height: 37px;
  padding: 2px 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #444444;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid lightgray;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 5px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

::v-deep .property-form-input .vs__dropdown-toggle {
  border: 0px;
}

::v-deep .modal-header {
  padding-bottom: 0px;
}

.input-not-editable {
  pointer-events: none;
  background-color: #e9ecef !important;
  opacity: 1 !important;
}

</style>
