<script>
import proofToggles from "../../components/global/proof-toggle";
import modalEditTags from "../../components/modals/modal-edittags.vue";
import thumbnails from "../../components/proof-item/thumbnails.vue";
import editSideBarPropertyModal from "../modals/modal-edit-sidebar-property";
import createWorkflowItem from "../automation-archive/create-workflow-item";
import _ from 'lodash';
import { PROOF_ACCESS_PERMISSIONS } from "../../utils/constants";

const { EVERYTHING } = PROOF_ACCESS_PERMISSIONS;

export default {
  name: "sidebarproof",
  props: {
    proof: {
      required: true,
      type: Object,
    },
    user: {
      required: true,
      type: Object,
    },
    teams: {
      required: true,
      type: Array
    },
    exportTimelineButtonLink: "",
  },
  components: {
    proofToggles,
    modalEditTags,
    thumbnails,
    editSideBarPropertyModal,
    createWorkflowItem
  },
  methods: {
    saveProof: function () {
      this.$root.$emit("proof::save");
    },
    emitExportTimelineButtonClick: function ($event) {
      if (this.$props.exportTimelineButtonLink && this.$props.exportTimelineButtonLink.length > 0) {
        $A.NavigateToView(this.$props.exportTimelineButtonLink, {});
      }
      this.$emit("exportTimeline", $event);
    },
    editProperty: function (prop) {
      this.$refs.editSideBarPropertyModal.editProperty(this.proof.id, prop);
    },
    getAllProperties: async function () {
      this.properties = await this.$A.AccountService.GetAllCustomProofProperties();
    },
    toggleSideBar: function () {
      this.$root.$emit("toggle::edit::workflow::sidebar");
    },
    displaySendMessageModal: function () {
      this.$root.$emit("show::send::message::modal", 1);
    }
  },
  computed: {
    canDeleteProof: function () {
      return _.get(this, 'user.permissions.proofAccess') === EVERYTHING;
    },
    teamName: function () {
      return _.get(this.proof, 'currentVersion.ashoreUsersTeam.name', 'No Team Assigned');
    },
    teamsWithViewAccess: function () {
      return _.get(this.proof, 'teamsWithViewAccess', []);
    },
    proofTeams: function () {
      return this.teams.filter(t => this.teamsWithViewAccess.includes(t.id));
    }
  },
};
</script>

<template>
  <div class="col-xl-4 col-xxl-3 side-right-padding">
    <!--Sidebar -->
    <div class="proof-timeline-sidebar">
      <div class="sidebar proof-sidebar p-4">
        <div class="d-flex flex-column gap-24">
          <div class="link-item d-flex justify-content-between mt-0">
            <div class="sidebar-header-text">{{ proof.name }}</div>
            <a
              v-b-modal.modalEditName
              class="edit-button"
              data-cy="editProofNameBtn"
            >
              <img src="/img/pencil-icon-new.svg" alt="edit" />
            </a>
          </div>

          <div class="proof-item-thumbnails" id="proof_thumbnail">
            <thumbnails :proof="proof" />
          </div>
          <div class="d-flex flex-column gap-12">
            <!-- Proof Name -->
            <p class="proof-item-text">
              Proof #{{ proof.id
              }}<span v-show="proof.archived"> - Archived</span>
            </p>
            <!-- Proof Files Number -->
            <p data-cy="proof_items_length" class="proof-item-text">
              {{ proof.currentVersion.versionFiles.length }} File{{
            proof.currentVersion.versionFiles.length > 1 ? "s" : ""
              }}
            </p>
            <!-- Proof Due Date -->
            <div class="link-item text-gray-600 mt-0">
              <span data-cy="proof_due_date" class="proof-item-text"
                >Due
                {{
              $A.FormatDateToString(proof.dueDate, true)
                }}</span
              >
              <a v-b-modal.EditDeadline class="edit-button">
                <img src="/img/pencil-icon-new.svg" />
              </a>
            </div>
            <!-- Proof Owned -->
            <div class="link-item text-gray-600 mt-0">
              <span class="proof-item-text"
                >Owned by {{ proof.currentVersion.sender.name }}</span
              >
              <a v-b-modal.EditProofOwner class="edit-button">
                <img src="/img/pencil-icon-new.svg" />
              </a>
            </div>
            <!-- Proof Teams -->
            <div class="link-item text-gray-600 mt-0" v-if="teams && teams.length > 0">
              <span data-cy="proof_teams" class="text-bold">Teams</span>
              <a v-b-modal.EditProofTeam class="edit-button">
                <img src="/img/pencil-icon-new.svg" />
              </a>
            </div>
            <div class="link-items">
              <a class="mb-0" v-for="team in proofTeams">{{ team.name }}</a>
            </div>
            <!-- @click="$A.NavigateToView('/proof-archive', { team: team })" -->
            <!-- Proof Tags -->
            <div class="link-item text-gray-600 mt-0">
              <span data-cy="proof_tags" class="text-bold">Tags</span>
              <a @click="$refs.editProofTags.openModal()" class="edit-button">
                <img src="/img/pencil-icon-new.svg" />
              </a>
            </div>
            <div class="link-items">
              <a
                class="mb-0"
                @click="$A.NavigateToView('/proof-archive', { tag: tag })"
                v-for="tag in proof.tags"
                >{{ tag.name }}</a
              >
            </div>
            <!-- Custom Labels -->
            <div v-for="prop in proof.customFields">
              <div class="link-item text-gray-600 mt-0">
                <span
                  data-cy="proof_tags"
                  class="text-bold"
                  >{{ prop.accountProofPropertyLabel }}</span
                >
                <a @click="editProperty(prop)" class="edit-button">
                  <img src="/img/pencil-icon-new.svg" />
                </a>
              </div>
              <div class="link-items mt-2">
                <a
                  data-cy="proof_tags"
                  class="enum-text mb-0"
                  v-if="prop.accountProofPropertyDataType === 'Checkboxes (Multiple Options)'"
                  v-for="value in prop.proofPropertyValue"
                >
                  {{ value }}
                </a>
                <span
                  data-cy="proof_tags"
                  class="proof-item-text"
                  v-if="prop.accountProofPropertyDataType === 'Single Line Text'"
                  v-for="value in prop.proofPropertyValue"
                >
                  {{ value }}
                </span>
                <span
                  data-cy="proof_tags"
                  class="proof-enum-text"
                  v-if="prop.accountProofPropertyDataType === 'Dropdown Select'"
                  v-for="value in prop.proofPropertyValue"
                >
                  {{ value }}
                </span>
                <span
                  data-cy="proof_tags"
                  class="proof-item-text"
                  v-if="prop.accountProofPropertyDataType === 'Datepicker'"
                  v-for="value in prop.proofPropertyValue"
                >
                  {{ $A.FormatDateToString(value, true) }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column gap-2">
            <!-- Get Review Link -->
            <a
              id="proof_link"
              v-b-modal.reviewLink
              v-show="!proof.archived"
              class="button proof-item-green-btn mb-0"
            >
              Get Review Link
            </a>
            <!-- Edit Workflow -->
            <a
              v-show="!proof.archived"
              class="button proof-item-green-btn mb-0"
              @click="
            proof.archived
              ? $A.AlertUser('Proof Is Archived. Can not Edit.')
              : toggleSideBar()
          "
            >
              Edit Workflow
            </a>
            <!-- Workflow Item -->
            <!-- <create-workflow-item :input-proof="proof"/>-->

            <!-- Send New Version -->
            <a
              id="proof_version"
              v-show="!proof.archived"
              v-b-modal.SendNewVersion
              class="button proof-item-gray-btn mb-0"
            >
              Send New Version
            </a>
            <!-- View Proof -->
            <a
              id="proof_view"
              class="button proof-item-gray-btn mb-0"
              @click="$A.ProofService.NavigateToProofReviewScreen(proof,true)"
            >
              View Proof
            </a>
            <!-- Send Message -->
            <a
              id="proof_message"
              v-show="!proof.archived"
              v-b-modal.UpdatedProofOptions
              @click="displaySendMessageModal()"
              class="button proof-item-white-btn mb-0"
            >
              Send Message
            </a>
            <!-- Add Note to Timeline -->
            <a
              v-b-modal.AddTimelineNote
              class="button proof-item-white-btn mb-0"
            >
              Add Note to Timeline
            </a>
            <!-- Manage Versions -->
            <a
              id="manage_proof_files"
              v-show="!proof.archived"
              v-b-modal.ManageFiles
              class="button proof-item-white-btn mb-0"
            >
              Manage Versions
            </a>
            <!-- Export Timeline -->
            <a
              id="export_timeline"
              class="button proof-item-white-btn mb-0"
              @click="emitExportTimelineButtonClick($event)"
            >
              Export Timeline
            </a>
            <a
              v-show="proof.archived"
              v-b-modal.restoreProofModal
              class="button proof-item-green-btn mb-0"
            >
              Restore Proof
            </a>
            <a
              v-show="proof.archived && canDeleteProof"
              v-b-modal.deleteProofModal
              class="button proof-item-red-btn mb-0"
            >
              Delete Proof
            </a>
            <a
              v-show="!proof.archived"
              v-b-modal.archiveProofModal
              class="button proof-item-white-btn mb-0"
            >
              Archive
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--Options Sidebar -->
    <div class="sidebar options-sidebar p-4" id="proof_options">
      <div class="options-header-text mb-3">Proof Options</div>
      <div class="options-sub-text mb-2">Allow:</div>
      <proofToggles :proof="proof" />
    </div>
    <!-- /Options Sidebar -->
    <!-- /Sidebar -->
    <modalEditTags :proof="proof" ref="editProofTags" />
    <editSideBarPropertyModal
      ref="editSideBarPropertyModal"
      v-on:propertyUpdated="getAllProperties"
    />
  </div>
</template>


<style scoped>

.options-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.options-sub-text {
  ont-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.sidebar-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #575759;
}

.proof-item-thumbnails {
  isolation: isolate;
  border-radius: 8px;
  width: 100%;
}

.proof-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
  margin-right: 5px;
  margin-bottom: 0px;
}

.enum-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #575759;
  margin-right: 5px;
}

.side-right-padding {
  padding-right: 9px !important;
}

.proof-enum-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.proof-item-green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px 0px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
}

.proof-timeline-sidebar {
  margin-top: 100px;
}

.proof-item-red-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  color: #EB4654;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #F9F9F9;
  border: 1px solid #EB4654;
}

.proof-item-gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px 0px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.proof-item-white-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px 0px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

v-deep .proof-select .vs__dropdown-toggle {
  padding: 0px !important;
  width: 100%;
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.5);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
  margin-top: 0px;
}

v-deep .vs__selected {
  display: flex;
  align-items: center;
  border: 1px solid rgba(60, 60, 60, .26);
  border-radius: 4px;
  color: #333;
  line-height: 1.4;
  margin: 8px 2px 0;
  padding: 0 0.25em;
  z-index: 0;
}

</style>