<script>
import ZapierService from "../services/ZapierService";

export default {
  data() {
    return {
      username: "",
      password: "",
      working: false,
      rememberMe: false,
    };
  },
  mounted() {
    let self = this;

    var rememberMeUser = $A.CheckRememberMe();
    if (rememberMeUser) {
      this.rememberMe = true;
      this.username = rememberMeUser;
    }
    if (
      this.$route.query.state !== undefined &&
      this.$route.query.redirect_uri !== undefined
    ) {
      //wait for loging auth.
    } else {
      $A.NavigateToView("/403");
      return;
    }
    this.$root.$on("alert::user", (message, type, timeout) =>
      // self.AlertUser(message, type)
        self.$A.AlertUser(message, type, timeout)
    );
  },
  methods: {
    async Authorize() {
      if (this.working) {
        return;
      }
      let c = this;
      if (this.rememberMe) {
        $A.SetRememberMe(this.username);
      }
      this.working = true;
      let authResponse = await $A.ZapierService.AuthorizeAccess(
        this.username,
        this.password,
        this.$route.query.redirect_uri,
        this.$route.query.state
      );
      if (authResponse.data.success) {
        this.$A.AlertUser(
          "You have successfully authorized Zapier, Please Wait To Be Redirected Back To Zapier",
          "success"
        );
        setTimeout(function () {
          console.log(
            "redirecting back to zapier",
            c.$route.query.redirect_uri +
              "?code=" +
              authResponse.data.authApprovalCode +
              "&state=" +
              c.$route.query.state
          );
          document.location.href =
            c.$route.query.redirect_uri +
            "?code=" +
            authResponse.data.authApprovalCode +
            "&state=" +
            c.$route.query.state;
        }, 5000);
      } else if (
        authResponse.data.message !== undefined &&
        authResponse.data.message === "Invalid Credentials"
      ) {
        this.$A.AlertUser("Your username or password is incorrect.");
      } else {
        this.$A.AlertUser("There was an issue that was encountered if you cannot get past this point please contact support.");
      }
      this.working = false;
    },
  },
};
</script>

<style scoped>
.generic-sidebar {
  min-height: 400px;
}
.zapier-logo {
  margin-top: 30px;
  border-radius: 60px;
  overflow: hidden;
  width: 100px;
  height: 100px;
  background: url("/img/zapier/zapier.png");
  background-size: contain;
}
</style>

<template>
  <div>
    <div class="zapier-auth-page">
      <div class="container-fluid">
        <div class="row">
          <!-- Sidebar -->
          <div class="col-lg-4 col-md-5" style="max-height: 362px">
            <div class="generic-sidebar">
              <h2>Zapier Authorization</h2>
              <p>
                To Authorize Zapier To Connect To Your Ashore Account On Your
                Behalf Login With Your Credentials Below:
              </p>
              <div class="form-group">
                <label>Your Email:</label>
                <input
                  type="text"
                  name="Username"
                  class="form-control"
                  v-model="username"
                />
              </div>
              <div class="form-group">
                <label>Your Password:</label>
                <input
                  type="password"
                  name="Password"
                  class="form-control"
                  v-model="password"
                  v-on:keyup.enter="Authorize()"
                />
              </div>
              <button
                v-on:click="Authorize()"
                style="margin-top: 25px"
                class="button button-light button-green button-block"
              >
                <b-spinner
                  variant="success"
                  v-show="working"
                  type="grow"
                  small
                ></b-spinner>
                Login
              </button>
            </div>
          </div>
          <!-- /Sidebar -->
          <!-- Elements -->
          <div class="col-lg-8 col-md-7">
            <div class="registration-logo">
              <img src="/img/logo-large.svg" />
              <div class="zapier-logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
