<script>
import moment from "moment";
import archiveProofModal from "../modals/modal-archive-proof.vue";
import _ from 'lodash';
import { PROOF_ACCESS_PERMISSIONS } from "../../utils/constants";

const { EVERYTHING } = PROOF_ACCESS_PERMISSIONS;
const PROOFS_PER_PAGE = 10;

export default {
  name: "prooflist",
  props: {
    workflowId: {
      type: Number,
      required: false,
    },
    approverId: {
      type: Number,
      required: false,
    },
    proofId: {
      type: Number,
      required: false,
    },
    viewMode: {
      type: Boolean,
      required: false
    },
    contactProofViewMode: {
      type: Boolean,
      required: false
    },
    editWorkflowMode: {
      type: Boolean,
      required: false
    }
  },
  components: {
    archiveProofModal
  },
  watch: {
    proofId: async function () {
      if (this.$props.proofId !== undefined && this.$props.proofId > 0) {
        this.proofs = [];
        this.proofStatusColors = {};
        var self = this;
        await this.$A.ProofService.GetProofsByFilterYieldAll(
            {
              proofId: this.$props.proofId,
            },
            function (results) {
              results.forEach(function (p) {
                self.InitProofStatusColor(p);
                self.proofs.push(p);
              });
            }
        );
      }
    },
    approverId: async function () {
      if (this.$props.approverId !== undefined && this.$props.approverId > 0) {
        let self = this;
        this.proofs = [];
        this.proofStatusColors = {};
        await this.$A.ProofService.GetProofsByFilterYieldAll(
            {
              approverId: this.$props.approverId,
            },
            function (results) {
              results.forEach(function (p) {
                self.InitProofStatusColor(p);
                self.proofs.push(p);
              });
            }
        );
        // this.proofs = await this.$A.ProofService.GetProofsByFilter({
        //   approverId:this.$props.approverId,
        // },1);
      }
    },
    selected: function (newValue) {
      if (newValue.length > 0) {
        if (this.proofs.length === newValue.length) {
          this.selectAllModel = true;
          this.someSelectedModel = false;
        } else {
          this.selectAllModel = false;
          this.someSelectedModel = true;
        }
      } else {
        this.selectAllModel = false;
        this.someSelectedModel = false;
      }
    }
  },
  async mounted() {
    if (this.$props.proofId !== undefined && this.$props.proofId > 0) {
      return;
    }
    if (this.$props.approverId === null || this.$props.workflowId === null) {
      return;
    }
    if (this.$props.workflowId > 0) {
      this.filter.workflowId = this.$props.workflowId;
    }
    if (this.$props.approverId > 0) {
      this.filter.approverId = this.$props.approverId;
    }
    if (
        this.$A.viewToViewData != null &&
        this.$A.viewToViewData.filter !== undefined &&
        this.$A.viewToViewData.filter !== null &&
        this.$A.viewToViewData.filter.status > -1
    ) {
      this.filter.status = this.$A.viewToViewData.filter.status;
      this.$A.SaveCookie("proof::list::filter::update", this.filter, 1 / 48);
    }

    this.$root.$on("proof_archive_page::archive::proof::confirmed::selected", this.ArchiveProofsSelected);
    this.$root.$on("proof_restore_page::restore::proof::confirmed::selected", this.RestoreProofsSelected);
    this.$root.$on("proof_delete_page::delete::proof::confirmed::selected", this.DeleteProofsSelected);

    await this.RegisterSearchTextEventListener();
    await this.RegisterFilterListener();
    await this.LoadProofs(1, false);

    let self = this;
    window.onscroll = function (ev) {
      // if (
      //     // window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      //     !self.isLoadingProofs && self.hasMoreProofs
      // ) {
      //   self.LoadProofs(++self.pageOn, false);
      // }
      // console.log(window.innerHeight, window.scrollY, document.body.offsetHeight)
      self.loadMore();
    };
  },
  methods: {
    loadMore: function () {
      if (!this.isLoadingProofs && this.hasMoreProofs) {
        this.LoadProofs(++this.pageOn, false);
      }
    },
    async RegisterSearchTextEventListener() {
      let self = this;
      this.$root.$on(
          "page::search::value::updated",
          async function (searchValue) {
            self.hasMoreProofs = true;
            self.filter.text = searchValue.toLowerCase();
            await self.LoadProofs(1);
          }
      );
    },
    async RegisterFilterListener() {
      let self = this;
      this.$root.$on("proof::list::filter::update", async function (filter) {
        self.hasMoreProofs = true;
        self.pageOn = 1;
        if (filter.status === undefined || filter.status === null)
          filter.status = 0;
        self.filter.status = filter.status;
        self.filter.proofId = filter.proofId;
        self.filter.senderId = filter.senderId;
        self.filter.workflowId = filter.workflowId;
        self.filter.approverId = filter.approverId;
        self.filter.tagIds = filter.tagIds;
        self.filter.tagFilterId = filter.tagFilterId;
        self.filter.overdue = filter.overdue;
        self.filter.archived = filter.archived;
        self.filter.sortByDeadlineDesc = filter.sortByDeadlineDesc;
        self.filter.customFieldValues = filter.customFieldValues;
        self.filter.teamId = filter.teamId;

        self.$A.SaveCookie("proof::list::filter::update", filter, 1 / 48);
        await self.LoadProofs(1);
      });
    },
    ResetFilters: function () {
      this.filter = {
        proofId: 0,
        senderId: 0,
        workflowId: 0,
        approverId: 0,
        overdue: false,
        archived: false,
        sortByDeadlineDesc: false,
        tagId: 0,
        tagFilterId: 0,
        text: "",
        status: 0
      };
    },
    LoadProofs: async function (page, alert) {
      this.loadProofCalled = true;
      if (this.isLoadingProofs) {
        return;
      }
      this.isLoadingProofs = true;
      if (page === undefined) page = 1;
      if (page === 1) {
        this.proofs = [];
        this.proofStatusColors = {};
      }
      let self = this;

      let proofsInPage = await this.$A.ProofService.GetProofsByFilter(
          this.filter,
          page
      );
      if (!proofsInPage || proofsInPage.length === 0) {
        self.pageOn--;
        self.hasMoreProofs = false;
      } else {
        proofsInPage.forEach(function (p) {
          p.checkbox = false;
          self.InitProofStatusColor(p);
          self.proofs.push(p);
        });
      }
      this.isLoadingProofs = false;
    },
    async RegisterDeleteModalEventListenerForProof(proof) {
      let self = this;
      this.$root.$on(
          "proof_archive_page::delete::proof::confirmed::proof_id_" + proof.id,
          async function () {
            await self.DeleteProof(proof);
          }
      );
    },
    async RegisterRestoreModalEventListenerForProof(proof) {
      let self = this;
      this.$root.$on(
          "proof_archive_page::restore::proof::confirmed::proof_id_" + proof.id,
          async function () {
            await self.RestoreProof(proof);
          }
      );
    },
    DeleteProof: async function (proof) {
      await this.$A.ProofService.Delete(proof.id);
      this.proofs.splice(this.proofs.indexOf(proof), 1);
    },
    ArchiveProof: async function (args) {
      await this.$A.ProofService.Archive(args.proofId, args.note);
      let p = this.proofs.find((value, index, array) => {
        return value.id === args.proofId
      })
      this.proofs.splice(this.proofs.indexOf(p), 1);
    },
    ArchiveProofsSelected: async function (args) {
      const selectedProofs = this.proofs.filter(p => p.checkbox === true);
      const selectedProofIds = selectedProofs.map(p => p.id);
      await this.$A.ProofService.ArchiveProofs(selectedProofIds, args.note);
      this.LoadProofs();
    },
    RestoreProofsSelected: async function () {
      const selectedProofs = this.proofs.filter(p => p.checkbox === true);
      const selectedProofIds = selectedProofs.map(p => p.id);
      await this.$A.ProofService.RestoreProofs(selectedProofIds);
      this.LoadProofs();
    },
    DeleteProofsSelected: async function () {
      const selectedProofs = this.proofs.filter(p => p.checkbox === true);
      const selectedProofIds = selectedProofs.map(p => p.id);
      await this.$A.ProofService.DeleteProofs(selectedProofIds);
      this.LoadProofs();
    },
    SetSelectAll: function (selected) {
      this.proofs.forEach(p => p.checkbox = selected);
      if (selected) {
        this.selected = this.proofs.map(p => p.id);
      } else {
        this.selected = [];
      }
    },
    RestoreProof: async function (proof) {
      await this.$A.ProofService.Restore(proof.id);
      await this.LoadProofs(1, false);
    },
    ViewProof(proof) {
      this.$A.NavigateToView("/proof-item", {
        proof: proof,
      });
    },
    getProofItemLink(proof) {
      return `/proof-item?id=${proof.id}`;
    },
    handleViewProof(event, proof) {
      if (event.metaKey || event.ctrlKey) return;
      else {
        event.preventDefault();
        this.ViewProof(proof);
      }
    },
    InitProofStatusColor(proof) {
      this.proofStatusColors[proof.id] = {};

      if (proof.status === 5) {
        this.proofStatusColors[proof.id].status = 'Approved';
        this.proofStatusColors[proof.id].color = "background-color:#019256";
      }
      if (proof.status === 6) {
        this.proofStatusColors[proof.id].status = 'Approved With Changes';
        this.proofStatusColors[proof.id].color = 'background-color:#019256';
      }
      if (proof.status === 4) {
        this.proofStatusColors[proof.id].status = 'Not Approved';
        this.proofStatusColors[proof.id].color = 'background-color:#EB4654';
      }
      if (proof.status === 1 || proof.status === 2 || proof.status === 3) {
        this.proofStatusColors[proof.id].status = 'Waiting for Review';
        this.proofStatusColors[proof.id].color = 'background-color:#D9B116';
      }
    },
    InitAvatarSize: function (p) {
      let size = '';
      if (proofs[p.id].docTypesUsed.length > 0) {
        size = '15px';
      } else {
        size = '0px';
      }
      return size;
    },
    formatDate: function (value) {
      if (!value) {
        return "N/A";
      }
      return moment(value).format('MMMM D, YYYY');
    },
    formatDateProp: function (prop) {
      if (prop.proofPropertyValue && prop.proofPropertyValue.length > 0) {
        return this.formatDate(prop.proofPropertyValue[0])
      }
      return "N/A";
    },
    formatCheckboxValue: function (prop) {
      if (prop.proofPropertyValue && prop.proofPropertyValue.length > 0) {
        return this.prop.proofPropertyValue
      }
      return "N/A";
    },
    getAvatarSize: function (proof) {
      let fileTypes = {};
      proof.docTypesUsed.forEach(type => fileTypes[type] = true);
      return (Object.keys(fileTypes).length > 1) ? '24px' : '0px';
    },
    isNotSubUser: function () {
      return _.get(this, 'user.permissions.proofAccess') === EVERYTHING;
    }
  },
  data() {
    return {
      instanceId: this.$A.GetUUID(),
      user: this.$A.Auth,
      shouldStopLoading: false,
      isLoadingProofs: false,
      loadProofCalled: false,
      hasMoreProofs: true,
      proofs: [],
      proofPageLoaded: 1,
      pageOn: 1,
      searchFunctionWaitForNoChanges: null,
      statusList: [
        "waiting",
        "sent",
        "viewed",
        "not_approved",
        "approved",
        "creating",
      ],
      filter: {
        proofId: 0,
        senderId: 0,
        workflowId: 0,
        approverId: 0,
        tagId:
            this.$A.viewToViewData !== null &&
            this.$A.viewToViewData.tag !== undefined
                ? this.$A.viewToViewData.tag.id
                : 0,
        tagFilterId:
            this.$A.viewToViewData !== null &&
            this.$A.viewToViewData.tagFilter !== undefined
                ? this.$A.viewToViewData.tagFilter.id
                : 0,
        text: "",
        status: 0,
        overdue: false,
        sortByDeadlineDesc: false,
      },
      proofStatusColors: {},
      selectAllModel: false,
      someSelectedModel: false,
      selected: [],
    }
  },
  computed: {
    anyProofSelected: function () {
      this.selected = this.proofs.filter(p => p.checkbox).map(p => p.id);
      return this.selected.length > 0;
    },
    shouldShowLoadButton: function () {
      return !this.isLoadingProofs && this.hasMoreProofs && this.proofs.length >= PROOFS_PER_PAGE;
    }
  },
}
</script>

<template>
  <div class="list-items" :id="instanceId" v-if="(!viewMode || proofs.length > 0)">
    <b-card :class="proofs.length === 0 && !contactProofViewMode ? 'd-none':'proof-list-card' "
            :style="viewMode ? 'margin-bottom: 15px; padding: 0px!important; background-color:#eef2f5': editWorkflowMode ? 'margin-bottom: 15px; background-color:white':''"
    >
      <!-- Select all + Archive - Restore Selected(s) -->
      <div v-if="!viewMode" class="visible-check-group">
        <b-form-checkbox
            class="proof-check-selected mt-3"
            v-model="selectAllModel"
            :indeterminate="someSelectedModel"
            @change="SetSelectAll"
            v-show="proofs.length > 0"
        >
          <label class="visible-text" v-show="proofs.length > 0">Select all visible</label>
        </b-form-checkbox>
        <!-- Archive Selected(s) -->
        <div class="d-flex ml-auto">
          <b-button
              v-b-modal="'multipleArchiveProofModal'"
              v-show="anyProofSelected && !filter.archived"
              class="button-outline-red"
              style="padding: 9px 17px;"
          >
            Archive
          </b-button>
          <!-- Restore Selected(s) -->
          <b-button
              v-b-modal="'multipleRestoreProofModal'"
              v-if="anyProofSelected && filter.archived"
              class="button-outline-green"
              style="padding: 9px 17px;"
          >
            Restore
          </b-button>
          <!-- Deleted Selected(s) -->
          <b-button
              v-b-modal="'multipleDeleteProofModal'"
              v-if="anyProofSelected && filter.archived && isNotSubUser()"
              class="button-outline-red ml-2"
              style="padding: 9px 17px;"
          >
            Delete
          </b-button>
        </div>
      </div>
      <b-card-body>
        <!-- Proof List -->
        <ul data-cy="proofListContainer" :style="viewMode ? 'marginBottom: 0px':''">
          <!-- Proof -->
          <li
              v-for="(proof, index) in proofs"
              :class="'proof-list-item line-item-large ' + (!viewMode && (index === 0) ? 'border-first' : (!viewMode && index === proofs.length-1) ? 'border-last' : '')"
              v-bind:key="proof.id"
              :data-cy="`proof_${proof.name}`"
          >
            <div class="row">
              <!--   Proof Name -->
              <div class="col-lg-6 col-md-12 proof-status">
                <div>
                  <div class="d-flex justify-content-start" style="margin-top: 16px">
                    <b-form-checkbox v-model="proof.checkbox" class="proof-list-item-checkbox"
                                     v-if="!viewMode"></b-form-checkbox>
                    <span class="proof-details-item-text cursor-pointer" @click="ViewProof(proof)"
                          :style="viewMode ? 'margin-left: 5px': ''">
                      {{ proof.name }}
                    </span>
                  </div>

                  <div class="d-flex justify-content-start">
                    <b-badge
                        variant="success"
                        class="proof-status-badge"
                    >
                      <span
                          :style="`margin-right: 4px; height: 6px; width: 6px; ${proofStatusColors[proof.id].color}`"
                          class="rounded-pill">
                      </span>
                      {{ proofStatusColors[proof.id].status }}
                    </b-badge>
                    <span class="proof-version-text">Version {{ proof.currentVersion }}</span>
                  </div>
                </div>
              </div>
              <!--  Avatars -->
              <div class="col-lg-4 col-md-12 d-flex align-items-center justify-content-sm-start justify-content-lg-end">
                <b-avatar-group
                    :size="getAvatarSize(proof)">
                  <!-- Web -->
                  <b-avatar
                      v-show="proof.docTypesUsed.indexOf('web_url') >= 0 || proof.docTypesUsed.indexOf('html') >= 0"
                      icon="globe"
                      :style="proofStatusColors[proof.id].color"
                      class="avatar-color"
                      size="32px"
                  >
                  </b-avatar>
                  <!-- Image -->
                  <b-avatar
                      v-show="proof.docTypesUsed.indexOf('image') >= 0"
                      icon="card-image"
                      class="avatar-color"
                      :style="proofStatusColors[proof.id].color"
                      size="32px"
                  >
                  </b-avatar>
                  <!-- Documents -->
                  <b-avatar
                      v-show="proof.docTypesUsed.indexOf('document') >= 0"
                      icon="file-earmark-fill"
                      class="avatar-color"
                      :style="proofStatusColors[proof.id].color"
                      size="32px"
                  >
                  </b-avatar>
                  <!-- Video -->
                  <b-avatar
                      v-show="proof.docTypesUsed.indexOf('video') >= 0"
                      icon="film"
                      class="avatar-color"
                      :style="proofStatusColors[proof.id].color"
                      size="32px"
                  >
                  </b-avatar>
                  <!-- Audio -->
                  <b-avatar
                      v-show="proof.docTypesUsed.indexOf('audio') >= 0"
                      icon="music-note-beamed"
                      class="avatar-color"
                      :style="proofStatusColors[proof.id].color"
                      size="32px"
                  >
                  </b-avatar>
                </b-avatar-group>
              </div>
              <div class="col-lg-2 col-md-12 d-flex justify-content-lg-around justify-content-sm-between">
                <!-- View Proof Details -->
                <a
                    class="view-proof-details-btn w-100"
                    :href="getProofItemLink(proof)"
                    @click="handleViewProof($event, proof)"
                >View
                </a
                >
                <!-- Expand Proof Details -->
                <div
                    class="ml-auto"
                    style="padding-top: 33px"
                    v-b-toggle="'approver_' + proof.id"
                    data-cy="moreDetailsBtn"
                    v-if="!viewMode"
                >
                  <span class="when-open">
                    <b-icon-chevron-up
                        style="width: 18px; height: 18px">
                    </b-icon-chevron-up>
                  </span>
                  <span class="when-closed">
                    <b-icon-chevron-down
                        style="width: 18px; height: 18px">
                    </b-icon-chevron-down>
                  </span>
                </div>
              </div>
            </div>
            <b-collapse class="accordion row" :id="'approver_' + proof.id" v-if="!viewMode">
              <div class="col-lg-6" style="padding-top: 10px">
                <!-- Proof Id -->
                <div class="col-md-12 mb-3">
                  <div class="proof-details-header-text">Proof ID</div>
                  <span class="proof-details-item-text pt-2">{{ proof.id }}</span>
                </div>
                <!-- Date Created -->
                <div class="col-md-12 mb-3">
                  <div class="proof-details-header-text">Date Created</div>
                  <span class="proof-details-item-text pt-2">{{ formatDate(proof.createdAt) }}</span>
                </div>
              </div>
              <div class="col-lg-6" style="padding-top: 10px">
                <!-- Owner -->
                <div class="col-md-12  mb-3">
                  <div class="proof-details-header-text">Owner</div>
                  <span class="proof-details-item-text pt-2">{{ proof.currentSender }}</span>
                </div>
                <!-- Due Date -->
                <div class="col-md-12 mb-3">
                  <div class="proof-details-header-text">Due Date</div>
                  <span class="proof-details-item-text pt-2">{{ formatDate(proof.dueDate) }}</span>
                </div>
              </div>
              <!-- Custom Fields -->
              <div class="w-100 d-flex flex-wrap proof-details-columns">
                <div class="col-lg-6 col-md-12 mb-3 flex-direction-row"
                     style="padding-left: 30px;"
                     v-for="customField in proof.customFields">
                  <!-- Datepicker Custom Label -->
                  <div v-if="customField.accountProofPropertyDataType === 'Datepicker'">
                    <div class="proof-details-header-text"> {{ customField.accountProofPropertyLabel }}</div>
                    <span class="proof-details-item-text">{{ formatDateProp(customField) }}</span>
                  </div>
                  <!-- Single Line Text-->
                  <div v-if="customField.accountProofPropertyDataType === 'Single Line Text'">
                    <div class="proof-details-header-text"> {{ customField.accountProofPropertyLabel }}</div>
                    <span class="proof-details-item-text"> {{
                        (customField.proofPropertyValue && customField.proofPropertyValue.length > 0) ? customField.proofPropertyValue[0] : "N/A"
                      }}</span>
                  </div>
                  <!-- Dropdown Custom Label -->
                  <div v-if="customField.accountProofPropertyDataType === 'Dropdown Select'">
                    <div class="proof-details-header-text"> {{ customField.accountProofPropertyLabel }}</div>
                    <span class="proof-details-item-text"> {{
                        (customField.proofPropertyValue && customField.proofPropertyValue.length > 0) ? customField.proofPropertyValue[0] : "N/A"
                      }}</span>
                  </div>
                  <!--  Checkboxes -->
                  <div v-if="customField.accountProofPropertyDataType === 'Checkboxes (Multiple Options)'">
                    <div class="proof-details-header-text"> {{ customField.accountProofPropertyLabel }}</div>
                    <div class="proof-details-item-text" v-for="value in customField.proofPropertyValue">
                      <span>{{ value }}</span>
                    </div>
                    <div class="proof-details-item-text" v-if="customField.proofPropertyValue.length === 0">
                      <span>N/A</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Archive & Restore Btn Inside Collapse -->
              <div class="col-12 d-flex flex-direction-column align-self-end justify-content-end">
                <b-button
                    v-b-modal="'archiveProofModal' + proof.id"
                    variant="ignore"
                    class="gray-outline-btn"
                    v-show="!proof.archived"
                    data-cy="archiveBtn"
                >
                  Archive
                </b-button>
                <b-button
                    v-b-modal="'restoreProofModal' + proof.id"
                    @click="RegisterRestoreModalEventListenerForProof(proof)"
                    variant="ignore"
                    class="gray-outline-btn"
                    style="margin-top: 15px"
                    v-show="proof.archived"
                >
                  Restore
                </b-button>
                <br/>
                <b-button
                    v-b-modal="'deleteProofModal' + proof.id"
                    style="margin-top: 15px"
                    @click="RegisterDeleteModalEventListenerForProof(proof)"
                    variant="ignore"
                    class="gray-outline-btn ml-2"
                    v-show="user.permissions.manageAccount && proof.archived"
                    data-cy="deleteBtn"
                >
                  Delete
                </b-button>
              </div>
            </b-collapse>
            <modal
                :key="proof.id + 'deleteProofModal'"
                :name="'deleteProofModal' + proof.id"
                :eventToEmitOnConfirmation="
            'proof_archive_page::delete::proof::confirmed::proof_id_' + proof.id
          "
                question="Delete This Proof?"
                body="Are you sure you want to delete this proof? You cannot undo this action."
                confirm-button-text="Yes, Delete"
            />
            <modal
                :key="proof.id + 'restoreProofModal'"
                :name="'restoreProofModal' + proof.id"
                :eventToEmitOnConfirmation="
            'proof_archive_page::restore::proof::confirmed::proof_id_' +
            proof.id
          "
                question="Restore This Proof?"
                body="Restoring this proof will allow you to actively manage this proof, including new versions, approvers and workflows."
                confirm-button-text="Yes, Restore"
            />
            <archiveProofModal
                :key="proof.id + 'archiveProofModal'"
                :name="'archiveProofModal' + proof.id"
                @confirmed="ArchiveProof"
                :proof-id="proof.id"
                question="Archive This Proof?"
                body="This proof will no longer be accessible to approvers and you will be unable to send new versions. You will have full access to files and timeline."
                confirm-button-text="Yes, Archive"
            />
          </li>
          <!--          &lt;!&ndash; /Proof &ndash;&gt;-->
          <!--          <li-->
          <!--              class="null-item"-->
          <!--              v-if="loadProofCalled && proofs.length == 0 && !contactProofViewMode && $route.path === '/proof-archive' && !isLoadingProofs"-->
          <!--          >-->
          <!--            <div class="row">-->
          <!--              <div class="col-md-12">-->
          <!--                <img src="/img/hook-icon.svg"/>-->
          <!--                <h4>Arrg, There Are No Proofs to Show</h4>-->
          <!--                <p>-->
          <!--                  You can create a new proof by selecting the "Create Proof" button-->
          <!--                  above.-->
          <!--                </p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </li>-->
        </ul>
        <button v-if="shouldShowLoadButton"
                class="gray-button btn"
                @click="loadMore"
                style="margin-top: 20px; max-width: 212px; margin-left: auto"
        >
          Load More
        </button>
        <!-- /Proof List -->
      </b-card-body>
      <!-- Archive Multiple Proof Modal -->
      <archiveProofModal
          key="multipleArchiveProofModal"
          name="multipleArchiveProofModal"
          @confirmed="ArchiveProofsSelected"
      />
      <!-- Restore Multiple Proof Modal -->
      <modal
          key="multipleRestoreProofModal"
          name="multipleRestoreProofModal"
          eventToEmitOnConfirmation="proof_restore_page::restore::proof::confirmed::selected"
          question="Restore These Proofs?"
          body="Restoring these proofs will allow you to actively manage the proofs, including new versions, approvers and workflows."
          confirm-button-text="Yes, Restore"
      />
      <!-- Delete Multiple Proof Modal -->
      <modal
          key="multipleDeleteProofModal"
          name="multipleDeleteProofModal"
          eventToEmitOnConfirmation="proof_delete_page::delete::proof::confirmed::selected"
          question="Delete These Proof(s)?"
          body="Are you sure you want to delete these proof(s)? You cannot undo this action."
          confirm-button-text="Yes, Delete"
      />
    </b-card>
    <!-- No Proof To Show -->
    <div
        class="null-item card-no-proofs"
        v-if="loadProofCalled && proofs.length == 0 && !contactProofViewMode && $route.path === '/proof-archive' && !isLoadingProofs"
    >
      <div class="row">
        <div class="col-md-12">
          <img src="/img/hook-icon.svg"/>
          <h4>Arrg, There Are No Proofs to Show</h4>
          <p>
            You can create a new proof by selecting the "Create Proof" button
            above.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.view-proof-details-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin: 24px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.proof-list-card {
  filter: drop-shadow(0px 1px 3px rgba(8, 11, 13, 0.1)) drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.06));
  border-radius: 6px;
}

.avatar-color {
  color: #F9F9F9 !important;
  border: 2px solid #F9F9F9 !important;
}

.visible-check-group {
  margin-bottom: 24px;
  display: flex;
  padding-left: 16px;
}

.proof-check-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  color: black;
}

.visible-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  opacity: 1 !important;
}

.null-item img {
  height: 100px;
  margin-bottom: 25px;
  animation: hook 5s infinite;
}

.null-item h4 {
  margin-bottom: 15px;
}

.null-item {
  list-style-type: none;
  text-align: center;
}

.proof-status-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 13px;
  height: 26px;
  border: 1px solid #B5B5B8;
  border-radius: 13px;
  background-color: white;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 8px;
}

.card-no-proofs {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 24px !important;
  filter: drop-shadow(0 1px 3px rgba(8, 11, 13, .1)) drop-shadow(0 1px 2px rgba(8, 11, 13, .06));
  border-radius: 6px;
}


.proof-list-item {
  background: #ffffff;
  margin-bottom: 1px;
  padding: 1px 24px;
  overflow: auto;
  box-shadow: 0px 1px 3px rgb(8 11 13 / 10%), 0px 1px 2px rgb(8 11 13 / 6%);
}

.proof-version-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #707073;
  margin-left: 7px;
}

.proof-details-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #707073;
}

.proof-details-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.gray-outline-btn {
  padding: 9px 17px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  margin-bottom: 16px;
}

.gray-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  width: 100%;
  margin-top: 24px;
}


.button-outline-red:hover {
  background: #EB4654;
  color: white;
}

.button-outline-green:hover {
  background-color: #019255;
  color: white;
}

/deep/ .proof-item-check .custom-control-label::before {
  background-color: #F9F9F9;
  border: 1px solid #B5B5B8;
  border-radius: 4px;
}

/deep/ .proof-list-item-checkbox .custom-control-label::before {
  top: 0.2rem;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid #B5B5B8;
  border-radius: 4px;
  color: black;
}

.border-first {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important
}

.border-last {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

/deep/ .proof-list-item-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

/deep/ .btn-secondary:focus, .btn-secondary.focus {
  background-color: white;
  border: white;
}

/deep/ .proof-list-item-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #006F41;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #006F41 !important;
}

/deep/ [data-v-50dde638] .custom-control-label:before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #006F41 !important;
  border: 1px solid #006F41 !important;
}

/deep/ .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background: #95D6B0 !important;
  border: 1px solid #006F41 !important;
  border-radius: 4px !important;
}

/deep/ .custom-checkbox .custom-control-input:not(checked) ~ .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:indeterminate ~ .custom-control-label::before {
  background: #95D6B0 !important;
  border: 1px solid #006F41 !important;
  border-radius: 4px !important;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #006F41;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ [data-v-a1fc98a6] .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
  border: 1px solid #B5B5B8 !important;
}

/deep/ [data-v-a1fc98a6] .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ .proof-list-item-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border: 1px solid #B5B5B8 !important;
}

/deep/ .card {
  padding: 24px !important;
}

/deep/ .card-body {
  padding: 0px !important;
}

/deep/ .b-avatar {
  height: 32px !important;
  width: 32px !important;
}

/deep/ .custom-control-label::before {
  top: 0.2rem;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid #006F41;
  border-radius: 4px;
}

/deep/ .custom-control-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

@-webkit-keyframes hook {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .view-proof-details-btn[data-v-a1fc98a6] {
    margin-left: 0px !important;
  }

  .proof-status {
    margin-bottom: 16px;
  }

  .proof-details-columns {
    flex-direction: column !important;
    flex-wrap: wrap !important;
    margin-left: 0px !important;
  }
}

</style>
