<template>
  <div>
    <div class="validate-page registration-page">
      <div class="container-fluid">
        <div class="row justify-content-md-center">
          <!-- Sidebar -->
          <div class="col-md-4">
            <div class="generic-sidebar text-center" style="max-height: 762px">
              <img
                style="height: 100px; margin-bottom: 25px"
                src="/img/island.svg"
              />
              <h4>Check Your Email</h4>
              <p class="mt-25">
                We sent an email link to complete your registration.
              </p>
              <p class="mt-25">
                <strong
                  >Tip: Check your spam folder in case the email was incorrectly
                  identified.</strong
                >
              </p>
            </div>
          </div>
          <!-- /Sidebar -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.validate-page {
  background-color: #019255;
}
</style>
