<script>
import confirmationModal from "../../components/modals/modal-confirmation.vue";
import CreateChecklistItem from "../automation-archive/create-checklist-item";

export default {
  name: "accountChecklists",
  mounted: async function () {
    await this.getAllChecklistProofRequirements();
    await this.loadChecklists();

    let self = this;
    this.$root.$on("check-list-item:saved:success", async function () {
      self.editThis = null;
      await self.loadChecklists();
    });
    this.$root.$on("confirm::delete::proofRequirement", async function () {
      if (self.toDelete.id > 0) {
        await self.$A.ProofChecklistService.DeleteProofChecklist(self.toDelete.id);
        self.toDelete = {
          id: 0,
          name: "",
        };
        await self.loadChecklists();
      }
    });
    this.$root.$on("checklist_item:canceled", function () {
      self.editThis = null;
    });
  },
  data() {
    return {
      checklist: [],
      fields: [
        {
          key: 'checklistinfo',
          sortable: false,
          label: "CHECKLISTS"
        },
        {
          key: 'edit',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'duplicate',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'delete',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
      ],
      editThis: null,
      duplicateThis: null,
      toDelete: {
        id: 0,
        name: "",
      },
      isMobile: window.innerWidth <= 767,
    };
  },
  components: {
    CreateChecklistItem,
    confirmationModal,
  },
  methods: {
    handleSaved: async function () {
      let checklist = await this.$A.ProofChecklistService.GetAllProofChecklistsItems();
      if (Array.isArray(checklist)) {
        this.checklist = checklist;
      }
    },
    loadChecklists: async function() {
      let checklist = await this.$A.ProofChecklistService.GetAllProofChecklistsItems();
      if (Array.isArray(checklist)) {
        this.checklist = checklist;
      }
    },
    editChecklist: function (checklistItem) {
      this.editThis = checklistItem;
      this.$root.$emit("edit::checklist::item");
    },
    deleteItem(pr) {
      this.toDelete = pr;
      this.$bvModal.show("deleteProofRequirement");
    },
    getAllChecklistProofRequirements: async function () {
      this.checklistItems = await $A.ProofChecklistService.GetAllProofChecklistsItems();
    },
    duplicate: async function (id) {
      this.duplicateThis = id;
      await this.$A.ProofChecklistService.DuplicateProofChecklist(id);
      await this.loadChecklists();
    }
  },
  watch: {}
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10 account-checklist-card">
      <b-card-body>
        <b-card-text class="mb-2 mx-md-3 mt-4 pl-md-2 pl-sm-4 text-dark account-checklists-header">Checklists</b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 pl-sm-4 description-text mb-4">Create checklists that you can apply to your entire account or
          approval stages.
        </b-card-text>
        <b-table
            class="mt-3 px-4 checklist-table table-borderless mb-4"
            :items="checklist"
            :fields="fields"
            responsive
            hover
            outlined
            v-show="checklist.length > 0"
            thead-class="checklist-table-bg"
        >
          <template #head()="data">
            <span class="table-header-text">{{ data.label }}</span>
          </template>
          <template #cell(checklistinfo)="data">
            <div class="d-flex fixed-column col-8 pl-0">
              <span class="column-header-text" v-html="data.item.name"></span>
              <span class="default-item ml-2" v-show="data.item.accountWide">Default</span>
            </div>
          </template>
          <template #cell(edit)="data">
            <div>
              <a class="font-weight-normal edit-delete-checklist"
                 v-b-toggle.create-checklist-sidebar-right
                 @click="editChecklist(data.item)">
                Edit
              </a>
            </div>
          </template>
          <template #cell(duplicate)="data">
            <div>
              <a class="font-weight-normal edit-delete-checklist"
                 @click="duplicate(data.item.id)"
              >
                Duplicate
              </a>
            </div>
          </template>
          <template #cell(delete)="data">
            <div>
              <a class="font-weight-normal edit-delete-checklist checklist" @click="deleteItem(data.item)"
                 v-show="!(data.item.accountWide)">
                Delete
              </a>
            </div>
          </template>
        </b-table>
        <confirmationModal
            ref="confirmPRDeletionModal"
            name="deleteProofRequirement"
            event-to-emit-on-confirmation="confirm::delete::proofRequirement"
            confirm-button-text="Delete Requirement"
            question="Are you sure you would like to delete this proof requirement?"
            :body="
              'This change will only effected new proofs. Existing Proofs will not be effected. </br/> </br/> <b>Deleting Requirement:</b> ' +
              toDelete.name
            "
        />
      </b-card-body>
      <div :class="isMobile ? 'w-100 px-4 py-3': 'border-top-0 d-flex mb-0 table-footer justify-content-end'">
          <a v-b-toggle:create-checklist-sidebar-right
             @click="editThis = null"
             class="button button-light button-green checklists-btn mb-0">
            Create Checklist
          </a>
        <create-checklist-item :existing-checklist="editThis"/>
      </div>
    </b-card>
  </div>
</template>

<style scoped>

/deep/ .table th, .table td {
  vertical-align: none;
}

.field-item h3 {
  font-size: 18px;
}

.checklists-btn {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.account-checklist-card ::v-deep .card-body {
  padding: 0px;
}

.account-checklists-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

/deep/ .checklist-table {
  border-radius: 4px !important;
}

::v-deep .checklist-table table thead th {
  border-bottom: 0px;
  padding: 12px 24px;
}

::v-deep .checklist-table table td {
  padding: 16px 24px !important;
  vertical-align: middle !important;
}

/deep/ .checklist-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.table-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  color: #575759;
}

.column-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.edit-delete-checklist {
  color: #006F41;
}

.edit-delete-checklist:hover {
  cursor: pointer;
}

.default-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 20px;
  background: #EBEBED;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #252526;
}


@media (max-width: 576px) {

  .button-locked-checklists {
    background: #F44271;
    color: #ffffff;
    transition: all .5s ease;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .checklists-btn {
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .account-checklists-header{
    margin-left: 24px;
  }

  .description-text {
    margin-left: 24px;
    margin-right: 24px;
    text-align: justify;
  }
}


</style>
