<script>
import draggable from "vuedraggable";
import redactor from "../form/redactor.vue";
import proofToggles from "../global/proof-toggle.vue";

export default {
  name: "create-user-role-sidebar",
  components: {
    draggable,
    redactor,
    proofToggles,
  },
  props: {},
  async mounted() {
  },
  computed: {},
  methods: {
    cancel: function () {
      this.role = {
        id: 0,
        name: "",
        proofPermissions: 4,
        manageBilling: false,
        manageUsers: false,
        manageAccount: false,
        manageTemplates: true,
        manageWorkflows: true,
      };
      this.showSideBar = false;
    },
    saveUserRole: async function () {
      if (!this.checkForm()) return;

      if (this.role.id > 0) {
        await this.$A.RoleService.UpdateRole(
            this.role.id,
            this.role.name,
            this.role.manageBilling,
            this.role.manageUsers,
            this.role.manageAccount,
            this.role.manageTemplates,
            this.role.manageWorkflows,
            this.role.proofPermissions
        )
      } else {
        await this.$A.RoleService.CreateRole(
            this.role.name,
            this.role.manageBilling,
            this.role.manageUsers,
            this.role.manageAccount,
            this.role.manageTemplates,
            this.role.manageWorkflows,
            this.role.proofPermissions
        )
      }
      this.$emit('saved');
      this.cancel();
    },
    edit: async function (userRole) {
      this.role = userRole;
      this.showSideBar = true;
    },
    checkForm: function () {
      this.errors = [];
      this.validated = true;

      if (!this.role.name) {
        this.errors.push("Role Name Required.");
      }

      if (!this.errors.length) {
        return true;
      }

      const errorMessage = "<ul>" + this.errors.map((e) => "<li>" + e + "</li>").join("") + "</ul>";
      this.$A.AlertUser(errorMessage);
    },

  },
  data() {
    return {
      user: this.$A.Auth,
      showSideBar: false,
      role: {
        id: 0,
        name: "",
        proofPermissions: 4,
        manageBilling: false,
        manageUsers: false,
        manageAccount: false,
        manageTemplates: true,
        manageWorkflows: true,
      },
      permissions: [
        {id: 1, name: 'Owned Only'},
        {id: 2, name: 'Team Only'},
        {id: 3, name: 'Everything'},
        {id: 4, name: 'None'}
      ]
    };
  },
  watch: {}
};
</script>

<template>
  <b-sidebar
      id="create-user-role-sidebar"
      right
      shadow
      v-model="showSideBar"
      @hidden="cancel"
      :no-header-close="true"
  >

    <!-- Create User Role Header -->
    <div class="workflow-header-container">
      <div class="sidebar-workflow-header mb-2">Manage User Role</div>
      <span class="sub-text">Your user role can be applied to users on your account.</span>
    </div>

    <!-- User Role Name -->
    <div class="workflow-field-container d-flex flex-direction-row align-items-center border-bottom">
      <span class="field-text col-md-3 px-0">User Role</span>
      <b-input type="text"
               class="field-input col-md-9"
               name="RoleName"
               v-model="role.name">
      </b-input>
    </div>

    <!--  Account Permissions  -->
    <div class="col-md-12 mb-4 px-4 border-bottom">
      <div class="setting-text my-3 ">Account Permissions</div>
      <div class="col-md-12 mb-3">
        <!-- User can access billing -->
        <div class="ashore-toggle">
          <label class="switch">
            <input
                type="checkbox"
                v-model="role.manageBilling"
            />
            <div class="slider round"></div>
          </label>
          User can access billing
        </div>
        <!-- User can access user management -->
        <div class="ashore-toggle">
          <label class="switch">
            <input
                type="checkbox"
                v-model="role.manageUsers"
            />
            <div class="slider round"></div>
          </label>
          User can access user management
        </div>
        <!--  User can access account settings -->
        <div class="ashore-toggle">
          <label class="switch">
            <input
                type="checkbox"
                v-model="role.manageAccount"
            />
            <div class="slider round"></div>
          </label>
          User can access account settings
        </div>
        <!-- User can create and manage email templates -->
<!--        <div class="ashore-toggle">-->
<!--          <label class="switch">-->
<!--            <input-->
<!--                type="checkbox"-->
<!--                v-model="role.manageTemplates"-->
<!--            />-->
<!--            <div class="slider round"></div>-->
<!--          </label>-->
<!--          User can create and manage email templates-->
<!--        </div>-->
        <!-- User can create and manage workflows -->
<!--        <div class="ashore-toggle">-->
<!--          <label class="switch">-->
<!--            <input-->
<!--                type="checkbox"-->
<!--                v-model="role.manageWorkflows"-->
<!--            />-->
<!--            <div class="slider round"></div>-->
<!--          </label>-->
<!--          User can create and manage workflows-->
<!--        </div>-->
      </div>
    </div>


    <!--  Proof Access Permissions -->
    <div class="d-flex flex-column align-items-start proof-name-container mb-4 mt-3 px-4">
      <span class="setting-text col-md-12 pl-0">Proof Access Permissions</span>
      <v-select
          :options="permissions"
          label="name"
          :reduce="option => option.id"
          placeholder="Select Template"
          class="mt-1 col-md-12 px-0 team-select"
          :searchable="false"
          :clearable="false"
          v-model="role.proofPermissions"
      >
      </v-select>
    </div>

    <!-- Footer -->
    <template #footer="{ hide }">
      <!--  Buttons  -->
      <div class="d-flex create-workflow-footer border-top col-md-12 justify-content-end pb-0">
        <b-button class="workflow-btn-white button ml-3 pb-0 mb-0"
                  @click="hide">
          Cancel
        </b-button>
        <b-button
            class="workflow-btn-green button ml-3 pb-0 mb-0"
            @click="saveUserRole()"
        >
          Save User Role
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>


<style scoped>


/deep/ .b-sidebar {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 672px;
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-body {
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}

.workflow-header-container {
  background: #F4F4F6;
  padding: 24px;
  border-bottom: 1px solid lightgray;
}

.sidebar-workflow-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
  margin-top: 4px;
}

.small-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.type-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.team-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border-radius: 6px;
}

.team-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
  font-weight: normal;
}

.team-select ::v-deep .vs__selected {
  padding: 4px 0px 8px 4px !important;
}

.team-select ::v-deep .vs__dropdown-option {
  font-weight: normal !important;
}

.field-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.setting-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.stage {
  padding: 0px 0px 24px 0px !important;
}

.field-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

.workflow-field-container {
  padding: 20px 24px;
  background-color: white;
}

::v-deep .stages-container-card .card-body {
  padding: 16px 24px !important;
  display: flex;
  border: 0px;
  border-right: 0.25px;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
  border-radius: 0px;
}

::v-deep .stages-container-card {
  border: 0px !important;
}


::v-deep .stages-container-card .card-body {
  padding: 16px 24px !important;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.gray-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  width: 100%;
}

.stage-dot-black {
  width: 8px;
  height: 8px;
  background: #444444;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 0px;
}

.stage-dot-white {
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.stages {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-workflow-footer {
  padding: 20px 24px !important;
  background: #f4f4f6;
  color: #575759;
}

.workflow-btn-green {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #019256;
}

.workflow-btn-white {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #f9f9f9;
  color: #3e3e40;
}

.workflow-btn-white:hover, .workflow-btn-green:hover {
  opacity: 0.5;
  transition: all 0.5s ease;
}

.overview-card {
  padding: 24px;
  border: 0px;
  border-radius: 0px !important;
  box-shadow: 0px 1px 3px rgba(8, 11, 13, 0.1), 0px 1px 2px rgba(8, 11, 13, 0.06);
}

/deep/ .overview-card .card-body {
  padding: 0px !important;
}

.header-text {
  font-weight: 700;
  margin-bottom: 10px;

}


.stage-number {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.stages-padding {
  padding: 20px 24px 0px 24px;
}

.checklist-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .checklist-select .vs__dropdown-menu {
  font-weight: 400 !important;
  overflow-y: auto !important;
  max-height: 75px !important;
}

/deep/ .vs__clear {
  fill: rgba(60, 60, 60, .5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 4px;
}

/deep/ ul {
  font-weight: 400;
}

/deep/ .ti-autocomplete {
  border: 1px solid #B5B5B8 !important;
  border-top: 0px !important;
}

/deep/ .vs__clear {
  margin-top: 2px;
}

/deep/ .vs__open-indicator {
  margin-top: 0px !important;
}

/deep/ .ti-tag span {
  line-height: 1.2;
}

/deep/ .vs__selected {
  padding: 0px 2px 4px 4px;
}

@media screen and (max-width: 991px) {

  .vs__search, .vs__search:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.4;
    font-size: 1em;
    border: 1px solid transparent;
    border-left: none;
    outline: none;
    margin: 0px;
    padding: 0 7px;
    background: none;
    box-shadow: none;
    width: 0;
    max-width: 100%;
    flex-grow: 1;
    z-index: 1;
  }

  /deep/ .ti-tag span {
    margin-bottom: 0px;
    opacity: 1;
    line-height: 1.2;
  }

  /deep/ .ti-icon-close {
    line-height: unset;
  }

}

@media screen and (max-width: 769px) {

  /deep/ .vs--single .vs__selected {
    width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

}

@media screen and (min-width: 770px) {

  /deep/ .vs--single .vs__selected {
    width: 400px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

}


</style>

