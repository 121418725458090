<script>
export default {
  name: "proofToggles",
  props: ["proof", "margin"],
  data() {
    return {
      downloadFiles: false,
      approveIndividualFiles: false,
      viewAllComments: true,
      approveWithChanges: false,
      limitVersionVisibility: false,
      blockCommentingAfterProofDecision: false,
    };
  },
  mounted() {
    const userSettings = _.get(this, '$A.Auth.userSettings', {});
    if (this.$props.proof === undefined) {
      this.downloadFiles = !!userSettings.downloadFiles;
      this.approveIndividualFiles = !!userSettings.approveIndividualFiles;
      this.viewAllComments = !!userSettings.viewAllComments;
      this.approveWithChanges = !!userSettings.approveWithChanges;
      this.limitVersionVisibility = !!userSettings.limitVersionVisibility;
      this.blockCommentingAfterProofDecision = !!userSettings.blockCommentingAfterProofDecision;
    }
  },
  watch: {
    proof: function () {
      if (this.$props.proof !== undefined && this.$props.proof.id > 0) {
        this.downloadFiles = this.$props.proof.allowDownload;
        this.approveIndividualFiles = this.$props.proof.allowApproveIndividualFiles;
        this.viewAllComments = this.$props.proof.allowViewAllComments;
        this.approveWithChanges = this.$props.proof.allowApproveWithChanges;
        this.limitVersionVisibility = this.$props.proof.limitVersionVisibility;
        this.blockCommentingAfterProofDecision = this.$props.proof.blockCommentingAfterProofDecision;
      }
    },
    downloadFiles: function (newValue) {
      this.updateSettings();
    },
    approveIndividualFiles: function (newValue) {
      this.updateSettings();
    },
    viewAllComments: function (newValue) {
      this.updateSettings();
    },
    approveWithChanges: function (newValue) {
      this.updateSettings();
    },
    limitVersionVisibility: function (newValue) {
      this.updateSettings();
    },
    blockCommentingAfterProofDecision: function (newValue) {
      this.updateSettings();
    },
  },
  methods: {
    updateSettings: function () {
      if (this.$props.proof !== undefined && this.$props.proof.id > 0) {
        let d = {
          allowDownload: this.downloadFiles,
          allowApproveIndividualFiles: this.approveIndividualFiles,
          allowViewAllComments: this.viewAllComments,
          allowApproveWithChanges: this.approveWithChanges,
          limitVersionVisibility: this.limitVersionVisibility,
          blockCommentingAfterProofDecision: this
              .blockCommentingAfterProofDecision,
        };
        this.$root.$emit("proof::save::settings", d);
      }
    },
    getCurrentSettings: function () {
      return {
        downloadFiles: this.downloadFiles,
        approveIndividualFiles: this.approveIndividualFiles,
        viewAllComments: this.viewAllComments,
        approveWithChanges: this.approveWithChanges,
        limitVersionVisibility: this.limitVersionVisibility,
        blockCommentingAfterProofDecision: this
            .blockCommentingAfterProofDecision,
      };
    },
  },
};

</script>

<template>
  <div class="d-flex flex-column gap-10">
    <!--Toggle -->
    <div class="ashore-toggle d-flex gap-2" id="proof_toggle_download">
      <label class="switch mb-0 opacity-100">
        <input type="checkbox" v-model="downloadFiles" />
        <div class="slider round"></div>
      </label>
      <div class="d-flex gap-10">
        <p class="text-break mb-0">Download Files</p>
        <label>
          <img
            id="proof_toggle_downloadfiles_icon"
            class="info-icon"
            src="/img/icon-info.svg"
          />
          <b-tooltip target="proof_toggle_downloadfiles_icon" triggers="hover">
            Turn this toggle on to allow approvers to download the files in your
            proof.
          </b-tooltip>
        </label>
      </div>
    </div>
    <!--/Toggle -->
    <!--Toggle -->
    <div class="ashore-toggle d-flex gap-2" id="proof_toggle_approve_ind_files">
      <label class="switch mb-0 opacity-100">
        <input type="checkbox" v-model="approveIndividualFiles" />
        <div class="slider round"></div>
      </label>
      <div class="d-flex gap-10">
        <p class="text-break mb-0">Approve Individual Files</p>
        <label>
          <img
            id="proof_toggle_approveindiviualfiles_icon"
            src="/img/icon-info.svg"
            class="info-icon"
          />
          <b-tooltip
            target="proof_toggle_approveindiviualfiles_icon"
            triggers="hover"
          >
            Turn this toggle on to require approvers to make an approval
            decision on each file in your proof. Otherwise, we'll ask for one
            approval decision for all files in this proof.
          </b-tooltip>
        </label>
      </div>
    </div>
    <!--/Toggle -->
    <!--Toggle -->
    <div class="ashore-toggle d-flex gap-2" id="proof_toggle_comments">
      <label class="switch mb-0 opacity-100">
        <input type="checkbox" v-model="viewAllComments" />
        <div class="slider round"></div>
      </label>
      <div class="d-flex gap-10">
        <p class="text-break mb-0">View All Comments</p>
        <label>
          <img
            id="proof_toggle_viewallcomments_icon"
            src="/img/icon-info.svg"
            class="info-icon"
          />
          <b-tooltip
            target="proof_toggle_viewallcomments_icon"
            triggers="hover"
          >
            Turn this toggle on to allow your approvers and commenters to see,
            interact with, and be notified about other comments on a proof.
          </b-tooltip>
        </label>
      </div>
    </div>
    <!--/Toggle -->
    <!--Toggle -->
    <div class="ashore-toggle d-flex gap-2" id="proof_toggle_changes">
      <label class="switch mb-0 opacity-100">
        <input type="checkbox" v-model="approveWithChanges" />
        <div class="slider round"></div>
      </label>
      <div class="d-flex gap-10">
        <p class="text-break mb-0">Approve With Changes</p>
        <label>
          <img
            id="proof_toggle_approvewithchanges_icon"
            src="/img/icon-info.svg"
            class="info-icon"
          />
          <b-tooltip
            target="proof_toggle_approvewithchanges_icon"
            triggers="hover"
          >
            Turn this toggle on to allow your approvers to say, "make these
            changes but I don't need to see a new version". We'll treat the
            proof as approved.
          </b-tooltip>
        </label>
      </div>
    </div>
    <!--/Toggle -->
    <!--Toggle -->
    <div
      class="ashore-toggle d-flex gap-2"
      id="proof_toggle_limitVersionVisibility"
    >
      <label class="switch mb-0 opacity-100">
        <input type="checkbox" v-model="limitVersionVisibility" />
        <div class="slider round"></div>
      </label>
      <div class="d-flex gap-10">
        <p class="text-break mb-0">Limit Version Visibility</p>
        <label>
          <img
            id="proof_toggle_limitVersionVisibility_icon"
            src="/img/icon-info.svg"
            class="info-icon"
          />
          <b-tooltip
            target="proof_toggle_limitVersionVisibility_icon"
            triggers="hover"
          >
            Turn this toggle on to prevent approvers from viewing proof versions
            sent to approvers in other workflow stages.
          </b-tooltip>
        </label>
      </div>
    </div>
    <!--/Toggle -->
    <!--Toggle -->
    <div
      class="ashore-toggle d-flex gap-2"
      id="proof_toggle_blockCommentingAfterProofDecision"
    >
      <label :class="'switch mb-0 opacity-100 ' + margin">
        <input type="checkbox" v-model="blockCommentingAfterProofDecision" />
        <div class="slider round"></div>
      </label>
      <div class="d-flex gap-10">
        <p class="text-break mb-0">Block Commenting After Decision</p>
        <label>
          <img
            id="proof_toggle_blockCommentingAfterProofDecision_icon"
            src="/img/icon-info.svg"
            class="info-icon"
          />
          <b-tooltip
            target="proof_toggle_blockCommentingAfterProofDecision_icon"
            triggers="hover"
          >
            Turn this toggle on to prevent approvers from submitting comments
            after making a decision.
          </b-tooltip>
        </label>
      </div>
    </div>
    <!--/Toggle -->
  </div>
</template>

<style scoped>

.info-icon {
  height: 18px;
}

.ashore-toggle .switch {
  position: relative !important;
  display: inline-block !important;
  width: 44px !important;
  height: 24px !important;
  margin-right: 8px !important;
}

</style>
