<script>
export default {
  name: "modalEditOwner",
  props: {
    proof: {
      required: true,
      type: Object,
    },
    users: {
      required: true,
      type: Array,
      default: () => []
    },
  },
  methods: {
    saveAndClose: function () {
      this.$root.$emit("proof::save::owner", {
        senderId: this.$props.proof.currentVersion.sender.id,
      });
      this.$bvModal.hide("EditProofOwner");
    },
  },
};
</script>

<template>
  <!-- Edit Proof Owner Modal -->
  <b-modal id="EditProofOwner" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <div class="header-text">Edit Proof Owner</div>
        <div class="sub-text" style="margin-top: 20px">All emails sent by Ashore for this proof will come from the Proof Owner.</div>
      </div>
    </template>

    <form role="form">
      <div class="row">
        <div class="col-md-12">
          <div>
            <div class="input-label">Select Proof Owner</div>
            <select
                required="required"
                class="form-control form-select edit-owner-input"
                v-model="proof.currentVersion.sender.id"
            >
              <option :value="user.id" v-for="user in users">
                {{ user.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            class="button gray-btn"
            @click="cancel()"
        >Cancel
        </b-button
        >
        <b-button
            class="button green-btn"
            @click="saveAndClose"
        >Update
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>


<style scoped>

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}


.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.input-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

.edit-owner-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 13px 1px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .modal-body {
  padding: 20px 24px 24px 24px;
}

/deep/ .modal-header {
  padding: 24px 24px 0px 24px;
}

@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
  }

}


</style>

