<script>
export default {
  name: "deleteUserModal",
  data() {
    return {
      userToDelete: {id: 0, name: ""},
      userIdToTransferTo: null,
      users: [],
      alert: "",
    };
  },
  async mounted() {
  },
  computed: {
    admins: function () {
      return this.users.filter((u) => _.get(u, ['permissions', 'roles', 0, 'roleId']) === 1);
    },
    userOptions: function () {
      return this.users
          .filter(user => user.id !== this.userToDelete.id)
          .map(user => ({id: user.id, name: user.name}));
    },
  },
  methods: {
    open: function (userToDelete, allUsers) {
      this.$set(this, 'userToDelete', userToDelete);
      this.users = allUsers;
      this.$bvModal.show("deleteUserModal");
    },
    close: function () {
      this.alert = "";
      this.$bvModal.hide("deleteUserModal");
    },
    confirm: async function () {
      this.alert = "";
      if (this.userIdToTransferTo > 0 && this.userToDelete.id > 0) {
        if (
            this.admins.length === 1 &&
            this.userToDelete.id === this.admins[0].id
        ) {
          this.alert =
              "If you wish to delete the last admin please delete the account, otherwise you need to have at least 1 admin per account.";
          return;
        }
        await this.$A.AccountService.DeleteUser(
            this.userToDelete.id,
            this.userIdToTransferTo
        );

        this.$emit("deleted");
        this.$bvModal.hide("deleteUserModal");
      } else {
        this.alert = "Please Select A User To Transfer To";
      }
    },
  },
};
</script>

<template>
  <b-modal id="deleteUserModal" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <div class="delete-modal-header">You are about to delete
          <strong style="color: #CC333F">{{ userToDelete.name }}</strong>,
          Who would you like to take ownership of their proofs? They will take charge of proofs, and workflows.
        </div>
      </div>
    </template>

    <form role="form">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <span class="sub-header-text">Transfer To:</span>
            <v-select
                required
                class="form-select mt-3 delete-select"
                v-model="userIdToTransferTo"
                :options="userOptions"
                label="name"
                :searchable="false"
                :clearable="false"
                :reduce="user => user.id"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label v-if="alert.length > 0" style="color: red">{{
                alert
              }}</label>
          </div>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
            class="gray-btn"
            @click="close()"
        >
          Cancel
        </b-button>
        <b-button
            class="red-btn ml-2"
            @click="confirm()"
        >Confirm And Delete
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.delete-modal-header {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.sub-header-text {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

/deep/ #deleteUserModal .modal-header {
  padding: 24px !important;
}

/deep/ #deleteUserModal .modal-body {
  padding-top: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

::v-deep .delete-select .vs__dropdown-toggle{
  height: 38px !important;
}


.red-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  color: white;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #CC333F;
  border: 1px solid #EB4654;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #F4F4F6;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  cursor: pointer;
}

::v-deep .user-select {
  padding-left: 10px;
  width: 100%;
  height: 38px !important;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

</style>
