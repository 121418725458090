<script>
import reviewLink from "../../components/form/review-link";

export default {
  name: "proofLink",
  props: {
    proofPublicId: {
      required: true,
      type: String,
    },
    proofReviewerId: {
      required: true,
      type: String,
    },
    version: {
      required: false,
      type: String
    }
  },
  data: function () {
    return {
      proofReviewLinkType: "approver-link",
      isMobile: window.innerWidth < 992
    };
  },
  computed: {
    reviewLinkId: function () {
      if (this.proofReviewLinkType && this.proofReviewLinkType == "reviewer-link") {
        return this.proofReviewerId;
      }
      return this.proofPublicId;
    }
  },
  components: {
    reviewLink,
  },
};
</script>

<template>
  <!-- Modal Proof Link Modal -->
  <b-modal id="reviewLink" size="lg" no-stacking>
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="row">
        <h4 class="col">Share This Proof Link</h4>
        <span class="col-12 mt-2 pr-0">
          Share the below link with anyone. Contacts will be appended to the current stage.
        </span>
        <div class="col-12 d-flex pt-4 m-auto flex-wrap">
          <div class="custom-control custom-radio col-lg-6 pr-0">
            <input
                type="radio"
                id="approver-proof-link"
                name="approver-link"
                value="approver-link"
                class="custom-control-input"
                v-model="proofReviewLinkType"
            />
            <label
                class="custom-control-label pb-2"
                for="approver-proof-link">Approver Link</label>
            <p class="text-justify text-sm-left">
              Share this link with approvers, allowing them to sign-off and receive automatic reminders.
            </p>
          </div>
          <div class="custom-control custom-radio col-lg-6 pr-0">
            <input
                type="radio"
                id="non-approver-proof-link"
                name="reviewer-link"
                value="reviewer-link"
                class="custom-control-input"
                v-model="proofReviewLinkType"
            />
            <label
                class="custom-control-label pb-2"
                for="non-approver-proof-link"
            >Comment Only Link</label
            >
            <p class="text-justify text-sm-left">
              Share this link with commenters, allowing them to view and make comments only.
            </p>
          </div>
        </div>
      </div>

    </template>

    <reviewLink :proofLinkId="reviewLinkId"/>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            @click="cancel()"
            class="text-dark p-0 button close-btn"
            variant="secondary"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #019255 !important;
}

.custom-control-input ~ .custom-control-label::before {
  border-color: #019255 !important;
}


.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
}

.close-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
}

.close-btn:hover {
  color: white !important;
}

@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
  }
}

@media screen and (max-width: 991px) {
  .modal-header {
    padding-bottom: 0px !important;
  }
}


</style>
