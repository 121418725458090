<script>
export default {
  name: "deletePropertyModal",
  data: function () {
    return {
      propertyToDelete: null,
      alert: "",
    };
  },
  methods: {
    open: async function (property) {
      this.propertyToDelete = property;
      this.$bvModal.show("deletePropertyModal");
    },
    close: async function () {
      this.$bvModal.hide("deletePropertyModal");
    },
    confirm: async function () {
      await this.$A.AccountService.DeleteCustomProperty(this.propertyToDelete.id);
      this.$emit("propertyDeleted");
      this.$bvModal.hide("deletePropertyModal");
    },
  },
};
</script>

<template>
  <b-modal id="deletePropertyModal" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100 d-flex flex-column">
        <span class="header-text-card pb-0">Delete <strong style="color: red">{{ propertyToDelete.label }}</strong> Property?</span>
        <span class="description-text mt-3">This action cannot be undone.</span>
      </div>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button class="cancel-btn cancel-btn-text mr-3" @click="close()">Cancel</b-button>
        <b-button class="submit-btn green-btn" @click="confirm()">Yes, Delete</b-button>
      </div>
    </template>
  </b-modal>
</template>
<editPropertyModal ref="editpropertyModal" v-on:propertySaved="getAllProperties"/>

<confirm-modal ref="confirmDeleteModal"
               v-on:
               body="This action can't be undone"
               question="Are you sure to delete this property?"
               name="confirmModalId"
               confirmButtonText="Yes, Delete"
               buttonCss="bg-success"
/>

<style scoped>

.header-text-card {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.submit-btn, cancel-btn {
  padding: 9px 17px;
  width: 130px;
  height: 38px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.cancel-btn-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 108px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.cancel-btn-text:hover {
  color: #F9F9F9;
}

.green-btn {
  background: #019256;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 130px;
  height: 38px;
}

</style>
