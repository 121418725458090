<script>
import deleteTeamModal from "../../components/modals/modal-delete-team.vue";
import createTeamSidebar from "../../components/settings/setting-create-team-sidebar.vue";

export default {
  name: "teams",
  components: {
    deleteTeamModal,
    createTeamSidebar
  },
  methods: {
    loadTeams: async function (preventEvent) {
      this.teams = await this.$A.TeamService.GetAccountTeams() || [];
      if (!preventEvent) {
        this.$emit("updated", this.teams);
      }
    },
    editTeam: function (team) {
      this.$refs.teamEditor.openForEdit(team);
    },
    deleteTeam: async function (team) {
      this.$refs.deleteTeamModal.open(team, this.teams);
    }
  },
  computed: {
    canCreateTeams: function () {
      const userLimit = _.get(this, '$A.Auth.account.subscriptionState.userLimit', 0);
      return !userLimit || this.users.length < userLimit;
    }
  },
  data() {
    return {
      teams: [],
      fields: [
        {
          key: 'teaminfo',
          sortable: false,
          label: "TEAM"
        },
        {
          key: 'edit',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'delete',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
      ],
      users: []
    };
  },
  async mounted() {
    await this.loadTeams(true);
    this.users = await this.$A.AccountService.All() || [];
  },
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10 account-user-card">
      <b-card-body>
        <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 text-dark account-users-header">Teams</b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 description-text">Create and manage teams in your organization.
        </b-card-text>
        <b-table class="px-4 pb-4 pt-2 rounded user-table"
                 :items="teams"
                 :fields="fields"
                 responsive hover outlined borderless
                 thead-class="user-table-bg">
          <template #head()="data">
            <span class="table-header-text">{{ data.label }}</span>
          </template>
          <template #cell(teaminfo)="data">
            <div class="center-content">
              <div class="row-cols-sm-8 row-cols-lg-3 ml-sm-2 ml-md-0">
                  <span class="ml-2 mt-1 column-header-text">
                    {{ data.item.name }}
                  </span>
              </div>
            </div>
          </template>
          <template #cell(edit)="data">
            <div>
              <a class="font-weight-normal edit-delete-user center-content" @click="editTeam(data.item)">Edit</a>
            </div>
          </template>
          <template #cell(delete)="data">
            <div>
              <a class="font-weight-normal edit-delete-user user center-content"
                 @click="deleteTeam(data.item)">
                Delete</a>
            </div>
          </template>
        </b-table>
        <delete-team-modal ref="deleteTeamModal" @deleted="loadTeams"/>
      </b-card-body>
      <div class="border-top-0 d-flex mb-0 justify-content-lg-between justify-content-md-around table-footer flex-sm-wrap">
        <div class="ml-auto" v-if="canCreateTeams">
          <a v-b-toggle.create-team-sidebar class="button button-light button-green users-btn">
            Create Team
          </a>
        </div>
        <div class="col-sm-4 col-lg-12" v-if="!canCreateTeams">
          <button
              class="button button-locked-users feature-lock-btn ml-auto"
              @click="$A.BuyNow()"
              v-b-tooltip.hover="'Upgrade to add additional teams'"
          >
            Feature Locked <img src="/img/feature-lock.svg" class="feature-icon"/>
          </button>
        </div>
      </div>
    </b-card>
    <create-team-sidebar ref="teamEditor" @saved="loadTeams"/>
  </div>
</template>

<style scoped>


.field-item h3 {
  font-size: 18px;
}

.user-button {
  cursor: pointer;
  float: right;
  margin-left: 35px;
}

.user-button img {
  height: 15px;
  margin-left: 10px;
}


.button-locked-users {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #F44271;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.feature-icon {
  margin-bottom: 2px;
  margin-left: 4px;
  height: 22px;
}

.feature-lock-btn {
  width: 160px;
}

.users-btn {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.account-user-card ::v-deep .card-body {
  padding: 0px;
}

.account-users-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

/deep/ .user-table {
  border-radius: 6px !important;
}

::v-deep .user-table .table, .table-responsive > .table, [class*=table-responsive-] > .table {
  margin-bottom: 0 !important;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
}

::v-deep .b-table-sticky-header, .table-responsive, [class*=table-responsive-] {
  margin-bottom: 0px !important;
}

::v-deep .user-table table thead th {
  border-bottom: 1px solid lightgray;
  padding: 12px 24px !important;
}

::v-deep .user-table table td {
  padding: 16px 24px !important;
  vertical-align: middle !important;
}

.center-content {
  display: flex;
  justify-content: start;
  align-items: center;
}

/deep/ .user-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.table-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  color: #575759;
}

.column-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.column-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
}

.edit-delete-user {
  color: #006F41;
}

.edit-delete-user:hover {
  cursor: pointer;
}

@media (max-width: 500px) {

  .button-locked-users {
    background: #F44271;
    color: #ffffff;
    transition: all .5s ease;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .users-btn {
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
  }


}

.edit-delete-user {
  color: #006F41;
}

.edit-delete-user:hover {
  cursor: pointer;
}

</style>
