<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "tags",
  components: {
    VueTagsInput,
  },
  data() {
    return {
      instanceId: this.$A.GetUUID(),
      tag: "",
      autoComplete: [],
      tags: [],
      actualTags: [],
    };
  },
  async mounted() {
    await this.updateTags();
  },
  methods: {
    updateTags: async function () {
      this.actualTags = await this.$A.TagsService.GetAll();
      let tags = [];
      let autoComplete = [];
      _.isArray(this.actualTags) && this.actualTags.forEach(function (t) {
        if (t.selected === undefined) {
          t.selected = false;
        }
        if (t.selected) {
          tags.push({ text: t.name });
        }
        autoComplete.push({ text: t.name });
      });
      this.autoComplete = autoComplete;
      this.tags = tags;
    },
    tagsUpdated: async function (newTags) {
      //update selected vs non selected
      for (let i = 0; i < this.actualTags.length; i++) {
        this.actualTags[i].selected = false;
        for (let i2 = 0; i2 < newTags.length; i2++) {
          if (this.actualTags[i].name === newTags[i2].text) {
            this.actualTags[i].selected = true;
          }
        }
      }

      //add non existant tags via the api
      for (let i2 = 0; i2 < newTags.length; i2++) {
        let tagExists = false;
        for (let i = 0; i < this.actualTags.length; i++) {
          if (this.actualTags[i].name === newTags[i2].text) {
            this.actualTags[i].selected = true;
            tagExists = true;
          }
        }
        if (!tagExists) {
          let newTag = await this.$A.TagsService.Save({
            name: newTags[i2].text,
          });
          newTag.selected = true;
          this.actualTags.push(newTag);
        }
      }

      //update Auto Complete
      let autoComplete = [];
      this.actualTags.forEach(function (t) {
        autoComplete.push({ text: t.name });
      });
      this.autoComplete = autoComplete;
    },
    getSelectedTagIds: function () {
      let tIds = [];
      this.actualTags.forEach(function (t) {
        if (t.selected) {
          tIds.push(t.id);
        }
      });
      return tIds;
    },
    getSelectedTags: function () {
      let t = [];
      this.actualTags.forEach(function (tag) {
        if (tag.selected) {
          t.push(tag);
        }
      });
      return t;
    },
    setCurrentTags: async function (tagIdArray, setSelected) {
      await this.updateTags();
      let tags = [];
      let autoComplete = [];
      _.isArray(this.actualTags) && this.actualTags.forEach(function (t) {
        if (t.selected === undefined) {
          t.selected = false;
        }
        if (tagIdArray.indexOf(t.id) !== -1) {
          tags.push({ text: t.name });
          t.selected = t.selected || setSelected;
        }
        autoComplete.push({ text: t.name });
      });
      this.autoComplete = autoComplete;
      this.tags = tags;
    },
  },
  computed: {
    getAutoComplete: function () {
      let curTag = this.tag;

      return this.autoComplete
        .filter((a) => a.text.toLowerCase().indexOf(curTag.toLowerCase()) > -1)
        .splice(0, 20);
    },
  },
  props: {
    isCreateTeamSideBar: {
      required: false,
      type: Boolean
    }
  }
};
</script>

<template>
  <div class="w-100">
    <vue-tags-input
        :placeholder="isCreateTeamSideBar ? 'Search and Select...':'Search...'"
        :class="isCreateTeamSideBar ? 'mt-1 px-0 ': '' "
        v-model="tag"
        :tags="tags"
        :autocomplete-items="getAutoComplete"
        @tags-changed="(newTags) => tagsUpdated(newTags)"
    >
      <template slot="autocomplete-header">
        <span>Select an Existing Tag</span>
      </template>
      <template slot="autocomplete-footer">
        <small> Create a New Tag by Selecting Enter </small>
      </template>
    </vue-tags-input>
  </div>
</template>

<style scoped>

/deep/ .vue-tags-input {
  width: 100% !important;
}

</style>