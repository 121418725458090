<script>

export default {
  name: "create-checklist-item",
  components: {},
  props: {
    existingChecklist: {
      required: false,
      type: Object
    }
  },
  async mounted() {
    let self = this;

    if (this.existingChecklist) {
      await this.loadChecklist();
    }

    this.$root.$on("edit::checklist::item", async function () {
      if (this.existingChecklist) {
        await this.loadChecklist();
      }
      this.alertMessage = "";
    });

    this.$root.$on("alert::user", (message, type, timeout) => {
      if (timeout === undefined) {
        timeout = 5000;
      }
      self.alertMessage = message;
      self.alertType = type;

    });
  },
  computed: {},
  methods: {
    AlertUser(message, type) {
      let self = this;
      self.alertMessage = message;
      self.alertType = type;
      setTimeout(function () {
        self.alertMessage = "";
        self.alertType = "";
      }, 5000);
    },
    Save: async function () {
      this.name = this.$refs.checklist_name.value;

      if (!this.name) {
        this.$A.AlertUser("Please Add a Checklist Name");
        return;
      }

      // if (!this.checklistItems || this.checklistItems.length == 0) {
      //   this.AlertUser("Please Add at Least One Checklist Item", "warning");
      //   return;
      // }

      let invalidItem = this.checklistItems.find(item => !item.name);
      if (invalidItem) {
        this.$A.AlertUser("Please Add a Checklist Item Text");
        return;
      }

      let checklistItem = {
        id: this.checkListId,
        name: this.name,
        accountWide: this.accountWide,
        items: this.checklistItems
      }
      const savedChecklist = await this.$A.ProofChecklistService.SaveProofChecklist(checklistItem);
      if (savedChecklist.id > 0) {
        this.showSideBar = false;
        const originalIds = this.editChecklistItems.map(i => i.id);
        let savedItems = {};
        for (const item of this.checklistItems) {
          const savedItem = await this.$A.ProofChecklistService.SaveProofChecklistProperty(savedChecklist.id, item.id, {
            workflowId: item.workflowId,
            stageId: item.stageId,
            checklistId: savedChecklist.id,
            name: item.name
          })
          savedItems[savedItem.id] = true;
        }
        for (const id of originalIds) {
          if (!savedItems[id]) {
            await this.$A.ProofChecklistService.DeleteProofChecklistProperty(savedChecklist.id, id);
          }
        }

        this.editChecklistItems = this.checklistItems.slice();

        this.$root.$emit("check-list-item:saved:success");
        this.cancel();
      }
    },
    EditProofProofRequirement(pr) {
      this.$bvModal.show("EditRequirement");
      this.checklistRequirement = pr;
      this.alertMessage = "";
    },
    cancel: function () {
      this.showSideBar = false;
      this.alertMessage = "";
      this.checklistItems = [];
      this.editChecklistItems = [];
      this.checkListId = 0;
      this.alertMessage = "";
      this.name = "";
      this.$root.$emit("checklist_item:canceled")
    },
    checkForm: function () {
      this.errors = [];
      this.validated = true;
      if (this.checklistItems.length < 0) {
        this.errors.push('Please "Add Checklist Item"');
      }
      if (!this.errors.length) {
        return true;
      }
    },
    addChecklistItem: async function () {
      this.checklistItems.push({});
    },
    loadChecklist: async function () {
      if (this.existingChecklist) {
        this.checkListId = this.existingChecklist.id;
        let proofChecklistItem = await this.$A.ProofChecklistService.GetProofChecklist(this.checkListId);
        this.name = proofChecklistItem.name;
        this.accountWide = proofChecklistItem.accountWide;
        this.checklistItems = proofChecklistItem.items;
        this.editChecklistItems = proofChecklistItem.items.slice();
        this.alertMessage = "";
      } else {
        this.name = "";
        this.accountWide = false;
        this.checklistItems = [];
        this.editChecklistItems = [];
        this.checkListId = 0;
      }
    },
    deleteChecklistProperty: function (index) {
        this.checklistItems.splice(index, '1');
    },
    setAlertClassColor: function () {
      if (this.alertType && this.alertType === 'warning') {
        return 'alert-warning';
      } else if (this.alertType && this.alertType === 'success') {
        return 'alert-success';
      } else if (this.alertType && this.alertType === 'error') {
        return 'alert-error';
      }
      return '';
    }
  },
  data() {
    return {
      showSideBar: false,
      editingLabel: "",
      errors: [],
      validated: false,
      notEditable: false,
      lockedOptionsCount: 0,
      name: "",
      accountWide: false,
      checklistItems: [],
      editChecklistItems: [],
      checkListId: 0,
      alertMessage: ""
    };
  },
  watch: {
    existingChecklist: function () {
      this.$nextTick(this.loadChecklist);
    },
  }
};
</script>

<template>
  <b-sidebar
      id="create-checklist-sidebar-right"
      right shadow
      v-model="showSideBar"
      @hidden="cancel"
      :no-header-close="true"
  >
    <div v-if="alertMessage" :class="'alert mb-0 position-absolute w-100 text-center font-weight-normal ' + setAlertClassColor()"
         style="z-index: 3000" role="alert">
      {{ alertMessage }}
    </div>
    <!-- Create Checklist Header -->
    <div class="checklist-header-container">
      <!-- Success message -->
      <div class="sidebar-workflow-header">Manage Checklist</div>
      <span class="sub-text">Your checklist can be used to require acknowledgement from approvers</span>
    </div>
    <!-- Checklist Name -->
    <div class="checklist-field-container d-flex flex-direction-row align-items-center border-bottom flex-wrap">
      <span class="field-text col-md-3 px-0">Checklist Name</span>
      <b-input type="text"
               class="field-input col-md-9"
               name="ChecklistName"
               ref="checklist_name"
               v-model="name"
      >
      </b-input>
    </div>
    <!-- Checklist Container -->
    <div v-if="showSideBar" class="checklist-field-container">
      <p v-show="errors && errors.length" class="text-danger">{{ errors[0] }}</p>
      <form role="form" @submit="checkForm">
        <div class="form-group">
          <div class="ashore-toggle mr-5 checklist-text" id="create_default_checklist">
            <label class="switch">
              <input type="checkbox" v-model="accountWide" :disabled="accountWide"/>
              <div class="slider round"></div>
            </label>
            Set as my account default checklist
          </div>
          <span class="checklist-items">Create a checklist your approvers must acknowledge in this stage:</span>
          <div
              class="d-flex justify-content-between"
              v-for="(item, index) in checklistItems"
          >
            <input
                name="label"
                type="text"
                class="mt-3 mr-2 property-form-input form-control"
                v-model="checklistItems[index].name"
                required
            />
            <b-icon
                icon="trash"
                variant="danger"
                @click="deleteChecklistProperty(index)"
                v-model="checklistItems[index].name"
                class="delete-icon"
            >
            </b-icon>
          </div>
          <b-button class="gray-btn w-md-25 w-sm-100 ml-auto mt-2" @click="addChecklistItem()">
            Add Checklist Item
          </b-button>
        </div>
      </form>
    </div>
    <!-- Footer -->
    <template #footer="{ hide }">
      <!--  Buttons  -->
      <div class="d-flex create-checklist-footer border-top col-md-12 justify-content-end">
        <b-button class="checklist-btn-white button ml-3 mb-0" @click="hide">Cancel</b-button>
        <b-button class="checklist-btn-green button ml-3 mb-0" @click="Save()">Save Checklist</b-button>
      </div>
    </template>
  </b-sidebar>
</template>


<style scoped>

/deep/ .b-sidebar {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 672px;
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-body {
  background: white;
}

/deep/ .b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}

.checklist-header-container {
  background: #F4F4F6;
  padding: 24px;
  border-bottom: 1px solid lightgray;
}

.checklist-items {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
  margin-bottom: 4px;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707073;
  margin-top: 4px;
}

.checklist-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #252526;
  margin-bottom: 10px;
}

.field-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.field-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-bottom: 10px;
}

.checklist-field-container {
  padding: 20px 24px;
  background-color: white;
}

.create-checklist-footer {
  padding: 20px 24px;
  background: #f4f4f6;
  color: #575759;
}

.checklist-btn-white {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #f9f9f9;
  color: #3e3e40;
}

.checklist-btn-white:hover, .checklist-btn-green:hover {
  opacity: 0.5;
  transition: all 0.5s ease;
}

/deep/ .checklist-redactor .redactor-box.redactor-styles-on {
  border: 0px !important;
}

/deep/ .checklist-redactor .redactor-focus.redactor-styles-on, .redactor-focus:focus.redactor-styles-on {
  box-shadow: none;
}

/deep/ .form-control:focus, .redactor-focus.redactor-styles-on, .redactor-focus.redactor-styles-on {
  border: 1px solid #B5B5B8 !important;
}

.checklist-btn-green {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: 400 !important;
  box-shadow: 0 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border: 1px solid #b5b5b8;
  height: 38px;
  transition: all .5s ease;
  background: #019256;
}

.checklist-btn-green:hover {
  opacity: 0.5;
  transition: all 0.5s ease;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 182px;
  height: 38px;
  background: #EEF2F5;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 14px;
}

.property-form-input {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  display: block;
  width: 100%;
  height: 37px;
  padding: 2px 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #444444;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid lightgray;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 5px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.delete-icon {
  width: 22px;
  height: 22px;
  margin-top: 23px;
}

.delete-icon:hover {
  cursor: pointer;
}


</style>