<script>
import redactor from "../../components/form/redactor.vue";

export default {
  name: "archiveProofModal",
  components: {
    redactor,
  },
  props: {
    name: {
      required: true,
      type: String,
    },
    proofId: {
      required: false,
      type: Number,
    },
    proof: {
      required: false,
      type: Object,
    },
    curUser: {},
  },
  methods: {
    archive: async function () {
      let note = this.$refs.redactor.getTextBody();
      this.$refs.redactor.setTextBody("");
      this.$emit(
          'confirmed',
          {note:note, proofId: this.$props.proofId});
      this.$bvModal.hide(this.name);
    },
    openModal() {
      this.open();
    },
    open() {
      this.$bvModal.show(this.name);
    },
  },
  computed: {
    commentFileApi: function () {
      if (this.proof) {
        const apiToken = _.get(this, 'curUser.apiToken.token', "")
        let apiUrl = `/annotation/comment-file/${this.proof.publicId}?u=${apiToken}`;
        return apiUrl;
      }
      return "";
    },
  }
};
</script>

<template>
  <b-modal :id="name" size="lg">
    <template v-slot:modal-header>
      <alertHeader />
      <div class="w-100">
        <h4>Archive These Proofs?</h4>
        These proofs will no longer be accessible to approvers, and you will be unable to send new versions. You will have full access to files and timeline.
      </div>
    </template>

    <h6>Note: </h6>
    <redactor ref="redactor"
              :comment-api-url="commentFileApi"
    />

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            class="button gray-btn"
            @click="cancel()"
        >Cancel</b-button
        >
        <b-button
            class="button green-btn"
            @click="archive()"
        >Yes, Archive</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}


@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
  }
}


</style>
