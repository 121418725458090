<script>
export default {
  name: "profileIntegrations",
};
</script>

<template>
  <!-- Field -->
  <div class="col-md-10 m-auto">
    <div class="field-item">
      <div class="row">
        <div class="col-md-12">
          <h3>Your Integrations</h3>
          <p>Connect Ashore to Your Applications</p>
        </div>
      </div>
      <div class="row mt-25">
        <div class="col-md-12">
          <a class="button button-dark button-gray button-block mt-10"
            >Connect Ashore to Zapier</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- /Field -->
</template>

<style scoped>
.field-item h3 {
  font-size: 18px;
}
</style>
