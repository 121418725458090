<template>
  <div>
    <navigation></navigation>
    <pageheader
      title="Proofs"
      buttonlink="/create"
      buttonfloat="float: right;"
      demostyle="display: none;"
    />
    <div class="container-fluid">
      <div class="row">
        <sidebarproof />
        <div class="col-md-9 ">
          <prooflist />
        </div>
      </div>
    </div>
<!--    <copyright />-->
    <modalManageTags />
    <modalManageTagFilters />
    <renameItem />
    <v-tour
      name="proof-archive"
      :steps="steps"
      :callbacks="tourCallbacks"
    ></v-tour>
  </div>
</template>

<script>
import sidebarproof from "../components/proof-archive/sidebar-proof.vue";
import prooflist from "../components/proof-archive/proof-list.vue";
import modalManageTags from "../components/modals/modal-managetags.vue";
import modalManageTagFilters from "../components/modals/modal-managetagsfilters.vue";
import renameItem from "../components/modals/modal-renameItem";

export default {
  components: {
    sidebarproof,
    prooflist,
    modalManageTags,
    modalManageTagFilters,
    renameItem,
  },
  data() {
    return {
      user: this.$A.Auth,
      proofs: [],
      steps: [
        {
          target: "#proofs_item",
          content: `Every proof created on your account lives here. Those icons show what file types are included in the proof. They are also color coded. Green means “Approved”, for example.`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: "#proofs_sort",
          content:
            "You can sort proofs by status. You can also sort by workflow or current sender.",
          params: {
            placement: "right",
          },
        },
        {
          target: "#proofs_tags",
          content:
            "Tags allow you to categorize proofs however you’d like. You can assign proofs to tags anytime, and sort your proof archive by those tags here.",
          params: {
            placement: "right",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("proof-archive");
        },
      },
    };
  },
};
</script>
