<script>
import VueTagsInput from "@johmun/vue-tags-input";
import modalEditContact from "../modals/modal-editcontact.vue";

export default {
  name: "selectApprovers",
  props: ["SelectedApprovers", "index", "placeholder", "createNewContactText", "testingId", "isUpdateProofOptionsModal", "availableApprovers"],
  components: {
    VueTagsInput,
    modalEditContact,
  },
  data() {
    return {
      instanceId: this.$A.GetUUID(),
      tag: "",
      tags: [],
      autoComplete: [],
      contacts: [],
    };
  },
  async mounted() {
    await this.loadContacts(true);

    this.registerEvents();
    if (Array.isArray(this.SelectedApprovers))
      this.setSelectedApprovers(this.SelectedApprovers);
  },
  methods: {
    getSelectedApprovers: function () {
      return this.contacts.filter(function (a) {
        return a.selected;
      });
    },
    setSelectedApprovers: function (selectedApprovers) {
      for (let i2 = 0; i2 < selectedApprovers.length; i2++) {
        for (let i = 0; i < this.contacts.length; i++) {
          if (selectedApprovers[i2].id === this.contacts[i].id) {
            this.contacts[i].selected = true;
          }
        }
      }
      this.calcTags();
    },
    calcTags: function () {
      let tags = [];
      this.contacts.forEach(function (t) {
        if (t.selected)
          tags.push({
            text: t.name.capitalize() + " (" + t.email.toLowerCase() + ")",
          });
      });
      this.tags = tags;
    },
    loadContacts: async function (ignoreSelectionStatus, forceUpdate) {
      let contacts = this.contacts;

      if (Array.isArray(this.availableApprovers)) {
        contacts = this.availableApprovers;
      } else {
        if (contacts.length === 0 || (forceUpdate !== undefined && forceUpdate))
          contacts = await this.$A.ApproverService.AllIndexed();
      }

      let autoComplete = [];
      if (Array.isArray(contacts)) {
        for (let i = 0; i < contacts.length; i++) {
          autoComplete.push({
            text:
                contacts[i].name.capitalize() +
                " (" +
                contacts[i].email.toLowerCase() +
                ")",
          }); //+" - "+approvers[i].email
          if (!ignoreSelectionStatus) {
            contacts[i].selected = false;
          } else {
            this.contacts.forEach(function (i2) {
              if (i2.id === contacts[i].id) {
                contacts[i].selected = i2.selected;
              }
            });
          }
        }
        this.contacts = contacts;
        this.autoComplete = autoComplete;
        this.calcTags();
      }
    },
    registerEvents: function () {
      let self = this;
      this.$root.$on(
          "confirmed::selecting::approver::" + this.instanceId,
          async (selectedUsersFromModal) => {
            await self.loadContacts(false, true);
            self.calcTags();
          }
      );
      // this.$root.$on('load:contacts', async function () {
      //   await self.loadContacts(true, true);
      // });
    },
    approverCreated: async function (approver) {
      // approver = approver.approver;
      // console.log(approver, "created new approver and adding it to the selected list");
      approver.selected = true;
      this.contacts.push(approver);
      this.calcTags();
      // this.$root.$emit('load:contacts');
        let self = this;
        setTimeout(async function () {
          await self.loadContacts(true, true);
          self.$emit("contactsChanged");
        }, 500);
    },
    selectedContactChanged: function (newTags) {
      for (let i = 0; i < this.contacts.length; i++) {
        let selected = false;
        for (let i2 = 0; i2 < newTags.length; i2++) {
          if (
              this.contacts[i].name.capitalize() +
              " (" +
              this.contacts[i].email.toLowerCase() +
              ")" ===
              newTags[i2].text
          ) {
            this.contacts[i].selected = true;
            selected = true;
          }
        }
        if (!selected) {
          this.contacts[i].selected = false;
        }
      }
      this.$emit("contactsChanged");
    },
  },
  computed: {
    getAutoComplete: function () {
      let curTag = this.tag;

      return this.autoComplete
          .filter((a) => a.text.toLowerCase().indexOf(curTag.toLowerCase()) > -1)
          .splice(0, 20);
    },
  },
};
</script>

<template>
  <div>
    <div :id="instanceId" class="d-flex justify-content-md-start col-md-12 px-0">
      <vue-tags-input
          :placeholder=this.$props.placeholder
          v-model="tag"
          :tags="tags"
          :autocomplete-items="getAutoComplete"
          :add-only-from-autocomplete="true"
          @tags-changed="(newTags) => selectedContactChanged(newTags)"
          :data-cy=this.$props.testingId
          :class="!isUpdateProofOptionsModal ? 'px-0 w-100 col-11': 'px-0 w-100 col-12'"
      >
      </vue-tags-input>
      <!--  Add Icon -->
      <a v-b-modal="instanceId" class="add-icon col-md-1 pl-2"
         :data-cy="`${this.$props.testingId}-stage-${index}`"
         v-show="!isUpdateProofOptionsModal">
        <b-img src="/img/add-member-button.svg" style="margin-top: 2px"></b-img>
        <b>{{ this.$props.createNewContactText }}</b>
      </a>
    </div>
    <modalEditContact v-on:approverCreated="approverCreated" :instanceId="instanceId" :index="index + 1"
                      :key="instanceId+'modalEditContact'"/>
  </div>
</template>

<style scoped>

/deep/ .ti-new-tag-input-wrapper input::placeholder {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #909092;
}

/deep/ .ti-tag {
  background: #D1F6D4 !important;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3E3E40 !important;
}


</style>