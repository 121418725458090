<script>
export default {
  name: "proofSent",
};
</script>

<template>
  <!-- Modal Proof Sent Modal -->
  <b-modal id="modalproofSent" size="lg">
    <template v-slot:modal-header>
      <alertHeader />
      <div class="w-100">
        <h4>Nice! Your Workflow Has Started</h4>
        We’re sending your proof to the first approval stage now. Track your
        progress from the Proof Timeline.
      </div>
    </template>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
          href="/proof-item"
          class="button button-light button-green normal-button">View Proof</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
