<script>
import hubspotCalendar from "../form/hubspotCalendar";

export default {
  name: "modal-new-user-welcome",
  components: {
    hubspotCalendar
  },
  props: {
    name: {
      required: true,
      type: String,
    },
  },
  methods: {
    openModal() {
      this.open();
    },
    open() {
      this.$bvModal.show(this.name);
    },
  },
  computed: {}
}
</script>

<template>
  <!-- New User Welcome Modal -->
  <b-modal id="NewUserWelcomeModal" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="pt-4 px-4">
        <div class="font-weight-bold mb-3 header-text">Thanks for Signing Up for Ashore!</div>
        <div class="font-weight-normal sub-text mb-2">Schedule your 1:1 demo to get the most value out of your new
          account!
        </div>
      </div>
    </template>

    <hubspot-calendar/>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-end px-0 footer-buttons">
        <b-button class="gray-btn"
                  @click="cancel()"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

/deep/ .modal-header, .modal-body {
  padding: 0px !important;
}

/deep/ .modal-body {
  max-height: 700px;
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

</style>