<script>
export default {
  name: "sidebarWorkflowItem",
};
</script>

<template>
  <!--Sidebar -->
  <div class="col-md-3">
    <b-embed
      type="iframe"
      aspect="16by9"
      src="https://www.youtube.com/embed/xJUSW_w79l4?modestbranding=1&autohide=1&showinfo=0&controls=0”"
      allowfullscreen
    ></b-embed>
    <div class="sidebar options-sidebar workflowitem-sidebar">
      <h4>How to Create a Proof</h4>
      <p>
        Upload your files to immediately create a new proof review link in Ashore.
        You can share that link with your approvers and commenters,
        or you can automate your approval process by adding a workflow to the proof.
      </p>
      <h5 class="mt-25">Proof Options</h5>
      <p>
        You can manage the privileges for contacts on this proof. For example,
        you can allow approvers to download files in this proof, or not.
        You can also set a deadline or assign this proof to a tag in your proof archive.
      </p>
      <h5 class="mt-25">Proof Link</h5>
      <p>
        As soon as you’ve uploaded your files, you can create a proof link that you can share with an
        approver or commenter. If you want to go one extra step, you can add an approval workflow to this proof.
      </p>
    </div>
  </div>
  <!-- /Sidebar -->
</template>

<style scoped>
.workflowitem-sidebar {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>
