<script>
import sidebarApproverItem from "../components/approver-item/sidebar-approveritem.vue";
import prooflist from "../components/proof-archive/proof-list.vue";
import approverSettings from "../components/approver-item/approverSettings.vue";

export default {
  components: {
    sidebarApproverItem,
    approverSettings,
    prooflist,
  },
  data() {
    return {
      user: this.$A.Auth,
      editingApprover: null,
      avgRoundRevision: null,
      avgRate: null,
      steps: [
        {
          target: "#approver_information",
          content: `You can edit your contacts information here. If you’d like, you can also create automated proof reminders, too.`,
          params: {
            enableScrolling: false,
            placement: "top",
          },
        },
      ],
      tourCallbacks: {
        onStop: function () {
          window.$A.SaveCompletionOfProductTours("approver-item");
        },
      },
    };
  },
  mounted() {
    if (this.$A.viewToViewData && this.$A.viewToViewData.approver) {
      this.handleLoadedApprover(this.$A.viewToViewData.approver);
      this.getStats(this.handleLoadedApprover(this.$A.viewToViewData.approver));
    }
    if (this.user.productTourState.indexOf("approver-item") === -1) {
      this.$tours["approverTour"].start();
    }
  },
  computed: {
    viewMode: function () {
      return this.editingApprover && this.editingApprover.totalProofs >= 1;
    },
  },
  methods: {
    handleLoadedApprover: function (approverWithStats) {
      this.editingApprover = approverWithStats;
    },
    getStats: async function () {
      let stats = await this.$A.ApproverService.GetWithStats(this.editingApprover.id);
      this.avgRoundRevision = stats.avgRoundsOfRevision;
      this.avgRate = stats.avgResponseRate;
    },
  },
};
</script>

<template>
  <div>
    <navigation/>
    <div class="container-fluid nav-margin">
      <div class="row mt-50">
        <sidebarApproverItem :approver="editingApprover"
                             :avg-rounds-of-revision="avgRoundRevision"
                             :avg-response-rate="avgRate"
        />
        <div class="col-md-9">
          <!-- Field -->
          <div class="field-item mb-50">
            <div class="row">
              <div class="col-md-12 mb-25">
                <h3 id="approver_information">Contact Information</h3>
              </div>
            </div>
            <approverSettings
                ref="editApproverInstance"
                v-on:approverCreated="handleLoadedApprover"
            />
            <div class="row">
              <div class="col-md-12 d-flex justify-content-end">
                <a data-cy="saveApproverBtn"
                   class="green-btn button"
                   @click="$refs.editApproverInstance.save()">Save</a>
              </div>
            </div>
          </div>
          <prooflist v-if="editingApprover && editingApprover.id" :approverId="editingApprover !== null ? editingApprover.id : null" :view-mode="viewMode" :contact-proof-view-mode="viewMode"/>
        </div>
      </div>
    </div>
    <modal
        name="deleteApproverModal"
        eventToEmitOnConfirmation="delete::approver::confirmed"
        question="Delete This Contact?"
        body="Are you sure you want to delete this contact? You cannot undo this action."
        confirm-button-text="Yes, Delete"
    />
    <v-tour
        name="approverTour"
        :steps="steps"
        :callbacks="tourCallbacks"
    ></v-tour>
  </div>
</template>

<style scoped>

#approver_information {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.green-btn {
  background: #019256;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 130px;
  height: 38px;
}

@media screen and (max-width: 767px) {

  .green-btn {
    margin-bottom: 0px !important;
  }


}


</style>