<script>
export default {
  name: "editUser",
  data: function () {
    return {
      editingUser: {
        name: "",
        title: "",
        phone: "",
        permissions: {
          role:
              {
                roleId: 2,
              },
        },
      },
      errors: [],
      validated: false
    };
  },
  methods: {
    checkForm: function () {
      this.errors = [];
      this.validated = true;

      if (!this.editingUser.name) {
        this.errors.push("Name required.");
      }
      if (!this.editingUser.title) {
        this.errors.push("Title required.");
      }
      if (!this.editingUser.phone) {
        this.errors.push("Phone required.");
      }
      if (!this.editingUser.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.editingUser.email)) {
        this.errors.push('Please enter a valid email.');
      }

      if (!this.errors.length) {
        return true;
      }
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validatePhoneNumber: function (phone) {
      let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(phone);
    },
    resetEditingUser: function () {
      this.validated = false;
      this.editingUser = {
        name: "",
        title: "",
        phone: "",
        email: "",
        permissions: {
          role:
              {
                roleId: 2,
              },
        },
      };
    },
    AddUser: function () {
      this.resetEditingUser();
      this.$bvModal.show("EditUser");
    },
    EditUser: function (user) {
      if (user === undefined) {
        this.resetEditingUser();
      } else {
        this.editingUser = user;
      }
      this.$bvModal.show("EditUser");
    },
    SaveUser: async function () {
      if (this.checkForm()) {
        let allUsers = await this.$A.AccountService.SaveAccountUser(
            this.editingUser
        );
        this.$emit("account_user_updated", allUsers);
        this.$bvModal.hide("EditUser");
        this.resetEditingUser();
      }
    },
  },
};

</script>

<template>
  <!-- Edit User Information Modal -->
  <b-modal id="EditUser" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <h4>User Information</h4>
      </div>
    </template>

    <form role="form" @submit="checkForm">
      <p v-if="errors.length">
        <b>Please correct the following error(s):</b>
      <ul>
        <li class="col-12" v-for="(error, index) in errors" :key="error.id"> {{ error }}</li>
      </ul>
      </p>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input
                name="userName"
                type="text"
                placeholder="Name"
                :class="'form-control ' + (validated ? (editingUser.name !== '' ? 'is-valid':'is-invalid') : (!validated && editingUser.name !== '' ? 'is-valid':''))"
                v-model="editingUser.name"
                required
            />
            <div v-show="editingUser.name === ''"
                 class="invalid-feedback">
              Please provide a name.
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input
                name="userTitle"
                type="text"
                placeholder="User Title"
                :class="'form-control ' + (validated ? (editingUser.title !== '' ? 'is-valid':'is-invalid'):(!validated && editingUser.title !== '' ? 'is-valid':''))"
                v-model="editingUser.title"
                required
            />
            <div v-show="editingUser.title === ''"
                 class="invalid-feedback">
              Please provide a title.
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input
                name="userPhone"
                type="text"
                placeholder="User Phone Number"
                :class="'form-control ' + (validated ? ((editingUser.phone !== '' && validatePhoneNumber(editingUser.phone)) ? 'is-valid':'is-invalid'):(!validated && validatePhoneNumber(editingUser.phone) ? 'is-valid':''))"
                v-model="editingUser.phone"
            />
            <div v-show="!validatePhoneNumber(editingUser.phone)" class="invalid-feedback">
              Please provide a valid phone number.
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <select
                class="form-control form-select"
                v-model="editingUser.permissions.role.roleId"
            >
              <option value="1">Admin | Full Access</option>
              <option value="2">
                Sub-User | Cannot Access Account Settings, Billing or Delete
                Proofs
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6" v-show="editingUser.id === undefined">
          <div class="form-group">
            <input
                name="userEmail"
                type="email"
                placeholder="User Email"
                :class="'form-control ' + (validated ? (editingUser.email && validEmail(editingUser.email) ? 'is-valid':'is-invalid'): (!validated && validEmail(editingUser.email) ? 'is-valid':''))"
                v-model="editingUser.email"
                required
            />
            <div v-show="!validEmail(editingUser.email)"
                 class="invalid-feedback">
              Please provide a valid email.
            </div>
          </div>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-end px-0">
        <b-button
            class="gray-btn mt-0"
            @click="cancel()"
        >Cancel
        </b-button
        >
        <b-button
            class="green-btn mt-0 ml-2"
            @click="SaveUser()"
        >Save
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #DEE3EC;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin-top: 14px;
  cursor: pointer;
}

.green-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019255;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.green-btn:hover {
  opacity: 0.9;
}

</style>
