<script>
import redactor from "../../components/form/redactor.vue";

export default {
  name: "modalEditApprovers",
  components: {
    redactor,
  },
  methods: {
    open: function (proof, stage) {
      this.proof = proof;
      this.stage = stage;
      this.$bvModal.show("modalEditApprovers");
    },
    editApprover: function (approver) {
      this.$root.$emit("modal::edit::contact", approver);
    },
    editApproverStatus: function (approver) {
      // console.log(approver);
      this.mode = 2;
      this.editingApprover = approver;
    },
    saveApproverStatus: async function (statusType) {
      // console.log(this.editingApprover);
      let newStatus = {
        statusId: statusType,
        note: this.$refs.redactor.getTextBody(),
      };
      // console.log(newStatus);
      let proof = await this.$A.ProofService.UpdateApproverStatus(
          this.proof.id,
          this.editingApprover.id,
          this.stage.id,
          newStatus
      );

      if (proof.id > 0) {
        this.proof = proof;
        // console.log(
        //   this.proof.currentVersion.stages.filter((s) => s.id === this.stage.id)
        // );
        this.stage = this.proof.currentVersion.stages.filter(
            (s) => s.id === this.stage.id
        )[0];
        this.mode = 1;
        const redactor = _.get(this,'$refs.redactor')
        redactor && redactor.setTextBody("");
        this.$root.$emit("proof::refresh", {proof: proof});
      }
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    getStatusBg: function (approver) {
      let statusColors = "";
      if (approver) {
        switch (approver.workflowStageApproverStatus) {
          case 1:
            statusColors = "background-color:rgb(253, 240, 189); color:rgb(87, 69, 0)";
            break;
          case 2:
            statusColors = "background-color:rgb(253, 240, 189); color:rgb(87, 69, 0)";
            break;
          case 3:
            statusColors = "background-color:#FABBC0;color:#CC333F ";
            break;
          case 4:
            statusColors = "background-color:#B8F1BD;color:rgb(38, 76, 41)";
            break;
          default:
            statusColors = "background-color:#D1F6D4; color:#264C29";
            break;
        }
      }
      return statusColors;
    }
  },
  computed: {
    approvers: function () {
      return this.stage === null || this.stage.approvers === undefined
          ? []
          : this.stage.approvers;
    },
    reviewers: function () {
      return this.stage === null || this.stage.reviewers === undefined
          ? []
          : this.stage.reviewers;
    },
    participants: function () {
      if (this.stage === null) return [];
      let all = [];
      if (this.stage.approvers) {
        all.push(...this.stage.approvers.map(a => ({isApprover: true, ...a})));
      }
      if (this.stage.reviewers) {
        all.push(...this.stage.reviewers);
      }
      return all;
    }
  },
  data() {
    return {
      mode: 1,
      editingApprover: null,
      stage: null,
      proof: null,
      fields: [
        {
          key: 'name',
          sortable: false,
          label: 'NAME'
        },
        {
          key: 'status',
          sortable: false,
          label: "STATUS",
        },
        {
          key: 'overwriteStatus',
          sortable: false,
          label: "",
        },
        {
          key: 'editInfo',
          sortable: false,
          label: "",
        },
      ],
      isMobile: window.innerWidth < 991,
    };
  },
};
</script>

<template>
  <!-- Edit Approvers Modal -->
  <b-modal id="modalEditApprovers" size="lg" no-stacking>
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100" v-show="mode === 1">
        <div class="header-text px-4 pt-4" style="margin-bottom: 20px">Approvers in Stage</div>
      </div>
      <div class="w-100" v-show="mode === 2" style="margin-bottom: 20px">
        <div class="header-text px-4 pt-4" style="margin-bottom: 20px">Overwrite Status
        </div>
        <span class="sub-text px-4">Optional: Add a note about this update </span>
      </div>
    </template>
    <b-table class="mt-3 rounded approvers-table table-borderless mb-4"
             :items="participants"
             :fields="fields"
             responsive
             hover
             outlined
             thead-class="approvers-table-bg"
             v-show="mode === 1 && participants.length"
    >
      <template #head()="data">
        <span class="table-header-text">{{ data.label }}</span>
      </template>
      <template #cell(name)="data">
        <div class="row-cols-4">
          <span class="column-header-text">{{ data.item.name }}</span>
        </div>
      </template>
      <template #cell(status)="data">
        <div v-if="data.item.isApprover" :class="isMobile ? 'status-rounded-pill': ' status-rounded-pill ml-4'"
             :style="getStatusBg(data.item)">
          {{ data.item.workflowStageApproverStatusLabel }}
        </div>
        <div v-else :class="isMobile ? 'status-rounded-pill-reviewer': 'status-rounded-pill-reviewer ml-4'">
          Commenting Only
        </div>
      </template>
      <template #cell(overwriteStatus)="data">
        <div class="text-center">
          <a v-if="data.item.isApprover" @click="editApproverStatus(data.item)" class="approver-link">
            Overwrite Approval Status
          </a>
        </div>
      </template>
      <template #cell(editInfo)="data">
        <div class="text-center">
          <a @click="editApprover(data.item)" class="approver-link">
            Edit Information
          </a>
        </div>
      </template>
    </b-table>
    <redactor v-if="mode === 2" ref="redactor"/>
    <div v-if="participants.length === 0">
      <div style="margin-bottom: 25px; margin-top: 15px">
        There are not currently any approvers in this stage. Add one by sharing your review link or editing the
        workflow!
      </div>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 justify-content-end align-items-center d-flex mobile-view" v-if="mode === 2">
        <b-button
            class="gray-btn-back mobile-btn-width"
            @click="mode = 1"
        >Back
        </b-button
        >
        <b-button
            class="gray-btn-waiting ml-2 mobile-btn-width"
            @click="saveApproverStatus(1)"
        >
          Waiting For Review
        </b-button
        >
        <b-button
            class="red-btn mobile-btn-width"
            @click="saveApproverStatus(3)"
        >
          Not Approved
        </b-button
        >
        <b-button
            class="green-btn mobile-btn-width"
            @click="saveApproverStatus(4)"
        > Approved
        </b-button>
      </div>
      <div class="w-100 d-flex justify-content-end" v-if="mode === 1">
        <b-button
            class="gray-btn-back mobile-btn-width"
            @click="cancel()"
        >Close
        </b-button>
      </div>
    </template>
  </b-modal>
  <!-- End Edit Approvers Modal -->
</template>

<style scoped>

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

::v-deep .modal-header {
  padding: 0px !important;
}

::v-deep .modal-body {
  padding: 0px 24px 24px 24px !important;
}

.gray-btn-back {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
  background: #EEF2F5;
}

.gray-btn-waiting {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
  background: lightgray;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  padding: 9px 17px !important;
}

.red-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
  height: 36px;
  background: #D44D68;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 9px 17px !important;
  margin-left: 10px;
}

.gray-btn-back:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

.gray-btn-waiting:hover {
  background-color: #EEF2F5;
}

.edit-btn:hover {
  cursor: pointer;
}

/deep/ .approvers-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
  border-bottom: 0.2px solid #EBEBED;
}

::v-deep .approvers-table .table, .table-responsive > .table, [class*=table-responsive-] > .table {
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0px;
}

::v-deep .approvers-table .table thead th {
  border-bottom: 0px;
  padding: 12px 24px 12px 44px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #707073;
}

::v-deep .approvers-table table td {
  vertical-align: middle !important;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.column-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.approver-link {
  color: #006F41;
  cursor: pointer;
}

.edit-delete-template:hover {
  cursor: pointer;
}

.status-rounded-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 20px;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #264C29;
  width: max-content;
}

.status-rounded-pill-reviewer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 20px;
  border-radius: 10px;
  width: max-content;
  background: #EBEBED;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #252526;
}

@media screen and (max-width: 991px) {

  ::v-deep .approvers-table .table, .table-responsive > .table, [class*=table-responsive-] > .table {
    width: 600px !important;
  }

}

@media screen and (max-width: 991px) {

  /deep/ .modal-footer {
    height: fit-content !important;
    flex-wrap: nowrap;
  }

  .mobile-view {
    flex-direction: column !important;
    width: 100%;
  }

  .mobile-btn-width {
    width: 100% !important;
    margin: 10px 0px 0px 0px !important;
  }


}


</style>