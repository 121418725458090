<script>
export default {
  props: [
    "selectedApproverIds",
    "title",
    "buttontext",
    "buttonlink",
    "demostyle",
    "buttonstyle",
    "searchstyle",
    "columns",
  ],
  name: "sidebarApprover",
  methods: {
    emitButtonClick: function ($event) {
      if (this.$props.buttonlink.length > 0) {
        $A.NavigateToView(this.$props.buttonlink, {});
      }
      this.$emit("linkClicked", $event);
    },
    broadcastSearchValues: function (e) {
      let self = this;
      clearTimeout(self.searchFunctionWaitForNoChanges);
      if (this.searchValue.length === 0 || e.keyCode === 13) {
        self.searchFunctionWaitForNoChanges = setTimeout(async function () {
          self.$root.$emit("page::search::value::updated", self.searchValue);
        }, 100);
      }
    },
    sortByStatus: function () {
      if (this.sortContactOptionSelected === 'sortByMostRecent') {
        this.$root.$emit("contacts::sort::most::recent", this.sortContactOptionSelected);
      }
      // Sort By Most Used
      if (this.sortContactOptionSelected === 'sortByMostUsed') {
        this.$root.$emit("contacts::sort::most::used", this.sortContactOptionSelected);
      }
    },
  },
  data() {
    return {
      searchValue: "",
      enterPressed: false,
      searchFunctionWaitForNoChanges: false,
      sortContactsOptions: [
        {status: 'sortByMostRecent', label: "Most Recent"},
        {status: 'sortByMostUsed', label: "Most Used"}
      ],
      sortContactOptionSelected: 'sortByMostRecent',
    }
  },
  mounted() {
  },
  computed: {
    hasSelectedApprovers: function () {
      for (const [key, value] of Object.entries(this.selectedApproverIds)) {
        if (!value) {
          delete this.selectedApproverIds[key];
        }
      }
      return Object.keys(this.selectedApproverIds).length > 0;
    }
  }
};
</script>

<template>
  <div class="row mb-25 d-flex align-items-baseline contacts-wrapper">
    <div class="col-md-4 mr-auto">
      <!-- Search Contacts -->
      <b-form-group>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text
                class="contact-search-input bg-white">
              <div class="h7">
                <b-icon-search></b-icon-search>
              </div>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
              class="contact-search-input border-left-0 pl-0"
              v-model="searchValue"
              type="text"
              placeholder="Search Contacts..."
              maxlength="30"
              size="25"
              v-on:keyup="broadcastSearchValues"
          />
        </b-input-group>
      </b-form-group>
    </div>
    <div class="col-lg-4 col-md-6 align-items-baseline text-right">
      <!-- Delete Contact -->
      <b-button
          v-if="hasSelectedApprovers"
          v-b-modal.deleteApproverModal
          class="delete-contact-btn mr-2">
        Delete
      </b-button>
      <!-- Create Contact -->
      <b-button
          id="approver_button"
          @click="emitButtonClick($event)"
          class="approver-btn"
      >
        Create Contact
      </b-button>
    </div>
  </div>
</template>

<!-- Saved -->
<!--  Sort Contacts  -->
<!--      <div class="sort-contacts-text">Sort Contacts</div>-->
<!--      <v-select-->
<!--          v-model="sortContactOptionSelected"-->
<!--          :options="sortContactsOptions"-->
<!--          label="label"-->
<!--          :reduce="option => option.status"-->
<!--          placeholder="Select"-->
<!--          class="contact-select"-->
<!--          :searchable="false"-->
<!--          :clearable="false"-->
<!--          @input="sortByStatus"-->
<!--      >-->
<!--      </v-select>-->

<style scoped>

.contact-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #575759;
  padding-bottom: 24px;
}

.sort-contacts-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
  margin-bottom: 4px;
}

.contact-search-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.contact-select ::v-deep .vs__dropdown-toggle {
  padding: 0px;
  width: 100%;
  height: 38px;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .filter-input .custom-control:focus {
  outline: none !important;
}

/deep/ .form-control:focus, .redactor-focus.redactor-styles-on, .redactor-focus:focus.redactor-styles-on {
  outline: 0;
  box-shadow: none;
  border-color: #B5B5B8 !important;
  border-left: none;
}

.approver-btn {
  padding: 9px 17px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  cursor: pointer;
  color: white !important;
}

.delete-contact-btn {
  padding: 9px 17px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #EB4654;
  background-color: white;
  border: 1px solid #EB4654;
  cursor: pointer;
}

@media screen and (max-width: 767px) {

  .approver-btn {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .contacts-wrapper {
    flex-direction: column-reverse;
    margin-bottom: 10px !important;
  }

}


</style>
