<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";

export default {
  name: "modalUploadImage",
  components: {
    Cropper,
  },
  props: {
    type: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loadingData: false,
      file: null,
      user: this.$A.Auth,
      cropper: {},
      destination:
          "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
      image: {},
      src:
          "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
    };
  },
  methods: {
    openModal: function () {
      this.$bvModal.show("UploadAndCropNewPhoto");
      let self = this;
      setTimeout(function () {
        self.image = self.$refs.image;
        self.loadCropper();
      }, 0);
    },
    loadCropper: function () {
      let options = {};
      if (this.$props.type === "profile") {
        options = {
          zoomable: false,
          scalable: false,
          aspectRatio: 1,
          minCropBoxWidth: 150,
          minCropBoxheight: 150,
          center: true,
        };
      }
      if (this.$props.type === "logo") {
        options = {
          zoomable: false,
          scalable: false,
          minCropBoxWidth: 150,
          minCropBoxheight: 150,
          center: true,
          cropBoxResizable: true,
        };
      }
      this.cropper = new Cropper(this.image, options);
    },
    loadNewImageSelected: function (e) {
      let self = this;
      setTimeout(function () {
        // console.log(self.file);
        let file = self.file;
        // if(e.target.files!==undefined && e.target.files.length!==0) {
        //     file = e.target.files[0];
        // }
        // if(file==null)
        //     return;

        let reader = new FileReader();
        self.loadingData = true;
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const elem = document.createElement("canvas");
            elem.width = img.width;
            elem.height = img.height;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width, img.height);
            const data = ctx.canvas.toDataURL(img, "image/png", 0.8);
            elem.remove();
            self.cropper.replace(data);
            self.loadingData = false;
          };
        };
      }, 100);
    },
    cropImage: function (runAfter) {
      const canvas = this.cropper.getCroppedCanvas();

      canvas.toBlob((blob) => {
        runAfter(blob);
      });
    },
    savePhoto: async function () {
      let self = this;
      this.cropImage(async function (dataBlob) {
        if (self.$props.type === "profile") {
          self.$A.Auth.profileImageUrl = await self.$A.UserService.UploadProfileImage(
              dataBlob
          );
          self.$root.$emit(
              "profile::photo::updated",
              self.$A.Auth.profileImageUrl
          );
        }
        if (self.$props.type === "logo") {
          self.$A.Auth.account.whiteLabelLogoUri = await self.$A.AccountService.UploadLogoPhoto(
              dataBlob
          );
          self.$root.$emit(
              "account::logo::updated",
              self.$A.Auth.account.whiteLabelLogoUri
          );
        }

        self.$A.SaveSessionData(self.$A.Auth);
        self.$bvModal.hide("UploadAndCropNewPhoto");
      });
    },
  },
  mounted() {
  },
};
</script>

<template>
  <div>
    <b-modal
        id="UploadAndCropNewPhoto"
        size="lg"
        ref="modal"
        no-close-on-backdrop
    >
      <template v-slot:modal-header>
        <alertHeader/>
        <div class="w-100">
          <h4>
            {{
              $props.type === "profile"
                  ? "Upload And Crop Profile Image"
                  : "Upload And Crop Account Logo Image"
            }}
          </h4>
          <p>Uploaded image must be 5MB or smaller</p>
          <b-spinner
              type="grow"
              label="Spinning"
              class="loading_data_spinner_internal"
              v-show="loadingData == true"
          ></b-spinner>
        </div>
      </template>

      <form role="form">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <b-form-file
                  data-cy="uploadProfileImg"
                  v-model="file"
                  ref="uploadFileDropper"
                  placeholder="Drag Image Here to Upload or Select File"
                  drop-placeholder="Drop file here..."
                  type="file"
                  accept=".jpg, .png, .gif"
                  @change="loadNewImageSelected"
              >
              </b-form-file>
            </div>
          </div>
        </div>

        <!--  I'd like to make this section v-show if an image has been uploaded -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <!--            <b-button @click="cropImage()" class="button button-light button-green menu-button">Crop</b-button>-->
              <div class="img-container">
                <img ref="image" :src="src"/>
              </div>
            </div>
          </div>
        </div>
      </form>

      <template v-slot:modal-footer="{ cancel }">
        <div class="d-flex justify-content-end align-items-center">
          <b-button
              class="button button-dark button-gray normal-button"
              @click="cancel()"
          >Cancel
          </b-button
          >
          <b-button
              data-cy="saveProfileImg"
              class="button button-light button-green normal-button"
              @click="savePhoto()"
          >{{
              $props.type === "profile"
                  ? "Save As Profile Photo"
                  : "Save As Account Logo"
            }}
          </b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style>

.img-container {
  width: 100%;
  overflow: hidden;
  height: 640px;
  float: left;
}

.img-preview {
  width: 150px;
  height: 150px;
  float: left;
  margin-left: 10px;
}

.loading_data_spinner_internal {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
