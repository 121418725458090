<script>
import Multiselect from 'vue-multiselect'


export default {
  name: "modalEditTeam",
  components: {
    Multiselect
  },
  props: {
    proof: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      user: this.$A.Auth,
      name: "",
      ashoreUsersTeamIds: [],
      teams: [],
      optionsPlaceholder: "Search and Select..."
    };
  },
  async mounted() {
    await this.loadTeams();
  },
  methods: {
    loadTeams: async function () {
      this.teams = await this.$A.TeamService.GetAccountTeams();
      // switch (_.get(this.user, 'permissions.proofAccess')) {
      //   case 3:
      //     // user can see all teams
      //     break;
      //   case 2:
      //     // user can only see its assigned teams
      //     this.teams = this.teams.filter(t => t.users.find(u => u.userId === this.user.id));
      //     break
      //   default:
      //     this.teams = [];
      // }
    },
    saveAndClose: function () {
      this.$root.$emit("proof::save::team", {
        ashoreUsersTeamIds: this.ashoreUsersTeamIds.map(t => t.id)
      });
      this.$bvModal.hide("EditProofTeam");
    },
    loadCurrentTeams: function () {
      this.ashoreUsersTeamIds = this.teams.filter(t => this.proof.teamsWithViewAccess.includes(t.id));
    }
  }
};
</script>

<template>
  <!-- Edit Proof Owner Modal -->
  <b-modal id="EditProofTeam" size="lg" @show="loadCurrentTeams">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100">
        <div class="header-text">Manage Teams for Proof</div>
      </div>
    </template>

    <form role="form">
      <div class="row">
        <div class="col-md-12">
          <span class="input-label font-weight-normal">Select Teams</span>
          <multiselect
              v-model="ashoreUsersTeamIds"
              :options="teams"
              :placeholder="optionsPlaceholder"
              :multiple="true"
              :show-labels="false"
              :hide-selected="true"
              :clear-on-select="true"
              :searchable="true"
              label="name"
              track-by="id"
              class="align-items-center w-100 mt-2"
          />
        </div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 col-md-12 d-flex justify-content-md-end px-0 footer-buttons">
        <b-button
            class="button gray-btn"
            @click="cancel()"
        >Cancel
        </b-button
        >
        <b-button
            class="button green-btn"
            @click="saveAndClose"
        >Update
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

::v-deep .multiselect__select {
  display: none !important;
}

::v-deep .modal-footer {
  border-top: none !important;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256 !important;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}


.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.input-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.edit-owner-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 12px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

::v-deep .edit-owner-input .vs__dropdown-toggle {
  border: none !important;
  width: -webkit-fill-available !important;
}

/deep/ .modal-body {
  padding: 20px 24px 24px 24px;
}

/deep/ .modal-header {
  padding: 24px 24px 0px 24px;
}

::v-deep .multiselect__tag {
  background: #D1F6D4 !important;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3E3E40 !important;
}

::v-deep .multiselect__tags {
  border: 0px !important;
}

::v-deep .multiselect {
  border: 1px solid #B5B5B8 !important;
  border-radius: 6px !important;
}

@media screen and (max-width: 1180px) {
  .footer-buttons {
    justify-content: end;
    margin-bottom: 0px !important;
    padding: 0px;
    align-items: center;
    margin-top: 12px !important;
  }

}


</style>

