<script>
import creditcard from '../../components/modals/modal-creditcard'
import invoices from '../../components/modals/modal-invoices'
import subscriptionCancelationModal from "../../components/modals/modal-cancel-subscription"

export default {
  name: 'pricePlan',
  methods: {
    cancelCurrentSub: async function (data) {
      let newSub = await this.$A.BillingService.CancelSubscription();
      if (newSub.success !== undefined && !newSub.success) {
        return;
      }
      await this.getCurrentSub();
      await this.$A.GetCurAccountData();
      this.$root.$emit('nav::reload');

      let slackTextMessage = `Cancellation Reason(s): ${data.reason}.\nFeatures Requested: ${data.features}\nAdditional Feedback: ${data.feedback}`
      await this.$A.FeedbackService.CreateSlackMessage({
        slackChannel: "ns_purchase_events",
        subject: "Subscription Cancellation Notice",
        text: slackTextMessage
      })
    },
    signupForPlan: async function () {
      let self = this;
      let isUpgrade = this.currentSubscription.planSku === "free" || this.currentSubscription.planSku === "suspended" || this.currentSubscription.planSku === "trial";
      if (this.currentSubscription.hasActivePaymentMethod) {
        let newSub = await self.$A.BillingService.UpdateSubscription(self.selectedPlan, self.couponCode);
        if (newSub.success !== undefined && !newSub.success) {
          return;
        }

        await self.getCurrentSub();
        await self.$A.GetCurAccountData();
        if (isUpgrade) {
          self.$A.NavigateToView("/thanks", {})
        }
      } else {
        this.stripe.createToken(this.stripeCardElement).then(async function (result) {
          if (result.error) {
            // Inform the user if there was an error.
            let errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
          } else {
            self.stripeCardToken = result.token.id;
            let currentPaymentMethod = await self.$A.BillingService.SetCurrentCardViaToken(self.stripeCardToken);
            let newSub = await self.$A.BillingService.UpdateSubscription(self.selectedPlan, self.couponCode);
            if (newSub.success !== undefined && !newSub.success) {
              return;
            }

            await self.getCurrentSub();
            await self.$A.GetCurAccountData();

            if (isUpgrade)
              self.$A.NavigateToView("/thanks", {})
          }
        });
      }
    },
    createCardCollectionElement: function () {
      let elements = this.stripe.elements();
      let style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
      this.stripeCardElement = elements.create('card', {style: style});
      this.stripeCardElement.mount('#card-element');
      this.stripeCardElement.addEventListener('change', function (event) {
        let displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    },
    getCurrentSub: async function () {
      this.currentSubscription = await this.$A.BillingService.GetCurrentSubscription() || this.emptySubscription();

      _.set(this, 'currentSubscription.plan.isUpgrade', false);
      _.set(this, 'currentSubscription.plan.isDownGrade', false);
      let plans = [];
      plans.push(this.currentSubscription.plan);
      this.currentSubscription.downGradeOptions.forEach(function (i) {
        i.isDownGrade = true;
        i.isUpgrade = false;
        plans.push(i)
      });
      this.currentSubscription.upgradeOptions.forEach(function (i) {
        i.isUpgrade = true;
        i.isDownGrade = false;
        plans.push(i)
      });

      this.plans = plans.sort(function (a, b) {
        return a.unitPrice - b.unitPrice;
      });
      await this.$A.UpdateCurrentSubscriptionState(_.get(this.currentSubscription, 'subscriptionState'));
    },
    selectPlan: function (plan) {
      this.selectedPlan = plan;
    },
    selectedSku: function() {
      return _.get(this, '$A.Auth.account.subscriptionState.sku', '');
    },
    emptySubscription: function() {
      return {
        planSku: this.selectedSku(),
        plan: {
          publicCyclePrice: ""
        },
        subscriptionState: {
          remainingProofsOnPlan: 0,
        },
        downGradeOptions : [],
        upgradeOptions : []
      };
    }
  },
  data() {
    return {
      stripeCardElement: null,
      currentPaymentMethod: null,
      couponCode: "",
      stripeCardToken: "",
      stripe: null,
      annualPlanSelected: false,
      selectedPlan: _.get(this.$A, 'Auth.account.subscriptionState.sku'),
      currentSubscription: this.emptySubscription(),
      hasExistingInvoices: false,
      user: this.$A.Auth,
      plans: [],
      isMobile: window.innerWidth < 991,
    }
  },
  async mounted() {
    await this.$A.GetCurAccountData();
    let self = this;
    if (this.$route.query.c !== undefined) {
      this.couponCode = this.$route.query.c.toUpperCase();
    }
    let stripeScript = document.createElement('script');
    stripeScript.setAttribute('src', "https://js.stripe.com/v3/");
    document.head.appendChild(stripeScript);
    stripeScript.onload = function () {
      self.stripe = Stripe(self.$A.Env.stripeKey);
      self.createCardCollectionElement();
    };
    await this.getCurrentSub()

  },
  computed: {
    currentlySelectedProduct: function () {
      let self = this;
      let selectedPlan = this.plans.filter(function (i) {
        return i.sku === self.selectedPlan
      })[0];
      if (selectedPlan === undefined) {
        return {
          isUpgrade: false,
          isDowngrade: false,
        }
      } else {
        return selectedPlan;
      }
    }
  },
  components: {
    creditcard,
    invoices,
    subscriptionCancelationModal
  }
}
</script>

<template>
  <div class="row">
    <div class="col-md-12 mb-25">
      <h2>Available Plans</h2>
    </div>

    <div class="col-md-7">
      <div class="ashore-toggle mb-3">
        <span style="padding-right: 10px">Monthly Plans</span>
        <label class="switch"><input v-model="annualPlanSelected" type="checkbox">
          <span class="slider round"></span>
        </label>
        <span>Annual Plans</span>
      </div>
      <div v-for="plan in plans" :key="plan.id" @click="selectPlan(plan.sku)"
           :class="{ selected_plan:selectedPlan === plan.sku,current_plan:currentSubscription.plan.sku===plan.sku}"
           v-show="annualPlanSelected && plan.cycleMonths===12 || !annualPlanSelected && plan.cycleMonths===1">
        <div class="pricing-plan row">
          <div class="col-md-4">
            <img :src="plan.iconUri">
            <h3>{{ plan.publicName }}</h3>
            <p v-if="plan.sku === 'premium-1-api'" class="mt-2" style="margin-bottom:0">$165/Month<br>Includes 5 Users
              <br> {{ plan.publicCyclePrice }}
            </p>
            <span v-if="plan.sku === 'premium-1-api'" style="margin-bottom:0; opacity: .5"><br/> $132/Month</span>
            <span v-if="plan.sku === 'premium-1-api'" style="margin-bottom:0; opacity: .5"><br/> Includes 5 Users</span>
            <span v-if="plan.sku === 'premium-1-api'" style="margin-bottom:0; opacity: .5"><br/> $33/Month/User</span>
            <p v-else class="mt-10" style="margin-bottom:0;">{{ plan.publicCyclePrice }}</p>
            <p v-show="
            (plan.cycleMonths===1)
            && (plan.monthlyCostWhenPaidAnnually
            && plan.monthlyCostWhenPaidAnnually !=='')
            && (plan.sku != 'premium-1-api')"
            >
              <span style="opacity: .5;">{{ plan.monthlyCostWhenPaidAnnually }}
                <span v-show="!(plan.id==28 || plan.id==29)">When Paid Annually</span>
              </span>
            </p>
            <span v-show="(plan.cycleMonths===12 && plan.description === 'Premium')">Includes 5 Users</span>
            <p v-show="(plan.cycleMonths===12)"><span style="opacity: .5;">{{ plan.annualDiscountPercent }}% Off Monthly Price</span>
            </p>
            <p v-if="plan.grandfatheredDate==null || plan.grandfatheredDate < new Date()">{{ plan.description }}</p>
            <p v-if="plan.grandfatheredDate!=null && plan.grandfatheredDate > new Date()" style="color: red;">Notice:
              Your access to this plan will be lost if you upgrade or downgrade.</p>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <h4>Features</h4>
                <ul class="price-details">
                  <li v-for="feature in plan.features">{{ feature }}</li>
                </ul>
              </div>
              <div class="col-md-6" v-show="plan.sku !=='suspended'">
                <h4>Proof Types</h4>
                <ul class="price-details">
                  <li v-for="type in plan.proofTypes">{{ type }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 offset-md-1" :style="isMobile ? 'padding-right: 0px; padding-left:0px': 'margin-top: 40px'">
      <div class="plan-details">
        <div class="active-plan" v-show="currentSubscription && currentSubscription.active">
          <h4 style="opacity:.5;">Your Subscription Plan:</h4>
          <h2>{{ currentSubscription.plan.publicName }}</h2>
          <div v-show="currentSubscription.plan.unitPrice>0">
            <p style="margin-top: 30px;">Subscription Renews:
              {{ $A.FormatDate(currentSubscription.currentPeriodEnd) }}</p>
            <p v-show="currentSubscription.subscriptionState.remainingProofsOnPlan < 1000000">Remaining Proofs:
              {{ currentSubscription.subscriptionState.remainingProofsOnPlan }}</p>
            <p v-show="currentSubscription.subscriptionState.remainingProofsOnPlan > 1000000">Remaining Proofs: <span
                class="inf">&infin;</span></p>
            <p>Subscription Cycle: {{ (currentSubscription.plan.cycleMonths === 12 ? "Yearly" : "Monthly") }}</p>
            <p>Subscription Cost: {{ currentSubscription.plan.publicCyclePrice }}</p>
            <p>Next Invoice Total: ${{ currentSubscription.nextInvoiceTotal }}</p>
          </div>

          <!--          <span style="margin-top: 50px;  margin-bottom: 0;" v-if="currentSubscription.usingCoupon.length>0">Attached Coupon: {{currentSubscription.usingCoupon}}</span>-->
          <p style="margin-top: 50px; opacity: .5; margin-bottom: 0;">Change your plan using the form below</p>
        </div>

        <div :class="isMobile ? 'form-group': 'form-group mt-50'" :style="isMobile ? 'margin-top: 25px': ''">
          <label>Select Plan:</label>
          <select name="select_plan" class="form-control form-select plan-input" v-model="selectedPlan">
            <option :value="plan.sku" v-for="plan in plans"
                    v-show="selectedSku() === plan.sku || annualPlanSelected && plan.cycleMonths === 12 || !annualPlanSelected && plan.cycleMonths === 1">
              {{ plan.publicName }} - {{ plan.publicCyclePrice }}
            </option>
          </select>
          <label v-show="couponCode!==''" class="mt-10 ">Coupon Code:</label>
          <input type="text" v-show="couponCode!==''" v-model="couponCode" class="form-control ">
          <!--          <p style="opacity: .75; margin-top: 15px;" v-show="selectedPlan!=='free'">-->
          <!--            <strong>Please note:</strong> If you go over your plan's file-->
          <!--            limit, we'll automatically charge your account $10 every 100 files.</p>-->
          <p style="opacity: .75; margin-top: 15px;" v-show="currentlySelectedProduct.proratedPriceForFirstCycle>0">
            <strong>Prorated Cost:</strong> We will prorate the first charge due to your existing subsription.
            ${{ currentlySelectedProduct.proratedPriceForFirstCycle }} is due today.</p>
        </div>
        <!--        <div style="margin: 25px 0;">-->
        <!--          <h4>Add-ons</h4>-->
        <!--          <div class="plan-toggle" id="proof_toggle_changes">-->
        <!--            <label class="switch">-->
        <!--              <input type="checkbox">-->
        <!--              <div class="slider round"></div>-->
        <!--            </label>-->
        <!--            <span style="line-height: 20px;">API Access | $50/Month</span>-->
        <!--            <img src="static/img/information-icon.svg" alt="Information" v-b-tooltip.hover-->
        <!--                 title="Get our access to our API, SDK, and a dedicated support representative">-->
        <!--          </div>-->
        <!--        </div>-->

        <form action="#" method="post" id="payment-form" ref="submitCreditCardForm" v-show="!currentSubscription.hasActivePaymentMethod && selectedPlan !== 'free'">
          <div class="form-row">
            <label for="card-element">
              Your Credit or Debit Card:
            </label>
          </div>
          <div class="form-row">
            <div id="card-element"></div>
            <div id="card-errors" role="alert"></div>
          </div>

        </form>
        <button v-show="currentlySelectedProduct.isUpgrade" class="button green-btn button-block mt-10 w-100" @click="signupForPlan()">Upgrade</button>
        <button v-show="currentlySelectedProduct.isDownGrade" class="button green-btn mt-10 button-block" @click="signupForPlan()">Downgrade</button>
        <b-button
            v-show="currentSubscription.hasActivePaymentMethod"
            id="creditcard"
            @click="$refs.updateCreditCardModal.open()"
            class="gray-btn mt-10 w-100">
          Update Credit Card
        </b-button>
        <b-button
            id="invoices"
            v-show="currentSubscription.plan.unitPrice>0"
            v-b-modal.invoices
            class="gray-btn mt-10 w-100">
          View Invoices
        </b-button>
        <b-button
            v-show="currentSubscription.plan.unitPrice>0"
            id="cancelSub"
            @click="$refs.cancelCurrentSubscriptionModal.open()"
            class="gray-btn mt-10 w-100">
          Cancel Subscription
        </b-button>
      </div>
    </div>
    <creditcard ref="updateCreditCardModal"/>
    <subscription-cancelation-modal
        ref="cancelCurrentSubscriptionModal"
        v-on:confirmed="cancelCurrentSub"
        :question="'Are you sure you want to cancel your '+ currentSubscription.plan.publicName +' subscription?'"
    />
    <invoices/>
  </div>
</template>

<style scoped>
.inf {
  font-size: 30px;
  font-family: cursive;
  line-height: 1px;
}

.current_plan .pricing-plan {
  border: 4px solid #019255 !important;
}

.selected_plan .pricing-plan {
  border: 4px solid #f4bf78;
}

.modal-toggle {
  margin: 15px auto 50px;
  display: block;
  width: 24%;
  padding-bottom: 0;
}

.pricing-plan {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 #6c737e;
  transition: all .3s ease;
  padding: 30px 15px;
  margin-bottom: 25px;
  cursor: pointer;
}

.price-details {
  margin-bottom: 30px;
}

.price-details img {
  height: 60px;
}

.pricing-plan h4 {
  font-size: 12px;
  font-weight: 900;
  color: #4a4a4a !important;
  text-transform: uppercase;
}

.pricing-plan h2 {
  margin-bottom: 30px;
  color: #4a4a4a !important;
  font-weight: 900;
}

.pricing-plan .list li {
  font-size: 12.4px;
  margin-top: 16px;
  color: #4a4a4a !important
}

.field-item span {
  opacity: 1;
  display: block;
  margin-top: 0px;
}

.ashore-toggle .slider {
  background-color: #DEE3EC;
}

.plan-toggle span {
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
}

.plan-toggle img {
  height: 20px;
  float: right;
}

.plan-toggle img:hover {
  cursor: pointer;
}

.pricing-plan h3 {
  color: #494949;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 35px;
  font-size: 14px;
  text-transform: uppercase;
}

.pricing-plan ul, .pricing-plan li {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.pricing-plan li, .pricing-plan p {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
}

.pricing-plan span {
  font-size: 12px;
  line-height: 1.5;
  padding: 5px 0;
  margin-bottom: 0;
}

.active-plan {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
}

.displaynone {
  display: none;
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.gray-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #DEE3EC;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
}

.gray-btn:hover {
  color: #444444;
  border: 1px solid #B5B5B8;
}

.plan-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

</style>
