import { render, staticRenderFns } from "./create-email-template-item.vue?vue&type=template&id=3e05433e&scoped=true&"
import script from "./create-email-template-item.vue?vue&type=script&lang=js&"
export * from "./create-email-template-item.vue?vue&type=script&lang=js&"
import style0 from "./create-email-template-item.vue?vue&type=style&index=0&id=3e05433e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e05433e",
  null
  
)

export default component.exports