<script>
export default {
  name: "selectTemplate",
  props: ["selectedTemplateId", "componentId", "index"], //todo store in stage object
  data() {
    return {
      instanceId: this.$A.GetUUID(),
      templateIdChosen: "0",
      templateSelected: null,
      templates: [],
    };
  },
  components: {},
  async mounted() {
    await this.loadTemplates();
    this.$root.$on("email::template::saved", this.loadTemplates);
    this.templateIdChosen =
        this.$props.selectedTemplateId === undefined ||
        this.$props.selectedTemplateId === null
            ? "Select Template"
            : this.$props.selectedTemplateId;
    let templateSelected = this.templates.filter(
        (t) => t.id === this.$props.selectedTemplateId
    );
    if (templateSelected.length === 1)
      templateSelected = templateSelected[0];
  },
  methods: {
    templateChosen: function () {
      if (this.templateIdChosen <= 0) return;
      this.templateSelected = this.templates.filter(
          (t) => t.id === this.templateIdChosen
      );

      if (this.templateSelected.length === 1) {
        this.$root.$emit("template::email::chosen", {
          template: this.templateSelected[0],
          componentId: this.$props.componentId,
        });
      }
    },
    loadTemplates: async function () {
      this.templates = await this.$A.TemplateService.GetAll();
    }
  },
};
</script>

<template>
  <div class="col-md-12 px-0">
    <v-select
        :id="instanceId"
        v-model="templateIdChosen"
        @input="templateChosen()"
        label="name"
        :reduce="template => template.id"
        class="email-template-select"
        :data-cy="`email-template-${index}`"
        :options="templates"
        placeholder="Select Template"
    >
    </v-select>
  </div>
</template>

<style scoped>

/deep/ .email-template-select .vs__dropdown-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .vs__search, .vs__search:focus {
  margin: 0px !important;
  padding: 0px !important;
}

/deep/ .vs__selected {
  margin: 0px !important;
  padding: 0px !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .email-template-select .vs__dropdown-menu {
  font-weight: 400 !important;
}

</style>