<script>
export default {
  name: "sidebarApproverItem",
  props: {
    approver: {
      required: true,
    },
    avgRoundsOfRevision: {
      required: true,
    },
    avgResponseRate: {
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {
  },
  watch: {},
};
</script>

<template>
  <div class="col-md-3 proof-timeline-sidebar">
    <div class="sidebar proof-sidebar">
      <div class="header-text">Contact Overview</div>
      <div class="link-item">
        <span class="sub-text">Average Response Rate</span>
        <a class="edit-button sub-text">
          {{ avgResponseRate}}
        </a>
      </div>
      <div class="link-item">
        <span class="sub-text">Average Rounds of Revision</span>
        <a class="edit-button sub-text">
          {{ avgRoundsOfRevision }}
        </a>
      </div>
      <hr/>
      <b-button
          modalDeleteItem="approver"
          v-b-modal.deleteApproverModal
          variant="link"
          class="link-item mt-25"
          v-show="approver !== null && approver.id > 0"
      >
        Delete Contact?
      </b-button>
    </div>
  </div>
</template>

<style scoped>

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

@media screen and (max-width: 998px) {

  .proof-timeline-sidebar {
    margin-top: 10px;
  }
}

</style>
