<script>
import users from "../components/settings/setting-accountUsers.vue";
import usersRoles from "../components/settings/setting-usersRoles.vue";
import teams from "../components/settings/setting-teams.vue";

export default {
  name: "UsersPage",
  components: {
    users,
    usersRoles,
    teams
  },
  methods: {
    setRoles: function(roles) {
      this.$refs.users.setRoles(roles);
      this.$refs.users.updateUsers(false);
    },
    setTeams: function(teams) {
      this.$refs.users.setTeams(teams);
    },
    loadTeams: function() {
      this.$refs.teams.loadTeams(true);
    },

  },
  data() {
    return {
      user: this.$A.Auth
    }
  }
}
</script>


<template>
  <div class="nav-margin">
    <navigation></navigation>
    <div>
      <b-tabs
          active-nav-item-class="font-weight-normal border-bottom border-success border-left-0 border-right-0 border-top-0 text-success"
          active-tab-class="text-success"
          justified
          nav-class="bg-white pt-0 d-flex"
          content-class="container-fluid px-0"
      >
        <b-tab title="Users" active>
          <template #title>
            <span class="font-weight-normal">Users</span>
          </template>
          <users ref="users" @updated="loadTeams"/>
        </b-tab>
        <b-tab title="Users Roles">
          <template #title>
            <span class="font-weight-normal">Users Roles</span>
          </template>
          <users-roles @updated="setRoles"/>
        </b-tab>
        <b-tab title="Teams">
          <template #title>
            <span class="font-weight-normal">Teams</span>
          </template>
          <div>
            <teams ref="teams" @updated="setTeams"/>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <!--    <v-tour-->
    <!--        name="automation-archive"-->
    <!--        :steps="steps"-->
    <!--        :callbacks="tourCallbacks"-->
    <!--    ></v-tour>-->
  </div>
</template>

<style scoped>

::v-deep .nav-tabs .nav-link {
  margin-bottom: -2px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

::v-deep .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 0;
  list-style: none;
}

::v-deep .nav > li > a {
  position: relative;
  display: block;
  padding: 19px 15px;
  font-weight: bold;
  color: #444444;
}

@media (max-width: 500px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
  }
}

@media (max-width: 750px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  ::v-deep .nav-link {
    display: block;
    padding: 0.4px 0.8px 14px 0.8px !important;
  }

  ::v-deep .nav-tabs .nav-link {
    margin-top: 10px !important;
  }

}

@media (min-width: 1500px)  and (max-width: 1700px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1998px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
}

@media (min-width: 1998px) and (max-width: 2194px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 500px !important;
    padding-right: 500px !important;
  }
}

@media (min-width: 2194px) and (max-width: 4000px) {
  ::v-deep .nav {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
    list-style: none;
    padding-left: 600px !important;
    padding-right: 600px !important;
  }
}


</style>