<script>
import ApproverSettings from "../approver-item/approverSettings";
import Vue from "vue";

export default {
  name: "modalEditContact",
  props: {
    instanceId: {
      required: false,
      type: String,
    },
    index: {
      required: false,
      type: Number,
    }
  },
  components: {
    ApproverSettings,
  },
  mounted() {
    let self = this;
    if (this.$props.instanceId !== undefined) {
      this.ActualinstanceId = this.$props.instanceId;
    }

    this.$root.$on("modal::edit::contact", function (approver) {
      self.edit(approver);
    });
  },
  data: function () {
    return {
      ActualinstanceId: this.$A.GetUUID(),
      curApprover: null,
    };
  },
  methods: {
    close: function () {
      this.approver = false;
      this.$bvModal.hide(this.ActualinstanceId);
    },
    save: async function () {
      let editApproverInstance = _.get(this.$refs, 'editApproverInstance');
      if (editApproverInstance) await editApproverInstance.save(this.ActualinstanceId);
      this.$bvModal.hide(this.ActualinstanceId);
    },
    edit: function (approver) {
      this.$bvModal.show(this.ActualinstanceId);
      let s = this;
       Vue.nextTick(function () {
        let editInstance = _.get(s.$refs, 'editApproverInstance');
        if (editInstance) editInstance.edit(approver);
      });
    },
    approverCreated: function (approver) {
      // console.log("should emit approverCreated")
      this.$emit("approverCreated", approver);
    }
  },
};
</script>

<template>
  <!-- Edit Approver Information Modal -->
  <b-modal :id="ActualinstanceId" size="lg">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="w-100 px-4 pt-4" style="margin-bottom: 20px">
        <div class="header-text">Contact Information</div>
        <span class="sub-text">Add or update the contact's information.</span>
      </div>
    </template>

    <approver-settings ref="editApproverInstance" v-on:approverCreated="approverCreated" :index="index + 1"/>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
            class="gray-btn"
            @click="close()"
        >Cancel
        </b-button
        >
        <b-button
            class="green-btn"
            @click="save()"
        >Save
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.modal-body {
  min-height: 300px;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 9px 17px !important;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  margin-bottom: 20px;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

::v-deep .modal-header {
  padding: 0px !important;
}

::v-deep .modal-body {
  padding: 24px !important;
}

</style>
