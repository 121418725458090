<script>
export default {
  name: "resetPassword",
  methods: {
    async updatePassword() {
      await this.$A.UserService.UpdatePassword(
          this.curPass,
          this.pass,
          this.passConfirm
      );
      this.curPass = "";
      this.pass = "";
      this.passConfirm = "";
    },
  },
  mounted() {
  },
  data: function () {
    return {
      curPass: "",
      pass: "",
      passConfirm: "",
    };
  },
};
</script>

<template>
  <!-- Field -->
  <div class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5 px-2">
      <div class="field-item border col-md-10 mt-4">
        <div class="header-text mb-3 px-4 pt-4 pb-3">Manage Password</div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-0 px-4">
              <label class="label-text mb-0">Your Current Password</label>
              <input
                  type="password"
                  name="currentPassword"
                  class="form-control set-password-input mb-3"
                  v-model="curPass"
              />
            </div>
            <div class="form-group mb-0 px-4">
              <label class="label-text mb-0">Your New Password</label>
              <input
                  type="password"
                  name="newPassword"
                  class="form-control set-password-input mb-3"
                  v-model="pass"
              />
            </div>
            <div class="form-group mb-0 px-4 pb-4">
              <label class="label-text mb-0">Confirm Your New Passwords</label>
              <input
                  type="password"
                  name="confirmNewPassword"
                  class="form-control set-password-input"
                  v-model="passConfirm"
              />
            </div>
            <div class="password-footer">
              <a class="green-btn mobile-btn-display" @click="updatePassword">Update</a>
            </div>
          </div>
        </div>
      </div>
  </div>
  <!-- /Field -->
</template>

<style scoped>

.field-item h3 {
  font-size: 18px;
}

.field-item {
  padding: 0px;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.label-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.set-password-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.green-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019255;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.green-btn:hover {
  opacity: 0.9;
}


@media screen and (max-width: 766px) {

  .mobile-btn-display {
    width: 100%;
  }

}

/deep/ .form-group {
  margin-bottom: 10px !important;
}

.password-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 24px;
  background: #F4F4F6;
  height: 62px;
}

</style>
