<script>
import confirmationModal from "../../components/modals/modal-confirmation.vue";
import apiTokenModal from "../form/api-token";


export default {
  name: "zapierConnections",
  methods: {
    load: async function () {
      let connections = await this.$A.ZapierService.GetAll();


      if (Array.isArray(connections.data)) {
        this.connections = connections.data;
      }
    },
    deleteItem(id, name) {
      this.toDelete.id = id;
      this.toDelete.name = name;
      this.$bvModal.show("deleteZapierConnection");
    },
    async actuallyDelete() {
      if (this.toDelete.id > 0) {
        await this.$A.ZapierService.DeleteConnection(this.toDelete.id);
        await this.load();
      }
    },
    createNew: function () {
      let win = window.open(
          "https://zapier.com/developer/public-invite/104519/5da9898b92b5ea9b3d463cf4704873cd/",
          "_blank"
      );
      win.focus();
      // let win = window.open(window.$A.Env.apiHost+'/Zapier/authorize-user?access_token='+window.$A.Auth.apiToken.token, '_blank');
      // win.focus();
    },
    get(object, path, defaultValue) {
      return _.get(object, path, defaultValue);
    },
    onClickPluginHandler(product) {
      window.open(product.link, '_blank');
    }
  },
  computed : {
    shouldShowTokenModals: function () {
      const sku = _.get($A, 'Auth.account.subscriptionState.sku', []);
      const countOfRedeemedCodesOfTheSameSku = _.get($A, 'Auth.account.subscriptionState.countOfRedeemedCodesOfTheSameSku', 0);

      return sku.indexOf('api') > 0 || (sku.indexOf('enterprise-code-stacking-1') !==-1 && countOfRedeemedCodesOfTheSameSku === 10)
    },
  },
  mounted: async function () {
    await this.load();
  },
  data() {
    return {
      connections: [],
      fields: [
        {
          key: 'user',
          label: "USER",
        },
        {
          key: 'time',
          label: "TIME",
        },
        {
          key: 'delete',
          label: ""
        }
      ],
      toDelete: {
        id: 0,
        name: "",
      },
      products: [
        {
          logo: '/img/logo.svg',
          title: 'Ashore for Illustrator & InDesign',
          icons: [
            { src: '/img/adobe-Illustrator.svg', alt: 'Illustrator Logo' },
            { src: '/img/adobe-InDesign.svg', alt: 'InDesign Logo' }
          ],
          link: 'https://exchange.adobe.com/apps/cc/201477/ashore-for-indesign-and-illustrator'
        },
        {
          logo: '/img/logo.svg',
          title: 'Ashore for Photoshop',
          icons: [
            { src: '/img/adobe-Photoshop.svg', alt: 'Photoshop Logo' }
          ],
          link: 'https://exchange.adobe.com/apps/cc/67e7bd11/ashore-for-photoshop'
        }
      ]
    };
  },
  components: {
    confirmationModal,
    apiTokenModal,
  },
};
</script>

<template>
  <div style="background-color: #eef2f5!important" class="text-dark d-flex justify-content-center flex-wrap pb-5">
    <!-- API -->
    <b-card class="col-sm-10 api-card mt-4" v-show="shouldShowTokenModals">
      <b-card-body>
        <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 api-info">API Information</b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 mt-4 mb-1 api-info">User Token</b-card-text>
        <!--  Tokens -->

        <!--  API Token -->
        <div
            class="form-group ml-4 mr-4"
            v-show="shouldShowTokenModals"
        >
          <apiTokenModal :api-token="get($A, 'Auth.apiToken.token', '')"/>
        </div>
        <b-card-text class="mx-md-3 pl-md-2 mt-4 mb-1 api-info">Refresh Token</b-card-text>
        <div
            class="form-group ml-4 mr-4"
            v-show="shouldShowTokenModals"
        >
          <apiTokenModal :api-token="get($A, 'Auth.apiToken.refreshToken', '')"/>
        </div>
        <!-- Buttons -->
        <div
            class="api-footer d-flex justify-content-end mt-4" v-show="shouldShowTokenModals">
          <b-button
              :href="$A.Env.apiHost + '/swagger'"
              target="nk"
              class="view-api-documentation px-2"
          >API Endpoints
          </b-button>
          <b-button
              href="https://kb.ashoreapp.com/how-do-i-use-access-tokens-and-refresh-tokens/"
              target="nk"
              class="ml-2 view-api-documentation px-2"
          >API Guide
          </b-button>

        </div>
      </b-card-body>
    </b-card>
    <b-card class="col-sm-10 p-0 mt-4 bg-ashore-white">
      <b-card-body class="m-3">
        <img src="/img/adobe-logo.svg" alt="Adobe Logo" class="h-8"/>
        <h4 class="api-info mt-2">Download Ashore on the Adobe Exchange</h4>
        <div class="row mt-4">
          <div v-for="(product, index) in products" :key="index" class="col-md-6 col-xl-4 mb-3">
            <div style="cursor: pointer" class="d-flex flex-column border rounded-lg bg-white shadow-sm h-100" @click="onClickPluginHandler(product)">
              <div class="d-flex align-items-center pt-3 px-3">
                <img :src="product.logo" :alt="product.title" class="logo"/>
                <div class="flex-grow-1">
                  <h5 class="mb-1 api-info">{{ product.title }}</h5>
                </div>
              </div>
              <div class="mt-2 px-4 py-2">
                <div class="d-flex justify-content-between align-items-center border-top py-3">
                  <div class="mb-0 api-info">
                    <span>For</span>
                    <img v-for="(icon, i) in product.icons" :key="i" :src="icon.src" :alt="icon.alt" class="ml-2 plugin-icon"/>
                  </div>
                  <img src="/img/chevron-right-icon.svg" alt="Right arrow"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </b-card-body>
    </b-card>
    <!-- Zapier -->
    <b-card class="col-sm-10 zapier-card mt-4">
      <b-card-body>
        <b-card-text class="mb-2 mx-md-3 mt-3 pl-md-2 api-info">Zapier Integration</b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 mb-4 api-sub-text">You can create a new connection to <a
            href="https://zapier.com" target="_blank">Zapier</a> in this section.
        </b-card-text>
        <b-table class="mt-3 px-4 rounded zapier-table" :items="connections" :fields="fields" responsive hover outlined
                 thead-class="zapier-table-bg" v-show="connections.length > 0">
          <template #head()="data" class="bg-secondary">
            <span class="font-weight-normal">{{ data.label }}</span>
          </template>
          <template #cell(user)="data">
            <span class="text-dark font-weight-normal" style="opacity: 0.90">{{ data.item.connectionName }}</span>
          </template>
          <template #cell(time)="data">
            <span class="text-dark font-weight-normal" style="opacity: 0.90">{{ data.item.creationDate }}</span>
          </template>
          <template #cell(delete)="data">
            <div class="mt-2">
              <a class="font-weight-normal" style="color: #006F41"
                 @click="deleteItem(data.item.id, data.item.connectionName)">Delete</a>
            </div>
            <confirmationModal
                v-on:confirmed="actuallyDelete"
                ref="confirmDelete"
                name="deleteZapierConnection"
                confirm-button-text="Delete Zapier Connection"
                question="Are you sure you would like to delete this zapier connection?"
                :body="
          'This will prevent all active zaps from functioning that are using this connection. </br/> </br/> <b>Deleting Connection:</b> ' +
          toDelete.name
        "
            />
          </template>
        </b-table>
        <div class="api-footer border-top-0 d-flex col-sm-12 mb-0 justify-content-end">
          <a @click="load()" class="button zapier-btn zapier-btn-white">Refresh</a>
          <a @click="createNew()" class="button zapier-btn ml-2 mr-1 zapier-btn-green text-white">Add Connection</a>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>

.zapier-btn:hover, .view-api-documentation:hover {
  opacity: 0.5;
  transition: all 0.5s ease;
}

.zapier-card, .api-card {
  padding: 0px !important;
}

.api-card {
  margin-bottom: 0px;
}

.zapier-table {
  border-radius: 2rem !important;
}

.zapier-btn {
  display: block;
  cursor: pointer;
  padding: 8px !important;
  font-weight: normal !important;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  border: 1px solid #B5B5B8;
  height: 38px;
  transition: all 0.5s ease;
}

.zapier-btn-green {
  background: #019256;
}

.zapier-btn-white {
  background: #F9F9F9;
  color: #3E3E40;
}

.api-info {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.api-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.api-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  color: #575759;
}

.view-api-documentation {
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  border: 1px solid #B5B5B8;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-size: 14px;
  color: #3E3E40;
}

.logo {
  margin-right: 8px;
  height: 30px;
  width: 30px;
}

.plugin-icon {
  width: 22px;
  height: 22px;
}

::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0rem;
}

/deep/ .zapier-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
}

@media (max-width: 500px) {
  ::v-deep .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
}

</style>
