<script>
export default {
  name: "modal-verify-smtp-email.vue",
  props: {
    eventToEmitOnConfirmation: {
      required: false,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      user: this.$A.Auth,
      oldNoReplyEmail: _.get(this, '$A.Auth.account.noReplyEmail', ""),
      newNoReplyEmail: _.get(this, '$A.Auth.account.noReplyEmail', ""),
      oldWhiteLabelDomainName: _.get(this, '$A.Auth.account.whiteLabelDomainName', ""),
      newWhiteLabelDomainName: _.get(this, '$A.Auth.account.whiteLabelDomainName', ""),
      validated: false,
      authStatus: {
        postmarkInfo: {
          enabled: true,
          confirmed: true,
          emailAddress: "",
        },
        sendMethodType: 1,
        smtpServerInfo: null
      },
      displayMethodType: "1",
      smtpEmailAddress: "",
      userName: "",
      password: "",
      serverAddress: "",
      serverPort: 587,
      isMobile: window.innerWidth < 991,
      serverWarningMsg: ""
    };
  },
  methods: {
    openModal() {
      this.open();
    },
    open() {
      this.$bvModal.show(this.name);
    },
    modalConfirmed() {
      if (this.eventToEmitOnConfirmation !== undefined)
        this.$root.$emit(this.eventToEmitOnConfirmation);
      this.$emit("confirmed");
      this.$bvModal.hide(this.name);
    },
    connectEmailServer() {
      this.serverWarningMsg = "Attempting to connect to your email server...";
      this.$A.AccountService.SaveAccountSmtpSendMethod({
        userName: this.userName,
        password: this.password,
        serverAddress: this.serverAddress,
        serverPort: this.serverPort
      }).then(res => this.modalConfirmed());
    },
    reset() {
      this.serverWarningMsg = "";
      this.$emit("cancel-verification");
    }
  },
  mounted() {
    this.reset();
  },
};
</script>

<template>
  <div>
    <b-modal :id="name" size="lg" @hidden="reset">
      <template v-slot:modal-header>
        <div class="col-md-12 px-0">
          <div class="modal-header-text">Check Your Inbox for a Verification Email...</div>
          <div class="sub-header-text my-3 text-justify">
            <p>
              We’ve sent an email to the inbox you specified. Please check your email and confirm your sender signature
              by selecting the button in the email.<span class="font-weight-bold"> This modal will close when you confirm your sender signature.</span>
            </p>
            <p>
              <span class="font-weight-bold">Pro Tip:</span>
              Check your spam email if you do not see the verification. If
              you do not receive an email in either inbox, please check your SMTP settings and try again.
            </p>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100 col-md-12 d-flex justify-content-md-end px-0">
          <b-button
              class="button gray-btn"
              @click="cancel"
          >Cancel Verification
          </b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  width: 18%;
}

.gray-btn:hover {
  color: white;
  cursor: pointer;
}

.modal-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

::v-deep .modal-header {
  padding: 24px !important;
}

.sub-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759
}

.labels {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #707073;
}

.email-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  border: 1px solid #B5B5B8;
}

.light-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 200px;
  height: 38px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.server-warning-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #019256;
  margin-left: 10px;
}

@media (max-width: 576px) {

  .mobile-view {
    margin-top: 20px;
  }
}

</style>
