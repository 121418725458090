<script>
export default {
  name: "PreviewEmailTemplate",
  components: {},
  props: {
    htmlBody: {
      required: true,
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    open: function() {
      this.$bvModal.show("PreviewEmailTemplate");
    }
  },
};
</script>

<template>
  <!-- Add Note Modal -->
  <b-modal id="PreviewEmailTemplate" size="lg" scrollable>
    <template v-slot:modal-header>
      <alertHeader />
      <div class="w-100">
        <div class="row">
          <div class="col-md-12" style="margin-bottom: 25px">
            <h4>Email Template Preview</h4>
          </div>
        </div>
      </div>
    </template>

    <iframe
        id="emailViewBox"
        :srcdoc="htmlBody"
        allowfullscreen
        class="email_frame"
        frameborder="0"
    ></iframe>

    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
            class="button button-dark button-gray normal-button"
            @click="cancel()"
        >Close</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.email_frame {
  width: 100%;
  min-height: 800px;
}

#emailViewBox {
  pointer-events: none;
}
.help-note {
  position: relative;
  margin: 0 0 25px;
  padding: 1.5em;
  background: #d4ead9;
  border-radius: 10px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.778em;
}

.help-note h4 {
  display: inline-block;
  position: absolute;
  top: -1.45em;
  left: 1.25em;
  margin: .625em 62.5em 0 0;
  padding: .313em;
  font-size: 16px;
  font-size: 1rem;
  border: 2px solid #267048;
  border-radius: 5px;
  color: #267048;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  min-width: 100px;
  text-align: center;
}

.help-note p {
  margin-bottom: 0px;
}
</style>
