<script>
import moment from "moment-timezone";
import { getAccountLogo } from "../utils/helpers";
import { SSO_PROVIDERS } from "../utils/constants";

const { MICROSOFT, GOOGLE } = SSO_PROVIDERS;

export default {
  components: {},
  data() {
    return {
      newAccount: {
        organization: "Ashore",
        name: "",
        email: "",
        title: "Your Title",
        phone: "",
        password: "",
        password_confirm: "",
        use_terms_accepted: false,
        data_terms_accepted: false,
      },
      errors: [],
      errorMessage: "",
      variant: "warning",
      validated: false,
      password_match: false,
      submited: false,
      working: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      signupH1: "",
      signupHeadline: "Create Your Free Ashore Account",
      codePlaceHolder: "Your Code",
      isCodeSignup: false,
      affiliate: 1,
      code: "",
      processing: false,
    };
  },
  methods: {
    ssoClicked: function(ssoMethod){
      window.$A.MarketingService.SSOPreSignupEvent();
      const anchor = document.createElement('a');
      anchor.target = '_blank';
      anchor.innerText = 'SSO';
      const currentDomain = window.location.hostname;
      if(ssoMethod === GOOGLE){
        anchor.href = `${window.$A.Env.apiHost}/login/sso/${GOOGLE}?domain=${currentDomain}`;
      }

      if(ssoMethod === MICROSOFT){
        anchor.href = `${window.$A.Env.apiHost}/login/sso/${MICROSOFT}?domain=${currentDomain}`;
      }
      document.body.appendChild(anchor);
      anchor.click();
    },
    getParamsAsObject: function() {
      const urlParams = new URLSearchParams(window.location.search);
      const paramsObject = {};
      for (const [key, value] of urlParams.entries()) {
        paramsObject[key] = value;
      }
      return paramsObject;
    },
    resetSubmitted: function () {
      let self = this;
      setTimeout(() => {
        self.submited = false;
      }, 5000)
    },
    SignUp() {
      this.checkForm();
      if (!this.allFormElementsValid() || this.password_match.length === 0) {
        this.showDismissibleAlert = true;
        this.resetSubmitted();
        this.showAlert();
        return;
      }

     window.$A.MarketingService.AshoreAuthSignUpEvent(
         this.newAccount.name,
         this.newAccount.organization,
         this.newAccount.email,
         this.newAccount.title,
         this.newAccount.phone);

      let paramsJsonString = JSON.stringify(this.getParamsAsObject());

      let response = window.$A.UserService.Register(
          this.newAccount.organization,
          this.newAccount.name,
          this.newAccount.email,
          this.newAccount.password,
          this.newAccount.phone,
          this.newAccount.title,
          moment.tz.zone(moment.tz.guess()).name,
          this.code,
          paramsJsonString
      );



      if (response.message !== undefined) {
        this.showDismissibleAlert = true;
        this.resetSubmitted();
        this.errorMessage = response.message;
        this.showAlert();
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validatePhoneNumber: function (phone) {
      let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(phone);
    },
    inputValid: function (val) {
      return val ? "has-success" : "has-error";
    },
    allFormElementsValid: function () {
      return (
          this.newAccount.organization &&
          this.newAccount.organization.length !== 0 &&
          this.newAccount.name &&
          this.newAccount.name.length !== 0 &&
          this.newAccount.phone &&
          this.newAccount.phone.length !== 0 &&
          this.newAccount.title &&
          this.newAccount.title.length !== 0 &&
          this.newAccount.password &&
          this.newAccount.password.length !== 0 &&
          this.newAccount.password_confirm &&
          this.newAccount.password_confirm.length !== 0 &&
          this.newAccount.email &&
          this.newAccount.email.length !== 0
      );
    },
    checkForm: function () {
      this.errors = [];
      this.validated = true;

      //Organization
      if (!this.newAccount.organization) {
        this.errors.push("Organization required.");
      }

      //Name
      if (!this.newAccount.name) {
        this.errors.push("Name required.");
      }

      //Title
      if (!this.newAccount.title) {
        this.errors.push("Title required.");
      }

      //Email
      if (!this.newAccount.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.newAccount.email)) {
        this.errors.push('Please enter a valid email.');
      }

      //Phone
      if (!this.newAccount.phone) {
        this.errors.push("Phone required.");
      } else if (!this.validatePhoneNumber(this.newAccount.phone)) {
        this.errors.push('Please enter a valid phone.');
      }


      //Password
      if (!this.newAccount.password) {
        this.errors.push('Password required.');
      }

      // Password Confirm
      if (!this.newAccount.password_confirm) {
        this.errors.push('Confirm password required.');
      }

      // Password vs Password Confirm
      if (this.newAccount.password !== this.newAccount.password_confirm) {
        this.errors.push('Password does not match.');
      } else {
        this.password_match = true;
      }

      //Terms of service
      if (!this.newAccount.use_terms_accepted) {
        this.errors.push("Terms of Use required.");
      }

      //Terms of service
      if (!this.newAccount.data_terms_accepted) {
        this.errors.push("Privacy Policy Terms required.");
      }

      //Errors
      if (!this.errors.length) {
        this.submited = true;
        return true;
      }
    },
  },
  computed: {
    accountLogo: function () {
      return getAccountLogo();
    }
  },
  beforeMount() {
    let query = this.$route.query;

    if (this.$route.path.indexOf("appsumo") !== -1) {
      this.isCodeSignup = 1;
      this.affiliate = 1;
      this.phone = "000.000.0000";
      this.signupH1 = "AppSumo";
      this.codePlaceHolder = "Your AppSumo Code";
      this.signupHeadline = "Create Your New Account";
      this.code = query.c.toLowerCase();
    } else if (query.c !== undefined) {
      this.isCodeSignup = 1;
      this.code = query.c.toLowerCase();
    }
  },
  mounted() {
    let self = this;
    
    this.$root.$on("alert::user", (message, type, timeout) => {
      self.errorMessage = message;
      self.variant = type;
      self.showDismissibleAlert = true;
      self.showAlert();
    });
  },
};
</script>

<template>

  <form class="signup-page registration-page" role="form" @submit="checkForm()">
    <div class="container-fluid">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-lg-4 col-md-5 m-auto">
          <div class="generic-sidebar-auth text-center">
            <div v-if="accountLogo" class="ashore-logo-registration">
                <img :src="accountLogo" class="account-logo"/>
              <img
                  v-show="signupH1 !== ''"
                  class="partner-image"
                  src="/img/appsumo.png"
                  alt="AppSumo"
              />
            </div>
            <div class="register-header-text my-4">{{ signupHeadline }}</div>
            <div class="form-group">
              <a  @click="ssoClicked('google')">
                <img src="/img/google-login-btn.svg" class="svg-btns"/>
              </a>
            </div>
            <div class="form-group">
              <a  @click="ssoClicked('microsoft')">
                <img src="/img/ms-login-btn.svg" class="svg-btns"/>
              </a>
            </div>
            <div class="auth-or-container form-group">
              <div class="auth-or">Or</div>
              <hr class="auth-or-line"/>
            </div>

            <div
                :class="['form-group', inputValid(newAccount.organization)]"
                v-show="false"
            >
              <input
                  type="text"
                  name="organization"
                  autocomplete="new-password"
                  class="form-control"
                  placeholder="Your Organization"
                  v-model="newAccount.organization"
              />
            </div>
            <label class="auth-sub-text d-flex">Your Name</label>
            <div cy-data="name"
                 :class="['form-group', inputValid(newAccount.name)]">
              <input
                  type="text"
                  name="name"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (newAccount.name !== '' ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.name"
              />
            </div>
            <label class="auth-sub-text d-flex">Your Email</label>
            <div cy-data="email" class="form-group">
              <input
                  type="text"
                  name="email"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (validEmail(newAccount.email) ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.email"
              />
            </div>
            <label class="auth-sub-text d-flex">Your Phone Number</label>
            <div
                :class="['form-group', inputValid(newAccount.phone)]"
                v-show="false"
            >
              <input
                  type="text"
                  name="title"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (newAccount.title !== '' ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.title"
              />
            </div>
            <div
                cy-data="phone"
                v-show="newAccount.phone !== '000.000.0000'"
                :class="['form-group', newAccount.phone]"
            >
              <input
                  type="text"
                  name="phone"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (validatePhoneNumber(newAccount.phone) ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.phone"
              />
            </div>
            <label class="auth-sub-text d-flex">Your Password</label>
            <div
                cy-data="password"
                :class="[
                  'form-group',
                  inputValid(newAccount.password && newAccount.password_confirm),
                ]"
            >
              <input
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (newAccount.password !== '' ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.password"
              />
            </div>
            <label class="auth-sub-text d-flex">Confirm Password</label>
            <div
                cy-data="password_confirm"
                :class="[
                  'form-group',
                  inputValid(newAccount.password_confirm === newAccount.password),
                ]"
            >
              <input
                  type="password"
                  name="password_confirm"
                  autocomplete="new-password"
                  :class="'form-control ' + (validated ? (newAccount.password_confirm !== '' && (newAccount.password_confirm === newAccount.password) ? ' is-valid': ' is-invalid') : ' auth-input')"
                  v-model="newAccount.password_confirm"
                  v-on:keyup.enter="SignUp()"
              />
            </div>
            <div :class="['form-group']" v-show="isCodeSignup === 1">
              <input
                  type="text"
                  autocomplete="Code"
                  class="form-control auth-input auth-sub-text"
                  name="code"
                  :placeholder="codePlaceHolder"
                  v-model="code"
              />
            </div>
            <div class="form-group" v-show="!password_match && validated">
              <span>Passwords do not match.</span>
            </div>

            <div
                id="terms-container"
                class="d-flex flex-wrap"
            >
              <b-form-checkbox
                  id="use_terms_checkbox"
                  v-model="newAccount.use_terms_accepted"
                  name="use_terms_checkbox"
              >
                <label class="font-weight-normal terms-style" style="text-align: start"> Yes, I have read and consent
                  to the
                  <a href="https://ashoreapp.com/terms-of-use/" target="_blank" style="color:#019256">
                    Terms of Use
                  </a> and
                  <a href="https://ashoreapp.com/terms-of-service/" target="_blank" style="color:#019256">
                    Terms of Service
                  </a>.
                </label>
              </b-form-checkbox>
              <b-form-checkbox
                  id="data_terms_checkbox"
                  v-model="newAccount.data_terms_accepted"
                  class="font-weight-normal"
                  name="data_terms_checkbox"
              >
                <label class="font-weight-normal terms-style" style="text-align: start">
                  Yes, I have read and consent to the use of my data as described in Ashore's <a
                    href="https://ashoreapp.com/privacy/" target="_blank" style="color:#019256">Privacy Policy</a>.
                </label>
              </b-form-checkbox>
            </div>
            <b-button :disabled="!newAccount.use_terms_accepted || !newAccount.data_terms_accepted"
                      data-cy="signUpButton"
                      @click="SignUp()"
                      id="signup_button"
                      class="auth-green-btn button w-100 mt-4"
            >
              <b-spinner
                  variant="success"
                  v-show="working"
                  type="grow"
                  small
              ></b-spinner>
              Create Your Account
            </b-button>
            <b-alert
                :show="dismissCountDown"
                dismissible
                :variant="variant"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
                style="margin-top: 25px"
                cy-data="alertErrorSignUp"
            >
              <!-- Errors List -->
              <p v-if="errors.length">
              <ul>
                <li class="col-12 text-left" v-for="(errorMessage, index) in errors" :key="errorMessage.id">
                  {{ errorMessage }}
                </li>
              </ul>
              </p>
              <b-progress
                  variant="warning"
                  :max="dismissSecs"
                  :value="dismissCountDown"
                  height="4px"
              ></b-progress>
            </b-alert>
            <a href="/" class="white-auth-btn button w-100 mt-3">Login Instead</a>
          </div>
        </div>
      </div>
    </div>
  </form>

</template>

<style scoped>
.account-logo {
  width: 50px;
  height: 50px;
}

.generic-sidebar {
  height: 762px;
  overflow: hidden;
}

.generic-sidebar {
  height: auto;
}

.partner-image {
  width: 100%;
  max-width: 250px;
  margin: 25px auto;
}

.terms-style {
  opacity: 98 !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
}

.auth-or {
  background: white;
  width: 40px;
  left: calc(50% - 29px);
  text-align: center;
  position: absolute;
  top: -10px;
}

.auth-or-container {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.register-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}

.auth-green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
}

.white-auth-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3E3E40;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  padding: 9px 17px;
  background: #F9F9F9;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.auth-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

.auth-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.generic-sidebar-auth {
  background: #ffffff;
  border-radius: 10px;
  padding: 32px 40px;
}

.auth-or-line {
  height: 1px;
  background: #D1D5DB;
}

.svg-btns {
  width: -webkit-fill-available;
}


</style>