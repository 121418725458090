<script>
export default {
  name: "reviewLink",
  props: {
    proofLinkId: {
      required: true,
      type: String,
    }
  },
  data: function () {
    return {};
  },
  methods: {
    CopyToClipBoard: function () {
      this.$A.CopyStringToClipboard(this.reviewLink);
      this.$A.AlertUser(
        "The Review Link Has Been Copied To Your Clipboard!",
        "success"
      );
      this.$bvModal.hide("reviewLink");
    },
  },
  computed: {
    reviewLink: function () {
      if($A.Env.envName === "local"){
        return `http://localhost:8080/review/${this.$props.proofLinkId}`
      }
      if ($A.Auth.account.whiteLabelDomainName !== null) {
        return `https://${$A.Auth.account.whiteLabelDomainName}/review/${this.$props.proofLinkId}`;
      }
      return `https://${window.location.hostname}/review/${this.$props.proofLinkId}`;
    },
  },
};
</script>

<template>
  <b-input-group class="col-lg-12">
    <b-form-input id="proof_copy_link" :value="reviewLink"></b-form-input>
    <b-input-group-append>
      <b-button
        variant="copy-link"
        v-b-tooltip.click
        title="Copied to Clipboard!"
        @click="CopyToClipBoard()"
        >Copy</b-button
      >
    </b-input-group-append>
  </b-input-group>
</template>

<style scoped>

.btn-copy-link {
  color: #ffffff;
  padding: 0 25px;
  background-color: #019255;
  border-color: #019255;
}

.btn-copy-link:hover {
  color: #ffffff !important;
  background-color: #0c390e;
  transition: all 0.5s ease;
}
</style>
