<script>
export default {
  name: "modalEditName",
  components: {},
  props: {
    proofName: {
      required: true,
      type: String,
    },
  },
  watch: {
    proofName: function ($new) {
      if ($new !== "" && this.name === "") {
        this.name = $new;
      }
    },
  },
  data() {
    return {
      name: "",
    };
  },
  async mounted() {},
  methods: {
    saveAndClose: function () {
      this.$emit("proof_name_update", { name: this.name });
      this.$bvModal.hide("modalEditName");
    },
  },
};
</script>

<template>
  <!-- Edit Deadline Modal -->
  <b-modal id="modalEditName" size="lg">
    <template v-slot:modal-header>
      <alertHeader />
      <div class="w-100">
        <div class="header-text">Edit Proof Name</div>
      </div>
    </template>
    <form role="form">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-0">
            <input
              v-model="name"
              type="text"
              data-cy="editProofNameInput"
              placeholder="Proof Name"
              class="form-control edit-proof-input"
            />
          </div>
        </div>
      </div>
    </form>
    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
          class="button gray-btn"
          @click="cancel()"
          >Cancel</b-button
        >
        <b-button
          class="button green-btn ml-2"
          @click="saveAndClose()"
          >Update</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.edit-proof-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 13px 1px 13px;
  height: 38px;
  background: white;
  border: 1px solid #B5B5B8;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .modal-body {
  padding: 20px 24px 24px 24px;
}

/deep/ .modal-header {
  padding: 24px 24px 0px 24px;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

</style>
