<script>
export default {
  name: "commentThread",
  components: {},
  props: {
    event: {
      require: true,
      type: Object
    },
    proofId: {
      required: true,
      type: Number,
    },
  },
  methods: {
  },
  mounted: function () {
  },
  computed: {
    commentAnnotation: function() {
      return _.get(this.event, "appEventObject.commentAnnotation") || _.get(this.event, "appEventObject.additionalData.commentAnnotation");
    },
    contextImage: function () {
      return _.get(this.commentAnnotation, "contextImageUrl");
    },
    proofFileAnnotationComments: function() {
      const proofFileAnnotationComments = _.get(this, "commentAnnotation.proofFileAnnotationComments", []);
      return proofFileAnnotationComments.filter((each) => each.proofId === _.get(this, '$props.proofId', -1) && each.proofFileId === _.get(this, 'event.appEventObject.proofVersionFile.id'));
    },
    firstComment: function () {
      return _.get(this, "proofFileAnnotationComments[0]");
    },
    subComments: function () {
      const comments = _.get(this, "proofFileAnnotationComments", []);
      return comments.slice(1);
    },
    shouldDisplayContextImage: function () {
      return !!this.contextImage;
    },
    getCurFileName: function () {
      return _.get(this.commentAnnotation, "proofVersionFile.name", "");
    }
  },
  data() {
    return {}
  },
};

</script>

<template>
  <div class="d-flex gap-12 flex-wrap flex-md-nowrap">
    <div class="" v-if="contextImage && shouldDisplayContextImage">
      <img :src="contextImage" width="200" class="context-image"/>
    </div>
    <div :class="'d-flex flex-column flex-fill justify-content-start'">
      <!--  main comment -->
      <div :class="'mb-2 ' + ((contextImage) ? 'comment-text':'')">
        <span v-html="firstComment.text" class="font-weight-normal text-gray-900 comments-text"></span>
        <div class="event-text font-weight-normal text-gray-500">
          {{ firstComment.description }}
          <span class="event-span">Said {{ firstComment.ownerName }} on {{
              $A.FormatDate(firstComment.createdAt, true)
            }} {{ firstComment ? ('on ' + getCurFileName) : '' }}</span>
        </div>
      </div>
      <!-- end main comment -->

      <!-- comment thread  -->
      <div class="comment-text d-flex flex-column thread-reply" v-for="comment in subComments">
        <span class="event-text font-weight-normal mb-0 text-gray-900" v-html="comment.text"></span>
        <span class="event-span font-weight-normal mb-0 text-gray-500">Said {{ comment.ownerName }} on {{
            $A.FormatDate(comment.createdAt, true)
          }}</span>
      </div>
      <!-- end comment thread -->

    </div>
  </div>

</template>

<style scoped>

.comment-text {
  line-height: 20px;
}

.thread-reply {
  padding : 16px 24px;
}

.context-image {
  border: 0.5px solid #707073;
  border-radius: 4px;
  width: 200px;
}

@media (max-width: 786px) {
  .context-image {
    width: 70%;
    justify-content: center;
    object-fit: contain;
  }
}

::v-deep .comment-text img {
  max-width: 100%;
}

::v-deep .comments-text img {
  max-width: 100%;
}

::v-deep .comment-text > span > p {
  margin-bottom: 0px !important;
}

</style>