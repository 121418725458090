<script>
export default {
  name: "creditcard",
  methods: {
    open: function () {
      this.$bvModal.show("creditcard");
      let self = this;
      setTimeout(function () {
        // console.log(self.$refs.cardElementDiv);
        if (self.$refs.cardElementDiv.childNodes.length === 0) {
          let elements = self.stripe.elements();
          let style = {
            base: {
              color: "#32325d",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          };
          self.stripeCardElement = elements.create("card", { style: style });
          self.stripeCardElement.mount("#card-element2");
          self.stripeCardElement.addEventListener("change", function (event) {
            let displayError = document.getElementById("card-errors2");
            if (event.error) {
              displayError.textContent = event.error.message;
            } else {
              displayError.textContent = "";
            }
          });
        }
      }, 1);
    },
    updateCard: async function () {
      let self = this;
      this.stripe
        .createToken(this.stripeCardElement)
        .then(async function (result) {
          if (result.error) {
            // Inform the user if there was an error.
            let errorElement = document.getElementById("card-errors2");
            errorElement.textContent = result.error.message;
          } else {
            let currentPaymentMethod = await self.$A.BillingService.SetCurrentCardViaToken(
              result.token.id
            );
            if (
              currentPaymentMethod.success !== undefined &&
              !currentPaymentMethod.success
            ) {
              self.$A.AlertUser(currentPaymentMethod.message, "warning", 10000);
              self.$bvModal.hide("creditcard");
            } else {
              self.$A.AlertUser(
                "Credit Card Has Been Updated",
                "success",
                10000
              );
              self.$bvModal.hide("creditcard");
            }
          }
        });
    },
  },
  data: function () {
    return {
      stripeCardElement: null,
      stripe: null,
    };
  },
  mounted() {
    let self = this;
    let stripeScript = document.createElement("script");
    stripeScript.setAttribute("src", "https://js.stripe.com/v3/");
    document.head.appendChild(stripeScript);
    stripeScript.onload = function () {
      self.stripe = Stripe(self.$A.Env.stripeKey);
    };
  },
};
</script>

<template>
  <div>
    <b-modal id="creditcard" size="lg">
      <template v-slot:modal-header>
        <alertHeader />
        <div class="w-100">
          <h4>Edit Credit Card</h4>
          <p>Update Your Credit Card</p>
        </div>
      </template>

      <form action="#" method="post" id="payment-form2">
        <div class="form-row">
          <div id="card-element2" ref="cardElementDiv">
            <!-- A Stripe Element will be inserted here. MISSING STRIPE JAVASCRIPT - CODY -->
          </div>
          <!-- Used to display form errors. -->
          <div id="card-errors2" role="alert"></div>
        </div>
      </form>

      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100">
          <b-button
            class="button button-dark button-gray normal-button"
            @click="cancel()"
            >Cancel</b-button
          >
          <b-button
            class="button button-light button-green normal-button"
            @click="updateCard"
            >Update</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>
.StripeElement {
  box-sizing: border-box;
  width: 100%;
  background: #f9f9f9;
  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#card-element2 {
  border: 1px solid #444444;
}
</style>
