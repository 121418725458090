<script>
import createWorkflowItem from "../automation-archive/create-workflow-item";

export default {
  name: "workflowlist",
  components: {
    createWorkflowItem
  },
  async mounted() {
    let self = this;
    await this.getAllWorkflows();

    this.$root.$on("workflow_list::delete::workflow::confirmed", async function () {
      await self.$A.WorkflowService.Delete(self.deleteThis);
      self.deleteThis = null;
      await self.getAllWorkflows();
    });
    this.$root.$on("workflow_list::duplicate::workflow::confirmed", async function () {
      // console.log(self.duplicateThis);
      await self.$A.WorkflowService.Duplicate(self.duplicateThis);
      self.duplicateThis = null;
      await self.getAllWorkflows();
    });
    this.$root.$on("workflow_item:saved:successfully", async function () {
      self.editThis = 0;
      await self.getAllWorkflows();
    })
    this.$root.$on("workflow_item:canceled", function () {
      self.editThis = 0;
    });
  },
  data() {
    return {
      workflows: [],
      deleteThis: null,
      duplicateThis: null,
      editThis: null,
      fields: [
        {
          key: 'workflowinfo',
          sortable: false,
          label: "WORKFLOWS"
        },
        {
          key: 'edit',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'duplicate',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
        {
          key: 'delete',
          sortable: false,
          label: "",
          thStyle: {width: "1%"}
        },
      ],
      mode: "workflow",
      isMobile: window.innerWidth <= 767,
    };
  },
  computed: {
    sortedWorkflows: function () {
      function compare(a, b) {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      }

      return this.workflows.sort(compare);
    }
  },
  methods: {
    getAllWorkflows: async function () {
      var workflows = await this.$A.WorkflowService.GetAll();
      if (Array.isArray(workflows)) this.workflows = workflows;
    },
    editWorkflow: function (workflowId) {
      this.editThis = workflowId;
      this.$root.$emit("edit::workflow::from::automation");
    },
    updateMode: function () {
      this.$root.$emit("create::workflow::from::automation");
    }
  },
};
</script>

<template>
  <div style="background-color: #eef2f5!important"
       class="on-mobile text-dark d-flex justify-content-center flex-md-wrap pb-5">
    <b-card class="mt-3 p-0 ml-lg-4 mr-md-3 h-75 col-sm-10 account-workflow-card">
      <b-card-body>
        <b-card-text class="mb-2 mx-md-3 mt-4 pl-md-2 pl-xs-2 pl-sm-4 text-dark account-workflows-header">Workflows</b-card-text>
        <b-card-text class="mx-md-3 pl-md-2 pl-sm-4 description-text mb-4 pl-xs-4">Workflows allow you to create proof approval
          sequences. You can create workflows here and append them to your proofs at any time!
        </b-card-text>
        <!-- Workflows Table -->
        <b-table :class="isMobile ? 'mt-3 px-4 rounded workflow-table table-borderless mb-0': 'mt-3 px-4 rounded workflow-table table-borderless mb-4'"
                 :items="workflows"
                 :fields="fields"
                 responsive hover outlined
                 thead-class="workflow-table-bg"
        >
          <template #head()="data">
            <span class="table-header-text">{{ data.label }}</span>
          </template>
          <template #cell(workflowinfo)="data">
            <div class="row-cols-9">
              <span class="column-header-text">{{ data.item.name }}</span>
            </div>
          </template>
          <template #cell(edit)="data">
            <div>
              <a class="font-weight-normal edit-delete-workflow"
                 v-b-toggle.create-workflow-sidebar-right
                 @click="editWorkflow(data.item.id)"
              >
                Edit
              </a>
            </div>
          </template>
          <template #cell(duplicate)="data">
            <div>
              <a class="font-weight-normal edit-delete-workflow"
                 v-b-modal.workflow_list_duplicateWorkflowModal
                 @click="duplicateThis = data.item.id"
              >
                Duplicate
              </a>
            </div>
          </template>
          <template #cell(delete)="data">
            <div>
              <a class="font-weight-normal edit-delete-workflow"
                 :data-cy="`delete-${data.item.name}`"
                 v-b-modal.workflow_list_deleteWorkflowModal
                 @click="deleteThis = data.item.id"
              >
                Delete
              </a>
            </div>
          </template>
        </b-table>
        <!-- Modals -->
        <modal
            name="workflow_list_deleteWorkflowModal"
            eventToEmitOnConfirmation="workflow_list::delete::workflow::confirmed"
            question="Delete This Workflow?"
            body="Are you sure you want to delete this workflow? You cannot undo this action."
            confirm-button-text="Yes, Delete"
        />
        <modal
            name="workflow_list_duplicateWorkflowModal"
            eventToEmitOnConfirmation="workflow_list::duplicate::workflow::confirmed"
            question="Duplicate This Workflow?"
            confirm-button-text="Yes, Duplicate"
            body="Create a copy of this workflow."
        />
      </b-card-body>
      <div :class="isMobile ? 'w-100 border-top-0 mb-0 px-4 py-3': 'border-top-0 d-flex mb-0 justify-content-end table-footer'">
        <div>
          <a
              class="button button-light button-green workflows-btn mb-0"
              v-b-toggle.create-workflow-sidebar-right
              @click="updateMode()"
          >
            Create Workflow</a>
          <!--  Create Workflow Right Sidebar  -->
          <createWorkflowItem :workflow-id="editThis" :hide-current-stage-active="true"/>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style scoped>

/deep/ .table th, .table td {
  vertical-align: none;
}

.field-item h3 {
  font-size: 18px;
}

.workflow-button img {
  height: 15px;
  margin-left: 10px;
}

::v-deep .account-workflow-card .card-body {
  padding: 0px;
}

.account-workflows-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3E3E40;
}

.description-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

/deep/ .workflow-table {
  border-radius: 4px !important;
}

::v-deep .workflow-table .table thead th {
  border-bottom: 0px;
  padding: 12px 24px;
}

::v-deep .workflow-table table td {
  padding: 16px 24px !important;
  vertical-align: middle !important;
}

/deep/ .workflow-table-bg {
  background: #F4F4F6;
  opacity: 0.50;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.column-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

.edit-delete-workflow {
  color: #006F41;
}

.edit-delete-workflow:hover {
  cursor: pointer;
}

.workflows-btn {
  padding: 9px 17px !important;
  font-weight: normal;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.05);
  border-radius: 6px;
}

.table-footer {
  padding: 12px 24px;
  background: #F4F4F6;
  color: #575759;
}

@media (max-width: 576px) {

  .button-locked-workflows {
    background: #F44271;
    color: #ffffff;
    transition: all .5s ease;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .workflows-btn {
    display: block;
    padding: 8px !important;
    border-radius: 5px;
    font-weight: normal !important;
  }

  .account-workflows-header {
    margin-left: 24px;
  }

  .description-text {
    margin-left: 24px;
    margin-right: 24px;
    text-align: justify;
  }

}

</style>










