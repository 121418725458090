<script>
import reviewLink from "../../components/form/review-link";
import createWorkflowItem from "../../components/automation-archive/create-workflow-item";

export default {
  name: "proofSent",
  components: {
    reviewLink,
    createWorkflowItem
  },
  props: {
    proof: {
      required: true,
      Type: Object,
    },
    selectedWorkflow: {
      required: false,
      Type: Boolean
    }
  },
  methods: {
    addWorkflow: function () {
      // this.state = 2;
      this.$A.NavigateToView("/proof-item", {proof: this.proof, selectedWorkflow: true});

    },
    selectExistingWorkflow: async function () {
      this.state = 3;
      this.existingWorkflows = await this.$A.WorkflowService.GetAll();
    },
    getSelectedWorkflow: function () {
      let self = this;
      return this.existingWorkflows.filter(function (w) {
        return w.id === self.selectedWorkflowId;
      })[0];
    },
    chooseWorkflowAndContinue: async function () {
      if (this.selectedWorkflowId === 0) {
        this.$A.AlertUser("Please Select A Workflow Before Continuing");
        return;
      }
      if (this.selectedWorkflowId > 0) {
        const proofId = _.get(this, 'proof.id', 0);
        if (proofId > 0) { 
          await this.$A.ProofService.AttachWorkflowToProof(
            proofId,
            this.selectedWorkflowId
          );
          this.$A.NavigateToView("/proof-item", {proof: this.proof, selectedWorkflow: true});
        }
      }
    },
    refreshIsMobile: function () {
      this.isMobile = window.innerWidth < 994;
    }
  },
  data() {
    return {
      state: 1,
      existingWorkflows: [],
      selectedWorkflowId: 0,
      proofReviewLinkType: "approver-link",
      displayWorkflowCard: true,
      isMobile: window.innerWidth < 994
    };
  },
  async mounted() {
    window.onresize = this.refreshIsMobile();
    // await this.selectExistingWorkflow();
  },
  computed: {
    sortedArray: function () {
      function compare(a, b) {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      }

      return this.existingWorkflows.sort(compare);
    },
    reviewLinkId: function () {
      if (this.$props.proof.reviewerPublicId && this.proofReviewLinkType == "reviewer-link") {
        return this.$props.proof.reviewerPublicId;
      }
      return this.$props.proof.publicId;
    }
  }
};
</script>

<template>
  <!-- Modal Proof Sent Modal -->
  <b-modal id="proofCreated" size="lg" no-stacking no-close-on-backdrop v-if="displayWorkflowCard">
    <template v-slot:modal-header>
      <alertHeader/>
      <div class="row" v-show="state === 1">
        <div class="col-12">
          <div class="header-text">Your Proof Has Been Created</div>
          <div class="sub-text mt-2">You can copy the below review link and share with contacts, or add an
            workflow to this proof.
          </div>
        </div>
        <div class="col-12 d-flex pt-4 m-auto flex-wrap">
          <div class="custom-control custom-radio col-lg-6 pr-0">
            <input
                type="radio"
                id="approver-proof-link"
                name="approver-link"
                value="approver-link"
                class="custom-control-input"
                v-model="proofReviewLinkType"
            />
            <label
                class="custom-control-label pb-2 checkbox-text"
                for="approver-proof-link">Approver Link</label>
            <p class="text-justify text-sm-left sub-text" style="padding: 0px 24px 0px 0px">
              Share this link with approvers, allowing them to sign-off and receive automatic reminders.
            </p>
          </div>
          <div class="custom-control custom-radio col-lg-6 pr-0">
            <input
                type="radio"
                id="non-approver-proof-link"
                name="reviewer-link"
                value="reviewer-link"
                class="custom-control-input"
                v-model="proofReviewLinkType"
            />
            <label class="custom-control-label pb-2 checkbox-text" for="non-approver-proof-link">Comment Only
              Link</label>
            <p class="text-justify text-sm-left sub-text" style="padding: 0px 24px 0px 0px">
              Share this link with commenters, allowing them to view and make comments only.
            </p>
          </div>
        </div>
      </div>
      <div class="w-100 modal-head" v-show="state === 2">
        <div class="header-text">Select Workflow or Create New</div>
        <div>You can create a brand new workflow or select from a pre-existing one.</div>
      </div>
      <div class="w-100" v-show="state === 3">
        <div class="header-text">Select Workflow</div>
        <div class="sub-text mt-2">Select the workflow to apply to this proof. You’ll have an opportunity
          to review and modify before sending the proof to your contacts.
        </div>
      </div>
    </template>

    <reviewLink :proofLinkId="reviewLinkId" v-show="state === 1"/>

    <div class="row justify-content-md-center mb-50" v-show="state === 2">
      <div class="col-md-3">
        <a @click="selectExistingWorkflow()">
          <div class="workflow-button">
            <img src="/img/workflow-icon.svg"/>
            Select Workflow
          </div>
        </a>
      </div>
      <div class="col-md-3">
        <a v-b-toggle.create-workflow-sidebar-right>
          <div class="workflow-button">
            <img src="/img/create-workflow-icon.svg"/>
            Create Workflow
          </div>
        </a>
      </div>
    </div>
    <create-workflow-item :input-proof="proof"
                          :workflow-id="selectedWorkflowId"
                          :hide-current-stage-active="false"
    />
    <form role="form" v-show="state === 3">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Select Workflow</label>
            <select
                required="required"
                class="form-control form-select"
                v-model="selectedWorkflowId"
            >
              <option value="0">Select Workflow</option>
              <option
                  v-for="workflow in sortedArray"
                  :value="workflow.id"
              >
                {{ workflow.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <template v-slot:modal-footer>
      <div
          :class="isMobile ? 'w-100 d-flex flex-column align-items-center ml-0': 'w-100 d-flex flex-row justify-content-end align-items-center'">
        <b-button
            v-show="state === 1"
            @click="addWorkflow()"
            :class="isMobile ? 'mb-2 gray-btn': 'gray-btn'"
        >
          Add Workflow
        </b-button>
        <b-button
            @click="$A.NavigateToView('/proof-item', { proof: $props.proof })"
            :class="isMobile ? 'ml-0 green-btn': 'green-btn ml-2'"
            v-show="state === 1">
          View Proof
        </b-button
        >
        <b-button
            class="green-btn"
            v-show="state === 2"
            @click="state = 1"
        >Back
        </b-button
        >
        <b-button
            class="green-btn"
            v-show="state === 3"
            @click="state = 2"
        >Back
        </b-button
        >
        <b-button
            :disabled="selectedWorkflowId === 0"
            @click="chooseWorkflowAndContinue()"
            class="green-btn"
            v-show="state === 3"
        >Next
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.modal-head {
  text-align: center;
  margin: 50px 0 25px;
}

.workflow-button {
  background: #ffffff;
  border-radius: 6px;
  padding: 50px 25px 25px;
  border: 1px solid #121c4d;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
}

.workflow-button:hover {
  background: rgba(68, 68, 68, 0.05);
  transition: all 0.5s ease;
}

.workflow-button img {
  display: block;
  margin: 0 auto 25px;
  height: 50px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #019255 !important;
}

.custom-control-input ~ .custom-control-label::before {
  border-color: #019255 !important;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 10px !important;
  color: white !important;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
  background: lightgray;
}

.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
}

.sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
}

/deep/ .modal-header {
  padding: 24px 24px 0px 24px !important;
}

.checkbox-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #252526;
}

/deep/ .modal-body {
  padding: 10px 24px 24px 24px !important;
}

.input-group {
  padding: 0px !important;
}

@media screen and (max-width: 994px) {

  /deep/ .modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 12px 24px;
    height: 100% !important;
    background: #F4F4F6;
  }

  .gray-btn {
    width: 100% !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 17px;
    height: 38px;
    filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
    border-radius: 6px;
    padding: 10px !important;
    background: lightgray;
  }

  .green-btn {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 17px;
    height: 38px;
    background: #019256;
    box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
    border-radius: 6px;
    border-radius: 6px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 10px !important;
  }
}


</style>
